<!DOCTYPE html>

<body class="static-contant pt-0">
    <section class="ver-sec-eml pt-0">
        <div class="container1440">
          <div class="Aboutus00">
            <div class="support">
                <form [formGroup]="changePasswordForm" novalidate autocomplete="off">
                <div class="form1_div pb-0">
                    <h2>Change Password</h2>
                    <div class="spt_section">
                        <label>Old password</label>
                        <input type="password" class="form-control sppt" formControlName="oldPassword" maxlength="16" placeholder="Please enter old password">
                        <div class="error" *ngIf="oldPassword.invalid && (oldPassword.dirty || oldPassword.touched)">
                            <div *ngIf="oldPassword.errors.required" class='danger' style="color: red" padding>
                                *Please enter existing password.
                            </div>
                        </div>  
                    </div>

                    <div class="spt_section">
                        <label>New password</label>
                        <input type="password" maxlength="16" formControlName="newPassword" class="form-control sppt" placeholder="Please enter new password">
                        <div class="error" *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)">
                            <div *ngIf="newPassword.errors.required" class='danger' style="color: red" padding>
                                *Please enter new password.
                            </div>
                            <div *ngIf="newPassword.errors.pattern && !newPassword.errors.minlength" class='danger' padding>
                                *Password must be alphanumeric including special characters(~! @ # $ % ^ & * _ - + = , : ; ’ ” /
                                <> ? \ |).
                             </div>
                             <div *ngIf="newPassword.errors.minlength" class='danger' padding>
                                *Password must be atleast 8 characters.
                             </div>
                        </div>
                    </div>

                    <div class="spt_section">
                        <label>Re-Enter password</label>
                        <input type="password" class="form-control sppt" formControlName="confirmPassword" maxlength="16" placeholder="Re-Enter password">
                        <div class="error" *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                            <div *ngIf="confirmPassword.errors.required" class='danger' style="color: red" padding>
                                *Please re-enter your password
                            </div>
                            <div *ngIf="confirmPassword.errors?.mismatch" style="color: red">*Confirm password should be same as password.</div>
                        </div>  
                    </div>





                    <div class="send_button">
                        <button data-toggle="modal"  [disabled]="!changePasswordForm.valid" (click)="changeFunc()">SUBMIT</button>
                    </div>
                </div>
            </form>
            </div>
          </div>
        </div>
    </section>
    <app-footer></app-footer>
    <!-- Modal -->
    <!-- <div class="modal fade" id="changepass" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body your_problem">
                    Your problem has been sent, one of our customer care executive will contact you soon!
                </div>
            </div>
        </div>
    </div>
</body> -->