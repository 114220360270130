import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-wallet-trading-limit',
  templateUrl: './wallet-trading-limit.component.html',
  styleUrls: ['./wallet-trading-limit.component.css']
})
export class WalletTradingLimitComponent implements OnInit {
  kycdata: any;

  constructor(public service: ServiceService) { }

  ngOnInit(): void {
    this.checkStatus()
  }
  checkStatus() {
    this.service.getCandyPixelForms('account/get-all-kyc-details').subscribe(res=>{
      this.kycdata = res['data']['kycStatus']
      console.log(this.kycdata);
      
    })
   
  }
}
