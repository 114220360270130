<div class="static-contant">
    <section>
        <div class="container1440">
            <div class="Aboutus00 mt-5">
                <div class="contactBying">
                    <h4 style="padding-bottom: 0px;margin-left: 16%">Trade id #{{tradeId}}</h4>
                </div>
                <div class="wrapper center-block">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="" style="padding: 0 17px;">
                            <div class="">
                                <div class="center-box-s">
                                    <div class="tym-count">
                                        <p id="demo"></p>
                                    </div>
                                    <span class="tradeStatus" style="color: #b96c18;">Send a message to
                                        admin</span>

                                    <div class="typMess">
                                        <div class="statusTradeInputChat">
                                            <input type="text" class="form-control chatInput" (keyup.enter)="sendChat()"
                                                placeholder="Enter your message here" id="usr" [(ngModel)]="obj.chat"
                                                autocomplete="off">
                                            <div class="chat-attachment-img-box" style="cursor: pointer;">
                                                <input type="file" class="statusTradeUploadChat"
                                                    (change)="handleFileInput1($event)">
                                                <img src="../../../assets/images/attached.png" alt=""
                                                    class="chat-attachment-img">
                                            </div>
                                        </div>
                                        <div class="sendMessage"
                                            style="opacity: 0.9;margin-left: -3px; cursor: pointer;"
                                            (click)="sendChat()">Send</div>
                                    </div>
                                    <div class="statusTrade">
                                        <div class="chat-container" #scrollMe style="overflow-y: auto; height: 300px;">
                                            <div *ngFor="let data of server.chatArr">
                                                <div *ngIf="myId != data.toUserId" style="padding: 17px 5px 0 12%;">
                                                    <div class="right-chat-box"
                                                        style="box-shadow: 0px 0px 1px #fff;padding-left: 10px;">
                                                        <div class="userDate">
                                                            <p style="color: #b96c18;">{{profileData?.firstName}}</p>
                                                            <p style="color: #b96c18;">{{currentDate | date}}</p>
                                                        </div>
                                                        <p *ngIf="data.messageFormat == 'TEXT' && data.message"
                                                            style="color: #b96c18;">{{data?.message}}</p>
                                                        <img class="img_def" target="_blank" [src]="data.message"
                                                            *ngIf="data.messageFormat == 'IMAGE'" alt="">
                                                    </div>
                                                </div>
                                                <div *ngIf="myId == data.toUserId" style="padding: 17px 12% 0 5px;">
                                                    <div class="left-chat-box"
                                                        style="box-shadow: 0px 0px 1px #fff;padding-left: 10px;">
                                                        <div class="userDate">
                                                            <p style="color: #b4b7d9;">Admin</p>
                                                            <p style="color: #b4b7d9;">{{data?.creationTime | date}}</p>
                                                        </div>
                                                        <p *ngIf="data.messageFormat == 'TEXT' && data.message"
                                                            style="color: #b4b7d9;">{{data?.message}}</p>
                                                        <img class="img_def" *ngIf="data.messageFormat == 'IMAGE'"
                                                            target="_blank" [src]="data.message" alt="">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>