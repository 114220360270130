import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {
  termsData: any;

  constructor(public service: ServiceService) { }

  ngOnInit() {
    this.getTerms()
  }

  getTerms(){ 
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=TERMS%20AND%20CONDITION').subscribe(res=>{
           if (res['status']==200){
             this.termsData=res['data'].pageData;
           }
    })
   }

   getTerms1(){ 
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=TERMS%20AND%20CONDITION').subscribe(res=>{
           if (res['status']==200){
             this.termsData=res['data'].pageData;
           }
    })
   }
}
