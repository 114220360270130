<!DOCTYPE html>
  <body>
    <section class="mainBody">
      <div class="container1440">
      <div class="log-in">
        <div class="bg-crypt">
        
          </div>

            <div class="crypto-log-login left">
              <form class="login-field-sec">
                <div class="verifyEmail"><img src="assets/images/email_verify.png" alt=""></div>
                <p class="log" style="padding-bottom: 0;">Email Verification</p>
                <p class="emailMessage">We've sent an email to your registered email
                    address: pq***@***.com Follow the instructions 
                    in the email to get started!</p>
                <div class="form-group inpt-frm">
                 <input type="email" class="form-control email-pass" placeholder="Enter registered email address">
                </div>
                <div>
                <p class="accSign">If you haven't received the email, Please try the following</p>
                <label class="containerRadio">Make sure the email address you provided is correct.
                    <input type="radio" checked="checked" name="radio">
                    <span class="checkmark"></span>
                  </label>
                  <label class="containerRadio">Check your Spam or Junk mail folders.
                      <input type="radio"  name="radio">
                      <span class="checkmark"></span>
                    </label>
                    <label class="containerRadio">Change email provider.
                        <input type="radio"  name="radio">
                        <span class="checkmark"></span>
                      </label>
                      <label class="containerRadio">Make sure your email is functioning normally.
                          <input type="radio"  name="radio">
                          <span class="checkmark"></span>
                        </label>
                  
                </div>
              </form>
               
             

            </div>
        </div>         
         </div> 
         <div class="modal fade" id="puzzle" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content fit-pzz">
              <div class="modal-header puzzl">
                <img src="assets/images/crypto-8.png" alt="">
              </div>
              <div class="modal-body crypto-00">
                <img src="assets/images/crypto-9.png" alt="">
              </div>
              <div class="modal-footer puzzl">
                <img src="assets/images/crypto-11.png" alt="">
                <img src="assets/images/crypto-10.png" alt="">
              </div>
            </div>
          </div>
        </div>
    </section>
  </body>
  <app-footer></app-footer>