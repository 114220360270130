<!DOCTYPE html>

<body class="static-contant">
    <section>
        <div class="container1440">
            <div class="security">
                <h6 class="google">Security > Google Authentication</h6>
                <div class="e-bled">
                    <h1>Enabled Google Authenticator</h1>
                </div>
                <div class="download-app">
                    <ul>
                        <li class="active">Download App
                            <p class="active">1</p>
                        </li>
                        <li class="active scan">
                            Scan QR Code
                            <p class="active">2</p>
                        </li>
                        <li class="active scan1">
                            Backup Key
                            <p class="active">3</p>
                        </li>
                        <li>
                            Enabled Google Authenticator
                            <p class="active">4</p>
                        </li>
                    </ul>
                </div>

                <div class="step">
                    <h3>Step 4</h3>
                    <p>Enable your Google Authenticator.</p>
                </div>

                <div class="login-password">
                    <div class="login">
                        <label>Login Password</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="google_code">
                        <h6>Google Authentication Code</h6>
                        <ul>
                            <li> <input type="text" class="form-control"></li>
                            <li> <input type="text" class="form-control"></li>
                            <li> <input type="text" class="form-control"></li>
                            <li> <input type="text" class="form-control"></li>
                            <li> <input type="text" class="form-control"></li>
                            <li> <input type="text" class="form-control"></li>
                        </ul>
                    </div>
                </div>
                <div class="pre"><a (click)="back()">
                        << Prev Step</a> </div> <div class="send_button">
                            <button (click)="next()">SUBMIT</button>
                </div>
            </div>
        </div>
    </section>
</body>