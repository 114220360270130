<footer>
    <div class="container1440">
        <div class="row centerdd">
            <div class="col-lg-3 col-md-12">
                <div class="logo-cover">
                    <!-- <img src="assets/images/crypto-logo.png" alt=""> -->
                    <img src="assets/images/logo.svg" alt="" style="height:70px">
                    <p>With a proven track record and a mature approach to the industry, we provide reliable trading of crypto-currencies.
                    </p>
                </div>
                <!-- <div class="dwnload-div">
                  <a (click)="navigateToUrl('playstore')">
                     <img src="assets/images/google-play.png">
                  </a>
                  <a (click)="navigateToUrl('appstore')">
                    <img src="assets/images/app-store.png">
                 </a>
               </div> -->
            </div>

            <div class="col-lg-3 col-md-4 col-6">
                <h5 class="learn-h5">Learn</h5>
                <ul class="fotterlist">
                    <li [routerLink]="['/aboutus']" >About Us</li>
                    <li [routerLink]="['/privacypolicy']" >Legal &amp; Privacy</li>
                    <li [routerLink]="['/support']" >Support</li>
                    <li [routerLink]="['/contactus']" >Contact Us</li>
                    <li [routerLink]="['/terms-conditions']" >Terms & Conditions</li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-4  col-6">
                <h5>Services</h5>
                <ul class="fotterlist" style="cursor: pointer;">
                    <li [routerLink]="['/advanced-exchange']">Buy Bitcoin</li>
                    <li [routerLink]="['/advanced-exchange']">Buy Bitcoin Cash</li>
                    <li [routerLink]="['/advanced-exchange']">Buy Ethereum</li>
                    <li [routerLink]="['/advanced-exchange']">Buy Litecoin</li>
                    <li [routerLink]="['/fees']" >Our Fee</li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-4  col-12">
                <!-- <h5>CONTACT</h5>
                <ul class="fotterlist fotterlist2">
                    <li>+91 525 2525 2252</li>
                    <li>contact@qte.com</li>
                    <li>Okhla Phase 1</li>
                </ul> -->

                <h5 class="mt-3">Community</h5>
                <div class="community-div">
              <a (click)="navigateToSocialMedia('facebook')">
                <i class="fa fa-facebook" aria-hidden="true" ></i>
              </a>
              <a (click)="navigateToSocialMedia('twitter')">
                <i class="fa fa-twitter" aria-hidden="true"></i>
              </a>
              <a (click)="navigateToSocialMedia('telegram')">
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </a>
              <a (click)="navigateToSocialMedia('instagram')">
                <i class="fa fa-instagram" aria-hidden="true"></i>
              </a>
              <a (click)="navigateToSocialMedia('youtube')">
                <i class="fa fa-youtube" aria-hidden="true"></i>
              </a>

                </div>
            </div>


        </div>
    </div>
    <p class="copyright">@qte-All rights reserved 2021</p>
</footer>
