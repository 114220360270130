<!DOCTYPE html>

<body class="static-contant pt-0">
  <section class="wraper">
    <div class="container1440">
      <div class="row pt-5">
        <div class="col-lg-3 col-md-12">
          <div class="card-div">
            <div class="cad-box">
              <ul>
                <li *ngFor="let item of coinList">
                  <figure><img class="coinLogo" [src]="item.coinImage" alt=""></figure>
                  <div class="card-text" (click)="selectcoin(item.coinShortName)" style="cursor: pointer;">
                    <h6>{{item?.coinFullName}} ( {{item.coinShortName}} )</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-md-12">
          <div class="wallet-div ">
            <ul>
              <li>WITHDRAW HISTORY</li>
            </ul>
            <div class="table-responsive mt-4 pb-2">
              <table class="table after-logintabel action" aria-describedby="">
                <thead>
                  <tr>
                    <th id="">DATE</th>
                    <th id="">AMOUNT</th>
                    <th id="">ADDRESS </th>
                    <th id="">TXN HASH</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let withdraw of withdrawList| paginate: { itemsPerPage: 10, currentPage: withdrawPageNumber, totalItems: total , id:'withdraw' } ; let i= index;">
                    <td>{{withdraw.txnTime | date :'short'}}</td>
                    <td>{{withdraw.units ? withdraw?.units : '---'}}</td>
                    <td>{{withdraw.address ? withdraw.address : '---'}}</td>
                    <td>{{withdraw.txnHash ? withdraw?.txnHash : '---'}}</td>
                  </tr>
                  <tr *ngIf="withdrawList == ''">
                    <td colspan="4"> No transaction found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <pagination-controls id="withdraw" (pageChange)="getWithdrawPage($event)">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</body>
<app-footer></app-footer>