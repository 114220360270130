
<!DOCTYPE html>
<body class="static-contant pt-0">
  <section>
    <div class="container1440">
      <div class="Aboutus00 confirmation_section mt-5">
          <img src="assets/images/confirmation.png"> 
          <h3>successfully</h3>
          <h6>transaction ID :- 58745852178966321747</h6>
      </div>
   </div>
 </section>
</body>
<app-footer></app-footer>