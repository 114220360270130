<section class="static-contant ">
  <!-- <div class="main-div currency">
    <div class="container1440 container1441" >
      <div class="slick_slider">
        <div class="main_slider slider ">
          <div class="custom-width custom-margin" *ngFor="let data of coinList; let i=index">
            <div class="commonstyle bacground-img"
              [ngClass]="(data.marketPriceInUsd24HourBefore > 0) ? 'bacground-img1' : 'bacground-img'">
              <div class="card-img">
                <img [src]="data.coinImage" alt="">
                <div class="uppertext">
                  <p class="leftcoin">{{data.coinFullName}}</p>
                  <div>{{data.last_updated | date:'medium'}}</div>
                </div>
              </div>
              <div class="text-cenetr price-div">
                <p class="coinvalue red">{{data?.price}} USD</p>
                <label class="rytvalue redvalue">
                  <span [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green' : 'landing-red' "
                    style=" display: contents">{{data?.marketPriceInUsd24HourBefore}} %
                  </span>
                </label>
              </div>
              <div class="uppertext text-cenetr">
                <div class="text-center">24h Volume: ${{data.volume_24h}} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- slider end -->

 
  <div class="newBox">
    <div class="container1440 container1441">
      <div class="market_info">
        <div class="exchenge-section">
          <div class="container1440">
             <div class="exchenge-heading" *ngIf="marketInfo">
              <div >
                <div class="row market-tabs">
                  <div class="col-md-2 market-tabs-name" (click)="changeTab('favourateCoinDiv')" [ngClass]="{activeTab: (currentTab == 'favourateCoinDiv')} "> <i class="fa fa-star star-clicked"  ></i>  Favorites</div>
                  <div class="col-md-2 market-tabs-name">USD Markets</div>
                  <div class="col-md-2 market-tabs-name">BTC Markets</div>
                  <div class="col-md-2 market-tabs-name">ETH Markets</div>
                </div>
                <div class="table-responsive mt-4 pb-5 table-striped ">
                    <table class="table after-logintabel action" aria-describedby="">
                        <thead>
                            <tr>
                                <th id="">Market</th>
                                <th id="">Price (BTC)</th>
                                <th id="">Volume (BTC)</th>
                                <th id="">Market Cap</th>
                                <th id="">24H Change</th>
                                <th id="">Action</th>
                                <th id=""><i class="fa fa-star-o"></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            *ngFor="let data of coinList; let i=index">
                                <td>
                                    <span class="bitcointable"><img [src]="data.coinImage"alt="img" class="coinImage">
                                    </span><span class="bitcoin-btc mr-0">{{data.coinFullName}}</span> 
                                    <span class="market-usdt"> / USDT</span>
                                </td>
                                <td>
                                    ${{data?.price}}
                                        <div [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                                    {{data?.marketPriceInUsd24HourBefore}}%</div>
                                </td>
                                <td>
                                    ${{data?.price}}<div [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                                        {{data?.marketPriceInUsd24HourBefore}}%</div>
                                </td>
                                <td>
                                    <div
                                    [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                                    {{data?.marketPriceInUsd24HourBefore}}%</div>
                                </td>
                                <td>
                                    <div
                                    [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green col-md-1 col-3 mobHide' : 'landing-red col-md-1 col-3 mobHide ' ">
                                    {{data?.marketPriceInUsd24HourBefore | number : '1.6-6'}}%</div>
                                </td>
                                <td>
                                    <div class="buy-sellbtb">
                                        <button class="btn btn-table" type="button"
                                            style="background: #e5565f; border-color: #e5565f"
                                            (click)="navigateTo('p2p-buy',order,'sell')">TRADE
                                        </button>
                                    </div>
                                </td>
                                
                                <td><div class="actionButton actionButtonStar">
                                  <div *ngIf="like"><i  class="fa fa-star-o star-clicked" (click)="favourite('like' , data.coinFullName)"></i></div>
                                  <!-- <div *ngIf="dislike"><i  class="fa fa-star star-clicked"  (click)="favourite('dislike' ,data.coinFullName)" ></i> </div> -->
                                  </div>
                                </td>
                            </tr>
                            <!-- <tr class="data" *ngIf="sellOrderArr?.length == 0">
                                <td colspan="5" class="space"><strong>No record found</strong></td>
                            </tr> -->

                        </tbody>
                    </table>
                    <!-- <div *ngIf="sellTotal > 5" class="view">
                        <p>
                            <pagination-controls id='orderSell' (pageChange)="sellPaginationEvent($event)">
                            </pagination-controls>
                        </p>
                    </div> -->
                </div>
            </div>
              <!-- <div class="row market-tabs">
                <div class="col-md-2 market-tabs-name">Favorites</div>
                <div class="col-md-2 market-tabs-name">USD Markets</div>
                <div class="col-md-2 market-tabs-name">BTC Markets</div>
                <div class="col-md-2 market-tabs-name">ETH Markets</div>
              </div>
                <div class="" style="color: #fff;">
                   <div class="row headerContent  py-2">
                      <div class="col-6  col-md-2 pr-0">Market</div>
                      <div class="col-6  col-md-1 pr-0">Price (BTC)</div>
                      <div class="col-6  col-md-1 pr-0">Volume (BTC)</div>
                      <div class="col-6  col-md-1 pr-0">Market Cap</div>
                      <div class="col-6  col-md-1 pr-0">24H Change</div>
                      <div class="col-6  col-md-1  action-tab star">Action</div>
                      <div class="col-6  col-md-1  action-tab star"><i class="fa fa-star-o"></i></div>
 
                   </div>
 
                   <div class="row py-lg-3 py-2 tableText table-striped" *ngFor="let data of coinList; let i=index"
                      (click)="buysell()">
                      <div class="market-data col-6 col-md-2 ellysys">
                        <span class="bitcointable"><img [src]="data.coinImage"alt="img" class="coinImage">
                        </span><span class="bitcoin-btc">{{data.coinFullName}}</span> 
                        <span class="market-usdt"> /USDT</span>
                            </div>
                            <div class="market-data col-6  col-md-1 ">${{data?.price}}
                               <div
                                  [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                                  {{data?.marketPriceInUsd24HourBefore}} %</div>
                            </div>
                            <div class=" market-data col-6  col-md-1 ">${{data?.price}}
                               <div
                                  [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                                  {{data?.marketPriceInUsd24HourBefore}} %</div>
                            </div>
                      <div class=" market-data col-6  col-md-1 ">${{data?.price}}
                         <div
                            [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                            {{data?.marketPriceInUsd24HourBefore}} %</div>
                      </div>
                      <div
                         [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green col-md-1 col-3 mobHide' : 'landing-red col-md-1 col-3 mobHide ' ">
                         {{data?.marketPriceInUsd24HourBefore}} %</div>
 
                     
 
                      <div class=" market-data col-16  col-md-1 d-flex mobHide p-0">
                             <div class="actionButton"> <button >Trade</button></div>
                      </div>
                      <div class=" market-data star col-16  col-md-1 d-flex mobHide p-0">
                        <div class="actionButton"> <i class="fa fa-star-o star-clicked"></i></div>
                 </div>
                   </div>
                </div> -->
             </div>
<!-- ------------------------------------------------------------------favourate coin div------------------------------------------------------------- -->
             <div class="favourateCoinDiv" *ngIf="favourateCoinDiv">
              <div >
                <div class="row market-tabs">
                  <div class="col-md-2 market-tabs-name"> <i class="fa fa-star star-clicked"  ></i>  Favorites</div>
                  <div class="col-md-2 market-tabs-name">USD Markets</div>
                  <div class="col-md-2 market-tabs-name">BTC Markets</div>
                  <div class="col-md-2 market-tabs-name">ETH Markets</div>
                </div>
                <div class="table-responsive mt-4 pb-5 table-striped ">
                    <table class="table after-logintabel action" aria-describedby="">
                        <thead>
                            <tr>
                                <th id="">Market</th>
                                <th id="">Price (BTC)</th>
                                <th id="">Volume (BTC)</th>
                                <th id="">Market Cap</th>
                                <th id="">24H Change</th>
                                <th id="">Action</th>
                                <th id=""><i class="fa fa-star star-clicked"></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            *ngFor="let data of coinList; let i=index">
                                <td>
                                    <span class="bitcointable"><img [src]="data.coinImage"alt="img" class="coinImage">
                                    </span><span class="bitcoin-btc mr-0">{{data.coinFullName}}</span> 
                                    <span class="market-usdt"> / USDT</span>
                                </td>
                                <td>
                                    ${{data?.price}}
                                        <div [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                                    {{data?.marketPriceInUsd24HourBefore}}%</div>
                                </td>
                                <td>
                                    ${{data?.price}}<div [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                                        {{data?.marketPriceInUsd24HourBefore}}%</div>
                                </td>
                                <td>
                                    <div
                                    [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                                    {{data?.marketPriceInUsd24HourBefore} }%</div>
                                </td>
                                <td>
                                    <div
                                    [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green col-md-1 col-3 mobHide' : 'landing-red col-md-1 col-3 mobHide ' ">
                                    {{data?.marketPriceInUsd24HourBefore | number : '1.6-6'}}%</div>
                                </td>
                                <td>
                                    <div class="buy-sellbtb">
                                        <button class="btn btn-table" type="button"
                                            style="background: #e5565f; border-color: #e5565f"
                                            (click)="navigateTo('p2p-buy',order,'sell')">TRADE
                                        </button>
                                    </div>
                                </td>
                                
                                <td>
                                  <div class="actionButton actionButtonStar text-center">
                                   <i class="fa fa-star star-clicked"  ></i>
                                  </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>  
                </div>
            </div>
             </div>
<!-- ------------------------------------------------------------------favourate coin div end------------------------------------------------------------- -->

          </div>
       </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>