<!DOCTYPE html>

<body class="static-contant">
  <section>
    <div class="container1440">
      <div class="Aboutus00 mt-5">
        <h4>Sell Bitcoins Using IMPS</h4>
        <div class="wrapper center-block">
          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            <div class="" style="padding: 0 17px;">
              <div class="row">
                <div class="col-lg-5 col-md-12 col-sm-12" style="border: 1px solid #4f5d78;">
                  <div class="mainPrice">
                    <div class="priceTag">
                      <p class="prieList">Price:</p>
                      <p class="prieAmount">50123542365</p>
                    </div>
                    <div class="priceTag">
                      <p class="prieList">Payment Mode:</p>
                      <p class="prieAmount">IMPS bank transfer</p>
                    </div>
                    <div class="priceTag">
                      <p class="prieList">User:</p>
                      <p class="prieAmount">Amit Kumar</p>
                    </div>
                    <div class="priceTag">
                      <p class="prieList">Trade Limits:</p>
                      <p class="prieAmount">11000-20000 INR<br>Delhi, India<br>1 hour 30 Minutes </p>
                    </div>
                    <div>
                      <form [formGroup]="transactionForm" novalidate autocomplete="off">
                        <p class="wish">How may you wish to buy ?</p>
                        <div class="mainInr">
                          <div class="mainInrInput">
                            <input type="number" class="form-control" formControlName="usd_amount"
                              placeholder="0.00 USD" name="email" (keyup)="calcCoinAmount($event.target.value)">
                          </div>
                          <div class="mainInrInput">
                            <input type="number" class="form-control" formControlName="coin_amount"
                              placeholder="0.00 {{userData.coinType}}" name="email"
                              (keyup)="calcUsdAmount($event.target.value)">
                          </div>
                        </div>
                        <p class="regFur">Register to proceed further</p>
                        <div>
                          <button class="btn save-btn" (click)="sendTradeReq()"
                            [disabled]="!transactionForm.valid || disableButton">Register</button>
                        </div>
                      </form>

                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-12 col-sm-12" style="border: 1px solid #4f5d78; margin: 0 0 0 70px;">
                  <div class="cover-rightmy">
                    <div class="trade">
                      Terms of trade with Amit Kumar
                    </div>
                    <div>
                      <p class="prieAmount" style="padding: 0px 10px 0; font-size: 16px;">Lorem Ipsum is simply dummy
                        text of the printing and typesetting industry.<br>
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an<br>
                        unknown printer took a galley of type and scrambled it to make a type specimen book.<br> It has
                        survived not only five centuries, but also the leap into electronic typesetting,<br> remaining
                        essentially unchanged. It was popularised in the 1960s with the release of<br> Letraset sheets
                        containing Lorem Ipsum passages, and more recently with desktop<br> publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</body>
<app-footer></app-footer>