import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent implements OnInit {
  addAccountForm: FormGroup;

  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.formValidation()
  }
// Form Validation 
formValidation(){
  this.addAccountForm = new FormGroup({
    'accountHolderName': new FormControl('',[Validators.required,Validators.pattern(/^[a-z A-Z]*$/i)]),
    'accountNumber': new FormControl('',[Validators.required]),
    'contactNumber': new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/i)]),
    'swiftNumber': new FormControl('',[Validators.required]),
    'ibanNumber':new FormControl('',[Validators.required]),
  })
}
// Contact Us Functionality
submitFunc(){
  let data={
    'accountHolderName': this.addAccountForm.value.accountHolderName,
    'accountNumber': this.addAccountForm.value.accountNumber,
    'contactNumber': this.addAccountForm.value.contactNumber,
    'swiftNumber':this.addAccountForm.value.swiftNumber,
    'ibanNumber':this.addAccountForm.value.ibanNumber
  }
this.service.showSpinner();
this.service.postCandyPixelForms('static/submit-contact-us-request',data).subscribe(res=>{
  this.service.hideSpinner();
  if (res['status']==200){
    this.service.showSuccessMessage('Successfully submitted,we will get back to you shortly!')
    this.addAccountForm.reset();
  }
}, err=>{
  this.service.hideSpinner();
})
}
}
