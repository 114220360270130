


<div class="static-contant">

  <section class="wraper">
    <div class="container1440">
      <div class=" pt-5">
        <ul class="nav nav-pills tab-ul" id="pills-tab" role="tablist">
          <li class="nav-item tab-li" style="width: 25%;">
            <a class="nav-link tab-a " id="pills-buy-tab" data-toggle="pill" href="#pills-buy" role="tab"
              aria-controls="pills-buy" aria-selected="true" (click)="quickbuy()">Quick Buy</a>
          </li>
          <li class="nav-item tab-li">
            <a class="nav-link tab-a active" id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab"
              aria-controls="pills-sell" aria-selected="false">Quick Sell</a>
          </li>
          <li class="nav-item tab-li">
            <a class="nav-link tab-a" id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab"
              aria-controls="pills-sell" aria-selected="false" (click)="createadvertisement()">Create Advertisment</a>
          </li>

        </ul>

        <form [formGroup]="searchForm">

          <ul class="nav nav-pills tab-ul" id="pills-tab" role="tablist">
            <li class="nav-item tab-li newTabinr">
              <a class="nav-link tab-a ">
                <select name="cars" id="cars" formControlName="amount">
                  <option value="">Amount</option>
                  <option value="saab">Saab</option>
                  <option value="opel">Opel</option>
                  <option value="audi">Audi</option>
                </select></a>
            </li>
            <li class="nav-item tab-li newTabinr">
              <a class="nav-link tab-a ">
                <select name="cars" id="cars" formControlName="amountType">
                  <option value="">India</option>
                  <option value="saab">Saab</option>
                  <option value="opel">Opel</option>
                  <option value="audi">Audi</option>
                </select></a>
            </li>
            <li class="nav-item tab-li newTabinr">
              <a class="nav-link tab-a ">
                <select name="cars" id="cars" formControlName="offer">
                  <option value="">All Onlin Offers</option>
                  <option value="saab">Saab</option>
                  <option value="opel">Opel</option>
                  <option value="audi">Audi</option>
                </select></a>
            </li>
            <li class="nav-item tab-li searchButton" (click)="search()">
              <a class="nav-link tab-a ">Search</a>
            </li>

          </ul>

        </form>
        
        <ul>
          <li style="color: #de8011; margin-top: 28px; font-size: 25px">Buy Bitcoin Online</li>
        </ul>
        <div class="table-responsive mt-4 pb-5">
          <table class="table after-logintabel action" aria-describedby="">
            <thead>
              <tr>
                <th id="">Seller</th>
                <th id="">Payment Method</th>
                <th id="">Price/BTC</th>
                <th id="">Limits</th>
                <th id="">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let order of sellOrderArr | paginate: { itemsPerPage: 5, currentPage: pageNumberSell, totalItems:buyTotal , id:'orderBuy' } ; let i=index ">
                <td>{{order.userName || '---'}}</td>
                <td>{{order.cryptoCoin}}/td>

                <td>{{order.price}} USD</td>
                <td>{{order.minValue}}-{{order.maxValue}} USD</td>
                <td>
                  <div class="buy-sellbtb">
                    <button class="btn btn-table" type="button" style="background: #e5565f; border-color: #e5565f"
                      (click)="navigateTo('sell-bitcoin',order,'buy')">SELL</button>
                  </div>
                </td>
              </tr>
              <tr class="data" *ngIf="sellOrderArr.length == 0">
                <td colspan="5" class="space"><b>No Record Found!</b></td>
              </tr>
           
            </tbody>
          </table>
          <div class="view">
            <p>
              <pagination-controls id='orderBuy' (pageChange)="sellPaginationEvent($event)">
              </pagination-controls>
            </p>
          </div>
        </div>



      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>




<!-- logout modal -->
<div class="modal fade" id="logout" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">Logout</h5>
        <button type="button" class="close " data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to Logout?</p>
        <div class="btn-modal">
          <button class="btn theam-btn mr-2">Yes</button>
          <button class="btn theam-btn2 ml-2">No</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- logout modal end -->
<!-- deposit modal -->
<div class="modal fade" id="deposit" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">DEPOSIT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Coin Amount</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control after-input" placeholder="Coin Amount"
              aria-label="Recipient's username" aria-describedby="basic-addon2">
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">Max</span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>BTC address you want to send.</label>
          <input type="email" class="form-control after-input" placeholder="BTC Address">

        </div>
        <label>Bit Trade Fee : <b>$1.000</b></label><br>
        <label>Final Amount: <b> $250.00</b></label>
        <div class="btn-modal">
          <button class="btn theam-btn mr-2">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- deposit modal end -->
<!-- wallet-address modal -->
<div class="modal fade" id="wallet-address" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">WALLET ADDRESS</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input" placeholder="sjhlkskjosafskmcaf1254da1s52">
          <i class="fa fa-files-o copy" aria-hidden="true"></i>

        </div>
        <div class="qr-div mt-4 mb-3">
          <img src="assets/images/qe-code.png" alt="">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- wallet-address modal end -->

