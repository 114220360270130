import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard'
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  profileForm: FormGroup;
  profileData: any = {};
  arr: any = [];
  imageUrl: any = 'assets/images/user_un.png';
  fileData: any;

  constructor(public router: Router, public service: ServiceService, private _clipboardService: ClipboardService) {

  }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.formValidation()
    this.getProfile()
  }

  // Form Validation Functionality
  formValidation() {
    this.profileForm = new FormGroup({
      'firstName': new FormControl('', [Validators.pattern(/^[a-zA-Z]*$/i), Validators.required]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern(/^[a-z A-Z]*$/i)]),
      'email': new FormControl('', Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)),
      'mobile': new FormControl('', Validators.pattern(/^[0-9]*$/i)),
      'address': new FormControl('', [Validators.required]),
      "zipcode": new FormControl('', [Validators.required]),
      'uniqueId': new FormControl('', [Validators.required]),
    })
  }

  // Get Profile Name-Email
  getProfile() {
    this.service.showSpinner();
    this.service.getCandyPixelForms(`account/my-account`).subscribe(res => {

      if (res['status'] == 200) {
        this.profileData = res['data'];
        let index = this.arr.findIndex(x => x.country == this.profileData.country)
        // this.stateList = this.arr[index].states;
        // this.mobileData = this.profileData.phoneNo.split('-');
        this.service.name = this.profileData.firstName
        setTimeout(() => {
          this.profileForm.patchValue({
            'firstName': this.profileData.firstName,
            'lastName': this.profileData.lastName,
            'email': this.profileData.email,
            'mobile': this.profileData.phoneNo,
            'address': this.profileData.address,
            //"zipcode": this.profileData.zipCode,
            "uniqueId": this.profileData.randomId

          });
          this.service.hideSpinner();
        }, 1000)

        // if(this.profileData.country) {
        //   this.getStateList(this.profileData.country);
        // }
        if (this.profileData.imageUrl) {
          this.imageUrl = this.profileData.imageUrl != null ? this.profileData.imageUrl : 'assets/images/mark-herrera.png';
          this.service.img = this.imageUrl
        }
        //this.states =this.profileData.state
      }
      else {
        this.service.hideSpinner();
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      this.service.showErrorMessage('Server error');
    })
  }

  // Image Functionality Start Here
  uploadImg(event) {
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        this.fileData = event.target.files[0];
        this.uploadImageFunc()
        var reader = new FileReader()
        reader.onload = (e) => {
        }
      } else {
        this.service.showErrorMessage("Select only jpg,jpeg and png file.");
        self.fileData = "";
      }
    }
  }

  uploadImageFunc() {
    let formdata = new FormData()
    formdata.append('file', this.fileData)
    this.service.showSpinner();
    this.service.postCandyPixelForms('account/upload-file', formdata).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.imageUrl = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
    })
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }

  // Update Profile Data Functionality
  updateFunc() {
    var apireq = {
      "address": this.profileForm.value.address,
      "firstName": this.profileForm.value.firstName,
      "imageUrl": this.imageUrl,
      "lastName": this.profileForm.value.lastName,
      //"state": this.profileForm.value.state,
      "phoneNo": this.profileForm.value.mobile,
      "zipCode": "",
      "randomId": this.profileForm.value.uniqueId
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('account/profile-update', apireq).subscribe(res => {
      this.service.hideSpinner();
      this.getProfile();
      this.service.showSuccessMessage('User details updated');
    }, err => {
      this.service.hideSpinner();
    })
  }

  navigateToPage(val) {
    this.router.navigate([val])
  }


  // kyc() {
  //   this.router.navigate(['/kyc'])
  // }
  // security() {
  //   this.router.navigate(['/security'])
  // }
  // kyclist() {
  //   this.router.navigate(['/kyclist'])
  // }
  addaccount() {
    this.router.navigate(['/add-account'])
  }
  changepassword() {
    this.router.navigate(['/change-password'])
  }
  googleauth() {
    this.router.navigate(['/google-auth'])
  }
  verifyAccount() {
    this.router.navigate(['/kyc'])
  }

  copy() {
    // this._clipboardService.copyFromContent(this.profileForm.value.uniqueId)
    // this.service.showSuccessMessage('Unique Id copied');
    this.service.showSuccessMessage('Unique Id copied');
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.profileForm.value.uniqueId));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
}
