<h5 class="walletSubHeading">My Trading Report</h5>
<div class="table-responsive mt-2 pb-5" >
   
      <form [formGroup]="filterForm">
      <div class="col-md-12 row mt-5">
        <div class="col-md-2 form-group coinPairDiv">
          <label for="coinPair">Search by coin pair </label>
          <select class="form-control coinDrop inputAll" name="" id="" style="    padding: 2px;" formControlName="coinPair" (chnage)="filterOncoinPairChange()">
            <option value="">USDT / USD</option>
            <option value="USDT/ZAR">USDT / ZAR</option>
            <option value="USDT/NAIRA">USDT / NAIRA</option>
            <option value="QT/USD">QT / USD</option>
            <option value="QT/ZAR">QT / ZAR</option>
            <option value="QT/NAIRA">QT / NAIRA</option>
        </select>
        </div>
        <div class="col-md-3 form-group">
            <label for="fDate">From </label>
            <input class="form-control inputAll" type="date" formControlName="fromDate"  max="{{minAge | date:'yyyy-MM-dd'}}">
        </div>
        <div class="col-md-3 form-group">
            <label for="toDate">To</label>
            <input class="form-control inputAll" type="date" formControlName="toDate" [min]="this.filterForm.controls['fromDate'].value"
             [readonly] = "!filterForm.value.fromDate">
        </div>
        <div class="col-md-1 form-group search">
          <label for=""></label>
          <button class="btn theam-btn searchBtn" (click)="searchByDate()">Search</button>
        </div>
        <div class="col-md-1 form-group">
            <label for=""></label>
          <button class="btn theam-btn reset resetbtn" (click)="resetSearch()">Reset</button>
          </div>
          <div class="col-md-2 form-group export">
            <label for=""></label>
          <button class="btn theam-btn reset " (click)="exportAsCSV()">Export as CSV</button>
          </div>
    </div>
    </form>
      <table class="table after-logintabel action table-striped" aria-describedby="" >
        <thead>
          <tr>
            <th id="" >Pair</th>
            <th id=""  class=" text-center">Order Type</th>
            <th id="" class=" text-center">Volume QTE</th>
            <th id="" class="text-center">Commission % </th>
            <th id="" class=" text-center">Reward</th>
            <th id="" class=" text-center">Fees</th>
            <th id="" class=" text-center"> P/L  </th>

          </tr>
        </thead>
        <tbody class="tablebody">
          <tr >
            <td>QTE/USDT</td>
            <td>Sell QTE</td>
            <td>100</td>
            <td>0.01</td>
            <td>0.01 QTE</td>
            <td>0.001</td>
            <td>0.09</td>
          </tr>
          <tr>
            <td>QTE/USDT</td>
            <td>Buy QTE</td>
            <td>300</td>
            <td>0.41</td>
            <td>0.301 QTE</td>
            <td>0.6001</td>
            <td>0.19</td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="col-md-12 text-center">
        <span>You don’t have any trade yet</span>
      </div> -->
</div>  