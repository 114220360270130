<!DOCTYPE html>

<body>
  <section class="mainBody">
    <div class="container1440">
      <div class="log-in">
        <div class="bg-crypt">

        </div>

        <div class="crypto-log-login left">
          <form class="login-field-sec" [formGroup]="resetForm">
            <p class="log">Reset Password</p>

            <div class="form-group reg-sec">
              <label for="exampleInputPassword1" class="add"> New Password :</label>
              <input type="{{tax1Type===true ? 'text' : 'password'}}" maxlength="16" formControlName="password" class="form-control email-pass"
                placeholder="Enter new password">
              <span toggle="#password-field" (click)="eyeShow(1)" class="fa fa-fw fa-eye field-icon toggle-password pass-icon"></span>
              <span>
                <p *ngIf="resetForm.get('password').hasError('required') &&  resetForm.get('password').dirty"
                  class="danger" padding>*Please enter new password.</p>
              </span>
            </div>


            <div class="form-group reg-sec">
              <label for="exampleInputPassword1" class="add">Confirm Password :</label>
              <input type="{{tax2Type===true ? 'text' : 'password'}}" maxlength="16" formControlName="confirmpassword" class="form-control email-pass"
                placeholder="Re-Enter your password">
              <span toggle="#password-field" (click)="eyeShow(2)" class="fa fa-fw fa-eye field-icon toggle-password pass-icon"></span>
              <span>
                <p *ngIf="(resetForm.get('password').value != resetForm.get('confirmpassword').value) && resetForm.get('confirmpassword').dirty"
                  class="danger" padding>*Confirm password does not match with password.</p>
              </span>
            </div>


            <button style="margin-bottom: 25px;" type="submit" class="btn register-btn" data-toggle="modal"
               [disabled]="resetForm.invalid || resetForm.get('password').value != resetForm.get('confirmpassword').value" (click)="resetFunc()" >SUBMIT</button>

          </form>



        </div>
      </div>
    </div>
    <div class="modal fade" id="puzzle" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content fit-pzz">
          <div class="modal-header puzzl">
            <img src="assets/images/crypto-8.png" alt="">
          </div>
          <div class="modal-body crypto-00">
            <img src="assets/images/crypto-9.png" alt="">
          </div>
          <div class="modal-footer puzzl">
            <img src="assets/images/crypto-11.png" alt="">
            <img src="assets/images/crypto-10.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
</body>

<app-footer></app-footer>