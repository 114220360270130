<section class="mainBody">
  <div class="container1440">
    <div class="log-in">
      <div class="bg-crypt">
      </div>
      <div class="crypto-log-login left">
        <form class="login-field-sec" [formGroup]="loginForm">
          <p class="log">Login</p>
          <div class="form-group inpt-frm">
            <label for="exampleInputEmail1" class="add">Email Address :</label>
            <input type="email" maxlength="256" formControlName="email" class="form-control email-pass"
              placeholder="Enter registered email address">
            <span>
              <p *ngIf="loginForm.get('email').hasError('required') &&  loginForm.get('email').dirty" class="danger"
                padding>*Please enter your registered email address.</p>
              <p *ngIf="loginForm.get('email').hasError('pattern') &&  loginForm.get('email').dirty" class="danger"
                padding>*Invalid email address.</p>
            </span>

          </div>
          <div class="form-group reg-sec" *ngIf="!showEye">
            <label for="exampleInputPassword1" class="add">Password :</label>
            <input type="password" maxlength="16" formControlName="password" class="form-control email-pass"
              placeholder="Enter password">
            <span toggle="#password-field" (click)="showHidePassword()"
              class="fa fa-eye-slash field-icon toggle-password pass-icon"></span>
          </div>
          <div class="form-group reg-sec" *ngIf="showEye">
            <label for="exampleInputPassword1" class="add">Password :</label>
            <input type="text" maxlength="16" formControlName="password" class="form-control email-pass"
              placeholder="Enter password">
            <span toggle="#password-field" (click)="showHidePassword()"
              class="fa fa-eye field-icon toggle-password pass-icon"></span>
          </div>

          <div class="d-flex">
            <label class="checkbox-design" style="color: white">
              <input type="checkbox" name="remember" formControlName="remember"
                checked="checked"><span>&nbsp;</span>Remember me

            </label>
            <span class="ml-auto">
              <h6 class="for-pwrd" (click)="forgotpassword()" style="cursor: pointer">Forgot Password?</h6>
            </span>
          </div>
          <button type="button" class="btn register-btn" data-toggle="modal" [disabled]="loginForm.invalid"
            (click)="loginFunc()">LOGIN</button>
          <p class="account" (click)="signup()">Don't have an account? <span class="accSign"
              style="cursor: pointer">Sign Up</span></p>
        </form>

      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>

<!-- suggestion modal -->
<div class="modal fade" id="suggest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">
          To increase your security, please enable 2FA.</h5>
        <button type="button" class="close " data-dismiss="modal" aria-label="Close" (click)="removeToken()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="btn-modal mt-0">
          <button class="btn theam-btn mr-2" (click)="suggesstionFunc('Google')">Google Authentication</button>
          <button class="btn theam-btn2 ml-2" (click)="suggesstionFunc('sms')">SMS Authentication</button>
        </div>
        <div class="btn-modal">

          <button class="btn theam-btn mr-2 mt-2" (click)="suggesstionFunc('Skip')">Skip For Now</button>
          <p class="waringText" style="color: red;">I understand the risk for not enabling the 2FA.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- suggestion modal end -->

<!-- Google Auth Modal  -->
<div class="modal fade" id="googleAuth" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">Google Authentication Verification</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="removeToken()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="qrCode" class="qr-div mt-4 mb-3">
          <img [src]="qrCode" *ngIf="qrCode" alt="">
        </div>
        <p *ngIf="secretKey" style="padding: 0px;">Secret Key : {{secretKey}}
          <i class="fa fa-clone" aria-hidden="true" style="cursor: pointer;" (click)="copyToClipboard(secretKey)"></i>
        </p>
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input" style="text-align:center;"
            (keypress)="restrictSpace($event);restrictChar($event)" maxlength="6" placeholder="Enter the google code"
            [(ngModel)]="googleAuth">
        </div>
        <div class="btn-modal">
          <button class="btn theam-btn mr-2" [disabled]='googleAuth.length != 6'
            (click)="verifyGoogleAuth('Google')">Verify</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Google Auth Modal end  -->

<!-- SMS Auth Modal  -->
<div class="modal fade" id="smsAuth" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">SMS Authentication Verification</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="removeToken()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input" style="text-align:center;"
            (keypress)="restrictSpace($event);restrictChar($event)" maxlength="6" placeholder="Enter the OTP"
            [(ngModel)]="smsAuth">
        </div>
        <div class="btn-modal">
          <button class="btn theam-btn mr-2" [disabled]='smsAuth.length != 6'
            (click)="verifyGoogleAuth('sms')">Verify</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- SMS Auth Modal end  -->