<div class="static-contant">
    <section>
        <div class="container1440">
            <div class="Aboutus00 mt-5">
                <h4>Feedback<span style="color: #fff; margin-left: 10px; font-size: 14px;">For
                        {{feedbackUserName}}</span>
                </h4>
                <ul class="nav nav-pills tab-ul" id="pills-tab" role="tablist">
                    <li class="nav-item tab-li" style="width: 25%;">
                        <a class="nav-link tab-a " id="pills-buy-tab" data-toggle="pill" href="#pills-buy" role="tab"
                            aria-controls="pills-buy" aria-selected="true"
                            [ngClass]="{'active': showFeedbackTab== 'All'}" (click)="switchTab('All')">All -
                            {{feedbackCountData?.totalFeedback}}</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a" id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab"
                            aria-controls="pills-sell" aria-selected="false"
                            [ngClass]="{'active': showFeedbackTab == 'POSITIVE'}"
                            (click)="switchTab('Positive')">Positive - {{feedbackCountData?.positiveFeedback}}</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a" id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab"
                            aria-controls="pills-sell" aria-selected="false"
                            [ngClass]="{'active': showFeedbackTab == 'NEGATIVE'}"
                            (click)="switchTab('Negative')">Negative -
                            {{feedbackCountData?.negativeFeedback}}</a>
                    </li>
                </ul>

                <!-- all feedback -->
                <div *ngIf="showFeedbackTab =='All'" class="wrapper center-block">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                        <div *ngFor="let item of feedbackData | paginate: {itemsPerPage: 10, currentPage:pageNumberFeedback, totalItems:feedbackTotal, id:'feedback'}; let i=index"
                            class="panel panel-default">
                            <div role="tabpanel">
                                <div class="panel-body00">
                                    <div class="feedLike">
                                        <div>
                                            <img *ngIf="item?.feedbackStatus == 'NUTRAL'"
                                                src="assets/images/neutral_icon.png" alt="like image">
                                            <img *ngIf="item?.feedbackStatus == 'POSITIVE'"
                                                src="assets/images/like_icon.png" alt="like image">
                                            <img *ngIf="item?.feedbackStatus == 'NEGATIVE'"
                                                src="assets/images/dislike_icon.png" alt="like image">
                                        </div>
                                        <div class="tradeFeed">
                                            <p class="niceTrade" style="margin: 0;font-size: 18px;">
                                                {{item?.feedbackMessage || '---'}}</p>
                                            <p class="tradeTime" style="margin: 0;font-size: 12px;color: #aaaaaa;">
                                                {{item?.createTime | date: 'MMM d, y, h:mm:ss a'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- end all feedback -->

                <!-- positive feedback-->
                <div *ngIf="showFeedbackTab == 'POSITIVE'" class="wrapper center-block">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                        <div *ngFor="let item of feedbackData" class="panel panel-default">
                            <div role="tabpanel">
                                <div class="panel-body00">
                                    <div class="feedLike">
                                        <div>
                                            <img src="assets/images/like_icon.png" alt="like image">
                                        </div>
                                        <div class="tradeFeed">
                                            <p class="niceTrade" style="margin: 0;font-size: 18px;">
                                                {{item?.feedbackMessage || '---'}}</p>
                                            <p class="tradeTime" style="margin: 0;font-size: 12px;color: #aaaaaa;">
                                                {{item?.createTime | date: 'MMM d, y, h:mm:ss a'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- end positive feedback -->

                <!-- negative feedback -->
                <div *ngIf="showFeedbackTab =='NEGATIVE'" class="wrapper center-block">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                        <div *ngFor="let item of feedbackData" class="panel panel-default">
                            <div role="tabpanel">
                                <div class="panel-body00">
                                    <div class="feedLike">
                                        <div>
                                            <img src="assets/images/dislike_icon.png" alt="like image">
                                        </div>
                                        <div class="tradeFeed">
                                            <p class="niceTrade" style="margin: 0;font-size: 18px;">
                                                {{item?.feedbackMessage || '---'}}</p>
                                            <p class="tradeTime" style="margin: 0;font-size: 12px;color: #aaaaaa;">
                                                {{item?.createTime | date: 'MMM d, y, h:mm:ss a'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- end negative feedback -->
                <div *ngIf="feedbackTotal > 10" class="view">
                    <p>
                        <pagination-controls id='feedback' (pageChange)="feedbackPaginationEvent($event)">
                        </pagination-controls>
                    </p>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>