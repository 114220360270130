<!DOCTYPE html>
<body class="static-contant pt-0">
  <section class="wraper pt-0">
    <div class="container1440 topMargin">
      <div class="Aboutus00    txt00">
        <ul class="nav nav-pills internal-ul mb-3" id="pills-tab" role="tablist">
          <li class="nav-item internal-li">
            <a class="nav-link internal-a active" id="pills-inetrnal-tab" data-toggle="pill" href="#pills-inetrnal"
              role="tab" aria-controls="pills-inetrnal" aria-selected="true" (click)="tabChange()">Internal Transfer
            </a>
          </li>
          <li class="nav-item internal-li">
            <a class="nav-link internal-a" id="pills-escrow-tab" data-toggle="pill" href="#pills-escrow" role="tab"
              aria-controls="pills-escrow" aria-selected="false" (click)="tabChange()">Escrow Transfer</a>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-inetrnal" role="tabpanel"
            aria-labelledby="pills-inetrnal-tab">
            <div class="inetrnal-div mt-5">
              <form [formGroup]='inetrnalTranferForm'>
                <div class="inetrnal-form">
                  <div class="form-group">
                    <label>ID:</label>
                    <input type="text" class="form-control" placeholder="Enter user ID" formControlName="toCoinAddress">
                    <span>
                      <p *ngIf="inetrnalTranferForm.get('toCoinAddress').hasError('requird') &&
                                     inetrnalTranferForm.get('toCoinAddress').hasError('dirty')" class="danger"
                        padding>*Please enter user Id.</p>
                    </span>
                  </div>
                  <div class="form-group">
                    <label>Coin type</label>
                    <select class="form-control" (change)="getCoinBalance($event)">
                      <option value="">Select</option>
                      <option *ngFor="let coin of coinList" value="{{coin.coinShortName}}">{{coin.coinShortName}}
                      </option>
                    </select>
                    <span *ngIf="this.coin" style="color: white; font-size: 12px;">Available balance:
                      {{totalBalance || 0}}{{coin}}</span>
                  </div>
                  <div class="form-group">
                    <label>Amount</label>
                    <input type="text" class="form-control" placeholder="Enter amount" formControlName="amount"
                      (keypress)="restrictSpace($event);restrictChar($event)">
                  </div>
                  <div class="text-center mt-5">
                    <button class="btn btn-submit" [disabled]="inetrnalTranferForm.invalid"
                      (click)="SubmitTransfer()">Submit</button>

                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-escrow" role="tabpanel" aria-labelledby="pills-escrow-tab">
            <div class="inetrnal-div mt-5">
              <form [formGroup]="escrowForm">
                <div class="inetrnal-form">
                  <div class="form-group">
                    <label>ID:</label>
                    <input type="text" class="form-control" formControlName="toCoinAddress"
                      placeholder="Enter user ID ">
                  </div>
                  <div class="form-group">
                    <label>Coin type</label>
                    <select class="form-control" (change)="getCoinBalance($event)">
                      <option value="">Select</option>
                      <option *ngFor="let coin of coinList" value="{{coin.coinShortName}}">{{coin.coinShortName}}
                      </option>
                    </select>
                    <span *ngIf="this.coin" style="color: white; font-size: 12px;">Available balance:
                      {{totalBalance || 0}}{{coin}}</span>
                  </div>
                  <div class="form-group">
                    <label>Amount</label>
                    <input type="text" class="form-control" formControlName="amount" placeholder="Enter amount"
                      (keypress)="restrictSpace($event);restrictChar($event)">
                  </div>
                  <div class="text-center mt-5">
                    <button [disabled]="escrowForm.invalid && !escrowForm.get('TagId').value" (click)="SubmitZEscrow()"
                      class="btn btn-submit"> Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</body>
<app-footer></app-footer>

<div class="modal fade" id="internalTransferModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">Confirm Transfer</h5>
        <button type="button" class="close " data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to transfer {{transferCoinBaln}} {{coin}} to {{transferAddress}}?</p>
        <div class="btn-modal">
          <button class="btn theam-btn mr-2" (click)="submitConfirm()">Yes</button>
          <button class="btn theam-btn2 ml-2" data-dismiss="modal">No</button>
        </div>
      </div>
    </div>
  </div>
</div>