<div class="static-contant">
  <section class="wraper">
    <div class="container1440">
      <div class="row my-5">
        <div class="col-lg-2 mb-3">
          <div>
            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <a class="nav-link  profiletab" id="v-pills-home-tab" data-toggle="pill" role="tab" href="javascript:;"
                aria-controls="v-pills-home" aria-selected="true" (click)="navigateToPage('my-profile')">Profile</a>
              <a class="nav-link active profiletab" id="v-pills-profile-tab" data-toggle="pill" role="tab"
                href="javascript:;" aria-controls="v-pills-profile" aria-selected="false">Security</a>
              <a class="nav-link profiletab" id="v-pills-messages-tab" data-toggle="pill" role="tab" href="javascript:;"
                aria-controls="v-pills-messages" aria-selected="false" (click)="navigateToPage('kyc')">KYC</a>
              <a class="nav-link profiletab" id="v-pills-settings-tab" data-toggle="pill" role="tab" href="javascript:;"
                aria-controls="v-pills-settings" aria-selected="false" (click)="navigateToPage('kyclist')">KYC List</a>
                <a class="nav-link profiletab" id="v-pills-settings-tab" data-toggle="pill" role="tab" href="javascript:;"
                aria-controls="v-pills-settings" aria-selected="false" (click)="navigateToPage('referral')">Referral</a>
            </div>
          </div>
        </div>
        <div class="col-lg-10">
          <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-pro" role="tabpanel" aria-labelledby="v-pills-home-tab">
              <div class="cover-myprofile">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="titleProfile">
                      <h2>Increase your account security</h2>
                    </div>
                    <div class="security-div">
                      <div class="bankAuth mt-3">2FA

                        <!-- google auth -->
                        <div class="mainAuth">
                          <div class="auth">
                            <img src="assets/images/auth.png" alt="">
                            <div class="authGoogle">
                              <p class="googleCation">Google Authentication</p>
                              <p class="googleCationused">Used for withdraw & security modification</p>
                            </div>
                          </div>

                          <div *ngIf="profileData.twoFaType == 'GOOGLE'">
                            <div>
                              <label>Google Auth : <span style="color: green">Enable</span>
                              </label>
                            </div>
                            <div>
                              <button class="offButton" type="button" (click)="openGoogleAuthModal()"
                                style="outline: 0;cursor: pointer">
                                Disable Google Auth
                              </button>
                            </div>
                          </div>

                          <div *ngIf="profileData.twoFaType != 'GOOGLE'">
                            <div>
                              <label>Google Auth : <span style="color: red">Disable</span>
                              </label>
                            </div>
                            <div>
                              <button class="onButton" type="button" (click)="enableGoogleAuth()"
                                style="outline: 0;cursor: pointer">
                                Enable Google Auth
                              </button>
                            </div>
                          </div>
                        </div>

                        <!-- sms auth -->
                        <div class="mainAuth" style="margin-top: 26px; border: 0px;">
                          <div class="auth">
                            <img src="assets/images/sms.png" alt="">
                            <div class="authGoogle">
                              <p class="googleCation">SMS Authentication</p>
                              <p class="googleCationused">Used for withdraw & security modification</p>
                            </div>
                          </div>

                          <div *ngIf="profileData.twoFaType == 'SMS'">
                            <div>
                              <label>SMS Auth : <span style="color: green">Enable</span> </label>
                            </div>
                            <div>
                              <button class="disableButton" type="submit" (click)="openSMSAuthModal()"
                                style="outline: 0; cursor: pointer">
                                Disable SMS Auth
                              </button>
                            </div>
                          </div>

                          <div *ngIf="profileData.twoFaType != 'SMS'">
                            <div>
                              <label>SMS Auth : <span style="color: red">Disable</span> </label>
                            </div>
                            <div>
                              <button class="enableButton" type="submit" (click)="openSMSAuthModal()"
                                style="outline: 0; cursor: pointer">
                                Enable SMS Auth
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="bankAuth mt-3">
                        <div class="mainAuth" style="border: 0px;">
                          <div class="auth">
                            <div class="authGoogle">
                              <p class="googleCation">Account Activity</p>
                            </div>
                          </div>
                          <div class="onButton" style="background: #2c66c2; cursor: pointer;" (click)="more()">
                            More
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>



<!-- Google Auth Modal  -->
<div class="modal fade" id="googleAuth" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">Google Authentication Verification</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="qrCode" class="qr-div mt-4 mb-3">
          <img [src]="qrCode" *ngIf="qrCode" alt="">
        </div>
        <p *ngIf="secretKey" style="padding: 0px;">Secret Key : {{secretKey}}
          <i class="fa fa-clone" aria-hidden="true" style="cursor: pointer;" (click)="copyToClipboard(secretKey)"></i>
        </p>
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input" style="text-align:center;"
            (keypress)="restrictSpace($event);restrictChar($event)" maxlength="6" placeholder="Enter the google code"
            [(ngModel)]="googleAuth">
        </div>
        <div class="btn-modal">
          <button class="btn theam-btn mr-2" [disabled]="googleAuth.length != 6"
            *ngIf="profileData.twoFaType != 'GOOGLE'" (click)="verifyGoogleAuth()">Enable</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Google Auth Modal end  -->

<!-- Google Auth disable Modal  -->
<div class="modal fade" id="googleAuthDisable" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">Google Authentication</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="removeToken()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input" style="text-align:center;"
            (keypress)="restrictSpace($event);restrictChar($event)" maxlength="6" placeholder="Enter the google code"
            [(ngModel)]="googleAuth">
        </div>
        <div class="btn-modal">
          <button class="btn theam-btn mr-2" [disabled]='googleAuth.length != 6'
            (click)="disableTwoFA()">Disable</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Google Auth disable Modal end  -->

<!-- SMS Auth Modal  -->
<div class="modal fade" id="smsAuth" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">SMS Authentication Verification</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input" style="text-align:center;"
            (keypress)="restrictSpace($event);restrictChar($event)" maxlength="6" placeholder="Enter the OTP"
            [(ngModel)]="smsAuth">
        </div>
        <div class="btn-modal" *ngIf="profileData.twoFaType != 'SMS'">
          <button class="btn theam-btn mr-2" [disabled]='smsAuth.length != 6' type="button"
            (click)="verifySMSAuth()">Enable</button>
        </div>
        <div class="btn-modal" *ngIf="profileData.twoFaType == 'SMS'">
          <button class="btn theam-btn mr-2" [disabled]='smsAuth.length != 6' type="button"
            (click)="disableSMSAuth()">Disable</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- SMS Auth Modal end  -->