<div class="static-contant">
    <section class="wraper">
        <div class="container1440">
            <div class=" pt-5">
                <ul class="nav nav-pills tab-ul tab-quick mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a active" href="javascript:;" (click)="switchTab('buy')"
                            [ngClass]="{'active': activeTab == 'buy'}">Quick Buy</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a" href="javascript:;" (click)="switchTab('sell')"
                            [ngClass]="{'active': activeTab == 'sell'}">Quick Sell</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a" href="javascript:;" (click)="switchTab('advertisement')"
                            [ngClass]="{'active': activeTab == 'advertisement' || activeTab=='buySell'}">Create
                            Advertisement</a>
                    </li>
                </ul>

                <!-- search common for both buy and sell -->
                <div *ngIf="(activeTab=='buy') || (activeTab == 'sell')">
                    <form [formGroup]="searchForm">
                        <ul class="nav nav-pills tab-ul mt-3" id="pills-tab" role="tablist">
                            <li class="nav-item tab-li newTabinr">
                                <a class="nav-link tab-a ">
                                    <select class="search-select-box searcj" formControlName="currency">
                                        <option value="">List of Currencies</option>
                                        <option value="USD">USD</option>
                                        <option value="INR">INR</option>
                                    </select></a>
                            </li>
                            <li class="nav-item tab-li newTabinr">
                                <a class="nav-link tab-a ">
                                    <select class="search-select-box searcj" formControlName="country">
                                        <option value="">Country List</option>
                                        <option *ngFor="let item of countryList" [value]="item.country">
                                            {{item?.country}}</option>
                                    </select></a>
                            </li>
                            <li class="nav-item tab-li newTabinr">
                                <a class="nav-link tab-a ">
                                    <select class="search-select-box searcj " formControlName="paymentType">
                                        <option value="">Payment Method</option>
                                        <option value="NationalBankTransfer">National bank transfer</option>
                                        <option value="IMPS">IMPS</option>
                                        <option value="paypal">paypal</option>
                                        <option value="paytm">paytm</option>
                                        <option value="UPI">UPI</option>


                                    </select>
                                </a>
                            </li>
                            <li class="nav-item tab-li searchButton" (click)="search()">
                                <a class="nav-link tab-a" href="javascript:;">Search</a>
                            </li>
                            <li class="nav-item tab-li searchButton" (click)="reset()">
                                <a class="nav-link tab-a" href="javascript:;">Reset</a>
                            </li>
                        </ul>
                    </form>
                </div>
                <!-- buy bitcoin  -->
                <div *ngIf="(activeTab == 'buy') || (activeTab == 'buySell')">
                    <ul>
                        <li style=" margin-top: 28px; font-size: 25px" class="p2pBuy">Buy Bitcoin Online</li>
                    </ul>
                    <div class="table-responsive mt-4 pb-5">
                        <table class="table after-logintabel action" aria-describedby="">
                            <thead>
                                <tr>
                                    <th id="">Seller</th>
                                    <th id="">Payment Method</th>
                                    <th id="">Price/BTC</th>
                                    <th id="">Limits</th>
                                    <th id="">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let order of buyOrderArr | paginate: { itemsPerPage: 5, currentPage: pageNumberSell, totalItems:buyTotal , id:'orderBuy' } ; let i=index ">
                                    <td>{{order?.userName || '---'}}</td>
                                    <td>{{order?.paymentType}}</td>
                                    <td>{{order?.price}} {{order?.fiatCoin}}</td>
                                    <td>{{order?.minValue}}-{{order?.maxValue}} {{order?.fiatCoin}}</td>
                                    <td>
                                        <div class="buy-sellbtb">
                                            <button class="btn btn-table" type="button"
                                                (click)="navigateTo('p2p-buy',order,'buy')">BUY</button>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="data" *ngIf="buyOrderArr?.length == 0">
                                    <td colspan="5" class="space"><strong>No records found</strong></td>
                                </tr>

                            </tbody>
                        </table>
                        <div *ngIf="buyTotal > 5" class="view">
                            <p>
                                <pagination-controls id='orderBuy' (pageChange)="buyPaginationEvent($event)">
                                </pagination-controls>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- end buy bitcoin  -->


                <!-- sell bitcoin -->
                <div *ngIf="(activeTab == 'sell') || (activeTab == 'buySell')">
                    <ul>
                        <li style=" margin-top: 28px; font-size: 25px" class="p2pBuy">Sell Bitcoin Online</li>
                    </ul>
                    <div class="table-responsive mt-4 pb-5">
                        <table class="table after-logintabel action" aria-describedby="">
                            <thead>
                                <tr>
                                    <th id="">Seller</th>
                                    <th id="">Payment Method</th>
                                    <th id="">Price/BTC</th>
                                    <th id="">Limits</th>
                                    <th id="">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let order of sellOrderArr | paginate: { itemsPerPage: 5, currentPage: pageNumberBuy, totalItems:sellTotal , id:'orderSell' } ; let i=index ">
                                    <td>{{order?.userName || '---'}}</td>
                                    <td>{{order?.paymentType}}</td>
                                    <td>{{order?.price}} USD</td>
                                    <td>{{order?.minValue}}-{{order?.maxValue}} {{order?.fiatCoin}}</td>
                                    <td>
                                        <div class="buy-sellbtb">
                                            <button class="btn btn-table" type="button"
                                                style="background: #e5565f; border-color: #e5565f"
                                                (click)="navigateTo('p2p-buy',order,'sell')">SELL
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="data" *ngIf="sellOrderArr?.length == 0">
                                    <td colspan="5" class="space"><strong>No record found</strong></td>
                                </tr>

                            </tbody>
                        </table>
                        <div *ngIf="sellTotal > 5" class="view">
                            <p>
                                <pagination-controls id='orderSell' (pageChange)="sellPaginationEvent($event)">
                                </pagination-controls>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- end sell bitcoin -->


                <!-- create advertisement -->
                <div *ngIf="activeTab == 'advertisement'" class="create-adv">
                    <h4>Create bitcoin trade advertisement</h4>
                    <div *ngIf="!userLoginStatus" class="p2p-div" style="padding: 20px;">
                        <span class="tradeStatus" style="color: #fff;">Please <span class="wating p2pBuy"
                                style=" cursor: pointer;" [routerLink]="['/login']">Login</span>
                            or <span class="wating p2pBuy" style=" cursor: pointer;"
                                [routerLink]="['/register']">Sign up</span>
                            before creating an
                            advertisement.</span>
                    </div>
                    <div *ngIf="userLoginStatus" class="p2p-div" style="padding: 20px;">
                        <span class="tradeStatus" style="color: #fff;">To Create advertisements, you have to complete
                            identity verification with qte-web.mobiloitte.com</span>
                        <span class="wating p2pBuy " > <a routerLink="/kyc" class="p2pBuy"> Proceed
                                to identity verification.</a></span>
                    </div>

                    <form [formGroup]="addForm" novalidate autocompletet="off">
                        <div class="wrapper center-block">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="addMain">

                                    <div class="p2p-div">
                                        <div class="trade" style="padding-bottom: 8px;">
                                            Advertisement rules and requirements
                                        </div>
                                        <div class="row">
                                            <div class="col-md-8 col-lg-6">
                                                <div class="form-group ">
                                                    <label for="exampleInputEmail1">I want to..</label>
                                                    <div class="p2o-redio">
                                                        <div class="priceTag">
                                                            <p class="prieAmount"><label
                                                                    class="containerRadio nutral">Sell
                                                                    bitcoin online
                                                                    <input type="radio" checked="checked"
                                                                        name="orderType" formControlName="orderType"
                                                                        value="SELL">
                                                                    <span class="checkmark"></span>
                                                                </label></p>
                                                        </div>
                                                        <div class="priceTag">
                                                            <p class="prieAmount">
                                                                <label class="containerRadio nutral">Buy bitcoin online
                                                                    <input type="radio" checked="checked"
                                                                        name="orderType" formControlName="orderType"
                                                                        value="BUY">
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                                <span
                                                                    *ngIf="addForm.get('orderType').hasError('required') && (addForm.get('orderType').dirty || isAdvertisementFormSubmitted)"
                                                                    class="text-danger">*Field is required.</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-lg-3">
                                                <div class="form-group ">
                                                    <label for="exampleInputEmail1">Country</label>

                                                    <select class="form-control search-select-box-country searcj"
                                                        formControlName="country">
                                                        <option value="">Select Country</option>
                                                        <option *ngFor="let item of countryList" [value]="item.country">
                                                            {{item?.country}}</option>
                                                    </select>
                                                    <span
                                                        *ngIf="addForm.get('country').hasError('required') && (addForm.get('country').dirty || isAdvertisementFormSubmitted)"
                                                        class="text-danger">*Please select country name.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="p2p-div">
                                        <div class="trade" style="padding-bottom: 8px;">
                                            More information
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4 col-lg-3">
                                                <div class="form-group ">
                                                    <label for="exampleInputEmail1">Currency</label><button
                                                        type="button" class="btn btn-default " data-placement="top"
                                                        title="Currency in which you want to buy/sell."
                                                        onmouseenter="$(this).tooltip('show')"><i
                                                            class="fa fa-question text-white tooltips"
                                                            aria-hidden="true"></i>
                                                    </button>
                                                    <select class="form-control search-select-box-country searcj"
                                                        (change)="changeCoin($event)" formControlName="advCurrency">
                                                        <option value="">Select currency</option>
                                                        <option value="INR">INR</option>
                                                        <option value="USD">USD</option>
                                                    </select>
                                                    <span
                                                        *ngIf="addForm.get('advCurrency').hasError('required') && (addForm.get('advCurrency').dirty || isAdvertisementFormSubmitted)"
                                                        class="text-danger">*Please select a currency.</span>

                                                </div>
                                            </div>
                                            <div class="col-md-4 col-lg-3">
                                                <div class="form-group ">
                                                    <label for="exampleInputEmail1">Margin</label><button type="button"
                                                        class="btn btn-default " data-placement="top"
                                                        title="Margin you want over the bitcoin market price. Use a negative value for buying or selling under the market price to attract more contacts."
                                                        onmouseenter="$(this).tooltip('show')"><i
                                                            class="fa fa-question text-white tooltips"
                                                            aria-hidden="true"></i>
                                                    </button>
                                                    <input class="form-control" placeholder="0%" maxlength="3"
                                                        type="number" formControlName="margin"
                                                        (keyup)="calcMargin($event.target.value)"
                                                        [disabled]="marketPrice == 0">

                                                    <span
                                                        *ngIf="addForm.get('margin').hasError('required') && (addForm.get('margin').dirty || isAdvertisementFormSubmitted)"
                                                        class="text-danger">*Please enter the margin.</span>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-lg-3">
                                                <div class="form-group ">
                                                    <label for="exampleInputEmail1">Min. transaction
                                                        limit</label><button type="button" class="btn btn-default "
                                                        data-placement="top"
                                                        title="Minimum transaction limit in one trade."
                                                        onmouseenter="$(this).tooltip('show')"><i
                                                            class="fa fa-question text-white tooltips"
                                                            aria-hidden="true"></i>
                                                    </button>
                                                    <input class="form-control" placeholder="0"
                                                        formControlName="minValue"
                                                        (keypress)="restrictSpace($event);restrictChar($event)"
                                                        maxlength="10">
                                                    <span class="pricingType" *ngIf="coin">{{coin}}</span>
                                                    <span
                                                        *ngIf="addForm.get('minValue').hasError('required') && (addForm.get('minValue').dirty || isAdvertisementFormSubmitted)"
                                                        class="text-danger">*Please enter min. transaction limit.</span>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-lg-3">
                                                <div class="form-group ">
                                                    <label for="exampleInputEmail1">Max. transaction
                                                        limit</label><button type="button" class="btn btn-default "
                                                        data-placement="top"
                                                        title="Maximum transaction limit in one trade."
                                                        onmouseenter="$(this).tooltip('show')"><i
                                                            class="fa fa-question text-white tooltips"
                                                            aria-hidden="true"></i>
                                                    </button>
                                                    <input class="form-control" placeholder="0"
                                                        formControlName="maxValue"
                                                        (keypress)="restrictSpace($event);restrictChar($event)"
                                                        maxlength="10">
                                                    <span class="pricingType" *ngIf="coin">{{coin}}</span>
                                                    <span
                                                        *ngIf="addForm.get('maxValue').hasError('required') && (addForm.get('maxValue').dirty || isAdvertisementFormSubmitted)"
                                                        class="text-danger">*Please enter max. transaction limit.</span>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-lg-3">
                                                <div class="form-group ">
                                                    <label for="exampleInputEmail1">Terms of trade</label><button
                                                        type="button" class="btn btn-default " data-placement="top"
                                                        title="Other information you wish to tell about your trade."
                                                        onmouseenter="$(this).tooltip('show')"><i
                                                            class="fa fa-question text-white tooltips"
                                                            aria-hidden="true"></i>
                                                    </button>
                                                    <textarea class="form-control" rows="1" placeholder="Terms of Trade"
                                                        formControlName="termsOfTrade"></textarea>
                                                    <span
                                                        *ngIf="addForm.get('termsOfTrade').hasError('required') && (addForm.get('termsOfTrade').dirty || isAdvertisementFormSubmitted)"
                                                        class="text-danger">*Please enter terms of trade.</span>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-lg-3 form-group">
                                                <label for="exampleInputEmail1">Payment Method</label><button
                                                    type="button" class="btn btn-default " data-placement="top"
                                                    title="Choose how to make the payment."
                                                    onmouseenter="$(this).tooltip('show')"><i
                                                        class="fa fa-question text-white tooltips"
                                                        aria-hidden="true"></i>
                                                </button>
                                                <select class="search-select-box searcj form-control paymentMethod"
                                                    formControlName="p2pPaymentMethod">
                                                    <option value="">Payment Method</option>
                                                    <option value="NationalBankTransfer">National bank transfer</option>
                                                    <option value="IMPS">IMPS</option>
                                                    <option value="paypal">Paypal</option>
                                                    <option value="paytm">Paytm</option>
                                                    <option value="UPI">UPI</option>
                                                </select>
                                            </div>



                                            <div class="col-md-4 col-lg-3">
                                                <div class="form-group ">
                                                    <label for="exampleInputEmail1">Set payment window</label><button
                                                        type="button" class="btn btn-default " data-placement="top"
                                                        title="Set the payment window in minutes, Example: 30"
                                                        onmouseenter="$(this).tooltip('show')"><i
                                                            class="fa fa-question text-white tooltips"
                                                            aria-hidden="true"></i>
                                                    </button>
                                                    <input class="form-control" placeholder="15 min"
                                                        formControlName="paymentWindow"
                                                        (keypress)="restrictSpace($event);restrictChar($event)"
                                                        maxlength="6">
                                                    <span
                                                        *ngIf="addForm.get('paymentWindow').hasError('required') && (addForm.get('paymentWindow').dirty || isAdvertisementFormSubmitted)"
                                                        class="text-danger">*Please set the payment window.</span>
                                                </div>
                                            </div>

                                            <div class="col-md-4 col-lg-3">
                                                <div class="form-group ">
                                                    <label for="exampleInputEmail1">Price Equation</label><button
                                                        type="button" class="btn btn-default " data-placement="top"
                                                        title="How the trade price is determined from the hourly market price."
                                                        onmouseenter="$(this).tooltip('show')"><i
                                                            class="fa fa-question text-white tooltips"
                                                            aria-hidden="true"></i>
                                                    </button>
                                                    <input class="form-control" placeholder="Price equation"
                                                        formControlName="equation" maxlength="6" disabled>
                                                    <span *ngIf="currentPrice" style="color:green; font-size: 12px;">
                                                        {{coin}}/BTC</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="p2p-div">
                                        <div class="trade" style="padding-bottom: 8px;">
                                            Security Options
                                        </div>
                                        <div class="sellingVacation">
                                            <input type="checkbox" formControlName="isIdentifiedPeople">
                                            <label class="privacy"> Identified people only
                                                <div *ngIf="addForm.get('isIdentifiedPeople').hasError('required') && (addForm.get('isIdentifiedPeople').dirty || isAdvertisementFormSubmitted)"
                                                    class="text-danger">*Field is required.</div>
                                            </label>
                                        </div>
                                        <div class="sellingVacation">
                                            <input type="checkbox" formControlName="twpfaType">
                                            <label class="privacy"> SMS verification needed
                                                <div *ngIf="addForm.get('twpfaType').hasError('required') && (addForm.get('twpfaType').dirty || isAdvertisementFormSubmitted)"
                                                    class="text-danger">*Field is required.</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div *ngIf="userLoginStatus" style="text-align: center; margin-top: 45px;">
                                        <button class="btn save-btn" (click)="createAdvertise()">Publish
                                            Advertisement</button>
                                    </div>
                                    <div *ngIf="!userLoginStatus" style="text-align: center; margin-top: 45px;">
                                        <button class="btn save-btn" [routerLink]="['/register']">Publish
                                            Advertisement</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- end create advertisement -->
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>


<!-- trade tutorial modal -->
<div class="modal fade" id="tradeTutorial" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content after-login-modal">
            <div class="modal-header">
                <h5 class="modal-title">Trade Tutorial</h5>
                <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p class="prieAmount" style=" font-size: 16px; text-align: justify;">Lorem Ipsum is
                    simply
                    dummy
                    text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                    an<br>
                    unknown printer took a galley of type and scrambled it to make a type specimen book. It
                    has
                    survived not only five centuries, but also the leap into electronic typesetting,
                    remaining
                    essentially unchanged. It was popularised in the 1960s with the release of Letraset
                    sheets
                    containing Lorem Ipsum passages, and more recently with desktop publishing software like
                    Aldus PageMaker including versions of Lorem Ipsum. </p>
                <div class="btn-modal">
                    <button class="btn theam-btn mr-2" data-dismiss="modal">skip</button>
                    <button class="btn theam-btn2 ml-2" data-dismiss="modal">continue</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- trade tutorial modal end -->

<!-- logout modal -->
<div class="modal fade" id="logout" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content after-login-modal">
            <div class="modal-header">
                <h5 class="modal-title">Logout</h5>
                <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to Logout?</p>
                <div class="btn-modal">
                    <button class="btn theam-btn mr-2">Yes</button>
                    <button class="btn theam-btn2 ml-2">No</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- logout modal end -->

<!-- deposit modal -->
<div class="modal fade" id="deposit" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content after-login-modal">
            <div class="modal-header">
                <h5 class="modal-title">DEPOSIT</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label>Coin Amount</label>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control after-input" placeholder="Coin Amount"
                            aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon2">Max</span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>BTC address you want to send.</label>
                    <input type="email" class="form-control after-input" placeholder="BTC Address">
                </div>
                <label>Bit Trade Fee : <strong>$1.000</strong></label><br>
                <label>Final Amount: <strong> $250.00</strong></label>
                <div class="btn-modal">
                    <button class="btn theam-btn mr-2">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- deposit modal end -->

<!-- wallet-address modal -->
<div class="modal fade" id="wallet-address" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content after-login-modal">
            <div class="modal-header">
                <h5 class="modal-title">WALLET ADDRESS</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group search-div mt-3">
                    <input type="address" class="form-control after-input" placeholder="sjhlkskjosafskmcaf1254da1s52">
                    <i class="fa fa-files-o copy" aria-hidden="true"></i>
                </div>
                <div class="qr-div mt-4 mb-3">
                    <img src="assets/images/qe-code.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- wallet-address modal end -->