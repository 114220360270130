
  <body class="static-contant pt-5"> 
    <section class="wraper">
      <div class="container1440">
      <div class="wallet-div ">
        <h2>My Wallet</h2>
          <!-- <ul class="mb-3"><li class="sameColor">WALLET BALANCE</li> 
        </ul> -->
        <!-- <button class="btn theam-btn" [routerLink]="['/deposit-history']"> Deposit History</button>
        <button class="btn theam-btn ml-3" [routerLink]="['/withdraw-history']"> Withdraw History</button> -->

        <div class="tabsDiv row container">
            <div class="col-md-2 tabs" (click)="myTradeTabs('Overview')" [ngClass]="{activeTab: (activeTab == 'Overview') }"><span>Overview</span> </div>
            <div class="col-md-2 tabs" (click)="myTradeTabs('FundingAccount')" [ngClass]="{activeTab: (activeTab == 'FundingAccount' ) || (activeTab == 'trasfer')}"><span >Funding Account</span></div>
            <div class="col-md-2 tabs" (click)="myTradeTabs('TradingAccount')" [ngClass]="{activeTab: (activeTab == 'TradingAccount' )}"><span>Trading Account</span> </div>
            <div class="col-md-2 tabs" (click)="myTradeTabs('WalletTradingLimit')" [ngClass]="{activeTab: (activeTab == 'WalletTradingLimit' )}">Wallet Trading Limit</div>
            <div class="col-md-2 tabs" (click)="myTradeTabs('MyTradingReport')" [ngClass]="{activeTab: (activeTab == 'MyTradingReport' )}">My Trading Report</div>
            <div class="col-md-2 tabs" (click)="myTradeTabs('FiatWallet')" [ngClass]="{activeTab: (activeTab == 'FiatWallet' )}">Fiat Wallet</div>
          </div>


      <!-- -----------------------------------------------Overview--------------------------------------------------------------------------------------- -->
        <div *ngIf="activeTab == 'Overview'" class="col-md-12 walletsDiv">
          <div class="content">
            <h5 class="walletSubHeading">Total Balance</h5>
            <h6>USDT 0.00</h6>
            <div class="col-md-12 row emptyBalance">
              <div class="col-md-6">
                <img src="../../../assets/images/account1.png" alt="">
                <h5>Your balance is currently empty</h5>
                <!-- <p>Buy crypto or deposit funds</p> -->
                <!-- <p class="margin-top:20px"><Span class="emptyButton">Deposite</Span> <span class="emptyButton buyButton">Buy</span></p> -->
              </div>
            </div>
          </div>
          <div class="table-responsive mt-4 pb-5" >
            <div class="col-md-12 walletsDiv">
              <div class="content">
                <h5 class="walletSubHeading">My accounts</h5>
              </div>
              </div>
            <table class="table after-logintabel action table-striped" aria-describedby="" >
              <thead>
                <tr>
                  <th id="" >Account</th>
                  <th id=""  class="mobHide text-center">AVAILABLE BALANCE</th>
                  <th id="" class="mobHide text-center">BLOCK BALANCE</th>
                  <th id="" class="text-center">TOTAL BALANCE</th>
                  <th id="" class="minWidth80 text-center">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  
                  <td  class="mobHide text-center">Funding account</td>
                  <td>USDT 0.00</td>
                  <td  class="mobHide text-center">0.00</td>
                  <td class="text-center">0.00</td>
                  <td  class="minWidth80 text-center">
                    <button class="btn theam-btn"  data-toggle="modal" (click)="recieveCoin('BTC')" ><span class="mobHide">Deposit</span><i class="fa fa-angle-up deskHide"></i></button>
                    <button class="btn theam-btn"  data-toggle="modal" (click)="selectedCoin('BTC', 433434)"><span class="mobHide">Withdraw</span><i class="fa fa-angle-down deskHide"></i></button>                     
                    <button class="btn theam-btn"  data-toggle="modal" (click)="transfer('trasfer')"><span class="mobHide">Transfer</span><i class="fa fa-angle-down deskHide"></i></button>                     

                  </td>                
                </tr>   
                <tr >
                  
                  <td  class="mobHide text-center "> Unified account</td>
                  <td>USDT 0.00</td>
                  <td  class="mobHide text-center">0.00</td>
                  <td class="text-center">0.00</td>
                  <td  class="minWidth80 text-center">
                    <!-- <img class="imgMore" src="../../../assets/images/more.png" alt="img">                     -->
                    <button class="btn theam-btn UnifiedButton"  data-toggle="modal" (click)="trade()"><span class="mobHide">Trade</span><i class="fa fa-angle-down deskHide"></i></button>                     
                    <button class="btn theam-btn UnifiedButton"  data-toggle="modal" (click)="transfer('trasfer')"><span class="mobHide">Transfer</span><i class="fa fa-angle-down deskHide"></i></button>                             

                  </td>                
                </tr> 
              </tbody>
            </table>
        </div>  

        <div class="content">
          <h5 class="walletSubHeading">Recent transactions</h5>
         
          <div class="col-md-12 row emptyBalance">
            <div class="col-md-6">
              <img src="../../../assets/images/account1.png" alt="">
              <h5>You don’t have any transactions yet</h5>
              <!-- <p>Click the deposit or buy button to make a deposit.</p> -->
              <!-- <p class="margin-top:20px"><Span class="emptyButton">Deposite</Span> <span class="emptyButton buyButton">Buy</span></p> -->
            </div>
          </div>
        </div>
        </div>
      <!-- -----------------------------------------------FundingAccount--------------------------------------------------------------------------------------- -->

        <div *ngIf=" activeTab == 'FundingAccount'" class="col-md-12 walletsDiv">
            <div class="content">
              <h5 class="walletSubHeading">Total Balance</h5>
              <h6>USDT <span style="color: rgb(10 24 37); font-weight: 600;">{{myFundingAccountBalance }}</span> </h6>
              <div class="col-md-12 row emptyBalance" *ngIf="myFundingAccountBalancee">
                <div class="col-md-6">
                  <img src="../../../assets/images/account1.png" alt="">
                  <h5>Your balance is currently empty</h5>
                  <!-- <p>Buy crypto or deposit funds</p> -->
                  <!-- <p class="margin-top:20px"><Span class="emptyButton">Deposite</Span> <span class="emptyButton buyButton">Buy</span></p> -->
                </div>
              </div>
            </div>
         
          <div class="table-responsive mt-4 pb-5" >
            <table class="table after-logintabel action table-striped" aria-describedby="" >
                <thead>
                  <tr>
                    <th id="" >COIN</th>
                    <th id=""  class="mobHide text-center">AVAILABLE BALANCE</th>
                    <th id="" class="mobHide text-center">BLOCK BALANCE</th>
                    <th id="" class="text-center">TOTAL BALANCE</th>
                    <th id="" class="minWidth80 text-center">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of coinList | paginate: { itemsPerPage: 10, currentPage: depositePageNumber, totalItems: coinList.length  } ">
                    <td class="coins"><span><img class="coinLogo"  [src]="item.coinImage" alt="img"></span>{{item?.coinFullName}} ( {{item.coinShortName}} )</td>
                    <td  class="mobHide text-center">{{item.availableBalance}}</td>
                    <td  class="mobHide text-center">{{item.inOrderBalance}}</td>
                    <td class="text-center">{{item.totalBalance}}</td>
                    <td  class="minWidth80 text-center">
                      <button class="btn theam-btn"  data-toggle="modal" (click)="recieveCoin(item.coinShortName)" ><span class="mobHide">Deposit</span><i class="fa fa-angle-up deskHide"></i></button>
                      <button class="btn theam-btn"  data-toggle="modal" (click)="selectedCoin(item.coinShortName, item.totalBalance)"><span class="mobHide">Withdraw</span><i class="fa fa-angle-down deskHide"></i></button>                     
                      <button class="btn theam-btn"  data-toggle="modal" (click)=" transferModal()"><span class="mobHide">Transfer</span><i class="fa fa-angle-down deskHide"></i></button>                     

                    </td>                
                  </tr>   
                </tbody>
              </table>
        </div>       
       </div>
      <!-- -----------------------------------------------TradingAccount--------------------------------------------------------------------------------------- -->

        <div *ngIf="activeTab == 'TradingAccount'" class="col-md-12 walletsDiv">
        <app-trading-account></app-trading-account>
         </div>
        <!-- -----------------------------------------------Transfer--------------------------------------------------------------------------------------- -->
        <div *ngIf="activeTab == 'trasfer'" class="col-md-12 walletsDiv" [ngClass]="{activeTab: (activeTab == 'TradingAccount' )}">
          <div class="content">
            <h5 class="walletSubHeading">Transfer</h5>
            <div class="row col-md-12 formTransfer">
              <form [formGroup]="trasferForm">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="exampleFormControlSelect1">Crypto</label>
                    <select class="form-control" id="exampleFormControlSelect1" (change)="changeCoin()" formControlName="coinName">
                      <option value="USDT">USDT</option>
                      <option value="BTC">BTC</option>
                      <option value="ETH">ETH</option>
                      <option value="QTE">QTE</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 toFrom">
                  <div class="col-md-5 leftDrop">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">From</label>
                      <select class="form-control" id="exampleFormControlSelect1" (change)="changeValueFrom()" formControlName="toAccount">
                        <option value="Funding_Account" >Funding Account</option>
                        <option value="Trading_Account">Trading Account</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 errowDiv">
                    <i class="fa fa-exchange" aria-hidden="true"></i>
                  </div>
                  <div class="col-md-5 rightDrop">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">To</label>
                      <!-- <select class="form-control" id="exampleFormControlSelect1" (change)="changeValueTo()" formControlName="fromAccount" disabled> -->
                        <select class="form-control" id="exampleFormControlSelect1"  formControlName="fromAccount" disabled>
                        <option  value="Trading_Account" disabled>Trading Account</option>
                        <option  value="Funding_Account" disabled>Funding Account</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Amount ( {{coinSelected}} ) </label>
                    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Amount" formControlName="amount">
                  </div>
                  <p>Available <span style="font-weight: 600;"> {{myFundingByCoinNAme }} {{ coinSelected}} </span> <span class="transferAll" style="color:#4785e9 " (click)="transferAll()">Transfer all</span> </p>

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <button type="submit" class="btn btntransfer" (click)="transferAmount()">Transfer</button>
                  </div>
                </div>
               
              </form>
              
            </div>

            <div class="transferType col-md-12">
              <div class="transferTab col-md-8 row">
                <div class="col-md-4 tabs" (click)="transferTab('transfer')" [ngClass]="{activeTab: (transferActiveTab == 'transfer' )}"> {{coinSelected}} transfers</div>
                <div class="col-md-4 tabs" (click)="transferTab('allTransfer')" [ngClass]="{activeTab: (transferActiveTab == 'allTransfer' )}">All transfers</div>
              </div>
              <div *ngIf="transferActiveTab == 'transfer'" class="transferData col-md-12">
                
                <div class="table-responsive mt-2 pb-5" >
                  <table class="table after-logintabel action table-striped" aria-describedby="" >
                      <thead>
                        <tr>
                          <th id="" >COIN</th>
                          <th id=""  class=" text-center">AMOUNT</th>
                          <th id="" class=" text-center">FROM</th>
                          <th id="" class="text-center">TO</th>
                          <th id="" class=" text-center">TIME</th>
                          <th id="" class=" text-center">STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="col-md-12 text-center">
                      <span>You don’t have any transfers yet</span>
                    </div>
              </div>   
              </div>
              <div *ngIf="transferActiveTab == 'allTransfer'" class="transferData col-md-12">
                <div class="table-responsive mt-2 pb-5" >
                  <table class="table after-logintabel action table-striped" aria-describedby="" >
                      <thead>
                        <tr>
                          <th id="" >COIN</th>
                          <th id=""  class=" text-center">AMOUNT</th>
                          <th id="" class=" text-center">FROM</th>
                          <th id="" class="text-center">TO</th>
                          <th id="" class=" text-center">TIME</th>
                          <th id="" class=" text-center">STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="col-md-12 text-center">
                      <span>You don’t have any transfers yet</span>
                    </div>
              </div>
              </div>
            </div>
          </div>      
         </div>
                 <!-- -----------------------------------------------Transfer end--------------------------------------------------------------------------------------- -->

<!-- -----------------------------------------------Wallet Trading limit------------------------------------------ -->
            <div  class="col-md-12 walletsDiv" >
              <div class="content">
                <app-wallet-trading-limit *ngIf="activeTab == 'WalletTradingLimit'"></app-wallet-trading-limit>
                <app-my-trading-report *ngIf="activeTab == 'MyTradingReport'"></app-my-trading-report>
                <app-fiat-wallet *ngIf="activeTab == 'FiatWallet'"></app-fiat-wallet>

              </div>
            </div>
            <!-- -----------------------------------------------Wallet Trading limit end------------------------------------------ -->


      </div>
      </div>
    </section>
    


<!-- deposit modal -->
<div class="modal fade" id="sendModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content after-login-modal">
        <div class="modal-header">
          <h5 class="modal-title" >WITHDRAW {{selectedUserCoin}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
        <div class="modal-body" >
          <form [formGroup]="sendForm"> 
            <div class="form-group">
              <label >Available {{selectedUserCoin}} Balance: <strong>{{perCoinTotalBaln}}</strong></label><br>
                <label >Coin Amount</label>
                <div class=" mb-3">
                    <input type="text" class="form-control after-input" formControlName="coinAmount" maxlength="10" (keyup)="getFinalAmount($event)" placeholder="Coin Amount" aria-label="Recipient's username" aria-describedby="basic-addon2">
                  
                    <div class="">
                    <small *ngIf="sendForm.get('coinAmount').hasError('required') && sendForm.get('coinAmount').dirty" class="danger errClass" padding>*Please enter amount.</small>
                    <small *ngIf="sendForm.get('coinAmount').hasError('pattern') && sendForm.get('coinAmount').dirty" class="danger errClass" padding>*Invalid amount.</small>
                   </div>
                
                  </div>
              </div>
              <div class="form-group">
                <label >{{selectedUserCoin}} address you want to send</label>
                <input type="email" class="form-control after-input" placeholder="Address" formControlName="address">
                <small class="danger errClass" *ngIf="sendForm.get('address').hasError('required') && sendForm.get('address').dirty" padding>*Please enter address.</small>
              </div>
              <div class="form-group" *ngIf="coinName =='XRP' || coinName =='XLM'">
                <label >Enter Tag Id</label>
                <input type="text" class="form-control after-input" placeholder="Enter Tags" 
                 (keyup)="getTagValue($event)">
              </div>
              <label >QTE Trade Fee : <strong>{{value}}</strong></label><br>
                <label >Final Amount: <strong> {{finalAmount.toFixed(8)}}</strong></label><br>
                <label >Min. Withdrawal Amount : <strong>{{coinWithdrawAmount}}</strong></label>
         <div class="btn-modal">
            <button class="btn theam-btn mr-2" [disabled]="!sendForm.valid" (click)="openModal()" >Submit</button>
         </div>
        </form>
        </div>
      
      </div>
    </div>
</div>
<!-- deposit modal end -->

<!-- -----------------------------transfer modal----------------------------------- -->
<div class="modal fade" id="transferModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title" >Transfer</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row col-md-12 formTransfer">
          <form [formGroup]="trasferForm">
            <div class="col-md-12">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Crypto</label>
                <select class="form-control" id="exampleFormControlSelect1" (change)="changeCoin()" formControlName="coinName">
                  <option value="USDT">USDT</option>
                  <option value="BTC">BTC</option>
                  <option value="ETH">ETH</option>
                  <option value="QTE">QTE</option>
                </select>
              </div>
            </div>
            <div class="col-md-12 toFrom">
              <div class="col-md-5 leftDrop modalSlect">
                <div class="form-group">
                  <label for="exampleFormControlSelect1">From</label>
                  <select class="form-control" id="exampleFormControlSelect1" (change)="changeValueFrom()" formControlName="toAccount">
                    <option value="Funding_Account" >Funding Account</option>
                    <option value="Trading_Account">Trading Account</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 errowDiv">
                <i class="fa fa-exchange" aria-hidden="true"></i>
              </div>
              <div class="col-md-5 rightDrop modalSlect">
                <div class="form-group">
                  <label for="exampleFormControlSelect1">To</label>
                  <select class="form-control" id="exampleFormControlSelect1"  formControlName="fromAccount" disabled>
                    <option  value="Trading_Account">Trading Account</option>
                    <option  value="Funding_Account">Funding Account</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="exampleInputPassword1">Amount ( {{coinSelected}} ) </label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Amount" formControlName="amount">
              </div>
              <p>Available <span style="font-weight: 600;"> {{myFundingByCoinNAme }} {{ coinSelected}} </span> <span class="transferAll" style="color:#4785e9 " (click)="transferAll()">Transfer all</span> </p>
            </div>
            <div class="col-md-12">
              <div class="form-group" style="float: right;"> 
                <button data-dismiss="modal" aria-label="Close" class="btn btn-dark" (click)="cancleTransferModal()">Cancle</button>
                <button type="submit" class="btn btntransfer" (click)="transferAmount()">Transfer</button>
              </div>
            </div>
           
          </form>
          
        </div>
        </div>
    </div>
  </div>
</div>
<!-- -----------------------------transfer modal ended----------------------------------- -->

<!-- wallet-address modal -->
<div class="modal fade" id="recieveModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content after-login-modal">
        <div class="modal-header">
          <h5 class="modal-title text-center" >WALLET ADDRESS</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- <form [formGroup]="receiveForm">  -->
        <div class="modal-body">
              <div class="form-group search-div mt-3" >
            
                <p class="form-control after-input copy-i"><label class="wallet_sapn">{{walletAddress}}</label>
                  <i class="fa fa-clone copyIcon" aria-hidden="true" (click)="copyToClipboard(walletAddress)"></i>
                </p>
              
               
              </div>
              <div class="form-group search-div mt-3" *ngIf="tags" ngxClipboard [cbContent]="tags" >
                <h6 *ngIf="tags">Tag Id</h6>
                <p class="form-control after-input copy-i" *ngIf="tags">{{tags}}  <i class="fa fa-clone copy-ii" aria-hidden="true" (click)="copyToClipboardTag(tags)"></i></p>
            </div>
              <div class="qr-div mt-4 mb-3" >
                <ngx-qrcode [value]="walletAddress" qrc-class="aclass" qrc-errorCorrectionLevel="L">
                </ngx-qrcode>
              </div>
        </div>
        <!-- </form> -->
      </div>
    </div>
</div>
<!-- wallet-address modal end -->

<!-- Google Auth Modal  -->
<div class="modal fade" id="googleAuth" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">Google Authentication Verification</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="qrCode" class="qr-div mt-4 mb-3">
          <img [src]="qrCode" *ngIf="qrCode" alt="">
        </div>
        <p *ngIf="secretKey" style="padding: 0px;">SecretKey : {{secretKey}}</p>
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input" style="text-align:center;"
            (keypress)="restrictSpace($event);restrictChar($event)" maxlength="6" placeholder="Enter the google code"
            [(ngModel)]="googleAuth">
        </div>
        <div class="btn-modal">
          <button class="btn theam-btn mr-2" [disabled]="googleAuth.length != 6"
          (click)="verifyGoogleAuth('Google')">Verify</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Google Auth Modal end  -->

<!-- SMS Auth Modal  -->
<div class="modal fade" id="smsAuth" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">SMS Authentication Verification</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input" style="text-align:center;"
            (keypress)="restrictSpace($event);restrictChar($event)" maxlength="6" placeholder="Enter the OTP"
            [(ngModel)]="smsAuth">
        </div>
        <div class="btn-modal" >
          <button class="btn theam-btn mr-2" [disabled]='smsAuth.length != 6' type="button"
          (click)="verifyGoogleAuth('sms')">Verify</button>
        </div>
       
      </div>
    </div>
  </div>
</div>
<!-- SMS Auth Modal end  -->
  </body>

  <app-footer></app-footer>