import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  navigateToUrl(urlvalue){
    if (urlvalue=='huobi'){
      window.open('https://www.huobi.com/en-us/')
    }
    else if (urlvalue=='okblockchain'){
      window.open('https://www.okcoin.com/')
    }
    else if(urlvalue=='nodecapital'){
      window.open('http://www.nodecap.com/')
    }
    else if(urlvalue=='genesis'){
      window.open('https://www.genesisblockchain.io/')
    }
    else if(urlvalue=='plumventures'){
      window.open('http://www.plumventures.cn/')
    }
    else if(urlvalue=='playstore'){
      window.open('https://play.google.com/store')
    }
    else if(urlvalue=='appstore'){
      window.open('https://www.apple.com/in/app-store/')
    }
  }
  navigateToSocialMedia(val){
    if (val=='facebook'){
      window.open('https://www.facebook.com/')
    }
    else if (val=='twitter'){
      window.open('https://twitter.com/?lang=en')
    }
    else if (val=='telegram'){
      window.open('https://telegram.org/')
    }
    else if (val=='instagram'){
      window.open('https://www.instagram.com/')
    }
    else if (val=='youtube'){
      window.open('https://www.youtube.com/')
    }
  }
}
