import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-quick-sell',
  templateUrl: './quick-sell.component.html',
  styleUrls: ['./quick-sell.component.css']
})
export class QuickSellComponent implements OnInit {
  sellOrderArr: any = [];
  pageNumberSell: any = 1;
  pageSize: any = 5;
  buyTotal: any;
  searchForm = new FormGroup({
    'amount': new FormControl(''),
    'amountType': new FormControl(''),
    'offer': new FormControl('')
  })

  constructor(public router: Router, public server: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.sellOrderList();
  }

  search() {
    console.log("search form value->", this.searchForm.value);
  }

  // to get advertisement list sell 
  sellOrderList() {
    this.sellOrderArr = []
    this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list?orderType=SELL&page=${this.pageNumberSell - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
      console.log("get sell order response ==>", res)
      if (res['status'] == 200) {
        this.sellOrderArr = res['data'].RESULT_LIST;
        this.buyTotal = res['data'].TOTAL_COUNT
      }
    })
  }

  sellPaginationEvent(page) {
    this.pageNumberSell = page;
    this.sellOrderList();
  }
  
  // to navigate
  navigateTo(path, data, type) {
    let val = data.fkUserId + '-' + data.peerToPeerExchangeId + '-' + type
    this.router.navigate([path, val])
  }



  quickbuy() {
    this.router.navigate(['/p2p-exchange'])
  }
  createadvertisement() {
    this.router.navigate(['/create-advertisement'])
  }

}
