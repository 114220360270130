<!DOCTYPE html>
<body class="static-contant">
  <section class="ver-sec-eml">
    <div class="container1440">
      <div class="security-sms">
        <h6 class="sms">Security > SMS Authentication</h6>
        <div class="e-bled">
          <h1>Enabled SMS Authenticator</h1>
        </div>

        <form class="login-sms-sec">
          <div class="form-group inpt-frm-pro000">
            <i class="fa fa-caret-down down-arrow" aria-hidden="true"></i>
            <select class="sms-sec">
              <option selected>+91 </option>
            </select>
            <input type="email" class="form-control email-pass-pro-sec00" placeholder="Enter phone number">
          </div>
          <div class="sms-code">
            <p>SMS Authenticatiaon Code</p>

              <div class="form-group sms-authentication-1">
                <input type="email" class="form-control">
                <input type="email" class="form-control auth">
                <input type="email" class="form-control">
                <input type="email" class="form-control auth">
                <input type="email" class="form-control auth1">
                <input type="email" class="form-control">
            
          </div>
          <button type="submit" class="btn sms-btn">Resend(59sec)</button>
      </div>
      <div class="ena-sms">
        <button style="max-width: 350px;" type="submit" class="btn register-btn">Enabled SMS Authenticator</button>
      </div>
    </form>
    </div>
    </div>
  </section>

</body>
<app-footer></app-footer>
