<div class="static-contant">
    <section class="wraper">
        <div class="container1440">
            <div class=" pt-5 pb-5">
                <ul>
                    <li style="margin-top: 28px; font-size: 25px" class="sameColor">Dashboard</li>
                    <p style="color: #878691;">On this page you can view and manage your advertisements and trade.</p>
                </ul>

                <ul class="nav nav-pills tab-ul" id="pills-tab" role="tablist">
                    <li class="nav-item tab-li" style="border: none; background-color: #172D3E;border: 1px solid #116753;">
                        <a class="nav-link tab-a " routerLink='/my-wallet'>WALLET {{walletData?.walletBalance}} BTC</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a" id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab"
                            aria-controls="pills-sell" aria-selected="false"
                            [ngClass]="{'active': tableType == 'Open Trades'}"
                            (click)="showTradeTable('Open Trades')">OPEN TRADE &
                            ADVERTISEMENT</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a" id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab"
                            aria-controls="pills-sell" aria-selected="false"
                            [ngClass]="{'active': tableType == 'Complete Trades'}"
                            (click)="showTradeTable('Complete Trades')">COMPLETED TRADES</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a" id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab"
                            aria-controls="pills-sell" aria-selected="false"
                            [ngClass]="{'active': tableType == 'Cancel Trades'}"
                            (click)="showTradeTable('Cancel Trades')">CANCELLED TRADES</a>
                    </li>
                </ul>
                <ul>
                    <li style=" margin-top: 28px; font-size: 25px" class="sameColor">
                        Your {{tableType}}
                        <div style="float: right;">
                            <button class="btn save-btn" (click)="exportCSV()">Download data in CSV Format</button>
                        </div>
                    </li>
                </ul>

                <div class="table-responsive mt-4 pb-5">
                    <!-- open order & advertisement -->
                    <table *ngIf="tableType == 'Open Trades'" class="table after-logintabel action" aria-describedby="">
                        <thead>
                            <tr>
                                <th id="" style="border-right: 1px solid #116753!important;">S. No.</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Advertisement Status</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Trade Type</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Payment Type</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Fiat</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Equation</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let order of getTradingArr | paginate: { itemsPerPage: 5, currentPage: pageNumberTrade, totalItems:tradeTotal  , id:'trade' } ; let i=index ">
                                <td>{{pageSize * (pageNumberTrade - 1) + i+1}}</td>
                                <td>{{order?.exchangeStatusType || '---'}}</td>
                                <td>{{order?.orderType || '---'}}</td>
                                <td>{{order?.paymentType || '---'}}</td>
                                <td>{{order?.fiatCoin || '---'}}</td>
                                <td>{{order?.price || '---'}}</td>
                                <td>{{order?.creationTime | date: 'MMM d, y, h:mm:ss a'}}</td>
                            </tr>
                            <tr *ngIf="getTradingArr?.length == 0">
                                <td colspan="6">
                                    <p style="color: #878691;">You don’t have any advertisement yet.</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- trades -->
                    <table *ngIf="tableType != 'Open Trades'" class="table after-logintabel action" aria-describedby="">
                        <thead>
                            <tr>
                                <th id="" style="border-right: 1px solid #116753!important;">S. No.</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Created At</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Trade Type</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Trading Partner</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Transaction Status</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Fiat</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Trade Amount</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Trading Fee</th>
                                <th id="" style="border-right: 1px solid #116753!important;">Total BTC</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let order of getTradingArr | paginate: { itemsPerPage: 5, currentPage: pageNumberTrade, totalItems:tradeTotal  , id:'trade' } ; let i=index ">
                                <td>{{pageSize * (pageNumberTrade - 1) + i+1}}</td>
                                <td>{{order?.creationTime | date : 'MMM d, y, h:mm:ss a'}}</td>
                                <td>{{order?.type}}</td>
                                <td>{{order?.tradingPartner}}</td>
                                <td>{{order?.tradeStatus}}</td>
                                <td>{{order?.fiatCoin}}</td>
                                <td>{{order?.tradeAmount | number: '.8-8'}}</td>
                                <td>{{order?.tradeFee | number:'.8-8'}} </td>
                                <td>{{order?.totalBTC}}</td>
                            </tr>
                            <tr *ngIf="getTradingArr?.length == 0">
                                <td colspan="10">
                                    <p style="color: #878691;">No Data Found!</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="tradeTotal > 5" class="view">
                        <p>
                            <pagination-controls id='trade' (pageChange)="tradePaginationEvent($event)">
                            </pagination-controls>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>