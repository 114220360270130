<!DOCTYPE html>
<body class="static-contant">
    <section class="wraper">
        <div class="container1440">
            <div class=" pt-5">
                <ul>
                    <li style="color:  #03CA9B; margin-top: 28px; font-size: 25px">Dashboard</li>
                    <p style="color: #878691;">On this page you can view and manage your advertisments and trade.</p>
                </ul>
                <ul class="nav nav-pills tab-ul" id="pills-tab" role="tablist">
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a " id="pills-buy-tab" data-toggle="pill" href="#pills-buy" role="tab"
                            aria-controls="pills-buy" aria-selected="true">WALLET OBTC</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a" id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab"
                            aria-controls="pills-sell" aria-selected="false">OPEN TRADE &
                            ADVERTISEMENT</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a " id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab"
                            aria-controls="pills-sell" aria-selected="false">ALL CLOSE TRADES</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a" id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab"
                            aria-controls="pills-sell" aria-selected="false">COMPLETE TRADES</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a" id="pills-sell-tab active" data-toggle="pill" href="#pills-sell"
                            role="tab" aria-controls="pills-sell" aria-selected="false">CANCEL TRADES</a>
                    </li>
                </ul>
                <ul>
                    <li style="color:  #03CA9B; margin-top: 28px; font-size: 25px">CANCEL TRADES</li>
                </ul>
                <div class="table-responsive mt-4 pb-5">
                    <table class="table after-logintabel action" aria-describedby="">
                        <thead>
                            <tr>
                                <th id="" style="border-right: 1px solid #3b3c3f!important;">S. No.</th>
                                <th id="" style="border-right: 1px solid #3b3c3f!important;">Status</th>
                                <th id="" style="border-right: 1px solid #3b3c3f!important;">Info</th>
                                <th id="" style="border-right: 1px solid #3b3c3f!important;">Price</th>
                                <th id="" style="border-right: 1px solid #3b3c3f!important;">Equation</th>
                                <th id="" style="border-right: 1px solid #3b3c3f!important;">Created at</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <p style="color: #878691;">No Data</p>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
    <!-- logout modal -->
    <div class="modal fade" id="logout" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content after-login-modal">
                <div class="modal-header">
                    <h5 class="modal-title">Logout</h5>
                    <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to Logout?</p>
                    <div class="btn-modal">
                        <button class="btn theam-btn mr-2">Yes</button>
                        <button class="btn theam-btn2 ml-2">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- logout modal end -->
    <!-- deposit modal -->
    <div class="modal fade" id="deposit" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content after-login-modal">
                <div class="modal-header">
                    <h5 class="modal-title">DEPOSIT</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Coin Amount</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control after-input" placeholder="Coin Amount"
                                aria-label="Recipient's username" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <span class="input-group-text" id="basic-addon2">Max</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>BTC address you want to send.</label>
                        <input type="email" class="form-control after-input" placeholder="BTC Address">
                    </div>
                    <label>Bit Trade Fee : <strong>$1.000</strong></label><br>
                    <label>Final Amount: <strong> $250.00</strong></label>
                    <div class="btn-modal">
                        <button class="btn theam-btn mr-2">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- deposit modal end -->
    <!-- wallet-address modal -->
    <div class="modal fade" id="wallet-address" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content after-login-modal">
                <div class="modal-header">
                    <h5 class="modal-title">WALLET ADDRESS</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group search-div mt-3">
                        <input type="address" class="form-control after-input"
                            placeholder="sjhlkskjosafskmcaf1254da1s52">
                        <i class="fa fa-files-o copy" aria-hidden="true"></i>

                    </div>
                    <div class="qr-div mt-4 mb-3">
                        <img src="assets/images/qe-code.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- wallet-address modal end -->
</body>