<div class="static-contant">
    <section class="wraper">
        <div class="container1440">
            <div class="row my-5">
                <div class="col-lg-2 mb-3">
                    <div>
                        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                            aria-orientation="vertical">
                            <a class="nav-link  profiletab" id="v-pills-home-tab" data-toggle="pill" role="tab"
                                href="javascript:;" aria-controls="v-pills-home" aria-selected="true"
                                (click)="navigateToPage('my-profile')">Profile</a>
                            <a class="nav-link  profiletab" id="v-pills-profile-tab" data-toggle="pill" role="tab"
                                href="javascript:;" aria-controls="v-pills-profile" (click)="navigateToPage('security')"
                                aria-selected="false">Security</a>
                            <a class="nav-link active profiletab" id="v-pills-messages-tab" data-toggle="pill"
                                role="tab" href="javascript:;" aria-controls="v-pills-messages" aria-selected="false"
                                (click)="navigateToPage('kyc')">KYC</a>
                            <a class="nav-link profiletab" id="v-pills-settings-tab" data-toggle="pill" role="tab"
                                href="javascript:;" aria-controls="v-pills-settings" aria-selected="false"
                                (click)="navigateToPage('kyclist')">KYC List</a>
                            <a class="nav-link profiletab" id="v-pills-settings-tab" data-toggle="pill" role="tab"
                                href="javascript:;" aria-controls="v-pills-settings" aria-selected="false"
                                (click)="navigateToPage('referral')">Referral</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-10">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-pro" role="tabpanel"
                            aria-labelledby="v-pills-home-tab">
                            <div class="cover-myprofile">
                                <div class="titleProfile">
                                    <h2 class="cryptoWallet">Crypto Wallet KYC - Profile Management</h2>
                                    <p class="complete">Complete Registration</p>
                                    <div class="completeRegistration">
                                        <p class="userEmail">User : <strong>{{profileData.firstName}}
                                                {{profileData.lastName}}</strong></p>
                                        <p class="userEmail" style="margin-top: 20px;">KYC(Know your customer)
                                            verification
                                            enables us to verify the identity
                                            of our customers and helps us evaluate their appropriateness, along with the
                                            prospects
                                            of unlawful purposes and practises. Mandating KYC formalities is an
                                            effective
                                            way to
                                            watch for illegal activities and avoid money laundering in the business.
                                        </p>
                                        <p class="userEmail reserve">We Reserve our time to validate your documents</p>
                                        <div class="cover">
                                            <div class="bankAdd d mt-3">Email Verification</div>
                                            <div class="bankAdd d mt-3">KYC User Information</div>
                                            <div class="bankAdd d mt-3">Upload KYC Documents</div>
                                        </div>
                                    </div>
                                    <p class="complete">Email Verification</p>
                                    <div class="emailVerification">
                                        <p class="userEmail">User : <strong>{{profileData.email}}</strong></p>

                                        <div class="signinCheck"><img src="assets/images/sign-check-icon.png" alt="">
                                        </div>
                                        <p class="userEmail verify">Verified</p>
                                        <p class="userEmail">If you face any issue, please contact us at -
                                            support@qte.com</p>
                                    </div>
                                </div>
                                <div class="titleProfile">
                                    <p class="complete">KYC User Information</p>
                                </div>
                                <div class="row kycUserInfo">
                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="cover-leftmy" style="margin-top: 0px;">
                                            <form [formGroup]="UserDetailsForm">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="First name"
                                                        formControlName="fname" disabled>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Mobile number"
                                                        formControlName="phone" disabled>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Male"
                                                        formControlName="gender" disabled>

                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Country"
                                                        formControlName="country" disabled>

                                                </div>
                                                <input type="checkbox" id="privacy" name="vehicle1" value="privacy"
                                                    formControlName="privacy" checked>
                                                <label class="privacy" for="vehicle1"><a routerLink="/privacypolicy"> I
                                                        agree to Privacy
                                                        Policy. </a></label><br>
                                                <input type="checkbox" id="term" name="vehicle1" value="term" checked
                                                    formControlName="term">
                                                <label class="privacy" for="vehicle1"><a routerLink="/terms-conditions">
                                                        I agree to Terms &
                                                        Conditions. </a></label><br>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="cover-rightmy" style="padding-top: 25px;">
                                            <form [formGroup]="UserDetailsForm1">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Last name"
                                                        formControlName="lname" disabled>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Email address"
                                                        formControlName="email_add" disabled>
                                                </div>

                                                <div class="form-group">
                                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Address"
                                                        formControlName="address" disabled>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="City"
                                                        formControlName="city" disabled>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="titleProfile">
                                    <p class="complete">KYC Document Upload</p>
                                </div>
                                <div class="kycDocumentUpload">
                                    <form [formGroup]="KycForm" novalidate autocompletet="off">
                                        <div class="coverID">
                                            <div class="bankAdd mt-3">
                                                <select class="form-control" formControlName="id_name">
                                                    <option value="">-Select-</option>
                                                    <option value="Passport">Passport</option>
                                                    <option value="National Id">National Id</option>
                                                    <option value="PAN">Pan Card</option>
                                                </select>
                                            </div>
                                            <div class="bankAdd mt-3">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                    placeholder="*Passport number or National Id number"
                                                    formControlName="id_number" maxlength="20">
                                            </div>
                                        </div>


                                        <!--  -->
                                        <!-- <div *ngIf="KycForm.value.id_number && KycForm.value.id_name"> -->
                                            <p class="userEmail">Images of your Id (front & back) format(.jpg, .jpeg,
                                                .png)
                                            </p>
                                            <div class="coverID">
                                                <div class="bankAdd mt-3">
                                                    <button class="attach-detail pointer attach-btn" type="button"
                                                        style="outline: 0;">
                                                        <input class="upload-input" type="file" accept=".jpeg, .png"
                                                            formControlName="attachmentName1"
                                                            (change)="handleFileInput1($event,'1')">{{fileName1}}</button>
                                                </div>
                                            </div>
                                            <div class="adhar"><img class="imgsize" [src]="imageUrl" alt=""></div>
                                            <div class="coverID">
                                                <div class="bankAdd mt-3">
                                                    <button class="attach-detail pointer attach-btn" type="button"
                                                        style="outline: 0;">
                                                        <input class="upload-input" type="file"
                                                            formControlName="attachmentName2" accept=".jpeg, .png"
                                                            (change)="handleFileInput1($event,'2')">{{fileName2}}</button>
                                                </div>
                                            </div>
                                            <div class="adhar"><img class="imgsize" [src]="imageUrl1" alt=""></div>
                                        <!-- </div> -->
                                        <!--  -->

                                        <!-- <div class="changeSave mt-3" (click)="submitKYC()" style="cursor: pointer;">Save
                                            Changes</div> -->


                                    <div class="changeSave mt-3" *ngIf="userKycStatus == 'REJECTED' || !userKycStatus"  (click)="submitKYC()" style="cursor: pointer;">Save Changes</div>
                                    <div class="changeSave mt-3" *ngIf="userKycStatus == 'PENDING'" style="cursor: pointer;">Under Process</div>
                                    <div class="changeSave mt-3" *ngIf="userKycStatus == 'ACCEPTED'" style="cursor: pointer;">Approved</div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>