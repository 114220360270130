
<div class="static-contant">
  <section>
    <div class="container1440">
      <div class="Aboutus00 mt-5">
        <h4>Notification <i class="fa fa-bell" aria-hidden="true" style=" color: #ee374d;"></i></h4>
        <div class="wrapper center-block">
          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            <div *ngFor="let item of service.notificationArr" class="panel panel-default">
              <div role="tabpanel" (click)="navigateTo(item)">
                <div class="panel-body00 col-md-12">
                {{item?.message}}
                </div>
              </div>
            </div>       
            <div *ngIf="!service?.notificationArr?.length" class="panel panel-default">
              <div role="tabpanel">
                <div class="panel-body00" style="text-align: center;">
                  No Notification Found!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  <app-footer></app-footer>
