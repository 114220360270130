import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-home-before-login',
  templateUrl: './home-before-login.component.html',
  styleUrls: ['./home-before-login.component.css']
})
export class HomeBeforeLoginComponent implements OnInit {
  userLoginStatus: boolean = false
  isLoggedIn = false;
  getStarted: FormGroup
  coinList: any;
  showCookie = true;
  permotionList: any;
  bannerData: any;
  announcementlist: any;
  institutionalList: any = [];
  coinListSearchData: any = [];

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public service: ServiceService, public fb: FormBuilder) {
    this.service.getWalletCoins();
  }

  ngOnInit() {
    this.getCoinList();
    this.getBanner()
    this.verifyEmail();
    this.service.connectChatSocket();
    this.getAnnouncementHeadlines();
    this.getStarted = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]]
    })
    window.scrollTo(0, 0)
    this.getPrice();
    this.getPermotionList()
    const token = localStorage.getItem('credential')
    if (token != null) {
      this.isLoggedIn = true
    }
    else {
      this.isLoggedIn = false
    }
    this.getInstitutionalList()
  }

  getPrice() {
    var getLiquiditydata = [];
    this.service.getLiquidity().subscribe((getLiquidity) => {
      getLiquiditydata = getLiquidity['data']
      getLiquiditydata.forEach((element) => {
        var ind = this.coinList.findIndex((x) => x.coinShortName == element.symbol)
        if (ind != -1) {
          this.coinList[ind].marketPriceInUsd24HourBefore = element.quote.USD.percent_change_24h
          this.coinList[ind].marketPriceInUsdAWeekBefore = element.quote.USD.percent_change_7d
          this.coinList[ind].price = element.quote.USD.price.toFixed(4);
          this.coinList[ind].volume_24h = element.quote.USD.volume_24h
          this.coinList[ind].last_updated = element.last_updated
        }
      })
    })
  }

  // verify email 
  verifyEmail() {
    this.activatedRoute.queryParams.subscribe(params => {
      let token = params['token'];
      if (token) {
        this.service.getCandyPixelForms('account/verify-user?token=' + token).subscribe(succ => {
          console.log(succ)
          if (succ['status'] == 200) {
            this.service.showSuccessMessage(succ['message'])
            this.router.navigate(['/login'])
          } else {
            this.service.showErrorMessage(succ['message'])
          }
        }, (error: any) => {
          console.log(error)
          this.service.showErrorMessage(error.error.message)
        })
      }
    })
  }

  register() {
    this.router.navigate(['/register'])
  }

  goToSignup() {
    this.service.email = this.getStarted.value.email;
    this.router.navigate(['/register'])
  }


  // get banner list and show in slider
  getBanner() {
    this.service.getCandyPixelForms('static/static-content/get-banner-list-for-web').subscribe((res: any) => {
      if (res.status == 200) {
        this.bannerData = res['data'][0]['categoryDetails'];
        // console.log(this.bannerData)
        $(document).ready(function () {
          $('.banner_slider').slick({
            autoplay: true,
            dots: false,
            loop: false,
            arrow: false,
            infinite: true,
            speed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [{
              breakpoint: 1280,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1024,
              settings:
              {
                slidesToShow: 1,
                dots: false,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
            ]
          });
        });
      }
    })
  }



  // tto get coin list
  getCoinList() {
    let coinShortName = ["USD", "NAIRA", "ZAR"]
    this.service.getCandyPixelForms('wallet/coin/get-coin-list').subscribe((getLiquidity) => {
      if (getLiquidity['status'] == 200) {
        coinShortName.forEach(element => {
          let index = getLiquidity['data'].findIndex(x => x.coinShortName == element)
          if (index != 1) {
            getLiquidity['data'].splice(index, 2)
            this.coinList = getLiquidity['data']
            this.coinListSearchData = this.coinList // for search data filter of market section
          }
        })

        $(document).ready(function () {
          $('.main_slider').slick({
            // autoplay: true,
            dots: false,
            loop: false,
            arrow: false,
            infinite: true,
            // speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [{
              breakpoint: 1280,
              settings:
              {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
            },
            {
              breakpoint: 1024,
              settings:
              {
                slidesToShow: 3,
                dots: false,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 768,
              settings:
              {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 600,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
            ]
          });
        });
        $(window).resize(function () {
          // debugger;
          $('.main_slider ').slick('setPosition');
        });

      }
    })
  }

  // get promotional list for slider
  getAnnouncementHeadlines() {
    this.service.postCandyPixelForms('static/get-announcement-Details-by-pagination-for-web?page=0&pageSize=6', '').subscribe(res => {
      console.log(res);
      if (res['status'] == 200) {
        this.announcementlist = res['data']['Announcement Details'][0]['title']
      }
      console.log(this.announcementlist);

    })
  }

  // get promotional list for slider
  getPermotionList() {
    this.service.getCandyPixelForms('static/get-promotion-list-for-web').subscribe((res: any) => {
      if (res.status == 200) {
        this.permotionList = res['data'] ? res['data'][0]['promotionDetails'] : []
        // console.log(this.permotionList);
        $(document).ready(function () {
          $('.promotional_slider').slick({
            autoplay: true,
            dots: false,
            // loop: true,
            loop: false,
            // arrow: true,
            arrow: false,
            infinite: true,
            // autoplaySpeed: 1200,
            speed: 1500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [{
              breakpoint: 1280,
              settings:
              {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1024,
              settings:
              {
                slidesToShow: 3,
                dots: false,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings:
              {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
            ]
          });
        });
        // $(window).resize(function () {
        //   // debugger;
        //   $('.upperPermotionslider ').slick('setPosition');
        // });
      }
    })
  }


  // get institutional list for slider
  getInstitutionalList() {
    this.service.getCandyPixelForms('static/get-institutional-list-for-web').subscribe((res: any) => {
      if (res.status == 200) {
        this.institutionalList = res['data'] ? res['data'][0]['institutionalDetails'] : []
        console.log(this.permotionList);
        $(document).ready(function () {
          $('.institutional_slider').slick({
            autoplay: true,
            dots: false,
            // loop: true,
            loop: false,
            // arrow: true,
            arrow: false,
            infinite: true,
            // autoplaySpeed: 1200,
            speed: 1500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [{
              breakpoint: 1280,
              settings:
              {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1024,
              settings:
              {
                slidesToShow: 3,
                dots: false,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings:
              {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings:
              {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
            ]
          });
        });
        // $(window).resize(function () {
        //   // debugger;
        //   $('.upperPermotionslider ').slick('setPosition');
        // });
      }
    })
  }

  // filter out data from market table based on entered coin name
  searchCoinList(event) {
    this.coinListSearchData = this.coinList.filter(x => (x.coinFullName.toLowerCase()).includes(event.target.value.toLowerCase()))
  }


  // to navigate depending on login status
  navigateAsPerLoginStatus(coin) {
    if (localStorage.getItem('credential')) {
      localStorage.setItem('animatedCoin', coin)
      this.router.navigateByUrl('basic-buy-sell/buy')
    } else {
      this.router.navigate(['login'])
    }
  }

  // to navigate depending on login status
  navigateAsPerLoginStatus1(coin) {
    if (localStorage.getItem('credential')) {
      localStorage.setItem('animatedCoin', coin)
      this.router.navigateByUrl('basic-buy-sell/buy')
    } else {
      this.router.navigate(['login'])
    }
  }
  buysell() {
    this.router.navigateByUrl('advanced-exchange')
  }
  navigateToUrl(urlvalue) {
    if (urlvalue == 'huobi') {
      window.open('https://www.huobi.com/en-us/')
    }
    else if (urlvalue == 'okblockchain') {
      window.open('https://www.okcoin.com/')
    }
    else if (urlvalue == 'nodecapital') {
      window.open('http://www.nodecap.com/')
    }
    else if (urlvalue == 'genesis') {
      window.open('https://www.genesisblockchain.io/')
    }
    else if (urlvalue == 'plumventures') {
      window.open('http://www.plumventures.cn/')
    }
    else if (urlvalue == 'playstore') {
      window.open('https://play.google.com/store')
    }
    else if (urlvalue == 'appstore') {
      window.open('https://www.apple.com/in/app-store/')
    }
  }
  closeCookie() {
    this.showCookie = false
  }

  navigateTo() {
    this.router.navigate(['announcement-list']);
  }
  navigateToTrade() {
    this.router.navigate(['advanced-exchange']);
  }
}
