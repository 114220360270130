import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-login-activity',
  templateUrl: './login-activity.component.html',
  styleUrls: ['./login-activity.component.css']
})
export class LoginActivityComponent implements OnInit {
  profiledetails: any;
  loginActivityList: any;
  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.profiledetails= JSON.parse(localStorage.getItem('profiledata') ) 
    this.getLoginActivity()
  }

   // Get login history List Functionality
   getLoginActivity() {
    var url = 'account/get-user-login-details?userIdForLoginHistoy=' + this.profiledetails.userId ;
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
      this.loginActivityList = res['data']
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  
}


  next() {
    this.router.navigate(['/security-activity'])
  }

}
