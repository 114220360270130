<div class="static-contant">
    <section class="wraper">
        <div class="container1440">
            <div class="create-adv pt-5">
                <ul class="nav nav-pills tab-ul" id="pills-tab" role="tablist">
                    <li class="nav-item tab-li" style="width: 25%;">
                        <a class="nav-link tab-a " id="pills-buy-tab" data-toggle="pill" href="#pills-buy" role="tab"
                            aria-controls="pills-buy" aria-selected="true" (click)="quickbuy()">Quick Buy</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a " id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab"
                            aria-controls="pills-sell" aria-selected="false" (click)="quicksell()">Quick Sell</a>
                    </li>
                    <li class="nav-item tab-li">
                        <a class="nav-link tab-a active" id="pills-sell-tab" data-toggle="pill" href="#pills-sell"
                            role="tab" aria-controls="pills-sell" aria-selected="false">Create Advertisment</a>
                    </li>
                </ul>
                <h4>Create bitcoin trade advertisement</h4>
                <div class="statusTrade" style="padding: 20px;">
                    <span class="tradeStatus" style="color: #fff;">To Create advertisements, you have to complete
                        identity verification with XYZ.com. </span><span class="wating" style="color: #b96c18;">Proceed
                        to Identity verification</span>
                </div>
                <div class="cover-rightmy">
                    <div class="trade" style="padding-bottom: 8px;">
                        Advertisement rules and requirements
                    </div>
                    <div>
                        <p class="prieAmount" style="padding: 0px 10px 0; font-size: 16px;">Lorem Ipsum is simply dummy
                            text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an<br>
                            unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap into electronic typesetting, remaining
                            essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                            containing Lorem Ipsum passages, and more recently with desktop publishing software like
                            Aldus PageMaker including versions of Lorem Ipsum. </p>
                    </div>
                </div>

                <form [formGroup]="addForm" novalidate autocompletet="off">
                    <div class="wrapper center-block">
                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                            <div class="addMain" style="padding: 0 17px;">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12" style="border: 1px solid #4f5d78;">
                                        <div class="trade" style="padding-bottom: 8px;">
                                            Advertisement rules and requirements
                                        </div>
                                        <div class="mainPrice">
                                            <div class="priceTag">
                                                <p class="prieList">I want to...</p>
                                                <p class="prieAmount"><label class="containerRadio nutral">Sell your
                                                        bitcoin
                                                        online
                                                        <input type="radio" checked="checked" name="orderType"
                                                            formControlName="orderType" value="SELL">
                                                        <span class="checkmark"></span>
                                                    </label></p>
                                            </div>
                                            <div class="priceTag">
                                                <p class="prieList"></p>
                                                <p class="prieAmount"><label class="containerRadio nutral">Buy bitcoin
                                                        online
                                                        <input type="radio" checked="checked" name="orderType"
                                                            formControlName="orderType" value="BUY">
                                                        <span class="checkmark"></span>
                                                    </label></p>
                                            </div>

                                            <div class="priceTag">
                                                <p class="prieList">Location</p>
                                                <div class="statusTrade" style="width: calc(100% - 129px); border: 0px">
                                                    <input class="form-control" placeholder="Enter your location">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12"
                                        style="border: 1px solid #4f5d78; margin-top: 40px;">
                                        <div class="trade" style="padding-bottom: 8px;">
                                            More information
                                        </div>

                                        <div class="mainPrice" style="padding: 0px 15px;">
                                            <div class="priceTag">
                                                <p class="prieList">Currency</p>
                                                <div class="statusTrade"
                                                    style="width: calc(100% - 129px); border: 0px; margin: 12px 0;">
                                                    <input class="form-control" placeholder="USD">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mainPrice" style="padding: 0px 15px;">
                                            <div class="priceTag">
                                                <p class="prieList">Margin</p>
                                                <div class="statusTrade"
                                                    style="width: calc(100% - 129px); border: 0px; margin: 12px 0;">
                                                    <input class="form-control" placeholder="USD" maxlength="3"
                                                        formControlName="margin"
                                                        (keyup)="calcMargin($event.target.value)"
                                                        [disabled]="marketPrice == 0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mainPrice" style="padding: 0px 15px;">
                                            <div class="priceTag">
                                                <p class="prieList">Min. transaction limit</p>
                                                <div class="statusTrade"
                                                    style="width: calc(100% - 129px); border: 0px; margin: 12px 0;">
                                                    <input class="form-control" placeholder="USD"
                                                        formControlName="minTransac">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mainPrice" style="padding: 0px 15px;">
                                            <div class="priceTag">
                                                <p class="prieList">Max. transaction limit</p>
                                                <div class="statusTrade"
                                                    style="width: calc(100% - 129px); border: 0px; margin: 12px 0;">
                                                    <input class="form-control" placeholder="USD"
                                                        formControlName="maxTransac">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mainPrice" style="padding: 0px 15px;">
                                            <div class="priceTag">
                                                <p class="prieList">Terms of trade</p>
                                                <div class="statusTrade"
                                                    style="width: calc(100% - 129px); border: 0px; margin: 12px 0;">
                                                    <input class="form-control" placeholder="USD">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mainPrice" style="padding: 0px 15px;">
                                            <div class="priceTag">
                                                <p class="prieList">Add tags</p>
                                                <div class="statusTrade"
                                                    style="width: calc(100% - 129px); border: 0px; margin: 12px 0;">
                                                    <input class="form-control" placeholder="USD">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mainPrice" style="padding: 0px 15px;">
                                            <div class="priceTag">
                                                <p class="prieList">Set payment window</p>
                                                <div class="statusTrade"
                                                    style="width: calc(100% - 129px); border: 0px; margin: 12px 0;">
                                                    <input class="form-control" placeholder="USD"
                                                        formControlName="payWindow">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12"
                                        style="border: 1px solid #4f5d78; margin-top: 40px;">
                                        <div class="trade" style="padding-bottom: 8px;">
                                            Security Options
                                        </div>
                                        <div class="sellingVacation">
                                            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
                                            <label class="privacy" for="vehicle1"> Identified people only</label>
                                        </div>
                                        <div class="sellingVacation">
                                            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
                                            <label class="privacy" for="vehicle1"> SMS verification needed</label>
                                        </div>
                                    </div>
                                </div>
                                <div style="text-align: center; margin-top: 45px;">
                                    <button class="btn save-btn" [disabled]="!addForm.valid"
                                        (click)="createAdvertise()">Publish advertisement</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>