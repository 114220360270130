<div class="static-contant">
    <section>
        <div class="container1440">
            <div class="Aboutus00 mt-5">
                <div class="contactBying">
                    <h4 style="padding-bottom: 0px;">Contact #29763260 :</h4><span class="bitcoinBuy">Buying 0.01000162
                        bitcoins for 5,000.00 INR with</span>
                </div>
                <p class="adver">Buying from advertisement #283177 (National Bank Transfer) by Gonee it at the exchange
                    rate 499,919.01 INR/BTC</p>
                <div class="statusTrade">
                    <span class="tradeStatus" style="color: #03CA9B;">Trade Status:</span><span class="wating">Waiting
                        for buyer to confirm payment.</span>
                </div>
                <div class="wrapper center-block">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="" style="padding: 0 17px;">
                            <div class="row">
                                <div class="col-lg-5 col-md-12 col-sm-12">
                                    <span class="tradeStatus" style="color: #03CA9B;">Send a message to Himanshu:</span>
                                    <div class="typMess">
                                        <div class="statusTrade" style="width: 100%; max-width: 500px;">
                                            Type your message here...
                                        </div>
                                        <div class="sendMessage">
                                            Send
                                        </div>
                                    </div>
                                    <div class="statusTrade" style="width: 100%; max-width: 500px;">
                                        <div>
                                            <div style="padding: 10px 12px 0 17px;">
                                                <div class="userDate">
                                                    <p style="color: #b4b7d9;">Avinish</p>
                                                    <p style="color: #b4b7d9;">02/23/2020, 00:00:18</p>
                                                </div>
                                                <p style="color: #b4b7d9;">How to pay advansh</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-5 col-md-12 col-sm-12" style=" margin: 0 0 0 70px;">
                                    <form [formGroup]="updateFeedbackForm">
                                        <div>
                                            <span class="tradeStatus" style="color: #03CA9B;">Please leave a feedback
                                                for
                                                Himanshu</span>
                                            <label class="containerRadio positive">Positive<p style="color: #b4b7d9;">
                                                    Postive feedback will improve your partner rating</p>
                                                <input type="radio" checked="checked" name="feedbackStatus"
                                                    value="POSITIVE" formControlName="feedbackStatus">
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="containerRadio negative">Negative<p style="color: #b4b7d9;">
                                                    Negative feedback will decrease your partner rating</p>
                                                <input type="radio" checked="checked" name="feedbackStatus"
                                                    value="NEGATIVE" formControlName="feedbackStatus">
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="containerRadio nutral">Neutral<p style="color: #b4b7d9;">
                                                    Neutral
                                                    feedback will not affect your partner rating</p>
                                                <input type="radio" checked="checked" name="feedbackStatus"
                                                    value="NUTRAL" formControlName="feedbackStatus">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="statusTradeInput" style="width: 100%; max-width: 500px;">
                                            <input class="form-control" placeholder="Enter your feedback here..."
                                                formControlName="feedbackMessage">
                                        </div>
                                        <div style="text-align: center; margin-bottom:15px;">
                                            <button class="btn save-btn" (click)="updateUserFeedback()">Update
                                                Feedback</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>
<app-footer></app-footer>