<div class="static-contant">
    <section class="wraper">
        <div class="container1440">
            <div class="pt-5 pb-5">
                <div>
                    <form [formGroup]="searchForm">
                        <ul class="nav nav-pills tab-ul mt-3" id="pills-tab" role="tablist">
                            <li class="nav-item tab-li newTabinr">
                                <a class="nav-link tab-a ">
                                    <select class="search-select-box searcj" formControlName="currency" (change)='onChnageTag()'>
                                        <option value="Tags" >Tags</option>
                                        <option value="BTC">BTC</option>
                                        <!-- <option value="ETC">ETC</option> -->
                                        <option value="ETH">ETH</option>
                                    </select>
                                </a>
                            </li>
                            <li class="nav-item tab-li searchButton" (click)="search()">
                                <a class="nav-link tab-a" href="javascript:;">Search</a>
                            </li>
                            <li class="nav-item tab-li searchButton" (click)="reset()">
                                <a class="nav-link tab-a" href="javascript:;">Reset</a>
                            </li>
                        </ul>
                    </form>
                </div>

                <div>
                    <ul>
                        <li style=" margin-top: 28px; font-size: 25px ;    font-weight: 600;" class="p2pBuy">Announcement</li>
                    </ul>
                    <div class="addMain" *ngFor="let item of announcementlist">
<!--  -->
                        <div class="p2p-div">
                           <div class="announcementHeading">
                            <label for="exampleInputEmail1">{{item.title}} </label>
                           </div>
                            <div class="tradeStatus mb-2" >{{item.description}} </div>

                            <div class="row">
                                <div class="col-xl-2 col-md-4 col-sm-4">
                                    <div class="">
                                        <label for="exampleInputEmail1">Tags: </label>
                                        <span class="tradeStatus" >{{item.coinName}} </span>
                                    </div>
                                </div>
                                <div class="col-xl-10 col-md-8 col-sm-8">
                                    <div class="">
                                        <label for="exampleInputEmail1">Publish: </label>
                                        <span class="tradeStatus" >{{item.updateTime}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <!-- <div class="p2p-div">
                            <div class="announcementHeading">
                                <label for="exampleInputEmail1">Earn Daily No.277: Lock ETH & Earn, up to 0.7% APR</label>
                            </div>
                            
                            <div class="tradeStatus mb-2" >Quantom HODL& Earn recently unveiled a new product “Earn Everyday” to facilitate even more chances to earn by holding popular coins and stablecoins (such as BTC, ETH, USDT, GT and USDG) for a period of 7 days. Everyday, at least one round of the product</div>

                            <div class="row">
                                <div class="col-xl-2 col-md-4 col-sm-4">
                                    <div class="form-group ">
                                        <label for="exampleInputEmail1">Tags: </label>
                                        <span class="tradeStatus" > ETH</span>
                                    </div>
                                </div>
                                <div class="col-xl-10 col-md-8 col-sm-8">
                                    <div class="form-group ">
                                        <label for="exampleInputEmail1">Publish: </label>
                                        <span class="tradeStatus" > 5/09/2021, 5:26 PM</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!--  -->
                        <!-- <div class="p2p-div">
                            <div class="announcementHeading">
                                <label for="exampleInputEmail1">Earn Daily No.277: Lock ETH & Earn, up to 0.7% APR</label>
                            </div>
                            
                            <div class="tradeStatus mb-2" >Quantom HODL& Earn recently unveiled a new product “Earn Everyday” to facilitate even more chances to earn by holding popular coins and stablecoins (such as BTC, ETH, USDT, GT and USDG) for a period of 7 days. Everyday, at least one round of the product</div>

                            <div class="row">
                                <div class="col-xl-2 col-md-4 col-sm-4">
                                    <div class="form-group ">
                                        <label for="exampleInputEmail1">Tags: </label>
                                        <span class="tradeStatus" > ETH</span>
                                    </div>
                                </div>
                                <div class="col-xl-10 col-md-8 col-sm-8">
                                    <div class="form-group ">
                                        <label for="exampleInputEmail1">Publish: </label>
                                        <span class="tradeStatus" > 5/09/2021, 5:26 PM</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!--  -->
                        <!-- <div class="p2p-div">
                            <div class="announcementHeading">
                                <label for="exampleInputEmail1">Earn Daily No.277: Lock ETH & Earn, up to 0.7% APR</label>
                            </div>
                            
                            <div class="tradeStatus mb-2" >Quantom HODL& Earn recently unveiled a new product “Earn Everyday” to facilitate even more chances to earn by holding popular coins and stablecoins (such as BTC, ETH, USDT, GT and USDG) for a period of 7 days. Everyday, at least one round of the product</div>

                            <div class="row">
                                <div class="col-xl-2 col-md-4 col-sm-4">
                                    <div class="form-group ">
                                        <label for="exampleInputEmail1">Tags: </label>
                                        <span class="tradeStatus" > ETH</span>
                                    </div>
                                </div>
                                <div class="col-xl-10 col-md-8 col-sm-8">
                                    <div class="form-group ">
                                        <label for="exampleInputEmail1">Publish: </label>
                                        <span class="tradeStatus" > 5/09/2021, 5:26 PM</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!--  -->

                    </div>
                </div>
        
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>