<!DOCTYPE html>
  <body class="static-contant pt-5">
    
     
    <section class="wraper">
      <div class="container1440">
      <div class=" ">
        
          <ul class="mb-3">
            <li style="color: #a7a8b2;">Security > Account Activity</li>
            <li class=" heading" >Recent account activity record</li>
        
        </ul>
        <ul class="nav nav-pills tab-ul" id="pills-tab" role="tablist">
            <li class="nav-item tab-li">
              <a class="nav-link tab-a " id="pills-buy-tab" data-toggle="pill" href="#pills-buy" role="tab" aria-controls="pills-buy" aria-selected="true" (click)="next()">Login Activity</a>
            </li>
            <li class="nav-item tab-li">
              <a class="nav-link tab-a active" id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab" aria-controls="pills-sell" aria-selected="false">Security Activity</a>
            </li>
          </ul>
        <div class="table-responsive mt-4 pb-5">
            <table class="table after-logintabel action table-striped" aria-describedby="">
                <thead>
                  <tr>
                    <th id="">Date & Time</th>
                    <th id="">Source</th>
                    <th id="">Status</th>
                    <th id="">IP Address</th>
                    <th id="">Message</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let securitydetails of securityHistoryList">
                    <td>{{securitydetails.createTime | date :'medium'}}</td>
                    <td>{{securitydetails.source}}</td>
                    <td>{{securitydetails.status}}</td>
                    <td>{{securitydetails.ipAddess}}</td>
                    <td>{{securitydetails.acitvityMessage}}</td>
                  </tr>
                  <tr *ngIf="securityHistoryList == ''">
                    <td colspan="4"> No activity found</td>
                </tr>
                
                </tbody>
              </table>
        </div>
      </div>
      </div>

    </section>
    
  </body>
  <app-footer></app-footer>