<!DOCTYPE html>

<body class="static-contant pt-0">


  <section class="wraper pt-0">
    <div class="container1440">
          <div class="Aboutus00   txt00">
        <h2 class="view-intermal">Internal Transfer History</h2>
        <div class="table-responsive mt-5">
          <table class="table after-logintabel action">
              <thead>
                <tr>
                  <th scope="col">User ID</th>
                  <th scope="col">Coin type </th>
                  <th scope="col">Status</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              
              <tbody>
                <tr *ngFor="let item of historyList;">
                  <td>{{item.randomId}}</td>
                  <td>{{item.coinType}}</td>
                  <td>{{item.status}}</td>
                  <td>{{item.units}}</td>
                </tr>
                
                <tr *ngIf="historyList==0">
                  <td> No transactions found</td>
                </tr>

              </tbody>
            </table>
        </div>
    </div>

  </div>

  </section>
</body>
<app-footer></app-footer>