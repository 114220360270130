<div class="content">
    <h5 class="walletSubHeading">Recent transactions</h5>
   
    <!-- <div class="col-md-12 row emptyBalance" *ngIf="1==2"> -->
    <div class="col-md-12 row emptyBalance">
      <div class="col-md-6">
        <img src="../../../assets/images/account1.png" alt="">
        <h5>You don’t have any transactions yet</h5>
        <!-- <p>Click the deposit or buy button to make a deposit.</p> -->
        <!-- <p class="margin-top:20px"><Span class="emptyButton">Deposite</Span> <span class="emptyButton buyButton">Buy</span></p> -->
      </div>
    </div>

    <div class="transferType col-md-12">
      <!-- <div class="transferTab col-md-8 row">
        <div class="col-md-4 tabs" (click)="transferTab('transfer')" [ngClass]="{activeTab: (transferActiveTab == 'transfer' )}"> {{coinSelected}} transfers</div>
        <div class="col-md-4 tabs" (click)="transferTab('allTransfer')" [ngClass]="{activeTab: (transferActiveTab == 'allTransfer' )}">All transfers</div>
      </div> -->
      <!-- <div *ngIf="transferActiveTab == 'transfer'" class="transferData col-md-12"> -->
        <div  class="transferData col-md-12">

        <div class="table-responsive mt-2 pb-5" >
          <!-- <table class="table after-logintabel action table-striped" aria-describedby="" >
              <thead>
                <tr>
                  <th id="" >COIN</th>
                  <th id=""  class=" text-center">AMOUNT</th>
                  <th id="" class=" text-center">FROM</th>
                  <th id="" class="text-center">TO</th>
                  <th id="" class=" text-center">TIME</th>
                  <th id="" class=" text-center">STATUS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table> -->
            <form [formGroup]="filterForm">
            <div class="col-md-12 row mt-5">
              <div class="col-md-2 form-group coinPairDiv formDivs">
                <label for="coinPair">Search by coin pair </label>
                <select class="form-control coinDrop formDivs" name="" id="" style="    padding: 2px;" formControlName="coinPair" (chnage)="filterOncoinPairChange()">
                  <option value="">USDT / USD</option>
                  <option value="USDT/ZAR">USDT / ZAR</option>
                  <option value="USDT/NAIRA">USDT / NAIRA</option>
                  <option value="QT/USD">QT / USD</option>
                  <option value="QT/ZAR">QT / ZAR</option>
                  <option value="QT/NAIRA">QT / NAIRA</option>
              </select>
              </div>
              <div class="col-md-3 form-group formDivs">
                  <label for="fDate">From </label>
                  <input class="form-control formDivs" type="date" formControlName="fromDate"  max="{{minAge | date:'yyyy-MM-dd'}}">
              </div>
              <div class="col-md-3 form-group formDivs">
                  <label for="toDate">To</label>
                  <input class="form-control formDivs" type="date" formControlName="toDate" [min]="this.filterForm.controls['fromDate'].value"
                   [readonly] = "!filterForm.value.fromDate">
              </div>
              <div class="col-md-1 form-group rearch formDivs">
                <label for=""></label>
                <button class="btn theam-btn formDivs" (click)="searchByDate()">Search</button>
              </div>
              <div class="col-md-1 form-group formDivs formDivs">
                  <label for=""></label>
                <button class="btn theam-btn reset formDivs" (click)="resetSearch()">Reset</button>
                </div>
          </div>
          </form>
            <table class="table after-logintabel action table-striped" aria-describedby="" >
              <thead>
                <tr>
                  <th id="" >Pair</th>
                  <th id=""  class=" text-center">Order Type</th>
                  <th id="" class=" text-center">Volume QTE</th>
                  <th id="" class="text-center">Commission % </th>
                  <th id="" class=" text-center">Reward</th>
                  <th id="" class=" text-center">Fees</th>
                  <th id="" class=" text-center"> P/L  </th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>QTE/USDT</td>
                  <td>Sell QTE</td>
                  <td>100</td>
                  <td>0.01</td>
                  <td>0.01 QTE</td>
                  <td>0.001</td>
                  <td>0.09</td>
                </tr>
                <tr>
                  <td>QTE/USDT</td>
                  <td>Buy QTE</td>
                  <td>300</td>
                  <td>0.41</td>
                  <td>0.301 QTE</td>
                  <td>0.6001</td>
                  <td>0.19</td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="col-md-12 text-center">
              <span>You don’t have any transactions yet</span>
            </div> -->
      </div>   
      </div>
      <!-- <div *ngIf="transferActiveTab == 'allTransfer'" class="transferData col-md-12">
        <div class="table-responsive mt-2 pb-5" >
          <table class="table after-logintabel action table-striped" aria-describedby="" >
              <thead>
                <tr>
                  <th id="" >COIN</th>
                  <th id=""  class=" text-center">AMOUNT</th>
                  <th id="" class=" text-center">FROM</th>
                  <th id="" class="text-center">TO</th>
                  <th id="" class=" text-center">TIME</th>
                  <th id="" class=" text-center">STATUS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-12 text-center">
              <span>You don’t have any transactions yet</span>
            </div>
      </div>
      </div> -->
    </div>
  </div>      