
    <!-- <section class="mainBody" style="background-image: url(assets/images/supportBack.png); background-repeat: no-repeat; background-size: cover; "> -->
      <section class="mainBody" >

      <div class="container1440">
      <div class="log-in pt-5">  
            <div class="crypto-log-login left" >
              <form class="login-field-sec" [formGroup]="supportForm">
                <p class="log">Support</p>
                <div class="form-group inpt-frm">
                  <label for="exampleInputEmail1" class="add">Name</label>
                  <input type="text" class="form-control email-pass" formControlName="name"
                   placeholder="Enter full name">
                  <span >
                    <small *ngIf="supportForm.get('name').hasError('required') &&  supportForm.get('name').dirty" class="danger"
                     >*Please enter your full name.</small>
                     <small *ngIf="supportForm.get('name').hasError('pattern') &&  supportForm.get('name').dirty" class="danger"
                     >*Invalid name, only alphabets are allowed.</small>
                    
                 </span>
                </div>
                <div class="form-group inpt-frm">
                  <label for="exampleInputEmail1" class="add">Email</label>
                  <input type="email" class="form-control email-pass" formControlName="email" 
                  placeholder="Enter email address">
                  <span >
                    <small *ngIf="supportForm.get('email').hasError('required') &&  
                    supportForm.get('email').dirty" class="danger"
                     >*Please enter your email address.</small>
                     <small *ngIf="supportForm.get('email').hasError('pattern') &&  
                     supportForm.get('email').dirty" class="danger"
                     >*Invalid email address.</small>
                    
                 </span>
                </div>
                <div class="form-group reg-sec">
                  <label for="exampleInputPassword1" class="add">Subject</label>
                  <input type="text" class="form-control email-pass" formControlName="subject" placeholder="Enter subject">
                  <span >
                    <small *ngIf="supportForm.get('subject').hasError('required') &&  supportForm.get('subject').dirty" class="danger"
                     >*Please enter the subject of your concern.</small>
                    </span>
                </div>

                <div class="form-group reg-sec">
                  <label for="exampleInputPassword1" class="add">Description</label>
                  <textarea type="text" class="form-control email-pass" formControlName="description" placeholder="Description"></textarea>
                  <span >
                    <small *ngIf="supportForm.get('description').hasError('required') &&  supportForm.get('description').dirty" class="danger"
                     >*Please describe your concern.</small>
                    </span>
                </div>

                <div class="form-group reg-sec">
                  <label for="exampleInputPassword1" class="add">Upload Image</label>
                 
               <div class="attach">
                <input style="position: absolute;"  type="file" class="" (change)="uploadImg($event)" /> 
                <i class="fa fa-paperclip" aria-hidden="true"></i>
               </div>
                </div>                
                <button type="submit" class="btn register-btn" data-toggle="modal" [disabled]="supportForm.invalid" (click)="supportFunc()" >SEND</button>
              </form>
            </div>
         </div>
         </div>   
    </section>
    <app-footer></app-footer>
 