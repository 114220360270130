<body class="pt-0" data-theme-version="dark">
    <section class="dark-page ">
        <div class="container-fluid pb-4 pt-4 ">
            <div class="orderSection navSec">
                <div class="col-md-2 col-lg-1" style="padding-right: 0px; padding-left: 8px;">
                    <div class="dropdown">
                        <button class="btn btc_button" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            {{coinDetail?.CoinPair}} <i class="fa fa-caret-down pl-2" aria-hidden="true"></i></button>
                        <div class="dropdown-menu coinSelection" aria-labelledby="dropdownMenuButton">
                            <div class="drop-ad selectCoin" style="width:100%;color: white; margin-top: 1rem;">
                                <div class="" *ngFor="let pair of coinpair">
                                    <!-- <a class="dropdown-item text-light">{{pair.baseCoin}}</a> -->
                                    <div class="coinTypePair" style="cursor: pointer;"
                                        *ngFor="let exec of pair.execCoin;let i = index"
                                        (click)="getSelectedPair(pair.baseCoin,exec)">
                                        <p class="eurValue" style="font-size: 13px;
              margin-left: 10px;" [ngClass]="{'active' : (exec.toLowerCase() == this.coinDetail.execCoin && pair.baseCoin.toLowerCase() == this.coinDetail.baseCoin)}">
                                            {{exec}} / {{pair?.baseCoin}}</p>
                                        <p class="amountValue" style="font-size: 20px;"> {{pair?.marketValue || 0}}
                                        </p>
                                        <hr>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="coinPairDiv"><select class="form-control coinDrop" name="" id="">
                        <option value="">USDT / USD</option>
                        <option value="USDT/ZAR">USDT / ZAR</option>
                        <option value="USDT/NAIRA">USDT / NAIRA</option>
                        <option value="QT/USD">QT / USD</option>
                        <option value="QT/ZAR">QT / ZAR</option>
                        <option value="QT/NAIRA">QT / NAIRA</option>
                    </select></div> -->

                <div class="head-title-div">
                    <h5 class="head-title" style="color: #dbdbdb;
                    margin-top: 10px;
                    margin-left: 10px;">USD 89.80</h5>
                </div>
                <div class="paymentMethodDiv">
                    <select class="form-control coinDrop2">
                        <option *ngFor="let item of paymentList">{{item?.paymentMethod}} </option>
                    </select>
                </div>
            </div>
            <div class="row row1">
                <!-- -----------------------------------------------1st------------------------------------------ -->
                <div class="col-md-4 p2pRow main-div">
                    <div class="maincol-div thirdDiv">
                        <div class="order-book-section order_book">
                            <div class=" mt-2">
                                <button class=" btn open_order active" type="submit" (click)="orderBook('MerketDepth')"
                                    [ngClass]="{marketDepth_orderVolume: (orderBookt == 'MerketDepth')}">Order
                                    Book</button>
                                <button class=" btn open_order  ml-3" type="submit" (click)="orderBook('OrderVolume')"
                                    [ngClass]="{marketDepth_orderVolume: (orderBookt == 'OrderVolume')}">Trade
                                    History</button>
                            </div>
                        </div>
                        <!-- Order Book  -->
                        <div class="buyer_seller_mainDiv" *ngIf="orderBookt == 'MerketDepth'">
                            <img data-placement="bottom" title="Buy Sell " onmouseenter="$(this).tooltip('show')"
                                src="../../../assets/images/icon-s1.png" class="iconBuySell" style="font-size:24px ;"
                                (click)="xidOrderBook('BuyerSellers')"
                                [ngClass]="{buyer_seller: (xidOrderBookTab == 'BuyerSellers')}" alt="">
                            <img data-placement="bottom" title="Buy " onmouseenter="$(this).tooltip('show')"
                                src="../../../assets/images/icon-s2.png" class="iconBuySell" style="font-size:24px ; "
                                (click)="xidOrderBook('Buyers')"
                                [ngClass]="{buyer_seller: (xidOrderBookTab == 'Buyers')}" alt="">
                            <img data-placement="bottom" title="Sell " onmouseenter="$(this).tooltip('show')"
                                src="../../../assets/images/icon-s3.png" class="iconBuySell" style="font-size:24px ;"
                                (click)="xidOrderBook('Sellers')"
                                [ngClass]="{buyer_seller: (xidOrderBookTab == 'Sellers')}" alt="">
                            <!-- Buy -->
                            <div class="table-responsive trade_table buy_sell_table buy_sell_table2 tableH"
                                *ngIf="xidOrderBookTab == 'Buyers'">
                                <table class="table exchange_table table-striped table-striped "
                                    aria-describedby="advance exchange table">
                                    <tbody>
                                        <tr>
                                            <th id="" class="theadData" scope="col">Payment</th>
                                            <th id="" class="theadData" scope="col">Price (USD)</th>
                                            <th id="" class="theadData" scope="col">Amount (BTC)</th>
                                            <th id="" class="theadData" scope="col">Total (BTC)</th>
                                        </tr>
                                        <tr class="" *ngFor="let item of buyList">
                                            <td>Paypal</td>
                                            <td class="green-text">{{item.sellPrice}}</td>
                                            <td>{{(item.sellVolume)}}</td>
                                            <td>{{(item.qid)}}</td>
                                            <!-- <td class=""><p *ngIf="sell" class="sellButton">Sell</p></td> -->
                                        </tr>
                                        <div *ngIf="buyList.length == 0 && loginStatus" class="login_registor">No
                                            Open Order Available
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                            <!-- Sell -->
                            <div class="table-responsive  trade_table buy_sell_table buy_sell_table2 tableH"
                                *ngIf="xidOrderBookTab == 'Sellers'">
                                <table class="table exchange_table table-striped table-striped"
                                    aria-describedby="advance exchange table">
                                    <tbody>
                                        <tr>
                                            <th id="" class="theadData" scope="col">Payment</th>
                                            <th id="" class="theadData" scope="col">Price (USD)</th>
                                            <th id="" class="theadData" scope="col">Amount (BTC)</th>
                                            <th id="" class="theadData" scope="col">Total (BTC)</th>
                                        </tr>
                                        <tr class="" *ngFor="let item of sellList">
                                            <td>Paypal</td>
                                            <td class="red-text">{{item.sellPrice}}</td>
                                            <td>{{(item.sellVolume)}}</td>
                                            <td>{{(item.qid)}}</td>
                                        </tr>
                                        <div *ngIf="sellList.length == 0 && loginStatus" class="login_registor">No
                                            Open Order Available
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                            <!-- buyer seller -->
                            <div class="table-responsive  trade_table buy_sell_table buy_sell_table2 tableH"
                                *ngIf="xidOrderBookTab == 'BuyerSellers'">
                                <div class="bothTable overflow-auto" style="    height: 150px;">
                                    <table class="  table exchange_table table-striped table-striped "
                                        aria-describedby="advance exchange table">
                                        <tbody>
                                            <tr>
                                                <th id="" class="theadData" scope="col">Payment</th>
                                                <th id="" class="theadData" scope="col">Price (USD)</th>
                                                <th id="" class="theadData" scope="col">Amount (BTC)</th>
                                                <th id="" class="theadData" scope="col">Total (BTC)</th>
                                            </tr>
                                            <tr class="" *ngFor="let item of sellList">
                                                <td>Paypal</td>
                                                <td class="red-text">{{item.sellPrice}}</td>
                                                <td>{{(item.sellVolume)}}</td>
                                                <td>{{(item.qid)}}</td>
                                            </tr>
                                            <div *ngIf="sellList.length == 0 && loginStatus" class="login_registor">No
                                                Open Order Available
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="order-trade-head">
                                    <h5 style="color: rgb(43, 94, 43);    padding-top: 10px;">34.00000</h5>
                                </div>
                                <div class="bothTable overflow-auto" style="    height: 150px;">
                                    <table class="table   exchange_table table-striped  "
                                        aria-describedby="advance exchange table">
                                        <tbody>
                                            <tr class="" *ngFor="let item of buyList">
                                                <td>Paypal</td>
                                                <td class="green-text">{{item.sellPrice}}</td>
                                                <td>{{(item.sellVolume)}}</td>
                                                <td>{{(item.qid)}}</td>
                                            </tr>
                                            <div *ngIf="buyList.length == 0 && loginStatus" class="login_registor">No
                                                Open Order Available
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- Trade History -->
                        <div class="table-responsive  trade_table buy_sell_table buy_sell_table2 tableH orderVolume"
                            *ngIf="orderBookt == 'OrderVolume'">
                            <table class="table exchange_table table-striped "
                                aria-describedby="advance exchange table ">
                                <tbody>
                                    <tr>
                                        <th id="" class="theadData" scope="col">Payment</th>
                                        <th id="" class="theadData" scope="col">Price(QTE)</th>
                                        <th id="" class="theadData" scope="col">Amount(USDT)</th>
                                        <th id="" class="theadData" scope="col">Time</th>
                                    </tr>
                                    <tr class="" *ngFor="let item of orderVolumeList">
                                        <td>Upi</td>
                                        <td>{{(item.buyPrice) }}</td>
                                        <td>{{(item.sellPrice) }}</td>
                                        <td>{{(item.sellVolume) }}</td>
                                    </tr>
                                    <div *ngIf="orderVolumeList.length == 0 && loginStatus" class="login_registor">No
                                        Open Order Available
                                    </div>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <!-- -----------------------------------------------2nd------------------------------------------ -->
                <div class="col-md-4 p2pRow tableH main-div buySellDivMAin">
                    <div class="maincol-div thirdDiv ">
                        <div class="order-book-section  "
                            style="display: flex; justify-content: space-around;margin-top: 7px;">
                            <span class="buttonbuysell buttonBuy" (click)="buySellCoin('Buy')"
                                [ngClass]="{buy_sell_active: (buySellCoinTab  == 'Buy')}">Buy</span>
                            <span class="buttonbuysell buttonSell" (click)="buySellCoin('Sell')"
                                [ngClass]="{buy_sell_active: (buySellCoinTab  == 'Sell')}">Sell</span>
                        </div>

                        <div class="table-responsive trade_table buy_sell_table buy_sell_table2 tableh"
                            *ngIf="buySellCoinTab == 'Buy'">
                            <div class="limit_stoplimitDiv">
                                <span class="limit" (click)="limitStoplimit('StopLimit')"
                                    [ngClass]="{limitStoplimitActive: (limitStoplimitTab  == 'StopLimit')}">Market</span>
                                <span class="stoplimit" (click)="limitStoplimit('Limit')"
                                    [ngClass]="{limitStoplimitActive: (limitStoplimitTab  == 'Limit')}">Limit</span>
                            </div>
                            <!-- ------------------------------------------------------------------limit------------------------------------------------------------- -->
                            <div *ngIf="limitStoplimitTab  == 'Limit'" class="tab-pane fade show active"
                                id="pills-market" role="tabpanel" aria-labelledby="pills-market-tab">
                                <div class="row m-0">
                                    <div class="col-md-12 paddingCustom">
                                        <form [formGroup]="limitBuyForm" #f="ngForm">
                                            <div class="market_box">
                                                <div class="buy_div">
                                                    <p>Buy {{coinDetail?.exec}}</p>
                                                    <!-- <p>
                                            <select  style="width: 104px;">
                                                <option value="kg">LIMIT</option>
                                                <option value="gm">STOP LIMIT</option>
                                              </select>
                                              
                                        </p> -->
                                                    <p>{{coinDetail?.base}}</p>
                                                </div>
                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Coin:</label>
                                                    <div class="btc-div-text">
                                                        <select name="coins" id="coins"
                                                            class="search-select-box searcj form-control paymentMethod frm"
                                                            formControlName="coin">
                                                            <!-- <option value="">Slect Coin</option> -->
                                                            <option value="USD">USD</option>
                                                            <option value="ZAR">ZAR</option>
                                                            <option value="NAIRA">NAIRA</option>
                                                            <option value="ZAR">ZAR</option>
                                                            <option value="QT">QT </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Payment method:</label>
                                                    <div class="btc-div-text">
                                                        <select
                                                            class="search-select-box searcj form-control paymentMethod frm"
                                                            formControlName="p2pPaymentMethod">
                                                            <option value="{{item.paymentMethod}} "
                                                                *ngFor="let item of paymentList">{{item.paymentMethod}}
                                                            </option>
                                                            <!-- <option value="">Payment Method</option> -->
                                                            <!-- <option value="NationalBankTransfer">National bank transfer</option>
                                                    <option value="IMPS">IMPS</option>
                                                    <option value="paypal">Paypal</option>
                                                    <option value="paytm">Paytm</option>
                                                    <option value="UPI">UPI</option> -->
                                                        </select>
                                                    </div>
                                                </div>



                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Limit Price:</label>
                                                    <div class="btc-div-text">
                                                        <input (keypress)="restrictSpace($event);restrictChar($event)"
                                                            type="text" class="form-control frm"
                                                            formControlName="limitBuyPrice" placeholder="Price">
                                                    </div>
                                                </div>
                                                <div class="btc-form mb-1 text-light"
                                                    [ngClass]="f.submitted && (limitBuyForm.get('limitBuyQuantity').hasError('required')) ? 'errClass' : ''">
                                                    <label class=" col-form-label">Quantity:</label>
                                                    <div class="btc-div-text">
                                                        <input (keypress)="restrictSpace($event);restrictChar($event)"
                                                            type="text" class="form-control frm"
                                                            formControlName="limitBuyQuantity" id="inputPassword"
                                                            placeholder="Quantity"
                                                            (keyup)="getAmountFunc($event.target.value,'MARKETBUY')">
                                                        <span class="danger">
                                                            <div
                                                                *ngIf="(f.submitted && (limitBuyForm.get('limitBuyQuantity').hasError('required')))">
                                                                <p class="danger" padding
                                                                    style=" font-size: 10 px !important;">
                                                                    *Please enter market Quantity.
                                                                </p>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Total:</label>
                                                    <div class="btc-div-text">
                                                        <label class=" col-form-label">0.00</label>
                                                    </div>
                                                </div>
                                                <div *ngIf="!loginStatus" class="buy-btn-div">
                                                    <button style="background: #28a745!important;"
                                                        class="btn exchange_btn " [routerLink]="['/login']">Log
                                                        In or
                                                        Register Now
                                                    </button>
                                                </div>
                                                <div *ngIf="loginStatus" class="buy-btn-div">
                                                    <button style="background: #28a745!important;"
                                                        class="btn exchange_btn " (click)="placeOrderFunc('LIMITBUY')"
                                                        style="width: 70%;">Buy
                                                        {{coinDetail?.exec}}</button>
                                                    <!-- <div class="bnncl">
                                        <div class="clearFilter">
                                            <button class="btn exchange_btn ml-2"
                                                (click)="clearFilterOfBuy()">Clear
                                                Filter</button>
                                        </div>
                                    </div> -->
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- ------------------------------------------------------------------limit end------------------------------------------------------------- -->
                            <!-- ------------------------------------------------------------------market------------------------------------------------------------- -->
                            <div *ngIf="limitStoplimitTab  == 'StopLimit'" class="tab-pane fade show active"
                                id="pills-market" role="tabpanel" aria-labelledby="pills-market-tab">
                                <div class="row m-0">
                                    <div class="col-md-12 paddingCustom">
                                        <form [formGroup]="marketBuyForm" #f="ngForm">
                                            <div class="market_box">
                                                <div class="buy_div">
                                                    <p>Buy {{coinDetail?.exec}}</p>
                                                    <!-- <p>
                                <select  style="width: 104px;">
                                    <option value="kg">LIMIT</option>
                                    <option value="gm">STOP LIMIT</option>
                                  </select>
                                  
                            </p> -->
                                                    <p>{{coinDetail?.base}}</p>
                                                </div>
                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Coin:</label>
                                                    <div class="btc-div-text">
                                                        <select name="coins" id="coins"
                                                            class="search-select-box searcj form-control paymentMethod frm"
                                                            formControlName="coin">
                                                            <!-- <option value="">Slect Coin</option> -->
                                                            <option value="USD">USD</option>
                                                            <option value="ZAR">ZAR</option>
                                                            <option value="NAIRA">NAIRA</option>
                                                            <option value="ZAR">ZAR</option>
                                                            <option value="QT">QT </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Payment method:</label>
                                                    <div class="btc-div-text">
                                                        <select
                                                            class="search-select-box searcj form-control paymentMethod frm"
                                                            formControlName="p2pPaymentMethod">
                                                            <option value="" *ngFor="let item of paymentList">
                                                                {{item.paymentMethod}} </option>
                                                            <!-- <option value="">Payment Method</option> -->
                                                            <!-- <option value="NationalBankTransfer">National bank transfer</option>
                                        <option value="IMPS">IMPS</option>
                                        <option value="paypal">Paypal</option>
                                        <option value="paytm">Paytm</option>
                                        <option value="UPI">UPI</option> -->
                                                        </select>
                                                    </div>
                                                </div>



                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Price:</label>
                                                    <div class="btc-div-text">
                                                        <input (keypress)="restrictSpace($event);restrictChar($event)"
                                                            type="text" class="form-control frm"
                                                            formControlName="marketBuyPrice" placeholder="Price"
                                                            disabled>
                                                    </div>
                                                </div>
                                                <div class="btc-form mb-1 text-light"
                                                    [ngClass]="f.submitted && (marketBuyForm.get('marketBuyquantity').hasError('required')) ? 'errClass' : ''">
                                                    <label class=" col-form-label">Quantity:</label>
                                                    <div class="btc-div-text">
                                                        <input (keypress)="restrictSpace($event);restrictChar($event)"
                                                            type="text" class="form-control frm"
                                                            formControlName="marketBuyquantity" id="inputPassword"
                                                            placeholder="Quantity"
                                                            (keyup)="getAmountFunc($event.target.value,'MARKETBUY')">
                                                        <span class="danger">
                                                            <div
                                                                *ngIf="(f.submitted && (marketBuyForm.get('marketBuyquantity').hasError('required')))">
                                                                <p class="danger" padding
                                                                    style=" font-size: 10 px !important;">
                                                                    *Please enter market volume.
                                                                </p>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Total:</label>
                                                    <div class="btc-div-text">
                                                        <label class=" col-form-label">0.00</label>
                                                    </div>
                                                </div>
                                                <div *ngIf="!loginStatus" class="buy-btn-div">
                                                    <button style="background: #28a745!important;"
                                                        class="btn exchange_btn " [routerLink]="['/login']">Log
                                                        In or
                                                        Register Now
                                                    </button>
                                                </div>
                                                <div *ngIf="loginStatus" class="buy-btn-div">
                                                    <button style="background: #28a745!important;"
                                                        class="btn exchange_btn " (click)="placeOrderFunc('MARKETBUY')"
                                                        style="width: 70%;">Buy
                                                        {{coinDetail?.exec}}</button>
                                                    <!-- <div class="bnncl">
                            <div class="clearFilter">
                                <button class="btn exchange_btn ml-2"
                                    (click)="clearFilterOfBuy()">Clear
                                    Filter</button>
                            </div>
                        </div> -->
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- ------------------------------------------------------------------market end------------------------------------------------------------- -->
                        </div>

                        <!-- sell tab section -->
                        <div class="table-responsive  trade_table buy_sell_table buy_sell_table2 tableH"
                            *ngIf="buySellCoinTab == 'Sell'">
                            <div class="limit_stoplimitDiv">
                                <span class="StopLimit limit" (click)="limitStoplimit('StopLimit')"
                                    [ngClass]="{limitStoplimitActive: (limitStoplimitTab  == 'StopLimit')}">Market</span>
                                <span class="limit" (click)="limitStoplimit('Limit')"
                                    [ngClass]="{limitStoplimitActive: (limitStoplimitTab  == 'Limit')}">Limit</span>
                            </div>

                            <!-- -----------------------------------------------limit ------------------------------------------ -->
                            <div *ngIf="limitStoplimitTab  == 'Limit'" class="tab-pane fade show active"
                                id="pills-market" role="tabpanel" aria-labelledby="pills-market-tab">
                                <div class="row m-0">
                                    <div class="col-md-12 paddingCustom">
                                        <form [formGroup]="limitSellForm" #f="ngForm">
                                            <div class="market_box">
                                                <div class="buy_div">
                                                    <p>Sell {{coinDetail?.exec}}</p>
                                                    <!-- <p>
                                            <select  style="width: 104px;">
                                                <option value="kg">LIMIT</option>
                                                <option value="gm">STOP LIMIT</option>
                                              </select>
                                              
                                        </p> -->
                                                    <p>{{coinDetail?.base}}</p>
                                                </div>
                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Coin:</label>
                                                    <div class="btc-div-text">
                                                        <select name="coins" id="coins"
                                                            class="search-select-box searcj form-control paymentMethod frm"
                                                            formControlName="coin">
                                                            <!-- <option value="">Slect Coin</option> -->
                                                            <option value="USD">USD</option>
                                                            <option value="ZAR">ZAR</option>
                                                            <option value="NAIRA">NAIRA</option>
                                                            <option value="ZAR">ZAR</option>
                                                            <option value="QT">QT </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Payment method:</label>
                                                    <div class="btc-div-text">
                                                        <select
                                                            class="search-select-box searcj form-control paymentMethod frm"
                                                            formControlName="p2pPaymentMethod">
                                                            <option value="" *ngFor="let item of paymentList">
                                                                {{item.paymentMethod}} </option>
                                                            <!-- <option value="">Payment Method</option> -->
                                                            <!-- <option value="NationalBankTransfer">National bank transfer</option>
                                                    <option value="IMPS">IMPS</option>
                                                    <option value="paypal">Paypal</option>
                                                    <option value="paytm">Paytm</option>
                                                    <option value="UPI">UPI</option> -->
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Limit Price:</label>
                                                    <div class="btc-div-text">
                                                        <input (keypress)="restrictSpace($event);restrictChar($event)"
                                                            type="text" class="form-control frm"
                                                            formControlName="limitSellPrice" placeholder="Price">
                                                    </div>
                                                </div>
                                                <div class="btc-form mb-1 text-light"
                                                    [ngClass]="f.submitted && (limitSellForm.get('limitSellQuantity').hasError('required')) ? 'errClass' : ''">
                                                    <label class=" col-form-label">Quantity:</label>
                                                    <div class="btc-div-text">
                                                        <input (keypress)="restrictSpace($event);restrictChar($event)"
                                                            (keyup)="restrictLength('bsq');" type="text"
                                                            class="form-control frm" formControlName="limitSellQuantity"
                                                            id="inputPassword" placeholder="Quantity"
                                                            (keyup)="getAmountFunc($event.target.value,'MARKETBUY')">

                                                        <span class="danger">
                                                            <div
                                                                *ngIf="(f.submitted && (limitSellForm.get('limitSellQuantity').hasError('required')))">
                                                                <p class="danger" padding
                                                                    style=" font-size: 10 px !important;">
                                                                    *Please enter market Quantity.
                                                                </p>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Total:</label>
                                                    <div class="btc-div-text">
                                                        <label class=" col-form-label">0.00</label>
                                                    </div>
                                                </div>
                                                <div *ngIf="!loginStatus" class="buy-btn-div">
                                                    <button class="btn exchange_btn" [routerLink]="['/login']"
                                                        style=" background: #dc3545!important;">Log
                                                        In or
                                                        Register Now
                                                    </button>
                                                </div>
                                                <div class="registerTrade botomBtn buy-btn-div" *ngIf="loginStatus">
                                                    <button class="btn exchange_btn"
                                                        (click)="placeOrderFunc('LIMITSELL')"
                                                        style="width: 70%; background: #dc3545!important;">Sell
                                                        {{coinDetail?.exec}}</button>
                                                    <!-- <div class="bnncl">
                                        <div class="clearFilter">
                                            <button type="button" class="btn exchange_btn ml-2"
                                                (click)="clearFilterOfSell()">Clear
                                                Filter</button>
                                        </div>
                                    </div> -->
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- -----------------------------------------------limit end------------------------------------------ -->
                            <!-- -----------------------------------------------market------------------------------------------ -->
                            <div *ngIf="limitStoplimitTab  == 'StopLimit'" class="tab-pane fade show active"
                                id="pills-market" role="tabpanel" aria-labelledby="pills-market-tab">
                                <div class="row m-0">
                                    <div class="col-md-12 paddingCustom">
                                        <form [formGroup]="marketSellForm" #f="ngForm">
                                            <div class="market_box">
                                                <div class="buy_div">
                                                    <p>Sell {{coinDetail?.exec}}</p>
                                                    <!-- <p>
                                        <select  style="width: 104px;">
                                            <option value="kg">LIMIT</option>
                                            <option value="gm">STOP LIMIT</option>
                                          </select>
                                          
                                    </p> -->
                                                    <p>{{coinDetail?.base}}</p>
                                                </div>
                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Coin:</label>
                                                    <div class="btc-div-text">
                                                        <select name="coins" id="coins"
                                                            class="search-select-box searcj form-control paymentMethod frm"
                                                            formControlName="coin">
                                                            <!-- <option value="">Slect Coin</option> -->
                                                            <option value="USD">USD</option>
                                                            <option value="ZAR">ZAR</option>
                                                            <option value="NAIRA">NAIRA</option>
                                                            <option value="ZAR">ZAR</option>
                                                            <option value="QT">QT </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Payment method:</label>
                                                    <div class="btc-div-text">
                                                        <select
                                                            class="search-select-box searcj form-control paymentMethod frm"
                                                            formControlName="p2pPaymentMethod">
                                                            <option value="" *ngFor="let item of paymentList">
                                                                {{item.paymentMethod}} </option>
                                                            <!-- <option value="">Payment Method</option> -->
                                                            <!-- <option value="NationalBankTransfer">National bank transfer</option>
                                                <option value="IMPS">IMPS</option>
                                                <option value="paypal">Paypal</option>
                                                <option value="paytm">Paytm</option>
                                                <option value="UPI">UPI</option> -->
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Price:</label>
                                                    <div class="btc-div-text">
                                                        <input (keypress)="restrictSpace($event);restrictChar($event)"
                                                            type="text" class="form-control frm"
                                                            formControlName="marketSellPrice" placeholder="Price"
                                                            disabled>
                                                    </div>
                                                </div>
                                                <div class="btc-form mb-1 text-light"
                                                    [ngClass]="f.submitted && (marketSellForm.get('marketSellquantity').hasError('required')) ? 'errClass' : ''">
                                                    <label class=" col-form-label">Quantity:</label>
                                                    <div class="btc-div-text">
                                                        <input (keypress)="restrictSpace($event);restrictChar($event)"
                                                            (keyup)="restrictLength('bsq');" type="text"
                                                            class="form-control frm"
                                                            formControlName="marketSellquantity" id="inputPassword"
                                                            placeholder="Quantity"
                                                            (keyup)="getAmountFunc($event.target.value,'MARKETBUY')">

                                                        <span class="danger">
                                                            <div
                                                                *ngIf="(f.submitted && (marketSellForm.get('marketSellquantity').hasError('required')))">
                                                                <p class="danger" padding
                                                                    style=" font-size: 10 px !important;">
                                                                    *Please enter market volume.
                                                                </p>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="btc-form mb-1 text-light">
                                                    <label class=" col-form-label">Total:</label>
                                                    <div class="btc-div-text">
                                                        <label class=" col-form-label">0.00</label>
                                                    </div>
                                                </div>
                                                <div *ngIf="!loginStatus" class="buy-btn-div">
                                                    <button class="btn exchange_btn" [routerLink]="['/login']"
                                                        style=" background: #dc3545!important;">Log
                                                        In or
                                                        Register Now
                                                    </button>
                                                </div>
                                                <div class="registerTrade botomBtn buy-btn-div" *ngIf="loginStatus">
                                                    <button class="btn exchange_btn"
                                                        (click)="placeOrderFunc('MARKETSELL')"
                                                        style="width: 70%; background: #dc3545!important;">Sell
                                                        {{coinDetail?.exec}}</button>
                                                    <!-- <div class="bnncl">
                                    <div class="clearFilter">
                                        <button type="button" class="btn exchange_btn ml-2"
                                            (click)="clearFilterOfSell()">Clear
                                            Filter</button>
                                    </div>
                                </div> -->
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- -----------------------------------------------market end------------------------------------------ -->
                        </div>


                    </div>
                </div>



                <!-- -----------------------------------------------3rd------------------------------------------ -->
                <div class="col-md-4 p2pRow main-div ">
                    <div class="maincol-div thirdDiv">
                        <!-- <div class="order-book-section">

                <select class="form-control coinDrop frm" name="" id="" >
                    <option value="">USDT / USD</option>
                    <option value="USDT/ZAR">USDT / ZAR</option>
                    <option value="USDT/NAIRA">USDT / NAIRA</option>
                    <option value="QT/USD">QT / USD</option>
                    <option value="QT/ZAR">QT / ZAR</option>
                    <option value="QT/NAIRA">QT / NAIRA</option>
                </select>
                <div class="head-title-div" >
                    <h5 class="head-title">USD 89.80</h5>
                </div>
            </div> -->

                        <!-- <div class="col-lg-10"> -->
                        <div class="tab-content trade_table" id="v-pills-tabContent ">
                            <div class="tab-pane fade show active" id="v-pills-pro" role="tabpanel"
                                aria-labelledby="v-pills-home-tab">
                                <div class="cover-myprofile " style="padding-bottom: 5px;">
                                    <div class="titleProfile">
                                        <h2 class="cryptoWallet">P2P Markets</h2>
                                        <p class="userEmail marketttt">Use Quontom P2P when you want to buy USDT to
                                            trade
                                            cryptos, or when you want to sell USDT and cash out to INR. It's
                                            safe and hassle free!</p>
                                        <p class="userEmail reserve">How it works</p>
                                        <div class="cover">
                                            <div class="bankAdd mt-3">
                                                <!-- <p class="complete">Get your link</p> -->
                                                <p class="userEmail">Pay directly to seller bank account</p>
                                            </div>
                                            <div class="bankAdd mt-3">
                                                <!-- <p class="complete">Share your link</p> -->
                                                <p class="userEmail">Seller confirms payment</p>
                                            </div>
                                            <div class="bankAdd mt-3">
                                                <!-- <p class="complete">Earn when they trade</p> -->
                                                <p class="userEmail">Quantom releases locked USDT to your
                                                    account </p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
            <div class="row row2">
                <!-- -----------------------------------------------4th------------------------------------------ -->

                <!-- <div class="col-md-4 p2pRow">
        <div class="maincol-div thirdDiv">
            <div class="order-book-section">
                <h5 class="head-title">QT ORDER BOOK</h5>
                <div class=" mt-2">
                    <button class=" btn open_order active" type="submit"
                        (click)="xidOrderBook('Buyers')" [ngClass]="{marketDepth_orderVolume: (xidOrderBookTab == 'Buyers')}">Buyers</button>
                    <button class=" btn open_order  ml-3" type="submit"
                        (click)="xidOrderBook('Sellers')" [ngClass]="{marketDepth_orderVolume: (xidOrderBookTab == 'Sellers')}">Sellers</button>
                </div>
            </div>

            <div class="table-responsive trade_table buy_sell_table buy_sell_table2 tableH"
                *ngIf="xidOrderBookTab == 'Buyers'">
                <table class="table exchange_table" aria-describedby="advance exchange table">
                    <tbody>
                        <tr>
                            <th id="" class="theadData">Buy Price</th>
                            <th id="" class="theadData">Volume</th>
                            <th id="" class="theadData">QT</th>
                            <th class="blockTD">Button </th>
                        </tr> -->
                <!-- <tr class=""
                        *ngFor="let orderitem of openOrderList | paginate: { itemsPerPage: 5, currentPage: openOrderPageNumber, totalItems: openOrderList.length , id:'openOrder' } ; let i=index ">
                        <td>{{orderitem.pair}}</td>
                        <td>{{manageExponential(orderitem.price) | number: '.8-8'}}</td>
                        <td>{{manageExponential(orderitem.amount) | number: '.8-8'}}</td>
                    </tr> -->
                <!-- <tr class="" *ngFor="let item of buyList">
                            <td class="green-text">{{item.sellPrice}}</td>
                            <td>{{(item.sellVolume)}}</td>
                            <td>{{(item.qid)}}</td>
                            <td class="">
                                <p *ngIf="sell" class="sellButton">Sell</p>
                               
                            </td>
                        </tr>
                        <div *ngIf="buyList.length == 0 && loginStatus" class="login_registor">No
                            Open Order Available
                        </div> -->
                <!-- <div *ngIf="!loginStatus" class="login_registor">
                            <p [routerLink]="['/login']">Log In</p> or<p
                                [routerLink]="['/register']">
                                Register
                            </p> Now To Trade
                        </div> -->
                <!-- </tbody>
                </table>
            </div>

            <div class="table-responsive  trade_table buy_sell_table buy_sell_table2 tableH"
                *ngIf="xidOrderBookTab == 'Sellers'" >
                <table class="table exchange_table" aria-describedby="advance exchange table">
                    <tbody>
                        <tr >
                            <th id="" class="theadData">Buy Price</th>
                            <th id="" class="theadData">Volume</th>
                            <th id="" class="theadData">QT</th>
                            
                        </tr> -->
                <!-- <tr class=""
                        *ngFor="let orderitem of openOrderList | paginate: { itemsPerPage: 5, currentPage: openOrderPageNumber, totalItems: openOrderList.length , id:'openOrder' } ; let i=index ">
                        <td>{{orderitem.pair}}</td>
                        <td>{{manageExponential(orderitem.price) | number: '.8-8'}}</td>
                        <td>{{manageExponential(orderitem.amount) | number: '.8-8'}}</td>
                    </tr> -->
                <!-- <tr class="" *ngFor="let item of sellList">
                            <td class="red-text">{{item.sellPrice}}</td>
                            <td>{{(item.sellVolume)}}</td>
                            <td>{{(item.qid)}}</td>
                            <td class="">
                                <p *ngIf="buy" class="buyButton">Buy</p>
                            </td>
                        </tr>
                        <div *ngIf="sellList.length == 0 && loginStatus" class="login_registor">No
                            Open Order Available
                        </div> -->
                <!-- <div *ngIf="!loginStatus" class="login_registor">
                            <p [routerLink]="['/login']">Log In</p> or<p
                                [routerLink]="['/register']">
                                Register
                            </p> Now To Trade
                        </div> -->
                <!-- </tbody>
                </table>
            </div>
        </div>
    </div> -->
                <!-- -----------------------------------------------5th------------------------------------------ -->

                <div class="col-md-8 p2pRow main-div third">
                    <div class="maincol-div thirdDiv">
                        <!-- <div id="tradingview_Ohl"></div> -->
                        <div class=" mt-2">
                            <button class=" btn open_order active" type="submit" (click)="showOrderStatus('open')"
                                [ngClass]="{marketDepth_orderVolume: (orderTab == 'open')}">Open Orders</button>
                            <button class=" btn open_order  ml-3" type="submit" (click)="showOrderStatus('history')"
                                [ngClass]="{marketDepth_orderVolume: (orderTab == 'history')}">Completed Orders</button>
                        </div>
                        <!-- -----------------------------------------------------open order ------------------------------------------------------- -->
                        <div class="table-responsive trade_table buy_sell_table buy_sell_table2 tableH"
                            *ngIf="orderTab == 'open'">
                            <table class="table exchange_table table-striped "
                                aria-describedby="advance exchange table">
                                <tbody>
                                    <tr>
                                        <th id="" class="theadData">Pair</th>
                                        <th id="" class="theadData">Price</th>
                                        <th id="" class="theadData">Amount</th>
                                        <th id="" class="theadData">Total</th>
                                        <th id="" class="theadData">Order Status</th>
                                        <!-- <th id="" class="theadData">Order Side</th> -->
                                        <th id="" class="theadData">Order Type</th>
                                        <!-- <th id="" class="theadData">Filled %</th> -->
                                        <th id="" class="theadData">Date & Time</th>
                                        <th id="" class="theadData">Action</th>
                                    </tr>
                                    <tr class=""
                                        *ngFor="let orderitem of openOrderDataList | paginate: { itemsPerPage: 5, currentPage: openOrderPageNumber, totalItems: openOrderList.length , id:'openOrder' } ; let i=index ">
                                        <td>{{orderitem.coinName}}</td>
                                        <td>{{manageExponential(orderitem.price) | number: '.8-8'}}</td>
                                        <td>{{manageExponential(orderitem.quatity) | number: '.8-8'}}</td>
                                        <td>{{manageExponential(orderitem.totalAmount) | number: '.8-8'}}</td>
                                        <td *ngIf="orderitem?.orderStatus === 'QUEUED'">Delivered</td>
                                        <td *ngIf="orderitem?.orderStatus !== 'QUEUED'">
                                            {{orderitem?.orderStatus}}
                                        </td>
                                        <!-- <td>{{orderitem.orderSide}}</td> -->
                                        <td>{{orderitem.orderType}}</td>
                                        <!-- <td>{{orderitem.happening }}</td> -->
                                        <td>{{orderitem.createTime }}</td>
                                        <td>
                                            <!-- <img class="img" src="assets/images/del.png" alt="" (click)="openCancel(orderitem.orderId)"> -->
                                            <button (click)="openCancel(orderitem.orderId)">Cancel</button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <div *ngIf="openOrderDataList?.length == 0 && loginStatus" class="login_registor reg">No
                                Open Order Available
                            </div>
                            <div *ngIf="!loginStatus" class="login_registor reg">
                                <p [routerLink]="['/login']">Log In</p> or<p [routerLink]="['/register']">
                                    Register</p> Now To Trade
                            </div>
                            <div class="pageContent">
                                <pagination-controls *ngIf="openOrderDataList?.length > 5" id='openOrder'
                                    (pageChange)="openOrderPageNumber = $event"></pagination-controls>
                            </div>
                        </div>
                        <!-- -----------------------------------------------------completed order ------------------------------------------------------- -->

                        <div class="table-responsive  trade_table buy_sell_table buy_sell_table2 tableH"
                            *ngIf="orderTab == 'history'">
                            <table class="table  exchange_table table-striped "
                                aria-describedby="advance exchange table">
                                <tbody>
                                    <tr>
                                        <th id="" class="theadData">Pair</th>
                                        <th id="" class="theadData">Price</th>
                                        <th id="" class="theadData">Amount</th>
                                        <th id="" class="theadData">Total</th>
                                        <th id="" class="theadData">Order Status</th>
                                        <!-- <th id="" class="theadData">Order Side</th> -->
                                        <th id="" class="theadData">Order Type</th>
                                        <!-- <th id="" class="theadData">Filled %</th> -->
                                        <th id="" class="theadData">Date & Time</th>
                                    </tr>
                                    <tr class=""
                                        *ngFor="let data of completedOrderDataList | paginate: { itemsPerPage: 5, currentPage: orderHistoryPageNumber, totalItems: orderHistoryList.length , id:'orderHistory' } ; let i=index ">
                                        <td>{{data.coinName}}</td>
                                        <td>{{manageExponential(data.price) | number: '.8-8'}}</td>
                                        <td>{{manageExponential(data.quatity) | number: '.8-8'}}</td>
                                        <td>{{manageExponential(data.totalAmount) | number:'.8-8'}}</td>
                                        <td>{{data.orderStatus}}</td>
                                        <!-- <td>{{data.orderSide}}</td> -->
                                        <td>{{data.orderType}}</td>
                                        <!-- <td>{{data.happening | number: '.8-8'}}</td> -->
                                        <td>{{data.createTime | date: 'MMM d, y, hh:mm:ss'}}</td>

                                    </tr>
                                    <div *ngIf="completedOrderDataList?.length == 0 && loginStatus "
                                        class="login_registor reg">
                                        No Completed Order
                                        Available
                                    </div>

                                    <div *ngIf="!loginStatus" class="login_registor reg">
                                        <p [routerLink]="['/login']">Log In</p> or<p [routerLink]="['/register']">
                                            Register</p> Now To Trade
                                    </div>
                                </tbody>
                            </table>
                            <div class="pageContent">
                                <pagination-controls id='orderHistory' *ngIf="completedOrderDataList?.length > 5"
                                    (pageChange)="orderHistoryPageNumber = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- -------------------------------------------------------------6th------------------------------------------------------------------ -->
                <div class="col-md-4 p2pRow main-div third">
                    <div class="maincol-div thirdDiv">
                        <div class="order-book-section" style="padding: 3px;    justify-content: center;">
                            <h5 class="head-title" style="color: #035002;;">TRADING RULES</h5>
                        </div>
                        <div class="table-responsive trade_table buy_sell_table buy_sell_table2 tableH">
                            <p>An instant P2P buying and selling of cryptos with fiat instantly.</p>
                            <p style="color: black;">Buyer places buy oredr of crypto and deposite /pays money to the
                                QTE holding
                                respective Wallet types( card , bank , mobile money , paypal, etc ) sellers paces sell
                                order
                                transfer crypto assets to QTE holdings Wallet.</p>
                        </div>
                    </div>
                </div>

            </div>


            <!-- 
aria-expandedd
sdasds
dfadfsdf
fsdfsdf -->


        </div>
    </section>
</body>




<!-- Delete Open Order Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content after-login-modal">
            <div class="modal-header">
                <h5 class="modal-title">Delete Order</h5>
                <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Do you want to delete this placed order?</p>
                <div class="btn-modal">
                    <button class="btn theam-btn mr-2" (click)="deleteOpenOrder()">Delete</button>
                    <button class="btn theam-btn2 ml-2" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="validation-modal" class="modal">
    <!-- Modal content -->
    <div class="modal-content">
        <header class="modal-heaader">
            <h5>Place Order</h5>
            <button type="button" class="cloose" data-dismiss="modal">&times;</button>
        </header>
        <div>
            <div class="row">
                <p>{{errMsg}}</p>
            </div>
            <div class="chnges-btn">
                <div class="cancel">
                    <button type="submit" data-dismiss="modal" style="outline: 0">OK</button>
                </div>
            </div>
        </div>
    </div>
</div>