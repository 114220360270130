<body class="static-contant">
  <div class="container1440">
    <ul class="nav nav-pills tab-ul" id="pills-tab" role="tablist">
      <li class="nav-item tab-li">
        <a class="nav-link tab-a active" id="pills-buy-tab" data-toggle="pill" href="#pills-buy" role="tab"
          aria-controls="pills-buy" aria-selected="true">Buy</a>
      </li>
      <li class="nav-item tab-li">
        <a class="nav-link tab-a" id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab"
          aria-controls="pills-sell" aria-selected="false">Sell</a>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-buy" role="tabpanel" aria-labelledby="pills-buy-tab">
        <div class="row ">
          <div class="col-lg-3 col-md-12">
            <div class="card-div">
              <div class="search-div">
                <input type="search" class="form-control" placeholder="Search by coin">
                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
              <div class="cad-box mt-4">
                <ul>
                  <li>
                    <figure><img src="assets/images/bitcoin.png" alt=""></figure>
                    <div class="card-text">
                      <h6>Bitcoin (BTC)</h6>
                      <ul class="text-ul">
                        <li>Price</li>
                        <li>$5,223.62</li>
                        <li>Volume</li>
                        <li> $12,907,329,</li>
                        <li>Average</li>
                        <li>
                          $173.05</li>
                        <li>Change</li>
                        <li class="red-text">+1.09</li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-9 col-md-12">
            <div class="wallet-div ">
              <div class="row mb-3 align-items-end">
                <div class="col-lg-4 col-md-12">
                  <div class="form-group date-div">
                    <label>Buy</label>
                    <div class="search-div ">
                      <input type="text" class="form-control " placeholder="Quantity">
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <div class="form-group date-div">
                    <label>Select Your Currency</label>
                    <div class="search-div ">
                      <select class="form-control after-select">
                        <option>USD</option>
                        <option>USD</option>
                        <option>USD</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <div class="form-group date-div">
                    <label>Price</label>
                    <div class="search-div ">
                      <input type="text" class="form-control " placeholder="00.00">
                    </div>
                  </div>
                </div>
              </div>
              <div class="buy-box pt-5">
                <h6>Select Your Payment Option</h6>
                <ul>
                  <li><img src="assets/images/wallet.png" alt="">
                    <p>Your Wallet</p>
                    <img src="assets/images/right-icon.png" alt="" class="right-img">
                  </li>
                  <li><img src="assets/images/bank.png" alt="">
                    <p>Bank Wiret</p>
                  </li>
                </ul>
                <div class="btn-modal">
                  <button class="btn btn-theam " data-toggle="modal" data-target="#buy-confirm">CALCULATE</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="tab-pane fade" id="pills-sell" role="tabpanel" aria-labelledby="pills-sell-tab">
        <div class="row ">
          <div class="col-lg-3 col-md-12">
            <div class="card-div">
              <div class="search-div">
                <input type="search" class="form-control" placeholder="Search by coin">
                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
              <div class="cad-box mt-4">
                <ul>
                  <li>
                    <figure><img src="assets/images/bitcoin.png" alt=""></figure>
                    <div class="card-text">
                      <h6>Bitcoin (BTC)</h6>
                      <ul class="text-ul">
                        <li>Price</li>
                        <li>$5,223.62</li>
                        <li>Volume</li>
                        <li> $12,907,329,</li>
                        <li>Average</li>
                        <li>
                          $173.05</li>
                        <li>Change</li>
                        <li class="red-text">+1.09</li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-9 col-md-12">
            <div class="wallet-div ">
              <div class="row mb-3 align-items-end">
                <div class="col-lg-4 col-md-12">
                  <div class="form-group date-div">
                    <label>Buy</label>
                    <div class="search-div ">
                      <input type="text" class="form-control " placeholder="Quantity">
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <div class="form-group date-div">
                    <label>Select Your Currency</label>
                    <div class="search-div ">
                      <select class="form-control after-select">
                        <option>USD</option>
                        <option>USD</option>
                        <option>USD</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <div class="form-group date-div">
                    <label>Price</label>
                    <div class="search-div ">
                      <input type="text" class="form-control " placeholder="00.00">
                    </div>
                  </div>
                </div>
              </div>
              <div class="buy-box pt-5">
                <h6>Select Your Payment Option</h6>
                <ul>
                  <li><img src="assets/images/wallet.png" alt="">
                    <p>Your Wallet</p>
                    <img src="assets/images/right-icon.png" alt="" class="right-img">
                  </li>
                  <li><img src="assets/images/bank.png" alt="">
                    <p>Bank Wiret</p>
                  </li>
                </ul>
                <div class="btn-modal">
                  <button class="btn btn-theam mr-2">CALCULATE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
<app-footer></app-footer>

<!-- buy-confirm modal -->
<div class="modal fade" id="buy-confirm" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">Buy confirmation</h5>
        <button type="button" class="close " data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body market-modal">
        <p>Market Price- 5.066.15</p>
        <p>You have to pay 75992.25 USD for buying 15 BTC.
          Would you like to continue?</p>
        <div class="btn-modal">
          <button class="btn theam-btn mr-2">Ok</button>
          <button class="btn theam-btn2 ml-2">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- buy-confirm modal end -->