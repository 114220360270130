<!-- <!DOCTYPE html> -->

<body class="static-contant pt-0">
  <section class="wraper pt-0">
    <div class="container1440 box">
      <div class="Aboutus00   txt00">
        <h2 class="view-intermal">Escrow Transfer History</h2>

        <div class="table-responsive mt-5">
          <table class="table after-logintabel action" aria-describedby="escrow transfer table">
            <thead>
              <tr>
                <th scope="col">User ID</th>
                <th scope="col">Coin type </th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of historyList;">
                <td>{{item.toRandomId}}</td>
                <td>{{item.baseCoin}}</td>
                <td>{{item.amount}}</td>
                <td>{{item.user1Status}}</td>
                <td class="internal-td">
                  <button
                    *ngIf="item.statusType==='SEND' && item.user1Status!='COMPLETED' && item.user1Status!='DISPUTED' && item.user1Status!='CANCELLED'"
                    (click)="sender(item.userToAdminTransferId)" class="btn btn-VIEW mr-2" data-toggle="modal"
                    data-target="#SEND">ACCEPT</button>
                  <button
                    *ngIf="item.statusType==='RECEIVE' && item.user1Status!='COMPLETED' && item.user1Status!='DISPUTED' && item.user1Status!='CANCELLED'"
                    (click)="sender(item.userToAdminTransferId)" class="btn btn-VIEW mr-2" data-toggle="modal"
                    data-target="#RECEIVE">ACCEPT</button>
                  <button *ngIf="item.user1Status=='WAITING'" class="btn btn-cancel"
                    (click)="cancelReq(item.userToAdminTransferId)">REJECT</button>
                  <button *ngIf="item.user1Status=='PAID'" class="btn btn-cancel"
                    (click)="dispute(item.userToAdminTransferId)">DISPUTE</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</body>
<app-footer></app-footer>

<div class="modal fade" id="RECEIVE" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">Are you sure you want to accept this request? </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body escrow-body">
        <button class="btn btn-transfar mt-3" (click)="viewSendar(2,'RECEIVE')">Accept</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="SEND" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">Are you sure you want to release coins?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body escrow-body">
        <button class="btn btn-transfar mt-3" (click)="viewSendar(1,'SEND')"> Release</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="rejectModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">Reject Request</h5>
        <button type="button" class="close " data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Do you want to reject this request?</p>
        <div class="btn-modal">
          <!-- <button class="btn theam-btn mr-2" (click)="cancelReq()">Yes</button> -->
          <button class="btn theam-btn mr-2" (click)="cancelReq(adminid)">Yes</button>
          <button class="btn theam-btn2 ml-2" data-dismiss="modal">No</button>
        </div>
      </div>
    </div>
  </div>
</div>
