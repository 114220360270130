<section class="register-sec001">
    <div class="container1440">
        <div class="log-in-reg">
            <div class="profle-sec">
                <img [src]="imageUrl || 'assets/images/profile-img.jpg'" alt="">
                <i class="fa fa-camera cmra-icon" aria-hidden="true">
                    <input type="file" class="input-file" accept=".png, .jpg, .jpeg" (change)="uploadImg($event)" />
                </i>
            </div>

            <form class="login-field-sec00" [formGroup]="signupTwoForm">
                <p>User Information</p>

                <div class="form-group row">
                    <div class="col-md-4"> <input type="email" maxlength="60" formControlName="firstName"
                            class="form-control email-pass-pro" placeholder="First Name">
                        <span>
                            <small
                                *ngIf="signupTwoForm.get('firstName').hasError('required') &&  signupTwoForm.get('firstName').dirty"
                                class="danger" padding>*First name field cannot be empty.</small>
                            <small
                                *ngIf="signupTwoForm.get('firstName').hasError('pattern') &&  signupTwoForm.get('firstName').dirty"
                                class="danger" padding>*Invalid first name.</small>
                        </span>
                    </div>

                    <div class="col-md-4"> <input type="email" maxlength="60" formControlName="lastName"
                            class="form-control email-pass" placeholder="Last Name">
                        <span>
                            <small
                                *ngIf="signupTwoForm.get('lastName').hasError('required') &&  signupTwoForm.get('lastName').dirty"
                                class="danger" padding>*Last name field cannot be empty.</small>
                            <small
                                *ngIf="signupTwoForm.get('lastName').hasError('pattern') &&  signupTwoForm.get('lastName').dirty"
                                class="danger" padding>*Invalid last name.</small>
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <my-date-picker name="startdate" class="email-pass-pro" formControlName='dob'
                            [options]="myDatePickerOptions" placeholder="Date of birth">
                        </my-date-picker>
                        <span>
                            <small
                                *ngIf="signupTwoForm.get('dob').hasError('required') &&  signupTwoForm.get('dob').dirty"
                                class="danger" padding>*Dob field cannot be empty.</small>
                        </span>
                    </div>

                    <div class="col-md-8"> <input type="email" maxlength="256" formControlName="address"
                            class="form-control email-pass-pro-sec" placeholder="Enter Address">
                        <span>
                            <small
                                *ngIf="signupTwoForm.get('address').hasError('required') &&  signupTwoForm.get('address').dirty"
                                class="danger" padding>*Address field cannot be empty.</small>
                            <small *ngIf="signupTwoForm.get('address').hasError('maxlength')" class="danger"
                                padding>*Address field cannot be less than 256 words.</small>
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <select class="form-control email-pass-pro" formControlName="selectCountry"
                            (change)="getStateList($event)">
                            <option value="">Select country</option>
                            <option *ngFor="let country of countryList"> {{country.country}} </option>
                        </select>
                        <span>
                            <small
                                *ngIf="signupTwoForm.get('selectCountry').hasError('required') &&  signupTwoForm.get('selectCountry').dirty"
                                class="danger" padding>*Country field cannot be empty.</small>
                        </span>
                    </div>

                    <div class="col-md-4">
                        <select class="form-control email-pass-pro" formControlName="selectState">
                            <option value="">Select state</option>
                            <option *ngFor="let state of stateList">{{state}}</option>
                        </select>
                        <span>
                            <small
                                *ngIf="signupTwoForm.get('selectState').hasError('required') &&  signupTwoForm.get('selectState').dirty"
                                class="danger" padding>*State field cannot be empty.</small>
                        </span>
                    </div>

                    <div class="col-md-4">
                        <input type="email" maxlength="60" formControlName="selectCity"
                            class="form-control email-pass-pro" placeholder="Enter The City">
                        <span>
                            <small
                                *ngIf="signupTwoForm.get('selectCity').hasError('required') &&  signupTwoForm.get('selectCity').dirty"
                                class="danger" padding>*City field cannot be empty.</small>

                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="form-group inpt-frm-pro01 mb-0 row">
                            <div class="col-md-2">
                                <input class="form-control email-pass-pro" placeholder="code" id="fname"
                                    value="{{countryCode}}">
                            </div>
                            <div class="col-md-6">
                                <input type="email" maxlength="18" class="form-control email-pass-pro-sec"
                                    placeholder="Mobile Number" formControlName="mobile" (keypress)="NumOnly($event)">
                            </div>
                            <div class="col-md-4">
                                <input type="email" maxlength="10" class="form-control email-pass-pro-sec"
                                    placeholder="RefferalCode" formControlName="refferalCode">
                            </div>
                        </div>
                        <span>
                            <small
                                *ngIf="signupTwoForm.get('mobile').hasError('required') &&  signupTwoForm.get('mobile').dirty"
                                class="danger" padding>*Mobile number field can not be empty.</small>
                            <small
                                *ngIf="signupTwoForm.get('mobile').hasError('pattern') &&  signupTwoForm.get('mobile').dirty"
                                class="danger" padding>*Invalid mobile number.</small>
                            <small
                                *ngIf="signupTwoForm.get('mobile').hasError('minlength')||signupTwoForm.get('mobile').hasError('maxlength')"
                                class="danger" padding>*Please enter valid number between 8-15 digit.
                            </small>
                        </span>
                    </div>
                </div>
            </form>

            <button class="btn register-btn" data-toggle="modal" [disabled]="signupTwoForm.invalid"
                (click)="signupFunc()">SIGN UP</button>

        </div>
    </div>
</section>
<app-footer></app-footer>