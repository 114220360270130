<!DOCTYPE html>
  <body class="static-contant">
    <section>
      <div class="container1440">
        <div class="Aboutus00 mt-5">
            <h4>FAQs</h4>
              
<div class="wrapper center-block">
  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
  <div class="panel panel-default" *ngFor="let items of faqData">
    <div class="panel-heading active" role="tab" id="headingOne">
      <h4 class="panel-title00">
        <a role="button" class="accordionstyle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          {{items?.question}}
          <img src="assets/images/updrop1.png" class="upperimages1 upperimages10 " alt="">
        </a>
      </h4>
    </div>
    <div id="collapseOne" class="panel-collapse1 collapse in" role="tabpanel" aria-labelledby="headingOne">
      <div class="panel-body00">
        {{items?.answer}}
      </div>
    </div>
  </div>  
</div>
</div>
        </div>
     </div>
   </section>
  </body>
  <app-footer></app-footer>