import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { AppComponent } from 'src/app/app.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-contact-exchange',
  templateUrl: './contact-exchange.component.html',
  styleUrls: ['./contact-exchange.component.css']
})
export class ContactExchangeComponent implements OnInit {
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;
  showDispute: boolean = false;
  tradeId: any;
  toUserId: any;
  cancelReason: boolean = false;
  feedbackForm: FormGroup;
  myId: any;
  // userName: string;
  tradeType: any;
  minutes: number;
  peerToPeerExchangeId: any;

  obj: any = {};
  myImage: any;
  currentTime: any
  x: any;

  exchangeDetails: any
  tradePartner: any;
  profileData: any;

  cancelPurchaseReasonForm: FormGroup;
  cancelPurchaseSectionStatus: boolean = false;
  updateFeedbackForm: FormGroup;
  feedbackId: any

  currentDate: Date;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public server: ServiceService, public appC: AppComponent) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.exchangeDetails = this.router.getCurrentNavigation().extras.state.exchangeDetails;
        localStorage.setItem('exchangeDetails', this.exchangeDetails)
        this.exchangeDetails = JSON.parse(this.exchangeDetails)
        console.log("exchange details->", this.exchangeDetails)
      } else {
        this.exchangeDetails = JSON.parse(localStorage.getItem('exchangeDetails'))
        console.log("exchange details->", this.exchangeDetails)
      }
    });


    this.server.getMessage().subscribe((res) => {
      this.scrollToBottom();
      if (res.text.message) {
        if (res.text.message.includes("MADE THE PAYMENT") && this.tradeType == 'BUY') {
          this.showDispute = true;
        } else if (res.text.message.includes("CANCELLED THE TRADE")) {
          this.server.showErrorMessage('TRADE CANCELLED');
          this.router.navigate(['/p2p-exchange'])
        } else if (res.text.message.includes("DISPUTED THE TRADE")) {
          this.server.showErrorMessage('DISPUTED THE TRADE');
          this.router.navigate(['/p2p-exchange'])
        }
      }
    })
    // this.getPreviousChat()
  }

  ngOnInit() {
    this.server.initSocketChat(); //chat
    window.scrollTo(0, 0)
    this.feedbackFormValidation();
    this.updatefeedbackFormValidation();
    this.cancelPurchaseReasonFormValidation();
    this.myId = localStorage.getItem('userId')
    console.log(this.myId)
    this.profileData = JSON.parse(localStorage.getItem('profiledata'))
    console.log(this.profileData)
    // let url = window.location.href
    // let new_arr = url.split('/')
    // let arr = new_arr[new_arr.length - 1].split('-')
    // // console.log(arr)
    // this.toUserId = arr[arr.length - 6]
    // this.tradeId = arr[arr.length - 5]
    // this.userName = arr[arr.length - 4]
    // this.tradeType = arr[arr.length - 3]
    // this.minutes = Number(arr[arr.length - 2])
    // // console.log('minutes',this.minutes)
    // this.peerToPeerExchangeId = arr[arr.length - 1]
    console.log(this.exchangeDetails.route)
    if (this.exchangeDetails.route == 'p2p-buy') {
      this.toUserId = this.exchangeDetails.toUserId
    } else {
      this.toUserId = this.exchangeDetails.fromUserId
    }
    this.tradeId = this.exchangeDetails.tradeId
    // this.userName = this.exchangeDetails.userName
    this.tradePartner = this.exchangeDetails.tradePartner
    this.tradeType = this.exchangeDetails.orderType
    this.minutes = this.exchangeDetails.paymentWindow
    this.peerToPeerExchangeId = this.exchangeDetails.peerToPeerExchangeId
    console.log("exchnage details->", this.exchangeDetails)

    this.checkStatus();
    this.server.getMessage().subscribe((res) => {
      if (res.text == 'online' && localStorage.getItem('chat-type') == 'p-p') {
        this.getPreviousChat()
      }
    })
    this.currentDate = new Date()
    // console.log(this.currentDate)
    // this.getPreviousChat()
  }

  // timeout(string) {
  //   setTimeout(() => {
  //     return string
  //   }, 1000)
  // }

  // to get previous chat
  // getPreviousChat() {
  //   let data = {
  //     "userId": this.appC.profileData.userId,
  //     "tradeId": this.tradeId
  //   }
  //   this.server.wsChat.send(JSON.stringify(data))
  // }
  getPreviousChat() {

    console.log("get previous chat call")
    this.server.getCandyPixelForms(`notification/get-chat-data-for-admin?tradeId=${this.tradeId}`).subscribe((res) => {

      if (res['status'] == 1620) {
        //alert(this.server.socketChat)
        console.log(res['data'])
        this.server.chatArr = res['data']
        this.scrollToBottom()
      }
    })
  }

  // check status
  checkStatus() {
    let data = {}
    this.server.postApi(`p2p-exchange/trade-status-check?peerToPeerExchangeId=${Number(this.peerToPeerExchangeId)}`, {}).subscribe((res) => {
      if (res['status'] == 200) {
        this.currentTime = new Date(res['data']['timeStamp'])
        this.toShowTimer(res['data']['timeStamp'])
      } else {
        this.server.showErrorMessage('Trade Expired')
      }
    }, err => {
      this.server.showErrorMessage('Trade Expired')
    })
  }

  // to show timer
  toShowTimer(time) {
    var self = this;
    // Set the date we're counting down to
    let ex_date = new Date(time).getTime()
    var countDownDate = new Date(time).getTime() + Number(this.minutes * 60 * 1000)
    // console.log(new Date(time).getTime())

    // Update the count down every 1 second
    this.x = setInterval(function () {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      // console.log(distance)

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      document.getElementById("demo").innerHTML = days + "d " + hours + "h "
        + minutes + "m " + seconds + "s ";

      // If the count down is over, write some text 
      if (distance < 0) {
        clearInterval(this.x);
        document.getElementById("demo").innerHTML = "EXPIRED";
        this.router.navigate['/login']
        // to cancel Trade
      }
    }, 1000);
  }

  // to send message after user paid
  sendMessageOnPay() {
    this.server.postApi(`p2p-exchange/send-message-after-paid-button?tradeId=${this.tradeId}`, {}).subscribe((res) => {
      if (res['status'] == 200) {
        this.server.showSuccessMessage('Amount paid successfully')
        this.showDispute = true;
        let data = {
          "userId": this.appC.profileData.userId,
          "toUserId": Number(this.toUserId),
          "messageFormat": "TEXT",
          "message": `${this.appC.userName} MADE THE PAYMENT`,
          // "type":"TEXT"
          "tradeId": this.tradeId
        }

        this.server.wsChat.send(JSON.stringify(data))
        this.server.chatArr.push(data)
        this.scrollToBottom();
      } else if (res['status'] == 206) {
        this.server.showSuccessMessage('Amount already paid')
        this.showDispute = true;
        let data = {
          "userId": this.appC.profileData.userId,
          "toUserId": Number(this.toUserId),
          "messageFormat": "TEXT",
          "message": `${this.appC.userName} MADE THE PAYMENT`,
          // "type":"TEXT"
          "tradeId": this.tradeId
        }
      }
    })
  }

  // to scroll down
  scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight
      } catch (err) { }
    }, 100);
  }

  // on destroy
  ngOnDestroy() {
    console.log("destroy call")
    clearInterval(this.x);
    localStorage.removeItem('exchangeDetails')
    this.server.chatArr = []
  }

  // cancel purchase
  cancelPurchase() {
    this.cancelReason = true;
  }

  cancelPurchaseReasonFormValidation() {
    this.cancelPurchaseReasonForm = new FormGroup({
      'reason': new FormControl('')
    })
  }

  // to send message after order cancelled
  cancelOrder() {
    let reqData = {
      'cancelReason': this.cancelPurchaseReasonForm.value.reason
    }
    this.server.postApi(`p2p-exchange/send-message-after-cancel-trade-button?cancelReason=${this.cancelPurchaseReasonForm.value.reason}&tradeId=${this.tradeId}`, reqData).subscribe((res) => {
      if (res['status'] == 200) {
        this.server.showSuccessMessage('Trade Cancelled')
        this.appC.chatArr = []
        let data = {
          "userId": this.appC.profileData.userId,
          "toUserId": Number(this.toUserId),
          "messageFormat": "TEXT",
          "message": `${this.appC.userName} CANCELLED THE TRADE`,
          // "type":"TEXT"
          "tradeId": this.tradeId
        }
        this.server.wsChat.send(JSON.stringify(data))
        this.server.chatArr.push(data)
        // this.router.navigateByUrl('trade/advertisement');
        this.cancelPurchaseSectionStatus = true
      }
    })
  }

  // to release coin
  releaseCoin() {
    let data = {
      'peerToPeerExchangeId': this.peerToPeerExchangeId,
      'tradeId': this.tradeId,
    }
    this.server.postApi(`p2p-exchange/release-bitcoins?peerToPeerExchangeId=${Number(this.peerToPeerExchangeId)}&tradeId=${this.tradeId}`, {}).subscribe((res) => {
      this.server.showSuccessMessage('Released')
      // this.showDispute = true;
      // this.route.navigateByUrl('trade/advertisement')
      this.router.navigate(['/p2p-exchange'])
    })
  }

  // send chat
  sendChat() {
    console.log("send chat clicked")
    if (this.obj.chat) {
      let data = {
        "userId": this.appC.profileData.userId,
        "toUserId": Number(this.toUserId),
        "messageFormat": "TEXT",
        "message": this.obj.chat,
        // "type": "TEXT"
        "tradeId": this.tradeId
      }
      console.log("send chat data->", data)
      this.server.wsChat.send(JSON.stringify(data))
      this.server.chatArr.push(data)
      this.obj.chat = ''
      this.scrollToBottom();
    }
  }
  showlist: any = false
  butnDisputeReason() {
    this.showlist = !this.showlist
  }


  disputeReason: any = 'Raised'
  dispute() {
    this.server.getCandyPixelForms(`p2p-exchange/after-press-dispute-button?disputeStatus=${this.disputeReason}&tradeId=${this.tradeId}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.server.showSuccessMessage('Trade disputed successfully')
        this.router.navigate(['/p2p-exchange'])
      } else {
        this.server.showErrorMessage(res['message'])
      }
    })
  }

  // to upload image
  handleFileInput1(event) {
    this.server.showSuccessMessage('Please wait! Upload in progress...')
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg' || type === 'application/pdf') {
        let fileData = event.target.files[0];
        this.sendFormData1(fileData)
        var reader = new FileReader()
      } else {
        this.server.showErrorMessage("Select only jpg,jpeg and png file.");
      }
    }
  }

  /** to call form data */
  sendFormData1(fileData) {
    let formdata = new FormData()
    formdata.append('file', fileData);
    this.server.postApi('account/upload-file', formdata).subscribe((succ) => {
      if (succ.status == 200) {
        this.myImage = succ.data
        let data = {
          "userId": this.appC.profileData.userId,
          "toUserId": Number(this.toUserId),
          "messageFormat": "IMAGE",
          "message": this.myImage,
          "type": "IMAGE",
          "tradeId": this.tradeId
        }
        this.server.wsChat.send(JSON.stringify(data))
        this.server.chatArr.push(data)
        this.myImage = ''
      }
    })
  }



  /**
   * feedback
   */
  // feedback form validation
  feedbackFormValidation() {
    this.feedbackForm = new FormGroup({
      'feedbackMessage': new FormControl('', Validators.required),
      'feedbackStatus': new FormControl('', Validators.required)
    })
  }

  // submit user feedback  
  submitUserFeedback() {
    let data = {
      "feedbackMessage": this.feedbackForm.value.feedbackMessage,
      "feedbackStatus": this.feedbackForm.value.feedbackStatus,
      "userId": this.toUserId
    }
    this.server.postApi('account/submit-user-feedback', data).subscribe((res) => {
      if (res['status'] == 200) {
        this.server.showSuccessMessage(res['message'])
        this.feedbackForm.reset();
        this.getUserFeedback();
      }
    })
  }

  // cancel purchase order
  updatefeedbackFormValidation() {
    this.updateFeedbackForm = new FormGroup({
      'feedbackMessage': new FormControl('', Validators.required),
      'feedbackStatus': new FormControl('', Validators.required)
    })
  }

  // get user feedback
  getUserFeedback() {
    this.server.getCandyPixelForms(`account/get-user-feedback?&feedbackUserId=${this.profileData.userId}&page=0&pageSize=1`).subscribe((res) => {
      if (res['status'] == 200) {
        let feedbackData = res['data'].list[0].feedbackId
        this.feedbackId = feedbackData
        this.getPerticularFeedbackDetail()
      }
    })
  }

  getPerticularFeedbackDetail() {
    this.server.getCandyPixelForms(`account/get-perticular-feedback-detail?feedbackId=${this.feedbackId}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.updateFeedbackForm.patchValue({
          'feedbackStatus': res['data'].feedbackStatus,
          'feedbackMessage': res['data'].feedbackMessage
        })
      }
    })
  }

  // submit user feedback  
  updateUserFeedback() {
    this.getUserFeedback()
    let data = {
      "feedbackMessage": this.updateFeedbackForm.value.feedbackMessage,
      "feedbackStatus": this.updateFeedbackForm.value.feedbackStatus,
      // "tradeId": this.tradeId,
      "userId": this.toUserId
    }
    this.server.postApi(`account/update-user-feedback?feedbackId=${this.feedbackId}`, data).subscribe((res) => {
      if (res['status'] == 200) {
        this.server.showSuccessMessage(res['message'])
        this.updateFeedbackForm.reset();
        this.router.navigate(['/p2p-exchange'])
      }
    })
  }

}
