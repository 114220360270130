// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-escrow-tranfer-history',
//   templateUrl: './escrow-tranfer-history.component.html',
//   styleUrls: ['./escrow-tranfer-history.component.css']
// })
// export class EscrowTranferHistoryComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }


// import { AnimationQueryMetadata } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
declare var $: any;

@Component({
  selector: 'app-escrow-tranfer-history',
  templateUrl: './escrow-tranfer-history.component.html',
  styleUrls: ['./escrow-tranfer-history.component.css']
})

export class EscrowTranferHistoryComponent implements OnInit {
  historyList: any
  constructor(private _service: ServiceService) { }
  StatusType: any
  ngOnInit() {
    window.scrollTo(0, 0)
    this.getEscrowHistoryList()
  }

  typeStatus: any

  getEscrowHistoryList() {
    this.historyList = []
    this._service.getCandyPixelForms('wallet/wallet/allgetData-ESCROW-transfer-api').subscribe(res => {
      if (res['status'] == 200) {
        if (res['data'] && res['data'].SEND && res['data'].SEND.length > 0) {
          res['data'].SEND.forEach((element, index) => {
            res['data'].SEND[index]['statusType'] = 'SEND'
          });
        }
        if (res['data'] && res['data'].RECEIVE && res['data'].RECEIVE.length > 0) {
          res['data'].RECEIVE.forEach((element, index) => {
            res['data'].RECEIVE[index]['statusType'] = 'RECEIVE'
          });
        }
        this.historyList = res['data'].SEND.concat(res['data'].RECEIVE)
        console.log("history list::::", this.historyList)
      }
    })
  }

  cancelId: any
  cancel(userToAdminTransferId) {
    $("#rejectModal").modal('show')
    //this.cancelId = userToAdminTransferId
  }
  cancelReq(userToAdminTransferId) {
    this._service.getCandyPixelForms('wallet/wallet/request-cancel-ESCROW?userToAdminTransferId=' + userToAdminTransferId).subscribe(res => {
      if (res['status'] == 200) {
        this._service.showSuccessMessage(res['message'])
        $("#rejectModal").modal('hide')
        this.getEscrowHistoryList()

      } else {
        this._service.showErrorMessage(res['message'])
      }
    })
  }
  adminid: any
  sender(adminid) {
    this.adminid = adminid
  }
  viewSendar(type, htmlId) {
    let url = type === 2 ? `wallet/wallet/request-send-to-user1-from-user2?userToAdminTransferId=${this.adminid}` : `wallet/wallet/approvedBy-user1?referenceId=${this.adminid}`
    let req = type === 2 ? { userId: this._service.getUserId() } : { adminId: this._service.getUserId() }
    this._service.postApi(url, req).subscribe(res => {
      if (res['status'] == 200) {
        this._service.showSuccessMessage(res['message'])
        this.getEscrowHistoryList()
        $("#SEND").modal('hide')
        $("#RECEIVE").modal('hide')
      } else {
        this._service.showErrorMessage(res['message'])
      }
    })
  }

  dispute(id) {
    let url = "wallet/wallet/dispute-by-user?referenceId=" + id
    this._service.postApi(url, 1).subscribe(res => {
      if (res['status'] == 200) {
        this._service.showSuccessMessage(res['message'])
        this.getEscrowHistoryList()
        // $("#"+htmlId).modal('hide')
      } else {
        this._service.showErrorMessage(res['message'])
      }
    })
  }
}

