import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  aboutData: any;

  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.getAboutUs()
  }
  getAboutUs(){ 
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=ABOUT%20US').subscribe(res=>{
           if (res['status']==200){
             this.aboutData=res['data'].pageData;
           }
    })
   }

}
