import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { ClipboardService } from 'ngx-clipboard';
import { FormGroup, FormControl } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-trading-account',
  templateUrl: './trading-account.component.html',
  styleUrls: ['./trading-account.component.css']
})
export class TradingAccountComponent implements OnInit {
  activeTab: any;
  transferActiveTab: string;
  coinSelected: any = 'USDT';
  minAge: Date;
  myFundingAccountBalancee: boolean = true;
  myFundingByCoinNAme: any;
  filterForm = new FormGroup({
    "coinPair": new FormControl(''),
    "fromDate": new FormControl(''),
    "toDate": new FormControl('')
  })

  myFundingAccountBalance: any;
  coinList: any = [];
  selectedUserCoin: any = {};

  constructor(public router: Router, public service: ServiceService, private _clipboardService: ClipboardService) {

  }
  ngOnInit(): void {
  }

  // Get All The Coin Functionality
  getAllCoins() {
    let coinShortName = ["USD", "NAIRA", "ZAR"]

    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        var coinList = res['data'].coinList;
        // remove usd naira and zar coin from coin list
        coinShortName.forEach(element => {
          let index = coinList.findIndex(x => x.coinShortName == element)
          if (index != 1) {
            coinList.splice(index, 2)
            this.coinList = coinList
          }
        })
        // end

        for (let x = 0; x < coinList.length; x++) {

          const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
          if (m != -1) {
            coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
            coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
            coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;
            // this.depositCoin(coinList[x].coinShortName);

          }
        }
        this.coinList = coinList;

        this.selectedUserCoin = this.coinList[0]

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  getallcoins1() {
    let coinShortName = ["USD", "NAIRA", "ZAR"]

    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe((res: any) => {
      console.log(res['data']['coinList']);

      this.service.hideSpinner();
      if (res['status'] == 200) {
        var coinList = res['data'].coinList;

        // remove usd naira and zar coin from coin list
        coinShortName.forEach(element => {
          let index = coinList.findIndex(x => x.coinShortName == element)
          if (index != 1) {
            coinList.splice(index, 2)
            this.coinList = coinList
          }
        })
        // end

        for (let x = 0; x < coinList.length; x++) {

          const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
          if (m != -1) {
            coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
            coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
            coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;
            //this.depositCoin(coinList[x].coinShortName);
          }
        }
        this.coinList = coinList;
        this.selectedUserCoin = this.coinList[0]

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  getFundingAccountBalance() {
    var coinName = 'USDT'
    this.service.getCandyPixelForms('wallet/wallet/get-balance?coinName=' + coinName).subscribe(res => {
      if (res['status'] == 200) {
        this.myFundingAccountBalance = res['data']['walletBalance']
        this.myFundingAccountBalancee = false
      }

    })

  }

  // ----------------transfer modal----------------
  transferModal() {
    $('#transferModal').modal('show')

  }
  // Ether Success 
  etherSuccess() {
    this.service.getCandyPixelForms('wallet/wallet-type2/get-async-transfer?coinName=ETH').subscribe(res => {

    }, err => {

    })
  }

  depositUSDT() {
    this.service.showSpinner();
    this.service.getCandyPixelForms("wallet/wallet/get-address?coinName=ETH").subscribe((res) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        // this.appC.showInfoToast('Address fetched successfully.');
        //  this.coinFullName = succ.body.data.coinName;
        //  this.textValue = succ.body.data.walletAddress;
        //  this.myAngularxQrCode = succ.body.data.walletAddress;
        //  this.tagId = succ.tag;
        this.etherSuccess()
        // $('#wallet-address').modal({backdrop: 'static', keyboard: false});
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        // this.service.showError('Something Went Wrong');
      }
    })
  }
  transfer(tab) {
    this.activeTab = 'trasfer'
  }
  trade() {
    this.router.navigate(['/advanced-exchange'])
  }

  searchByDate() {

  }
  resetSearch() {

  }
  filterOncoinPairChange() {

  }
}
