import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.css']
})
export class KycComponent implements OnInit {
  UserDetailsForm: any;
  UserDetailsForm1: any;
  profileData: any = {};
  arr: any = [];
  countryList: any = [];
  stateList: any = [];
  countryCode: any;
  states: any;
  KycForm: FormGroup;
  fileName1: any = 'Upload Front Page Of Your Passport or National Id'
  fileName2: any = 'Upload Back Page Of Your Passport or National Id'
  docArr: any = []
  kycStatus: boolean = false;
  resultArr: any = [];
  imageUrl: any = 'assets/images/image_placeholder.jpeg';
  imageUrl1: any = 'assets/images/image_placeholder.jpeg';
  constructor(public router: Router, public service: ServiceService) {
    this.arr = this.service.countryListJson;
    this.arr.forEach(obj1 => {
      this.countryList.push(obj1.country)
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.validateUserDetailsForm()
    this.getProfile()

  }

  // top validate user details form
  validateUserDetailsForm() {
    this.UserDetailsForm = new FormGroup({
      'fname': new FormControl('', [Validators.required, Validators.pattern(/^[a-z ,.'-]+$/i)]),
      'phone': new FormControl('', [Validators.required]),
      'gender': new FormControl('', [Validators.required]),
      'country': new FormControl('', [Validators.required]),
      // 'state': new FormControl('', [Validators.required]),   
      'term': new FormControl(true, [Validators.required]),
      'privacy': new FormControl(true, [Validators.required]),

    })
    this.UserDetailsForm1 = new FormGroup({
      'lname': new FormControl('', [Validators.pattern(/^[a-z ,.'-]+$/i)]),
      'email_add': new FormControl('', [Validators.required, Validators.maxLength(64), Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)]),
      'city': new FormControl('', [Validators.required]),
      'address': new FormControl(true, [Validators.required])
    })
    this.KycForm = new FormGroup({
      'attachmentName1': new FormControl('', [Validators.required]),
      'attachmentName2': new FormControl(''),
      'id_name': new FormControl('', [Validators.required]),
      'id_number': new FormControl('', [Validators.required]),
    })
  }
  /** to get the value of field  */
  get fname(): any {
    return this.UserDetailsForm.get('fname');
  }
  get lname(): any {
    return this.UserDetailsForm1.get('lname');
  }
  get email_add(): any {
    return this.UserDetailsForm1.get('email_add');
  }
  get phone(): any {
    return this.UserDetailsForm.get('phone');
  }
  get gender(): any {
    return this.UserDetailsForm.get('gender');
  }
  get country(): any {
    return this.UserDetailsForm.get('country');
  }
  get address(): any {
    return this.UserDetailsForm1.get('address');
  }
  get city(): any {
    return this.UserDetailsForm1.get('city');
  }
  get term(): any {
    return this.UserDetailsForm.get('term');
  }

  get privacy(): any {
    return this.UserDetailsForm.get('privacy');
  }

  // Get StateList Functionality
  getStateList(event) {
    var stateList = this.service.countryListJson.filter(x => (x.country == event));
    this.stateList = stateList[0].states;
    this.countryCode = stateList[0].code;

  }
  // Get Profile Name-Email
  userKycStatus : any

  getProfile() {
    this.service.showSpinner();
    this.service.getCandyPixelForms(`account/my-account`).subscribe(res => {

      if (res['status'] == 200) {
        this.profileData = res['data'];
        console.log("data------->>>>", this.profileData)

        let index = this.arr.findIndex(x => x.country == this.profileData.country)
        // this.stateList = this.arr[index].states;
        // this.mobileData = this.profileData.phoneNo.split('-');
        this.service.name = this.profileData.firstName
        setTimeout(() => {
          this.UserDetailsForm.patchValue({
            "fname": this.profileData.firstName,
            "phone": this.profileData.phoneNo,
            "gender": this.profileData.gender,
            "country": this.profileData.country,
          });
          this.UserDetailsForm1.patchValue({
            "lname": this.profileData.lastName,
            "email_add": this.profileData.email,
            "address": this.profileData.address,
            "city": this.profileData.city,
          });
          this.KycForm.patchValue({
            'id_name': this.profileData.kyc.document[0].docName ? this.profileData.kyc.document[0].docName : '',
            'id_number': this.profileData.kyc.document[0].docIdNumber ? this.profileData.kyc.document[0].docIdNumber : '',
          })
          this.service.hideSpinner();
        }, 1000)

        if (this.profileData.country) {
          this.getStateList(this.profileData.country);
        }
        // if (this.profileData.imageUrl) {
        //   this.imageUrl = this.profileData.imageUrl != null ? this.profileData.imageUrl : 'assets/imgs/profile-img.jpg';
        //   this.service.img = this.imageUrl
        // }
        this.userKycStatus = res["data"]["kyc"]["kycStatus"]

        this.states = this.profileData.state
        this.imageUrl = this.profileData.kyc.document[0].frontIdUrl
        this.imageUrl1 = this.profileData.kyc.document[0].backIdUrl
      }
      else {
        this.service.hideSpinner();
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      this.service.showErrorMessage(err['message']);
    })
  }

  /** To upload document 1 -- Front*/
  handleFileInput1(event, index) {
    this.service.showSuccessMessage('Please wait! Upload in progress...')
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        let fileData = event.target.files[0];
        if (index == '1')
          this.fileName1 = event.target.files[0].name;
        else if (index == '2')
          this.fileName2 = event.target.files[0].name;

        this.sendFormData1(fileData, index)

        var reader = new FileReader()
      } else {
        this.service.showErrorMessage("Select only jpg,jpeg and png file.");
      }
    }
  }

  /** to call form data */
  sendFormData1(fileData, index) {
    let formdata = new FormData()
    formdata.append('file', fileData);
    this.service.postApi('account/upload-file', formdata).subscribe((succ) => {
      if (succ.status == 200) {
        this.service.hideSpinner();
        this.service.showSuccessMessage('File Uploaded')
        let url = succ.data
        if (index == '1') {
          this.imageUrl = succ.data
        }
        else {
          this.imageUrl1 = succ.data
        }
        var data = {};
        if (index == '1' || index == '2') {
          data = {
            "name": this.KycForm.value.id_name,
            "number": this.KycForm.value.id_number,
            "url": url,
            "documentNumber": index
          }
        }
        else {
          data = {
            "name": 'Selfie with Id',
            "number": 'NA',
            "url": url,
            "documentNumber": index
          }
        }
        this.docArr[Number(index)] = data
      } else {
        this.service.showSuccessMessage(succ.message)
      }
    }, error => {
      if (index == '1') {
        this.fileName1 = 'Upload Front Page Of Your 1st ID';
      }
      else if (index == '2')
        this.fileName2 = 'Upload Front Page Of Your 2nd ID';
      this.service.hideSpinner();
    })
  }

  // to get kyc status
  checkStatus() {
    this.service.getCandyPixelForms('account/get-all-kyc-details').subscribe((res) => {
      let kycdata = {}
      kycdata = res['data']

      this.resultArr = kycdata['document'];
      if (kycdata && this.resultArr != null) {
        if ((kycdata['kycStatus'] == 'PENDING') || (kycdata['kycStatus'] == 'ACCEPTED')) {
          this.kycStatus = true
        } else if (kycdata['kycStatus'] == 'REJECTED') {
          this.kycStatus = false
        }
      }
      if (this.resultArr == null) {
        this.resultArr = []
      }
    }, (err) => {

    })
  }
  /** to submit kyc details */
  submitKYC() {
    // this.docArr.shift();
    this.docArr = this.docArr.filter(value => Object.keys(value).length !== 0);
    var documentData = [];
    documentData.push({
      'backIdUrl': this.docArr[1].url,
      'createTime': new Date(),
      'docIdNumber': this.docArr[0]['number'],
      // 'docName': this.docArr[0]['name'],
      'docName': this.KycForm.value.id_number,
      'documentId': 0,
      'documentNumber': 1,
      //'documentStatus':'',
      'frontIdUrl': this.docArr[0].url,
      'latest': true,
      'reason': '',
      'updateTime': new Date()
    })
    // documentData.push({
    //   'backIdUrl': 'NA',
    //   'createTime': new Date(),
    //   'docIdNumber': this.docArr[2].number,
    //   'docName': this.docArr[2].name,
    //   'documentId': 0,
    //   'documentNumber': 2,
    //   //'documentStatus':'',
    //   'frontIdUrl': this.docArr[2].url,
    //   'latest': true,
    //   'reason': '',
    //   'updateTime': new Date()
    // })

    let data = {
      "document": documentData,
    }
    if (this.docArr[0].url && this.docArr[1].url) {
      this.service.postApi('account/save-kyc-details', data).subscribe((succ) => {
        if (succ.status == 200) {
          this.service.showSuccessMessage('KYC Submitted successfully')
          this.kycStatus = true;
          this.checkStatus();
          this.router.navigate(['/kyclist']);
        } else {
          this.service.showErrorMessage(succ.message)
        }
      }, error => {
        this.service.showErrorMessage(error.error.message)
      })
      // } else {
      //   this.service.showErrorMessage('Re-upload again!')
      // }
    }
  }

  navigateToPage(val) {
    this.router.navigate([val])
  }

  // profile() {
  //   this.router.navigate(['/my-profile'])
  // }
  // kyc() {
  //   this.router.navigate(['/kyc'])
  // }
  // security() {
  //   this.router.navigate(['/security'])
  // }
  // kyclist() {
  //   this.router.navigate(['/kyclist'])
  // }
  // referral() {
  //   this.router.navigate(['/referral'])
  // }
}
