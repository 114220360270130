import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  forgotForm: FormGroup;
  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.forgotForm = new FormGroup({
      'email': new FormControl('',[Validators.required,Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)])
    })
  }
 // Forgot Functionality
 forgotFunc() {
  var url = "account/forget-password?email="+this.forgotForm.value.email+"&webUrl=" + this.service.webUrl + "/resetpassword/";

   this.service.showSpinner();
   this.service.getCandyPixelForms(url).subscribe(res => {
     console.log(res)
     if (res['status'] == 200) {
       this.service.hideSpinner();
       this.forgotForm.reset();
        this.service.showSuccessMessage(res['message'])
     }
     if(res['error'].status == 400){
      this.service.showErrorMessage('sdfsdfds')
     }
     else {
       this.service.hideSpinner();
       this.service.showErrorMessage(res['message']);
     }
   }, err => {
     this.service.hideSpinner();
   })
 }


 forgotFunc1() {
  var url = "account/forget-password?email="+this.forgotForm.value.email+"&webUrl=" + this.service.webUrl + "/resetpassword/";

   this.service.showSpinner();
   this.service.getCandyPixelForms(url).subscribe(res => {
     console.log(res)
     if (res['status'] == 200) {
       this.service.hideSpinner();
       this.forgotForm.reset();
        this.service.showSuccessMessage(res['message'])
     }
     if(res['error'].status == 400){
      this.service.showErrorMessage('sdfsdfds')
     }
     else {
       this.service.hideSpinner();
       this.service.showErrorMessage(res['message']);
     }
   }, err => {
     this.service.hideSpinner();
   })
 }

}
