<!-- <body class="static-contant"> -->

<div class="static-contant">
  <section class="wraper">
    <div class="container1440">

      <div class="row pt-5 pb-5">
        <div class="col-lg-2 mb-3">
          <div>
            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <a class="nav-link  profiletab" id="v-pills-home-tab" data-toggle="pill" role="tab" href="javascript:;"
                aria-controls="v-pills-home" aria-selected="true" (click)="navigateToPage('my-profile')">Profile</a>
              <a class="nav-link  profiletab" id="v-pills-profile-tab" data-toggle="pill" role="tab" href="javascript:;"
                aria-controls="v-pills-profile" (click)="navigateToPage('security')" aria-selected="false">Security</a>
              <a class="nav-link profiletab" id="v-pills-messages-tab" data-toggle="pill" role="tab" href="javascript:;"
                aria-controls="v-pills-messages" aria-selected="false" (click)="navigateToPage('kyc')">KYC</a>
              <a class="nav-link active profiletab" id="v-pills-settings-tab" data-toggle="pill" role="tab"
                href="javascript:;" aria-controls="v-pills-settings" aria-selected="false"
                (click)="navigateToPage('kyclist')">KYC
                List</a>
              <a class="nav-link profiletab" id="v-pills-settings-tab" data-toggle="pill" role="tab" href="javascript:;"
                aria-controls="v-pills-settings" aria-selected="false" (click)="navigateToPage('referral')">Referral</a>
            </div>
          </div>
        </div>
        <div class="col-lg-10 col-md-12">
          <div class=" row col-md-12">
            <div class="col-md-2 ">
              <h4 class="my-kyc">My KYC</h4>
            </div>
            <div class="col-md-8"></div>

          </div>


          <div class=" col-md-11 col-sm-12 table-responsive mt-4 pb-2">
            <table class="table after-logintabel action ">
              <thead>
                <tr>

                  <th id="">DOCUMENT NAME</th>
                  <th id="">DOCUMENT ID</th>
                  <th id="">REJECTION REASON</th>
                  <th id="">STATUS</th>
                </tr>
              </thead>
              <tbody style="cursor: pointer;">
                <tr *ngFor="let item of resultArr; let i = index">
                  <td>{{item.docName || "---" }}</td>
                  <td>{{item.docIdNumber || "---" }}</td>
                  <td>{{item.reason || "---" }}</td>
                  <td>
                    <span [ngClass]="item.documentStatus == 'ACCEPTED' ? 'green-text' : 'red-text'"
                      (click)="navigateToKyc()">
                      {{item.documentStatus || "---" }}
                    </span>
                  </td>

                </tr>



              </tbody>
              <tr *ngIf="resultArr.length==0">
                <td colspan="6" vertical-align="middle">
                  <div class="no-record" style="text-align: center">
                    <div class="no-recordin">
                      <span>No record found</span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>


      </div>
    </div>

  </section>
</div>
<!-- </body> -->
<app-footer></app-footer>