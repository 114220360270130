<!-- <div *ngIf=" activeTab == 'FundingAccount'" class="col-md-12 walletsDiv"> -->
    <!-- <div class="content"> -->
        <h5 class="walletSubHeading">Total Balance</h5>
        <!-- <h6>USDT <span style="color: rgb(10 24 37); font-weight: 600;">{{myFundingAccountBalance }}</span> </h6> -->
        <!-- <div class="col-md-12 row emptyBalance" *ngIf="myFundingAccountBalancee">
            <div class="col-md-6">
                <img src="../../../assets/images/account1.png" alt="">
                <h5>Your balance is currently empty</h5>
            </div>
        </div> -->
    <!-- </div> -->

    <div class="table-responsive mt-4 pb-5">
        <table class="table after-logintabel action table-striped" aria-describedby="">
            <thead>
                <tr>
                    <th id="">CURRENCY</th>
                    <th id="" class="mobHide text-center">AVAILABLE BALANCE</th>
                    <th id="" class="mobHide text-center">BLOCK BALANCE</th>
                    <th id="" class="text-center">TOTAL BALANCE</th>
                    <th id="" class="minWidth80 text-center">ACTION</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let item of coinList | paginate: { itemsPerPage: 10, currentPage: depositePageNumber, totalItems: coinList.length  } ">
                    <td class="coins"><span><img class="coinLogo" [src]="item.coinImage"
                                alt="img"></span>{{item?.coinFullName}} ( {{item.coinShortName}} )</td>
                    <td class="mobHide text-center">{{item.availableBalance}}</td>
                    <td class="mobHide text-center">{{item.inOrderBalance}}</td>
                    <td class="text-center">{{item.totalBalance}}</td>
                    <td class="minWidth80 text-center">
                        <button class="btn theam-btn" data-toggle="modal"
                           ><span class="mobHide">Deposit</span><i
                                class="fa fa-angle-up deskHide"></i></button>
                        <button class="btn theam-btn" data-toggle="modal"
                            ><span
                                class="mobHide">Withdraw</span><i class="fa fa-angle-down deskHide"></i></button>
                        <!-- <button class="btn theam-btn" data-toggle="modal" (click)=" transferModal()"><span
                                class="mobHide">Transfer</span><i class="fa fa-angle-down deskHide"></i></button> -->

                    </td>
                </tr>
            </tbody>
        </table>
    </div>
<!-- </div> -->