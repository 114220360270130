import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard'
declare var $: any;
@Component({
  selector: 'app-my-wallet',
  templateUrl: './my-wallet.component.html',
  styleUrls: ['./my-wallet.component.css']
})
export class MyWalletComponent implements OnInit {
  coinList: any = [];
  walletAddress: any;
  coinName: any;
  coinWithdrawFee: any;
  sendForm: FormGroup;
  finalAmount: number = 0;
  tags: any;
  account: any;
  tagError: string;
  selectedUserCoin: any = {};
  depositePageNumber: number = 1;
  qrCode: any = '';
  secretKey: any = '';
  googleAuth: any = '';
  twoFAStatus: any;
  smsAuth: any = '';
  profileData: any;
  usdtdata: any;
  btcdata: any;
  value: number = 0;
  withdrawfee1: any;
  withdrawFee1: any;
  coinWithdrawAmount: any;
  coinamt: number;
  myFundingAccountBalance: boolean = true
  perCoinTotalBaln: any;
  receiveForm: FormGroup;
  userStatus: any;
  activeTab: string;
  // trasferForm:FormGroup ;
  trasferForm = new FormGroup({
    'coinName': new FormControl('USDT'),
    'toAccount': new FormControl('Funding_Account'),
    'fromAccount': new FormControl('Trading_Account'),
    'amount': new FormControl(''),
  })

  filterForm = new FormGroup({
    "coinPair": new FormControl(''),
    "fromDate": new FormControl(''),
    "toDate": new FormControl('')
  })
  transferActiveTab: string;
  coinSelected: any = 'USDT';
  minAge: Date;
  myFundingAccountBalancee: boolean = true;
  myFundingByCoinNAme: any;
  // ------------------------------------------- change transfer type from dropfown ------------------------------------------

  changeValueFrom() {
    if (this.trasferForm.value.toAccount == "Funding_Account") {
      this.trasferForm.patchValue({
        fromAccount: "Trading_Account"
      })
    }
    else if (this.trasferForm.value.toAccount == "Trading_Account") {
      this.trasferForm.patchValue({
        fromAccount: "Funding_Account"
      })
    }
  }
  cancleTransferModal() {
    this.trasferForm.reset()
  }
  // -------------------------------------------transfer Amount ------------------------------------------
  transferAmount() {
    if (this.trasferForm.value.toAccount == "Funding_Account") {
      var url = 'wallet/wallet/update-wallet-for-transfer-amount-from-wallet-to-trade?coinName=' + this.trasferForm.value.coinName + '&walletBalance=' + this.trasferForm.value.amount;
      this.service.postCandyPixelForms(url, '').subscribe(res => {
        if (res == 200) {
          this.service.showSuccessMessage('Amount transfer successfully')
          this.getAllCoins()

        } else {
          this.service.showErrorMessage('Something went wrong')
        }
        console.log(res);
      })
    }
    else if (this.trasferForm.value.toAccount == "Trading_Account") {
      var url = 'wallet/wallet/update-wallet-for-transfer-amount-from-trade-to-wallet?coinName=' + this.trasferForm.value.coinName + '&tradeBalance=' + this.trasferForm.value.amount;
      this.service.postCandyPixelForms(url, '').subscribe(res => {
        console.log(res);
        if (res == 200) {
          this.service.showSuccessMessage('Amount transfer successfully')
          this.getAllCoins()

        } else {
          this.service.showErrorMessage('Something went wrong')
        }

      })
    }
  }

  // changeValueTo() {
  //   if (this.trasferForm.value.fromAccount == "Funding_Account") {
  //     this.trasferForm.patchValue({
  //       toAccount: "Trading_Account"
  //     })
  //   }
  //   else if (this.trasferForm.value.fromAccount == "Trading_Account") {
  //     this.trasferForm.patchValue({
  //       toAccount: "Funding_Account"
  //     })
  //   }
  // }
  changeCoin() {
    this.coinSelected = this.trasferForm.value.coinName;
    this.service.getCandyPixelForms('wallet/wallet/get-balance?coinName=' + this.trasferForm.value.coinName).subscribe(res => {
      if (res['status'] == 200) {
        this.myFundingByCoinNAme = res['data']['walletBalance']
      }
    })
  }
  transferAll() {
    this.trasferForm.patchValue({
      "amount": this.myFundingByCoinNAme
    })
  }
  copyToClipboard(item) {

    this.service.showSuccessMessage('Copied successfully')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  copyToClipboardTag(item) {

    this.service.showSuccessMessage('Copied successfully')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  constructor(public router: Router, public service: ServiceService, private _clipboardService: ClipboardService) {

  }

  ngOnInit() {
    this.myTradeTabs('Overview')
    window.scrollTo(0, 0)
    this.getAllCoins()
    this.depositETH();
    this.formValidationFunctionality();
    this.getProfile();
    this.getFundingAccountBalance()
    this.transferTab('transfer')
    this.dateValidation()
    this.changeCoin()
  }

  dateValidation() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
  }

  // formValidation Functionality
  formValidationFunctionality() {
    this.sendForm = new FormGroup({
      'coinAmount': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'address': new FormControl('', Validators.required)
    })
    // this.receiveForm = new FormGroup({
    //   'walletAdd': new FormControl('',),

    // })
  }

  myTradeTabs(tabs) {
    this.activeTab = 'Overview'

    switch (tabs) {
      case 'FundingAccount':
        this.activeTab = 'FundingAccount'
        break;
      case 'TradingAccount':
        this.activeTab = 'TradingAccount'
        break;
      case 'WalletTradingLimit':
        this.activeTab = 'WalletTradingLimit'
        break;
      case 'MyTradingReport':
        this.activeTab = 'MyTradingReport'
        break;
      case 'FiatWallet':
        this.activeTab = 'FiatWallet'
        break;
      default:
        this.activeTab = 'Overview'
        break;
    }

    // if (tabs == 'Overview') {
    //   this.activeTab = 'Overview'
    // }
    // else if (tabs == 'FundingAccount') {
    //   this.activeTab = 'FundingAccount'
    // }
    // else {
    //   this.activeTab = 'TradingAccount'
    // }

  }

  // transfer tabs call

  transferTab(tab) {
    this.transferActiveTab = 'transfer'
    if (tab == 'transfer') {
      this.transferActiveTab = 'transfer'
    }
    else {
      this.transferActiveTab = 'allTransfer'
    }

  }

  // Get All The Coin Functionality
  getAllCoins() {
    let coinShortName = ["USD", "NAIRA", "ZAR"]

    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        var coinList = res['data'].coinList;
        // remove usd naira and zar coin from coin list
        coinShortName.forEach(element => {
          let index = coinList.findIndex(x => x.coinShortName == element)
          if (index != 1) {
            coinList.splice(index, 2)
            this.coinList = coinList
          }
        })
        // end

        for (let x = 0; x < coinList.length; x++) {

          const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
          if (m != -1) {
            coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
            coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
            coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;
            this.depositCoin(coinList[x].coinShortName);

          }
        }
        this.coinList = coinList;

        this.selectedUserCoin = this.coinList[0]

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  getallcoins1() {
    let coinShortName = ["USD", "NAIRA", "ZAR"]

    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe((res: any) => {
      console.log(res['data']['coinList']);

      this.service.hideSpinner();
      if (res['status'] == 200) {
        var coinList = res['data'].coinList;

        // remove usd naira and zar coin from coin list
        coinShortName.forEach(element => {
          let index = coinList.findIndex(x => x.coinShortName == element)
          if (index != 1) {
            coinList.splice(index, 2)
            this.coinList = coinList
          }
        })
        // end

        for (let x = 0; x < coinList.length; x++) {

          const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
          if (m != -1) {
            coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
            coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
            coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;
            //this.depositCoin(coinList[x].coinShortName);
          }
        }
        this.coinList = coinList;
        this.selectedUserCoin = this.coinList[0]

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  getFundingAccountBalance() {
    var coinName = 'USDT'
    this.service.getCandyPixelForms('wallet/wallet/get-balance?coinName=' + coinName).subscribe(res => {
      if (res['status'] == 200) {
        this.myFundingAccountBalance = res['data']['walletBalance']
        this.myFundingAccountBalancee = false
      }

    })

  }
  depositCoin(coinShortName) {
    var url = ''
    this.tags = '';
    this.account = ''
    // if (coinShortName == 'XRP' || coinShortName == 'XLM' || coinShortName == 'OMG' || coinShortName == 'USDT' || coinShortName == 'ETH') {
    if (coinShortName == 'XRP' || coinShortName == 'XLM' || coinShortName == 'OMG' || coinShortName == 'ETH') {
      url = 'wallet/wallet-type2/get-deposits?coinName=' + coinShortName + '&page=' + (this.depositePageNumber - 1) + '&pageSize=10';
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
          // this.service.showError('Something Went Wrong');
        }
      })
    }
    /*else if (coinShortName == 'USDT') {
      url = 'coin/get-usdt-network-balance';
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.usdtdata= res['data'].USDT_balance
          this.btcdata= res['data'].BTC_balance
          if(this.usdtdata>=1 && this.btcdata>=0.0001){
          this.getusdtdepositlist(coinShortName);
          }
          else{
            this.service.showError('You should have USDT more than 1 and BTC more than 0.001 to perform deposit')
          }
          // this.account = res['data']['eosAccountName']
          // this.walletAddress = res['data'].walletAddress;
          //$('#recieveModal').modal({ backdrop: 'static' })
        }
        else {
         // this.service.showError(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showError('Session Expired!');
        } else {
          //this.service.showError('Something Went Wrong');
        }
      })
    }*/
    // btc and usdt
    else {
      url = 'wallet/wallet/get-deposits?coinName=' + coinShortName + '&page=' + (this.depositePageNumber - 1) + '&pageSize=10';
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.getallcoins1();
          // this.account = res['data']['eosAccountName']
          // this.walletAddress = res['data'].walletAddress;
          //$('#recieveModal').modal({ backdrop: 'static' })
        }
        else {
          // this.service.showError(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
          //this.service.showError('Something Went Wrong');
        }
      })
    }
  }

  // Send & Recieve Coin Functionality
  sendCoin(coinShortName) {

    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/coin/get-coin-details?coinName=' + coinShortName).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.coinWithdrawFee = res['data'].withdrawlFee;
        this.coinWithdrawAmount = res['data'].withdrawalAmount;

      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();

    })

    this.coinName = coinShortName;
    //this.coinWithdrawFee = this.withdrawfee1;
    this.sendForm.reset();
    //$('#sendModal').modal({ backdrop: 'static' })
  }
  getFinalAmount(event) {
    this.finalAmount = 0;
    if ((/^(\d+)?([.]?\d{0,8})?$/).test(event.target.value)) {
      // this.finalAmount = Number(event.target.value) - ((this.coinWithdrawFee / 100) * Number(event.target.value));
      this.finalAmount = Number(event.target.value) - this.coinWithdrawFee
      this.coinamt = Number(event.target.value)
      // this.value = ((this.coinWithdrawFee / 100) * Number(event.target.value));
      this.value = this.coinWithdrawFee
    }
  }
  getTagValue(event) {
    this.tags = event.target.value;
  }
  sendCoinFunc() {
    var apiReq = {};
    var base;
    if (this.coinName == 'OMG') {
      apiReq = {
        "amount": this.sendForm.value.coinAmount,
        "coinName": this.coinName,
        "isWithdraw": false,
        "isKycAccepted": true,
        "toAddress": this.sendForm.value.address,
        "url": this.service.webUrl + "/wallet-transaction-status"
      }
      base = 'wallet/wallet-type2/withdraw'
    }
    // else if (this.coinName == 'USDT') {
    //   apiReq = {
    //     "amount": this.sendForm.value.coinAmount,
    //     "coinName": this.coinName,
    //     "isWithdraw": false,
    //     "isKycAccepted": true,
    //     "toAddress": this.sendForm.value.address,
    //     "url": this.service.webUrl + "/wallet-transaction-status"
    //   }
    //   base = 'wallet/wallet-type2/withdraw-type3'
    // }
    else {
      apiReq = {
        "amount": this.sendForm.value.coinAmount,
        "coinName": this.coinName,
        "isWithdraw": false,
        "isKycAccepted": true,
        "tag": this.tags,
        "toAddress": this.sendForm.value.address,
        "url": this.service.webUrl + "/wallet-transaction-status"
      }
      base = 'wallet/wallet/withdraw'
    }

    // this.service.showSpinner();
    this.service.postCandyPixelForms(base, apiReq).subscribe(res => {
      // this.service.hideSpinner();
      if (res['status'] == 200) {
        $('#sendModal').modal('hide');
        this.sendForm.reset();
        this.service.hideSpinner();
        this.service.showSuccessMessage(res['message']);
      }
      else {
        this.service.hideSpinner();
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  resetForm() {
    this.sendForm.reset();
    this.value = 0;
    this.finalAmount = 0;
  }

  recieveCoin(coinShortName) {
    if (this.userStatus == 'BLOCK') {
      this.service.showErrorMessage('You have been blocked and suspended by admin, kindly contact our customer support')
      return
    }
    else {
      var url = ''
      this.tags = '';
      this.account = ''
      if (coinShortName == 'XRP' || coinShortName == 'XLM') {
        url = 'wallet/wallet-type2/get-address?coinName=' + coinShortName;
        this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            this.walletAddress = res['data'].walletAddress;
            this.tags = res['data'].tag;
            $('#recieveModal').modal({ backdrop: 'static' })
          }
        }, err => {
          this.service.hideSpinner();
          if (err['status'] == '401') {
            localStorage.clear();
            this.router.navigate(['/login']);
            this.service.showErrorMessage('Session Expired!');
          } else {
            this.service.showErrorMessage('Kyc not done');
          }
        })
      }
      else if (coinShortName == 'OMG') {
        url = 'wallet/wallet/get-address-for-erc20?coinName=' + coinShortName;
        this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            this.walletAddress = res['data'];
            $('#recieveModal').modal({ backdrop: 'static' })
          }
          else {
            this.service.showErrorMessage(res['message']);
          }
        }, err => {
          this.service.hideSpinner();
          if (err['status'] == '401') {
            localStorage.clear();
            this.router.navigate(['/login']);
            this.service.showErrorMessage('Session Expired!');
          } else {
            this.service.showErrorMessage('Kyc not done');
          }
        })
      } else {
        url = 'wallet/wallet/get-address?coinName=' + coinShortName;
        this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            this.account = res['data']['eosAccountName']
            this.walletAddress = res['data'].walletAddress;
            $('#recieveModal').modal({ backdrop: 'static' })
          }
          else {
            this.service.showErrorMessage(res['message']);
          }
        }, err => {
          this.service.hideSpinner();
          if (err['status'] == '401') {
            localStorage.clear();
            this.router.navigate(['/login']);
            this.service.showErrorMessage('Session Expired!');
          } else {
            this.service.showErrorMessage('Kyc not done');
          }
        })
      }
    }
  }

  getProfile() {
    if (localStorage.getItem("credential")) {
      this.service.showSpinner();
      this.service.getCandyPixelForms(`account/my-account`).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.profileData = res['data']['twoFaType'];
          this.userStatus = res['data']['userStatus']
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        // this.service.showError(err['message']);
      })
    }

  }

  openModal() {
    this.googleAuth = '';
    this.smsAuth = '';
    if (this.coinamt < this.coinWithdrawAmount) {
      this.service.showErrorMessage("Coin amount cannot be less than min. withdrawal amount")
    }
    else {
      if (this.profileData == 'GOOGLE') {
        $('#googleAuth').modal({ backdrop: 'static' });
      } else if (this.profileData == 'SMS') {
        this.suggesstionFunc('sms')
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileData == 'NONE' || this.profileData == 'SKIP') {
        this.service.showErrorMessage('Please Enable Two FA First.')
      }
    }

  }



  verifyGoogleAuth(select) {
    // this.googleAuth= '';
    if (select == 'Google') {
      var apireq = {};
      var url = '';
      if (this.twoFAStatus == 'GOOGLE') {
        url = 'account/verify-google-code';
        apireq = {
          "code": this.googleAuth,
          "secretKey": this.secretKey
        }
      } //account/verify-google-code
      else {
        url = 'auth/verify-google';
        apireq = {
          "otp": Number(this.googleAuth),
          //"secretKey": this.secretKey
        }
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms(url, apireq).subscribe(res => {
        // this.service.hideSpinner();
        if (res['status'] == 200) {
          localStorage.removeItem('authToken')
          if (this.twoFAStatus == 'GOOGLE') {
            //this.service.changeLoginSub('login');
            localStorage.setItem('credential', res['data']);
            sessionStorage.setItem('secretKey', this.secretKey);
            this.service.hideSpinner();
          }
          this.service.showSuccessMessage(res['message']);
          $('#googleAuth').modal('hide');
          this.googleAuth = '';
          this.sendCoinFunc();
          // this.routes.navigate(['/landing']);
        }
        else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        this.service.showErrorMessage('Something Went Wrong');
      })

    }
    else if (select == 'sms') {
      var smsapireq = {};
      var url = '';
      if (this.twoFAStatus == 'SMS') {
        url = 'auth/verify-sms';
        smsapireq = {
          'otp': this.smsAuth
        }
      }
      else {
        url = 'account/verify-sms-code';
        smsapireq = {
          'code': this.smsAuth
        }
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms(url, smsapireq).subscribe(res => {
        // this.service.hideSpinner();
        if (res['status'] == 200) {
          //this.service.changeLoginSub('login');
          localStorage.removeItem('authToken')
          if (this.twoFAStatus == 'SMS')

            localStorage.setItem('credential', res['data']);
          this.service.showSuccessMessage(res['message']);
          this.sendCoinFunc();
          //this.routes.navigate(['/landing']);
        }
        else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        this.service.showErrorMessage('Something Went Wrong');
      })
    }
  }






  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }
  // to remove token
  removeToken() {
    localStorage.removeItem('authToken')
  }


  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    this.googleAuth = '';
    if (action == 'Skip') {
      this.service.showSpinner();
      this.service.getCandyPixelForms('account/skip-twoFa').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.service.showSuccessMessage('Logged In');
          $('#suggest').modal('hide');
          //this.service.changeLoginSub('login');
          let credential = localStorage.getItem('authToken')
          localStorage.removeItem('authToken')
          localStorage.setItem('credential', credential);
          this.router.navigate(['']);
        }
        else {
          this.service.showErrorMessage(res['message'])
        }
      }, err => {
        this.service.hideSpinner();
      })
    }
    else if (action == 'Google') {
      this.service.showSpinner();
      this.service.getCandyPixelForms('account/google-auth').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          // this.qrCode = res['data']['qrCode'];
          this.secretKey = res['data']['secretKey'];
          $('#suggest').modal('hide');
          $('#googleAuth').modal('show');
        }
      }, err => {
        this.service.hideSpinner();
      })
    }
    else if (action == 'sms') {
      this.service.showSpinner();
      this.service.getCandyPixelForms('account/send-sms-code').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          $('#suggest').modal('hide');
          $('#smsAuth').modal('show');

          this.service.showSuccessMessage(res['message']);
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '500') {
          this.service.showErrorMessage('Mobile Number Not Verified');
        }
        else {
          this.service.showErrorMessage(err['message']);
        }
      })
    }
  }

  // Resend Email For Verification
  //  resSendEmail() {
  //   var url = "account/resend-verify-email?email=" + this.loginForm.value.email + "&webUrl=" + this.service.webUrl + "/landing";
  //   this.service.showSpinner();
  //   this.service.getCandyPixelForms(url).subscribe(res => {
  //     this.service.hideSpinner();

  //   }, err => {
  //     this.service.hideSpinner();
  //   })
  // }
  // Copy Functionality
  copy(val) {
    if (val == 1) {
      this._clipboardService.copyFromContent(this.receiveForm.value.walletAdd)
      this.service.showSuccessMessage('Wallet Address Copied Successfully');
    }
    // } else  (val == 2) {
    //   this._clipboardService.copyFromContent(this.sendForm.value.uniqueId)
    //   this.service.showSuccessMessage('Tag Id Copied Successfully');
    // } 
  }


  // selectedCoin Functionality
  selectedCoin(coinname, maxtotalbaln) {
    if (this.userStatus == 'BLOCK') {
      this.service.showErrorMessage('You have been blocked and suspended by admin, kindly contact our customer support')
      return
    }
    else {
      this.selectedUserCoin = coinname;
      this.perCoinTotalBaln = maxtotalbaln

      $('#sendModal').modal({ backdrop: 'static' })
      this.sendCoin(coinname)
    }

  }


  getDepositePage(pageNumber) {
    this.depositePageNumber = pageNumber - 1;
    this.depositCoin(this.coinList);
  }


  getusdtdepositlist(coinShortName) {
    var url = ''
    this.tags = '';
    this.account = ''
    url = 'wallet/wallet/get-deposits?coinName=' + coinShortName + '&page=' + (this.depositePageNumber - 1) + '&pageSize=10';
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.getallcoins1();
        // this.account = res['data']['eosAccountName']
        // this.walletAddress = res['data'].walletAddress;
        //$('#recieveModal').modal({ backdrop: 'static' })
      }
      else {
        // this.service.showError(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        //this.service.showError('Something Went Wrong');
      }
    })
  }
  depositETH() {

    this.service.showSpinner();
    this.service.getCandyPixelForms("wallet/wallet/get-address?coinName=ETH").subscribe((res) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        // this.appC.showInfoToast('Address fetched successfully.');
        //  this.coinFullName = succ.body.data.coinName;
        //  this.textValue = succ.body.data.walletAddress;
        //  this.myAngularxQrCode = succ.body.data.walletAddress;
        //  this.tagId = succ.tag;
        this.etherSuccess()
        // $('#wallet-address').modal({backdrop: 'static', keyboard: false});
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        // this.service.showError('Something Went Wrong');
      }
    })

  }


  // ----------------transfer modal----------------
  transferModal() {
    $('#transferModal').modal('show')

  }
  // Ether Success 
  etherSuccess() {
    this.service.getCandyPixelForms('wallet/wallet-type2/get-async-transfer?coinName=ETH').subscribe(res => {

    }, err => {

    })
  }

  depositUSDT() {
    this.service.showSpinner();
    this.service.getCandyPixelForms("wallet/wallet/get-address?coinName=ETH").subscribe((res) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        // this.appC.showInfoToast('Address fetched successfully.');
        //  this.coinFullName = succ.body.data.coinName;
        //  this.textValue = succ.body.data.walletAddress;
        //  this.myAngularxQrCode = succ.body.data.walletAddress;
        //  this.tagId = succ.tag;
        this.etherSuccess()
        // $('#wallet-address').modal({backdrop: 'static', keyboard: false});
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        // this.service.showError('Something Went Wrong');
      }
    })
  }
  transfer(tab) {
    this.activeTab = 'trasfer'
  }
  trade() {
    this.router.navigate(['/advanced-exchange'])
  }

  searchByDate() {

  }
  resetSearch() {

  }
  filterOncoinPairChange() {

  }
}
