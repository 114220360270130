<div class="static-contant">
    <section class="wraper">
        <div class="container1440">
            <div class="row my-5">
                <div class="col-lg-2 mb-3">
                    <div>
                        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                            aria-orientation="vertical">
                            <a class="nav-link  profiletab" id="v-pills-home-tab" data-toggle="pill" role="tab"
                                href="javascript:;" aria-controls="v-pills-home" aria-selected="true"
                                (click)="navigateToPage('my-profile')">Profile</a>
                            <a class="nav-link  profiletab" id="v-pills-profile-tab" data-toggle="pill" role="tab"
                                href="javascript:;" aria-controls="v-pills-profile" (click)="navigateToPage('security')"
                                aria-selected="false">Security</a>
                            <a class="nav-link profiletab" id="v-pills-messages-tab" data-toggle="pill"
                                role="tab" href="javascript:;" aria-controls="v-pills-messages" aria-selected="false"
                                (click)="navigateToPage('kyc')">KYC</a>
                            <a class="nav-link profiletab" id="v-pills-settings-tab" data-toggle="pill" role="tab"
                                href="javascript:;" aria-controls="v-pills-settings" aria-selected="false"
                                (click)="navigateToPage('kyclist')">KYC List</a>
                            <a class="nav-link profiletab active" id="v-pills-referral-tab" data-toggle="pill" role="tab"
                                href="javascript:;" aria-controls="v-pills-referral" aria-selected="false"
                                (click)="navigateToPage('referral')">referral</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-10">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-pro" role="tabpanel"
                            aria-labelledby="v-pills-home-tab">
                            <div class="cover-myprofile">
                                <div class="referralHeadDiv row col-md-12">
                                    <div class="headingTitle col-md-12 row">
                                        <div class="col-md-8 text-center"><h2>Quantum Referral Program</h2></div>
                                        <div class="col-md-8 text-center qtrf"><h6>Refer and earn 50% commission of every trading fee. Be your own Boss!</h6></div>
                                    </div>
                                    <div class="userReferral col-md-12 row">
                                        <div class="col-md-3 referralCount text-center">
                                            <h6>TOTAL REFERRED FRIENDS</h6>
                                            <span>{{refrredFriendCount || 00}} </span>
                                        </div>
                                        <div class="col-md-3 referralCount text-center">
                                            <h6>TOTAL COMMISSIONS EARNED</h6>
                                            <span>₹ {{totalCommissionCount || 00.00}} </span>
                                        </div>
                                        <div class="col-md-3 referralCount text-center">
                                            <h6>YOUR COMMISSION RATE</h6>
                                            <span>50 %</span>
                                        </div>
                                    </div>
                                </div>



                                <!-- ------------------------------------------------------------------table------------------------------------------------------------- -->
                                <div class="titleProfile" style="padding: 0px;">
                                    <h3 class="cryptoWallet" style="padding-top: 22px;">Referral Commissions Earnings Report</h3>
                                    <!-- <h4 class="complete">Refer and earn 5% commission of every trade.</h4> -->
                                    <form [formGroup]="dateForm">
                                    <div class="col-md-12 row mt-5 formMainDiv">
                                        
                                        <div class="row col-md-6 form">
                                            <div class="col-md-6 filterItems form-group">
                                                <label for="fDate">From </label>
                                                <input class="form-control" type="date" formControlName="fromDate" max="{{minAge | date:'yyyy-MM-dd'}}">
                                            </div>
                                            <div class="col-md-6 filterItems form-group">
                                                <label for="toDate">To</label>
                                                <input class="form-control" type="date" formControlName="toDate" [readonly] = "!dateForm.value.fromDate" 
                                                [min]="this.dateForm.controls['fromDate'].value">
                                            </div>
                                        </div>
                                        <div class="row col-md-3  button">
                                            <div class="col-md-1 filterItems">
                                                <button class="btn theam-btn" (click)="searchByDate()">Search</button>
                                            </div>
                                            <div class="col-md-1 filterItems">
                                                <button class="btn theam-btn" (click)="resetSearch()">Reset</button>
                                            </div>
                                        </div>
                                        
                                        
                                       
                                    </div>
                                </form>
                                    <div class="howItWorks table "  style="padding: 14px">
                                       
                                        <table class="table table-striped table table-responsive">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Total Friends</th>
                                                    <th scope="col">Total Traded Friends</th>
                                                    <th scope="col">Pair</th>
                                                    <th scope="col">Traded Volume</th>
                                                    <th scope="col">Fees</th>
                                                    <th scope="col">Trader’s P/L</th>
                                                    <th scope="col">Commission Type</th>
                                                    <th scope="col">Commission %</th>
                                                    <th scope="col">QTE Reward</th>
                                                    <th scope="col">Level</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>01/01/2001</td>
                                                    <td>11</td>
                                                    <td>34</td>
                                                    <td>BTC/ETH</td>
                                                    <td>1000000</td>
                                                    <td>55</td>
                                                    <td>300</td>
                                                    <td>P/L</td>
                                                    <td>5</td>
                                                    <td>20</td>
                                                    <td>1</td>
                                                </tr>
                                                <tr>
                                                    <td>01/01/2002</td>
                                                    <td>11</td>
                                                    <td>34</td>
                                                    <td>QTE/USD</td>
                                                    <td>1000000</td>
                                                    <td>55</td>
                                                    <td>300</td>
                                                    <td>P/L</td>
                                                    <td>5</td>
                                                    <td>20</td>
                                                    <td>13</td>
                                                </tr>
                                                <tr>
                                                    <td>01/01/2002</td>
                                                    <td>11</td>
                                                    <td>34</td>
                                                    <td>QTE/USD</td>
                                                    <td>10002000</td>
                                                    <td>55</td>
                                                    <td>300</td>
                                                    <td>P/L</td>
                                                    <td>52</td>
                                                    <td>20</td>
                                                    <td>2</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- ------------------------------------------------------------------table end------------------------------------------------------------- -->
                                <div class="titleProfile">
                                    <h1 class="cryptoWallet">Quantum Referral Process</h1>
                                    <h4 class="complete">Refer and earn 5% commission of every trade.</h4>
                                    <!-- <p class="userEmail reserve">How it works?</p>
                                    <div class="cover">
                                        <div class="bankAdd mt-3">
                                            <p class="complete">Get your link</p>
                                           
                                        </div>
                                        <div class="bankAdd mt-3">
                                            <p class="complete">Share your link</p>
                                            
                                        </div>
                                        <div class="bankAdd mt-3">
                                            <p class="complete">Earn when they trade</p>
                                           
                                        </div>
                                    </div>

                                    <div class="signinCheck">
                                        
                                        <i class="fa fa-link link-icon mb-3" aria-hidden="true"></i>
                                    </div>
                                    <p class="userEmail verify">Link</p>
                                   
                                    <div class="col-md-12 col-lg-10 m-auto">
                                        <div class="referral-link-box">
                                            <label>{{service?.webUrl}}/register?myReferalCode={{referralLink?.myRefferalCode}}
                                                
                                                <em class="fa fa-copy copy-icon" (click)="copyReferralLink()"
                                                    alt="Copy"></em>
                                              
                                            </label>
                                        </div>
                                    </div> -->
                                    <div class="col-md-12 row  howItWorks" >
                                        <div class="col-md-3 imgContent">
                                            <img src="../../../assets/images/Layer.png" alt="">
                                            <span class="imgText">Earn 50% commission in QTE on every trade!</span>
                                        </div>
                                        <div class="col-md-3 imgContent">
                                            <img src="../../../assets/images/six.png" alt="">
                                            
                                            <span class="imgText">Payout within 24 hours!</span>
                                        </div>
                                        <div class="col-md-3 imgContent">
                                            <img src="../../../assets/images/three.png" alt="">
                                            <span class="imgText">Unlimited earnings</span>
                                        </div>
                                        <div class="col-md-3 imgContent">
                                            <img src="../../../assets/images/ref1.png" alt="">
                                            <span class="imgText">Easy Tracking & Reports</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="howItWorks co0l-md-12">
                                    <h1>How it works?</h1>

                                    <div class="row worksType">
                                        <div class="col-md-3 boxes">
                                            <div class="action"><img src="https://wazirx.com/static/media/partnerx-sign-up.3d7218c7.svg" alt=""></div>
                                            <div class="head"><h5>Get your link</h5></div>
                                            <div class="contentMessage">
                                                <p>Join QTE and get your unique tracking link. You'll earn for customers who sign up through this link.</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 boxes">
                                            <div class="action">
                                                <!-- <img src="https://wazirx.com/static/media/partnerx-share-your-link.f6c21e6b.svg" alt=""> -->
                                                <label class="link">{{service?.webUrl}}/register?myReferalCode={{referralLink?.myRefferalCode}}
                                                
                                                    <em class="fa fa-copy copy-icon" (click)="copyReferralLink()"
                                                        alt="Copy"></em>
                                                  
                                                </label>
                                            </div>
                                            <div class="head"><h5>Share your link</h5></div>
                                            <div class="contentMessage">
                                                <p>Share your links via Telegram, Twitter, Emails or anyway you want. The more you promote, the more you earn.</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 boxes">
                                            <div class="action"><img src="https://wazirx.com/static/media/partnerx-earn-rewards.666ab92e.svg" alt=""></div>
                                            <div class="head"><h5>Earn when they trade!</h5></div>
                                            <div class="contentMessage">
                                                <p>You earn 50% commission in QTE on every friend's trading fees! Even while you're asleep!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-12 loginReffrelButtondiv">
                                    <div class="col-md-6 loginbutton">
                                        Login to participate
                                    </div>
                                </div> -->
                                <div class="questions row">
                                    <div class="col-md-12 questionDiv" data-toggle="collapse" href="#q1" role="button" aria-expanded="false" aria-controls="collapseExample">
                                        How much can I earn in this trade commission program?
                                        <i class="fa fa-caret-down downIcon" aria-hidden="true"></i>
                                      </div>
                                     
                                    <div class="collapse" id="q1">
                                        <div class="card card-body">
                                            As much as you want. The earnings are truly unlimited! Every time your friend trades, you get 50% of his transaction 
                                            fees in QTE. The more your referred friend's trade, the more you earn. So help your friend make more trades everyday 
                                            and you will earn more commissions everyday!
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-12 questionDiv" data-toggle="collapse" href="#q2" role="button" aria-expanded="false" aria-controls="collapseExample">
                                        When will the commission be credited to my account?
                                         <i class="fa fa-caret-down downIcon" aria-hidden="true"></i>

                                      </div>
                                    <div class="collapse" id="q2">
                                        <div class="card card-body">
                                            All commissions earned for a day is credited to your account once every 24 hours. Usually early in the morning.
                                        </div>
                                           
                                        </div> -->
                                    <div class="col-md-12 questionDiv" data-toggle="collapse" href="#q3" role="button" aria-expanded="false" aria-controls="collapseExample">
                                        How will referral commissions be paid? 
                                        <i class="fa fa-caret-down downIcon" aria-hidden="true"></i>
                                    </div>
                                    <div class="collapse" id="q3">
                                        <div class="card card-body">
                                            From 15 August 2021, commissions will be paid in QTE irrespective of the currency in which the original trade occurred. Commissions earned before 15 August 2021 remain unaffected and will not be touched.                                        </div>
                                           
                                        </div>
                                    <div class="col-md-12 questionDiv" data-toggle="collapse" href="#q4" role="button" aria-expanded="false" aria-controls="collapseExample">
                                        Do you have any tips that will help me earn more rewards? 
                                        <i class="fa fa-caret-down downIcon" aria-hidden="true"></i>
                                    </div>
                                    <div class="collapse" id="q4">
                                        <div class="card card-body">
                                            <ul>
                                                <li>Share your invite link with as many friends as you can. The more friends you share it with, the more likely they are to sign up. Use social media, chat and emails to share your invite link</li>
                                                <li>Get your friends excited about crypto and why they need to sign up on an exchange like QTE</li>
                                                <li>Let your friends know about the benefits of signing up with your invite link instead of signing up directly</li>
                                                <li>Encourage your friends to use their own referral links after they sign up. This will build their trust in you</li>
                                                <li>Push your friends to trade everyday. The more they trade, the more you earn.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-12 questionDiv" data-toggle="collapse" href="#q5" role="button" aria-expanded="false" aria-controls="collapseExample">
                                        I referred a friend but I am not receiving commissions on his/her trades!
                                        <i class="fa fa-caret-down downIcon" aria-hidden="true"></i>
                                      </div>
                                    <div class="collapse" id="q5">
                                        <div class="card card-body">
                                            <p>There are some reasons why even though you think you may have successfully referred a friend, that referral did not count -</p>
                                        <ul>
                                            <li>Your friend clicked on your referral link but signed up through the app. The referral program does not currently support sign ups through the Android or iOS apps.</li>
                                            <li>Your friend did not click on your referral link and instead signed up directly on QTE</li>
                                            <li>Your friend clicked on another referral link and signed up with that link</li>
                                            <li>Your friend's KYC is still under verification or has been unapproved</li>
                                            <li>You or your friend did not meet the rules below</li>
                                        </ul>
                                        </div>
                                    </div>


                                    <div class="col-md-12 questionDiv" data-toggle="collapse" href="#q6" role="button" aria-expanded="false" aria-controls="collapseExample">
                                        Important rules to keep in mind
                                        <i class="fa fa-caret-down downIcon" aria-hidden="true"></i>
                                      </div>
                                    <div class="collapse" id="q6">
                                        <div class="card card-body">
                                            <p>Please keep in mind the below rules when you participate in the referral Program. These simple but important rules help us keep the program fair for the entire community.</p>
                                        <ul>
                                            <li>Do not misrepresent rewards when sharing the referral link with your friends</li>
                                            <li>We constantly look out for fake or duplicate accounts. If we find something amiss we will disqualify those accounts from being part of the Quantum program and withdraw any rewards that may have been credited earlier</li>
                                            
                                        </ul>
                                       <span>Note:- </span> <small>While we run this program in good faith, Quantum reserves the right to make any changes 
                                           to the referral program without prior notice. We may make such changes in view of changing market conditions,
                                            security or risk of fraud or any other reason. The decision on reward eligibility will be made by Quantum and 
                                            will be final and binding. By participating in the referral program you acknowledge the above.If you find someone
                                             misusing the referral program, you can let us know by contacting us directly. We'll look into it and take the 
                                             necessary steps to fix it.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>