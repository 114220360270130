<!DOCTYPE html>
  <body class="static-contant">



    <section class="ver-sec-eml">
      <div class="container1440">
        <div class="security-sms">
          <div class="e-bled"><h1>GOOGLE AUTHENTICATION</h1>
          <p class="dummy">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.

            </p>
            <div class="scanQr">SCAN QR CODE</div>
            <div class="qrScaner">
              <img src="assets/images/qrScaner.png" alt="">
            </div>
          </div>


          <form class="login-sms-sec">
            <div class="form-group inpt-frm-pro000">
             
            
              <input type="email" class="form-control email-pass-pro-sec00">
            </div>
            <div class="sms-code">
            </div>
            <div class="ena-sms">
                <button style="max-width: 350px;" type="submit" class="btn register-btn">SUBMIT</button>
              </div>
            </form>
          </div>
         
          

          </div>
    </section>
  </body>