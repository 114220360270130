<body class="" data-theme-version="dark">
  <section class="dark-page ">
    <div class="container-fluid pb-4 pt1 ">

      <div class="row  align-items-center setting-head">
        <div class="col-md-2 col-lg-1" style="padding-right: 0px; padding-left: 8px;">
          <div class="dropdown">
            <button class="btn btc_button" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              {{coinDetail?.CoinPair}} <i class="fa fa-caret-down pl-2" aria-hidden="true"></i></button>
            <div class="dropdown-menu coinSelection" aria-labelledby="dropdownMenuButton">
              <div class="drop-ad selectCoin" style="width:100%; color: white; margin-top: 1rem;">
                <div class=" " *ngFor="let pair of coinpair">
                  <!-- <a class="dropdown-item text-light">{{pair.baseCoin}}</a> -->
                  <div class="coinTypePair" style="cursor: pointer;" *ngFor="let exec of pair.execCoin;let i = index"
                    (click)="getSelectedPair(pair.baseCoin,exec)">
                    <p class="eurValue" style="font-size: 13px;
                    margin-left: 10px;"
                      [ngClass]="{'active' : (exec.toLowerCase() == this.coinDetail.execCoin && pair.baseCoin.toLowerCase() == this.coinDetail.baseCoin)}">
                      {{exec}} / {{pair?.baseCoin}}</p>
                    <p class="amountValue" style="font-size: 20px;"> {{pair?.marketValue || 0}}
                    </p>
                    <hr>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7 col-lg-6">
          <div class="price_list">
            <p id="p">Last Price <strong>{{marketalldata?.lastPrice || 0}}</strong></p>
            <p id="p">24h Change <strong>{{marketalldata?.highest24HourPrice || 0}}</strong></p>
            <p id="p">24h High <strong>{{marketalldata?.highest24HourPrice || 0}}</strong></p>
            <p id="p">24h Low <strong>{{marketalldata?.lowest24HourPrice || 0}}</strong></p>
            <p id="p">24h Volume <strong> {{marketalldata?.volume24Hour || 0}}</strong></p>
          </div>
        </div>
        <div class="col-md-3 col-lg-5">
          <div class="day_ngt_icon">
            <i class="fa fa-moon-o icon-head" aria-hidden="true" (click)="modeChangeFunc('night')"></i>
            <i class="fa fa-sun-o icon-head" aria-hidden="true" (click)="modeChangeFunc('day')"></i>
            <i class="fa fa-circle icon-head1" *ngIf="connection == 'online'" aria-hidden="true"></i>
            <i class="fa fa-circle icon-head2" *ngIf="connection != 'online'" aria-hidden="true"></i>
          </div>
        </div>
      </div>



      <!-- <div class="row col-md-7 graph-tab-div">
        <div class="col-md-4  professional-depth">
          <p><span class="btn open_order graph-tab" [ngClass]="{'active': showGraph == 'Professional'}" (click)="switchGraph('Professional')">Professional</span><span class="btn open_order graph-tab" [ngClass]="{'active': showGraph == 'Depth'}" (click)="switchGraph('Depth')">Depth</span></p>
          </div>
      </div> -->


      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-7 paddingCustom">
          <!-- chart header with tab switch button -->
          <div class="graph-tab-div">
            <div class="professional-depth">
              <h5>Chart</h5>
              <p class="profDept mb-0"><span class="btn  graph-tab mr-3"
                  [ngClass]="{'active': showGraph == 'Professional'}"
                  (click)="switchGraph('Professional')">Professional</span>
                <span class="btn  graph-tab" [ngClass]="{'active': showGraph == 'Depth'}"
                  (click)="switchGraph('Depth')">Depth</span></p>
            </div>
          </div>
          <!-- chart view -->
          <div [hidden]="showGraph == 'Depth'" id="tradingview_Ohl"></div>
          <!-- <div [hidden]="showGraph == 'Professional'" id="depth_chart"></div> -->
          <div [hidden]="showGraph == 'Professional'" id="depth_chart" class="amclass" [style.width.%]="100"></div>

          <div class="order-trade-tab mt-2">
            <button class=" btn open_order active" type="submit" [ngClass]="{'active': orderTab =='open' }"
              (click)="showOrderStatus('open')">Open Order</button>
            <button class=" btn open_order  ml-3" type="submit" [ngClass]="{'active': orderTab =='history' }"
              (click)="showOrderStatus('history')">Order
              History</button>
            <button class=" btn open_order " type="submit" [ngClass]="{'active': orderTab =='trade' }"
              (click)="showOrderStatus('trade')">My Trade History</button>
            <button class=" btn open_order " type="submit" [ngClass]="{'active': orderTab =='fund' }"
              (click)="showOrderStatus('fund')">Fund</button>
          </div>

          <div class="table-responsive trade_table buy_sell_table  " *ngIf="orderTab == 'open'">
            <table class="table table-striped table-hover exchange_table" aria-describedby="advance exchange table">
              <tbody>
                <tr>
                  <th id="">Pair</th>
                  <th id="">Price</th>
                  <th id="">Amount</th>
                  <th id="">Total</th>
                  <th id="">Order Status</th>
                  <!-- <th id="">Order Side</th> -->
                  <th id="">Order Type</th>
                  <!-- <th id="">Filled %</th> -->
                  <th id="">Date & Time</th>
                  <th id="">Action</th>
                </tr>
                <tr class=""
                  *ngFor="let orderitem of openOrderList | paginate: { itemsPerPage: 5, currentPage: openOrderPageNumber, totalItems: openOrderList.length , id:'openOrder' } ; let i=index ">
                  <td>{{orderitem.pair}}</td>
                  <td>{{manageExponential(orderitem.price) | number: '.8-8'}}</td>
                  <td>{{manageExponential(orderitem.amount) | number: '.8-8'}}</td>
                  <td>{{manageExponential(orderitem.total) | number: '.8-8'}}</td>
                  <td *ngIf="orderitem?.orderStatus === 'QUEUED'">Delivered</td>
                  <td *ngIf="orderitem?.orderStatus !== 'QUEUED'">{{orderitem?.orderStatus}}</td>
                  <!-- <td>{{orderitem.orderSide}}</td> -->
                  <td>{{orderitem.orderType}}</td>
                  <!-- <td>{{orderitem.happening | number: '.8-8'}}</td> -->
                  <td>{{orderitem.orderTime | date: 'MMM d, y, hh:mm:ss'}}</td>
                  <td> <img class="img" src="assets/images/del.png" alt="" (click)="openCancel(orderitem.orderId)"></td>
                </tr>
                <div *ngIf="openOrderList.length == 0 && loginStatus" class="login_registor">No Open Order Available
                </div>
                <div *ngIf="!loginStatus" class="login_registor">
                  <p [routerLink]="['/login']">Log In</p> or<p [routerLink]="['/register']"> Register</p> Now To Trade
                </div>
              </tbody>
            </table>
            <div class="pageContent">
              <pagination-controls *ngIf="openOrderList.length > 5" id='openOrder'
                (pageChange)="openOrderPageNumber = $event"></pagination-controls>
            </div>
          </div>

          <div class="table-responsive  trade_table buy_sell_table " *ngIf="orderTab == 'history'">
            <table class="table table-striped table-hover exchange_table" aria-describedby="advance exchange table">
              <tbody>
                <tr>
                  <th id="">Pair</th>
                  <th id="">Price</th>
                  <th id="">Amount</th>
                  <th id="">Total</th>
                  <th id="">Order Status</th>
                  <!-- <th id="">Order Side</th> -->
                  <th id="">Order Type</th>
                  <!-- <th id="">Filled %</th> -->
                  <th id="">Date & Time</th>
                </tr>
                <tr class=""
                  *ngFor="let data of orderHistoryList | paginate: { itemsPerPage: 5, currentPage: orderHistoryPageNumber, totalItems: orderHistoryList.length , id:'orderHistory' } ; let i=index ">
                  <td>{{data.pair}}</td>
                  <td>{{manageExponential(data.price) | number: '.8-8'}}</td>
                  <td>{{manageExponential(data.amount) | number: '.8-8'}}</td>
                  <td>{{manageExponential(data.total) | number:'.8-8'}}</td>
                  <td>{{data.orderStatus}}</td>
                  <!-- <td>{{data.orderSide}}</td> -->
                  <td>{{data.orderType}}</td>
                  <!-- <td>{{data.happening | number: '.8-8'}}</td> -->
                  <td>{{data.orderTime | date: 'MMM d, y, hh:mm:ss'}}</td>

                </tr>
                <div *ngIf="orderHistoryList.length == 0 && loginStatus" class="login_registor">No Order History
                  Available
                </div>

                <div *ngIf="!loginStatus" class="login_registor">
                  <p [routerLink]="['/login']">Log In</p> or<p [routerLink]="['/register']"> Register</p> Now To Trade
                </div>
              </tbody>
            </table>
            <div class="pageContent">
              <pagination-controls id='orderHistory' *ngIf="orderHistoryList.length > 5"
                (pageChange)="orderHistoryPageNumber = $event"></pagination-controls>
            </div>
          </div>
          <!-- trade history -->
          <div class="table-responsive trade_table buy_sell_table  " *ngIf="orderTab == 'trade'">
            <table class="table table-striped table-hover exchange_table" aria-describedby="advance exchange table">
              <tbody>
                <tr class="">
                  <th id="">Price({{coinDetail?.base}})</th>
                  <th id=""> Amount({{coinDetail?.exec}})</th>
                  <th id="">Time</th>
                </tr>
                <tr class="" *ngFor="let trade of myTradeHistoryList">
                  <td class="price-red" [ngClass]="{'selectPercentage' : trade?.side == 'SELL'}"
                    title="{{trade?.price}}">
                    {{trade?.price}}</td>
                  <td [ngClass]="{'selectPercentage' : trade?.side == 'SELL'}">
                    {{trade?.amount}}</td>
                  <td [ngClass]="{'selectPercentage' : trade?.side == 'SELL'}">
                    {{trade?.time | date : 'hh:mm:ss'}}</td>
                </tr>
                <div *ngIf="myTradeHistoryList.length == 0 && loginStatus" class="login_registor">No Open Order
                  Available
                </div>
                <div *ngIf="!loginStatus" class="login_registor">
                  <p [routerLink]="['/login']">Log In</p> or<p [routerLink]="['/register']"> Register</p> Now To Trade
                </div>
              </tbody>
            </table>
            <div class="pageContent">
              <pagination-controls *ngIf="myTradeHistoryList.length > 5" id='openOrder'
                (pageChange)="openOrderPageNumber = $event"></pagination-controls>
            </div>
          </div>
          <!-- fund -->
          <div class="table-responsive trade_table buy_sell_table  " *ngIf="orderTab == 'fund'">
            <table class="table table-striped table-hover exchange_table" aria-describedby="advance exchange table">
              <tbody>
                <tr>
                  <th id="">Coin</th>
                  <th id="">Name</th>
                  <th id="">Total Balance</th>
                  <th id="">Available Balance</th>
                  <th id="">Frozen Balance</th>

                  <th id="">In order</th>
                  <!-- <th id="">BTC value</th> -->
                  <!-- <th id="">Action</th> -->

                </tr>
                <tr class=""
                  *ngFor="let orderitem of openOrderList | paginate: { itemsPerPage: 5, currentPage: openOrderPageNumber, totalItems: openOrderList.length , id:'openOrder' } ; let i=index ">
                  <td>{{orderitem.pair}}</td>
                  <td>{{manageExponential(orderitem.price) | number: '.8-8'}}</td>
                  <td>{{manageExponential(orderitem.amount) | number: '.8-8'}}</td>
                  <td>{{manageExponential(orderitem.total) | number: '.8-8'}}</td>
                  <td>{{manageExponential(orderitem.total) | number: '.8-8'}}</td>

                  <td *ngIf="orderitem?.orderStatus === 'QUEUED'">Delivered</td>
                  <!-- <td *ngIf="orderitem?.orderStatus !== 'QUEUED'">{{orderitem?.orderStatus}}</td>
                  <td>{{orderitem.orderSide}}</td> -->

                </tr>
                <div *ngIf="openOrderList.length == 0 && loginStatus" class="login_registor">No Open Fund Available
                </div>
                <div *ngIf="!loginStatus" class="login_registor">
                  <p [routerLink]="['/login']">Log In</p> or<p [routerLink]="['/register']"> Register</p> Now To Trade
                </div>
              </tbody>
            </table>
            <!-- <div class="pageContent">
              <pagination-controls *ngIf="openOrderList.length > 5" id='openOrder'
                (pageChange)="openOrderPageNumber = $event"></pagination-controls>
            </div> -->
          </div>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-5 p-0 row m-0">
          <div class="col-md-12 col-lg-6 paddingCustom">
            <!-- <h5>Buy</h5> -->
            <div class="order-trade-head">
              <h5>Order Book</h5>
            </div>
            <div class="p-1">
              <img src="../../../assets/images/icon-s1.png" class="iconBuySell" style="font-size:24px ; "
                (click)="orderBookTab('Both')" [ngClass]="{buyer_seller: (orderBookTabShow == 'Both')}" alt="">
              <img src="../../../assets/images/icon-s2.png" class="iconBuySell ml-2" style="font-size:24px ; "
                (click)="orderBookTab('Buyers')" [ngClass]="{buyer_seller: (orderBookTabShow == 'Buyers')}" alt="Buy">
              <img src="../../../assets/images/icon-s3.png" class="iconBuySell ml-2" style="font-size:24px ;"
                (click)="orderBookTab('Sellers')" [ngClass]="{buyer_seller: (orderBookTabShow == 'Sellers')}"
                alt="Sell">
            </div>
            <!-- <div [hidden]="orderBookTabShow == 'Sellers'" class="table-responsive trade_table buy_sell_table" > -->
            <div [hidden]="orderBookTabShow == 'Sellers'" class="table-responsive"
              [ngClass]="orderBookTabShow == 'Both'? 'trade_table buy_sell_table': 'order_buy_sell_table'">
              <table class="table table-striped table-hover exchange_table " aria-describedby="advance exchange table">
                <tbody>
                  <tr class="">
                    <th id="">Price({{coinDetail?.base}})</th>
                    <th id="">Amount({{coinDetail?.exec}})</th>
                    <th id="">Total</th>
                  </tr>
                  <tr class="" *ngFor="let buy of buyOrderList">
                    <td style="color: green;">{{buy?.price}}</td>
                    <td style="color: green;">{{buy?.amount}}</td>
                    <td style="color: green;">{{buy?.total}}</td>
                  </tr>
                  <tr class="priceColor" *ngIf="buyOrderList.length == 0 && loginStatus">
                    <td colspan="100" class="no_data">No Buy Order
                      Available</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <h5 class="mt-4">Sell</h5> -->
            <div [hidden]="orderBookTabShow == 'Sellers' || orderBookTabShow == 'Buyers'" class="order-trade-head">
              <h5>34.00000</h5>
            </div>

            <!-- <div [hidden]="orderBookTabShow == 'Buyers'" class="  table-responsive trade_table buy_sell_table"> -->
            <div [hidden]="orderBookTabShow == 'Buyers'" class="  table-responsive"
              [ngClass]="orderBookTabShow == 'Both'? 'trade_table buy_sell_table': 'order_buy_sell_table'">

              <table class="table table-striped table-hover exchange_table" aria-describedby="advance exchange table">
                <tbody>
                  <!-- <tr class="">
                    <th id="">Price({{coinDetail?.base}})</th>
                    <th id="">Amount({{coinDetail?.exec}})</th>
                    <th id=""> Total</th>
                  </tr> -->
                  <tr class="" *ngFor="let sell of sellOrderList">
                    <td style="color: red;">{{sell?.price}}</td>
                    <td style="color: red;">{{sell?.amount}}</td>
                    <td style="color: red;">{{sell?.total}}</td>
                  </tr>
                  <tr class="priceColor" *ngIf="sellOrderList.length == 0 && loginStatus">
                    <td colspan="100" class="no_data">
                      <div>No Sell Order
                        Available</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 paddingCustom">
            <!-- <h5>Market Trade</h5> -->
            <div class="order-trade-head">
              <h5>Trade History</h5>
            </div>
            <div class=" table-responsive  trade_table">
              <table class="table table-striped table-hover exchange_table" aria-describedby="advance exchange table">
                <tbody>
                  <tr class="">
                    <th id="">Price({{coinDetail?.base}})</th>
                    <th id=""> Amount({{coinDetail?.exec}})</th>
                    <th id="">Time</th>
                  </tr>
                  <tr class="" *ngFor="let trade of tradeHistoryList">
                    <td class="price-red" [ngClass]="{'selectPercentage' : trade?.side == 'SELL'}"
                      title="{{trade?.price}}">
                      {{trade?.price}}</td>
                    <td [ngClass]="{'selectPercentage' : trade?.side == 'SELL'}">
                      {{trade?.amount}}</td>
                    <td [ngClass]="{'selectPercentage' : trade?.side == 'SELL'}">
                      {{trade?.time | date : 'hh:mm:ss'}}</td>
                  </tr>
                  <div *ngIf="tradeHistoryList.length == 0 && loginStatus" class="login_registor">No Trade Available
                  </div>
                  <div *ngIf="tradeHistoryList.length == 0 && !loginStatus" class="login_registor">
                    <p [routerLink]="['/login']">Log In</p> or<p [routerLink]="['/register']"> Register</p> Now To Trade
                  </div>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 paddingCustom">
            <ul class="nav nav-pills mb-0 order-trade-tab mt-2" id="pills-tab" role="tablist">
              <li class="nav-item ">
                <a class="nav-link open_order  active" (click)="tabNav('market')" id="pills-market-tab"
                  data-toggle="pill" href="#pills-market" role="tab" aria-controls="pills-market"
                  aria-selected="false">Market</a>
              </li>
              <li class="nav-item ">
                <a class="nav-link open_order ml-3" id="pills-limit-tab" data-toggle="pill" href="#pills-sell"
                  role="tab" aria-controls="pills-sell" aria-selected="false">Limit</a>
              </li>
              <li class="nav-item ">
                <a class="nav-link open_order ml-3" id="pills-stoplimit-tab" data-toggle="pill" href="#pills-stoplimit"
                  role="tab" aria-controls="pills-stoplimit" aria-selected="false">Stop Limit</a>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <!---MARKET-->
              <div class="tab-pane fade show active" id="pills-market" role="tabpanel"
                aria-labelledby="pills-market-tab">
                <div class="row m-0">
                  <div class="col-xl-6 col-lg-12 col-md-6 p-0 outline-dashed">
                    <form [formGroup]="marketBuyForm" #f="ngForm">
                      <div class="market_box">
                        <div class="buy_div">
                          <p>Buy {{coinDetail?.exec}}</p>
                          <p>{{coinDetail?.base}} Balance:
                            {{manageExponential(baseCoinBalance)}}</p>
                        </div>

                        <div class="btc-form mb-1 text-light">
                          <label class=" col-form-label">Price:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('bmp');getAmountFunc($event.target.value,'MARKETBUY')" type="text"
                              class="form-control" formControlName="marketBuyPrice" placeholder="Market" readonly>
                          </div>
                        </div>

                        <div class="btc-form mb-1 text-light"
                          [ngClass]="f.submitted && (marketBuyForm.get('marketBuyquantity').hasError('required')) ? 'errClass' : ''">
                          <label class=" col-form-label">Quantity:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('bsq');" type="text" class="form-control"
                              formControlName="marketBuyquantity" id="inputPassword" placeholder="{{coinDetail.exec}}"
                              (keyup)="getAmountFunc($event.target.value,'MARKETBUY')">

                            <span class="danger">
                              <div
                                *ngIf="(f.submitted && (marketBuyForm.get('marketBuyquantity').hasError('required')))">
                                <p class="danger" padding style=" font-size: 10 px !important;">
                                  *Please enter market quantity.
                                </p>
                              </div>
                            </span>
                          </div>
                        </div>

                        <div class="btc-form mb-3">
                          <label class=" col-form-label"></label>
                          <div class="btc-div-text">
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : buyPercentage == 25}"
                              (click)="selectBuyPerFunc('percentage',25,'buy','MARKETBUY')">25%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : buyPercentage == 50}"
                              (click)="selectBuyPerFunc('percentage',50,'buy','MARKETBUY')">50%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : buyPercentage == 75}"
                              (click)="selectBuyPerFunc('percentage',75,'buy','MARKETBUY')">75%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : buyPercentage == 100}"
                              (click)="selectBuyPerFunc('percentage',100,'buy','MARKETBUY')">100%</button>
                          </div>
                        </div>
                        <div *ngIf="!loginStatus" class="buy-btn-div">
                          <button class="btn exchange_btn buy-btn-register" style=" background: red!important;"
                            [routerLink]="['/login']">Log In or Register Now
                          </button>
                        </div>
                        <div *ngIf="loginStatus" class="buy-btn-div">
                          <!-- BUY -->
                          <button class="btn exchange_btn" (click)="placeOrderFunc('MARKETBUY')"
                            style="background: green!important;">Buy
                            {{coinDetail?.exec}}</button>
                          <div class="bnncl">
                            <div class="clearFilter">
                              <button class="btn exchange_btn ml-2" (click)="clearFilterOfBuy()">Clear
                                Filter</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-6 p-0">
                    <form [formGroup]="marketSellForm" #f="ngForm">
                      <div class="market_box">
                        <div class="buy_div">
                          <p>Sell {{coinDetail?.exec}}</p>
                          <p>{{coinDetail?.exec}} Balance:
                            {{manageExponential(execCoinBalance)}}</p>
                        </div>
                        <div class="btc-form mb-1  text-light">
                          <label class=" col-form-label">Price:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('spm');getPriceFunc($event.target.value,'MARKETSELL')" type="text"
                              class="form-control" formControlName="marketSellPrice" placeholder="Market" readonly>
                          </div>
                        </div>
                        <div class="btc-form mb-1 text-light"
                          [ngClass]="f.submitted && (marketSellForm.get('marketSellquantity').hasError('required')) ? 'errClass' : ''">
                          <label class=" col-form-label">Quantity:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('msq');" type="text" class="form-control"
                              formControlName="marketSellquantity" id="inputPassword" placeholder="{{coinDetail?.exec}}"
                              (keyup)="getAmountFunc($event.target.value,'MARKETSELL')">
                            <span class="danger">
                              <div
                                *ngIf="(f.submitted && (marketSellForm.get('marketSellquantity').hasError('required')))">
                                <p class="danger" padding style=" font-size: 10 px !important;">
                                  *Please enter market quantity.
                                </p>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="btc-form mb-3">
                          <label class=" col-form-label"></label>
                          <div class="btc-div-text">
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : sellPercentage == 25}"
                              (click)="selectSellPerFunc('percentage',25,'sell','MARKETSELL')">25%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : sellPercentage == 50}"
                              (click)="selectSellPerFunc('percentage',50,'sell','MARKETSELL')">50%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : sellPercentage == 75}"
                              (click)="selectSellPerFunc('percentage',75,'sell','MARKETSELL')">75%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : sellPercentage == 100}"
                              (click)="selectSellPerFunc('percentage',100,'sell','MARKETSELL')">100%</button>
                          </div>
                        </div>
                        <div *ngIf="!loginStatus" class="buy-btn-div">
                          <button class="btn exchange_btn" [routerLink]="['/login']">Log In or Register Now
                          </button>
                        </div>
                        <div class="registerTrade botomBtn buy-btn-div" *ngIf="loginStatus">
                          <!-- SELL -->
                          <button class="btn exchange_btn" (click)="placeOrderFunc('MARKETSELL')"
                            style=" background: red!important;">Sell
                            {{coinDetail?.exec}}</button>
                          <div class="bnncl">
                            <div class="clearFilter">
                              <button type="button" class="btn exchange_btn ml-2" (click)="clearFilterOfSell()">Clear
                                Filter</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
              <!---MARKET-->

              <!----LIMIT-->
              <div class="tab-pane fade" id="pills-sell" role="tabpanel" aria-labelledby="pills-limit-tab">
                <div class="row m-0">
                  <div class="col-xl-6 col-lg-12 col-md-6 p-0 outline-dashed">
                    <form [formGroup]="limitBuyForm" #f="ngForm">
                      <div class="  market_box  ">
                        <div class="buy_div">
                          <p>Buy {{coinDetail?.exec}}</p>
                          <p>{{coinDetail?.base}} Balance:
                            {{manageExponential(baseCoinBalance)}}</p>
                        </div>
                        <div class=" btc-form mb-1 text-light"
                          [ngClass]="f.submitted && (limitBuyForm.get('limitBuyPrice').hasError('required')) ? 'errClass' : ''">
                          <label class=" col-form-label">Price:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('lmp');" type="text" class="form-control"
                              formControlName="limitBuyPrice" placeholder="{{coinDetail.base}}"
                              (keyup)="getPriceFunc($event.target.value,'LIMITBUY');">

                            <div *ngIf="(f.submitted && (limitBuyForm.get('limitBuyPrice').hasError('required')))">
                              <p class="danger" padding style=" font-size: 10 px !important;">
                                *Please enter limit price.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class=" btc-form mb-1 text-light"
                          [ngClass]="f.submitted && (limitBuyForm.get('limitBuyQuantity').hasError('required')) ? 'errClass' : ''">
                          <label class=" col-form-label">Quantity:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('lsb');" type="text" class="form-control"
                              formControlName="limitBuyQuantity" id="inputPassword" placeholder="{{coinDetail.exec}}"
                              (keyup)="getAmountFunc($event.target.value,'LIMITBUY')">
                            <span class="danger">
                              <div *ngIf="(f.submitted && (limitBuyForm.get('limitBuyQuantity').hasError('required')))">
                                <p class="danger" padding style=" font-size: 10 px !important;">
                                  *Please enter limit quantity.
                                </p>
                              </div>
                            </span>
                          </div>
                        </div>

                        <div class=" btc-form mb-1">
                          <label class=" col-form-label"></label>
                          <div class="btc-div-text">
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : buyPercentage == 25}"
                              (click)="selectBuyPerFunc('percentage',25,'buy','LIMITBUY')">25%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : buyPercentage == 50}"
                              (click)="selectBuyPerFunc('percentage',50,'buy','LIMITBUY')">50%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : buyPercentage == 75}"
                              (click)="selectBuyPerFunc('percentage',75,'buy','LIMITBUY')">75%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : buyPercentage == 100}"
                              (click)="selectBuyPerFunc('percentage',100,'buy','LIMITBUY')">100%</button>
                          </div>
                        </div>

                        <div class=" btc-form mt-1 text-light mb-3">
                          <label class=" col-form-label">Total:</label>
                          <div class="btc-div-text">
                            <input type="text" class="form-control " formControlName="limitBuyTotal"
                              placeholder="{{coinDetail.base}}" readonly>
                          </div>
                        </div>
                        <div class="buy-btn-div " *ngIf="!loginStatus">
                          <button class="btn exchange_btn buy-btn-register" style=" background: red!important;"
                            [routerLink]="['/login']">Log In or Register Now
                          </button>
                        </div>
                        <div *ngIf="loginStatus" class="buy-btn-div">
                          <!-- BUY -->
                          <button class="btn exchange_btn" (click)="placeOrderFunc('LIMITBUY')"
                            style=" background: green!important;">Buy
                            {{coinDetail?.exec}}</button>
                          <div class="bnncl">
                            <div class="clearFilter">
                              <button class="btn exchange_btn ml-2" (click)="clearFilterOfBuy()">Clear
                                Filter</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-6 p-0">
                    <form [formGroup]="limitSellForm" #f="ngForm">
                      <div class="   market_box">
                        <div class="buy_div">
                          <p>Sell {{coinDetail?.exec}}</p>
                          <p>{{coinDetail?.exec}} Balance:
                            {{manageExponential(execCoinBalance)}}</p>
                        </div>

                        <div class="btc-form mb-1 text-light"
                          [ngClass]="f.submitted && (limitSellForm.get('limitSellPrice').hasError('required')) ? 'errClass' : ''">
                          <label class=" col-form-label">Price:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('lpm');" type="text" class="form-control "
                              formControlName="limitSellPrice" id="inputPassword" placeholder="{{coinDetail?.base}}"
                              (keyup)="getPriceFunc($event.target.value,'LIMITSELL');">
                            <div *ngIf="(f.submitted && (limitSellForm.get('limitSellPrice').hasError('required')))">
                              <p class="danger" padding style=" font-size: 10 px !important;">
                                *Please enter limit price.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="btc-form mb-1 text-light"
                          [ngClass]="f.submitted && (limitSellForm.get('limitSellQuantity').hasError('required')) ? 'errClass' : ''">
                          <label class="col-form-label">Quantity:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('lsq');" type="text" class="form-control"
                              formControlName="limitSellQuantity" id="inputPassword" placeholder="{{coinDetail?.exec}}"
                              (keyup)="getAmountFunc($event.target.value,'LIMITSELL')">
                            <span class="danger">
                              <div
                                *ngIf="(f.submitted && (limitSellForm.get('limitSellQuantity').hasError('required')))">
                                <p class="danger" padding style=" font-size: 10 px !important;">
                                  *Please enter limit quantity.
                                </p>
                              </div>
                            </span>
                          </div>
                        </div>

                        <div class="btc-form mb-1">
                          <label class=" col-form-label"></label>
                          <div class="btc-div-text">
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : sellPercentage == 25}"
                              (click)="selectSellPerFunc('percentage',25,'sell','LIMITSELL')">25%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : sellPercentage == 50}"
                              (click)="selectSellPerFunc('percentage',50,'sell','LIMITSELL')">50%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : sellPercentage == 75}"
                              (click)="selectSellPerFunc('percentage',75,'sell','LIMITSELL')">75%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : sellPercentage == 100}"
                              (click)="selectSellPerFunc('percentage',100,'sell','LIMITSELL')">100%</button>
                          </div>
                        </div>
                        <div class="btc-form mt-1 text-light mb-3">
                          <label class=" col-form-label">Total:</label>
                          <div class="btc-div-text">
                            <input type="text" class="form-control " formControlName="limitSellTotal"
                              placeholder="{{coinDetail.base}}" readonly>
                          </div>
                        </div>
                        <div *ngIf="!loginStatus" class="buy-btn-div">
                          <button class="btn exchange_btn" [routerLink]="['/login']">Log In or Register Now
                          </button>
                        </div>
                        <div *ngIf="loginStatus" class="buy-btn-div">
                          <!-- BUY -->
                          <button class="btn exchange_btn" (click)="placeOrderFunc('LIMITSELL')"
                            style=" background: red!important;">Sell
                            {{coinDetail?.exec}}</button>
                          <div class="bnncl">
                            <div class="clearFilter">
                              <button class="btn exchange_btn ml-2" (click)="clearFilterOfSell()">Clear
                                Filter</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
              <!----LIMIT-->

              <!----STOPLIMIT-->
              <div class="tab-pane fade" id="pills-stoplimit" role="tabpanel" aria-labelledby="pills-stoplimit-tab">
                <div class="row m-0">
                  <div class="col-xl-6 col-lg-12 col-md-6 p-0 outline-dashed">
                    <form [formGroup]="buyForm" #f="ngForm">
                      <div class=" market_box   ">
                        <div class="buy_div">
                          <p>Buy {{coinDetail?.exec}}</p>
                          <p>{{coinDetail?.base}} Balance:
                            {{manageExponential(baseCoinBalance)}}</p>
                        </div>
                        <div class="btc-form mb-1 text-light"
                          [ngClass]="f.submitted && (buyForm.get('stopLimit').hasError('required')) ? 'errClass' : ''">
                          <label class=" col-form-label">Stop Limit:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('bsl');" type="text" class="form-control"
                              formControlName="stopLimit" id="inputPassword" placeholder="{{coinDetail.base}}">
                          </div>
                        </div>

                        <div class="btc-form mb-1  text-light"
                          [ngClass]="f.submitted && (buyForm.get('price').hasError('required')) ? 'errClass' : ''">
                          <label class=" col-form-label">Limit:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('smb');" type="text" class="form-control " formControlName="price"
                              placeholder="{{coinDetail.base}}"
                              (keyup)="getPriceFunc($event.target.value,'STOPLIMITBUY');">
                          </div>
                        </div>

                        <div class="btc-form  mb-1 text-light"
                          [ngClass]="f.submitted && (buyForm.get('quantity').hasError('required')) ? 'errClass' : ''">
                          <label class=" col-form-label">Quantity:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('ssb');" type="text" class="form-control "
                              formControlName="quantity" id="inputPassword" placeholder="{{coinDetail.exec}}"
                              (keyup)="getAmountFunc($event.target.value,'STOPLIMITBUY')">
                          </div>
                        </div>

                        <div class="btc-form mb-1">
                          <label class=" col-form-label"></label>
                          <div class="btc-div-text">
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : buyPercentage == 25}"
                              (click)="selectBuyPerFunc('percentage',25,'buy','STOPLIMITBUY')">25%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : buyPercentage == 50}"
                              (click)="selectBuyPerFunc('percentage',50,'buy','STOPLIMITBUY')">50%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : buyPercentage == 75}"
                              (click)="selectBuyPerFunc('percentage',75,'buy','STOPLIMITBUY')">75%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : buyPercentage == 100}"
                              (click)="selectBuyPerFunc('percentage',100,'buy','STOPLIMITBUY')">100%</button>
                          </div>
                        </div>
                        <div class="btc-form mb-3 text-light">
                          <label class=" col-form-label">Total:</label>
                          <div class="btc-div-text">
                            <input type="text" class="form-control " formControlName="total"
                              placeholder="{{coinDetail.base }}" readonly>
                          </div>
                        </div>
                        <div *ngIf="!loginStatus" class="buy-btn-div">
                          <button class="btn exchange_btn buy-btn-register" style=" background: red!important;"
                            [routerLink]="['/login']">Log In or Register Now
                          </button>
                        </div>
                        <div *ngIf="loginStatus" class="buy-btn-div">
                          <!-- BUY -->
                          <button class="btn exchange_btn" (click)="placeOrderFunc('STOPLIMITBUY')"
                            style=" background: green!important;">Buy
                            {{coinDetail?.exec}}</button>
                          <div class="bnncl">
                            <div class="clearFilter">
                              <button class="btn exchange_btn ml-2" (click)="clearFilterOfBuy()">Clear
                                Filter</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-6 p-0">
                    <form [formGroup]="sellForm" #f="ngForm">
                      <div class="  market_box">
                        <div class="buy_div">
                          <p>Sell {{coinDetail?.exec}}</p>
                          <p>{{coinDetail?.exec}} Balance:
                            {{manageExponential(execCoinBalance)}}</p>
                        </div>
                        <div class="btc-form  mb-1  text-light"
                          [ngClass]="f.submitted && (sellForm.get('stopLimit').hasError('required')) ? 'errClass' : ''">
                          <label class=" col-form-label">Stop Limit:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('ssl');" type="text" class="form-control "
                              formControlName="stopLimit" id="inputPassword" placeholder="{{coinDetail.base}}"
                              (keyup)="getPriceFunc($event.target.value,'STOPLIMITSELL')">
                          </div>
                        </div>

                        <div class="btc-form mb-1 text-light"
                          [ngClass]="f.submitted && (sellForm.get('price').hasError('required')) ? 'errClass' : ''">
                          <label class=" col-form-label">Limit:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('spm');" type="text" class="form-control " formControlName="price"
                              id="inputPassword" placeholder="{{coinDetail?.base}}"
                              (keyup)="getPriceFunc($event.target.value,'STOPLIMITSELL');">
                          </div>
                        </div>

                        <div class="btc-form mb-1 text-light"
                          [ngClass]="f.submitted && (sellForm.get('quantity').hasError('required')) ? 'errClass' : ''">
                          <label class=" col-form-label">Quantity:</label>
                          <div class="btc-div-text">
                            <input (keypress)="restrictSpace($event);restrictChar($event)"
                              (keyup)="restrictLength('ssq');" type="text" class="form-control "
                              formControlName="quantity" id="inputPassword" placeholder="{{coinDetail?.exec}}"
                              (keyup)="getAmountFunc($event.target.value,'STOPLIMITSELL')">
                          </div>
                        </div>

                        <div class="btc-form mb-1">
                          <label class=" col-form-label"></label>
                          <div class="btc-div-text">
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : sellPercentage == 25}"
                              (click)="selectSellPerFunc('percentage',25,'sell','STOPLIMITSELL')">25%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : sellPercentage == 50}"
                              (click)="selectSellPerFunc('percentage',50,'sell','STOPLIMITSELL')">50%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : sellPercentage == 75}"
                              (click)="selectSellPerFunc('percentage',75,'sell','STOPLIMITSELL')">75%</button>
                            <button class="btn-bk ad-button" [ngClass]="{'selectPercentage' : sellPercentage == 100}"
                              (click)="selectSellPerFunc('percentage',100,'sell','STOPLIMITSELL')">100%</button>
                          </div>
                        </div>
                        <div class="btc-form mb-3 text-light">
                          <label class=" col-form-label">Total:</label>
                          <div class="btc-div-text">
                            <input type="text" class="form-control " formControlName="total"
                              placeholder="{{coinDetail.base}}" readonly>
                          </div>
                        </div>
                        <div *ngIf="!loginStatus" class="buy-btn-div">
                          <button class="btn exchange_btn" [routerLink]="['/login']">Log In or Register Now
                          </button>
                        </div>
                        <div *ngIf="loginStatus" class="buy-btn-div">
                          <!-- BUY -->
                          <button class="btn exchange_btn" (click)="placeOrderFunc('STOPLIMITSELL')"
                            style=" background: red!important;">Sell
                            {{coinDetail?.exec}}</button>
                          <div class="bnncl">
                            <div class="clearFilter">
                              <button class="btn exchange_btn ml-2" (click)="clearFilterOfSell()">Clear
                                Filter</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
              <!----STOPLIMIT-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</body>

<!-- Delete Open Order Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title">Delete Order</h5>
        <button type="button" class="close " data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Do you want to delete this placed order?</p>
        <div class="btn-modal">
          <button class="btn theam-btn mr-2" (click)="deleteOpenOrder()">Delete</button>
          <button class="btn theam-btn2 ml-2" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="validation-modal" class="modal">
  <!-- Modal content -->
  <div class="modal-content">
    <header class="modal-heaader">
      <h5>Place Order</h5>
      <button type="button" class="cloose" data-dismiss="modal">&times;</button>
    </header>
    <div>
      <div class="row">
        <p>{{errMsg}}</p>
      </div>
      <div class="chnges-btn">
        <div class="cancel">
          <button type="submit" data-dismiss="modal" style="outline: 0">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>