import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-cancel-contact-exchange',
  templateUrl: './cancel-contact-exchange.component.html',
  styleUrls: ['./cancel-contact-exchange.component.css']
})
export class CancelContactExchangeComponent implements OnInit {
  updateFeedbackForm: FormGroup;
  toUserId: any;
  tradeId: any;
  feedbackId: any;
  
  constructor(public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.feedbackFormValidation()
    this.getPerticularFeedbackDetail()
  }

  feedbackFormValidation() {
    this.updateFeedbackForm = new FormGroup({
      'feedbackMessage': new FormControl('', Validators.required),
      'feedbackStatus': new FormControl('', Validators.required)
    })
  }

  // submit user feedback  
  updateUserFeedback() {
    let data = {
      "feedbackMessage": this.updateFeedbackForm.value.feedbackMessage,
      "feedbackStatus": this.updateFeedbackForm.value.feedbackStatus,
      "tradeId": this.tradeId,
      "userId": this.toUserId
      // "userId": 22
    }
    console.log(data)
    this.service.postApi(`account/update-user-feedback?feedbackId=${this.feedbackId}`, data).subscribe((res) => {
      console.log(res);
      if (res['status'] == 200) {
        this.service.showSuccessMessage(res['message'])
        this.updateFeedbackForm.reset();
      }
    })
  }

  getPerticularFeedbackDetail(){
    this.service.getCandyPixelForms(`account/get-perticular-feedback-detail?feedbackId=${this.feedbackId}`).subscribe((res)=>{
      console.log(res)
      if(res['status']==200){
        this.updateFeedbackForm.patchValue({
          'feedbackStatus': res['data'].feedbackStatus,
          'feedbackMessage': res['data'].feedbackMessage
        })
      }
    })
  }
}
