import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.css']
})
export class ReferralComponent implements OnInit {
  UserDetailsForm: any;
  UserDetailsForm1: any;
  profileData: any = {};
  arr: any = [];
  countryList: any = [];
  stateList: any = [];
  countryCode: any;
  states: any;
  KycForm: FormGroup;
  fileName1: any = 'Upload Front Page Of Your Passport or National Id'
  fileName2: any = 'Upload Back Page Of Your Passport or National Id'
  docArr: any = []
  kycStatus: boolean = false;
  resultArr: any = [];
  imageUrl: any = 'assets/images/image_placeholder.jpeg';
  imageUrl1: any = 'assets/images/image_placeholder.jpeg';
  refrredFriendCount: any;
  totalCommissionCount: any;
  formData: {};
  minAge: Date;
  constructor(public router: Router, public service: ServiceService,  private clipboardApi: ClipboardService) {
    this.arr = this.service.countryListJson;
    this.arr.forEach(obj1 => {
      this.countryList.push(obj1.country)
    });
  }
  referralLink:any;
  copyContent: any;
  dateForm = new FormGroup({
    "fromDate": new FormControl(''),
    "toDate":new FormControl('')
   })
  ngOnInit() {
    this.totalCommissionEarned()
    window.scrollTo(0, 0)
    this.referralLink = JSON.parse(localStorage.getItem('profiledata'))
    console.log(this.referralLink);
    this.totalRefrredFriend()
   this.dateValidation()
  }

  
  dateValidation() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
  } 
 
  copyReferralLink() {
    this.copyContent = `${this.service.webUrl}/register?myReferalCode=${this.referralLink.myRefferalCode}`
    this.service.showSuccessMessage('Referral link copied.')
    this.clipboardApi.copyFromContent(this.copyContent)
  }
totalRefrredFriend(){
  let apiReqUrl = `account/totalReffalCount?myReferralCode=${this.referralLink.myRefferalCode}`
  this.service.getCandyPixelForms(apiReqUrl).subscribe(res=>{
    this.refrredFriendCount = res['data']
    console.log(res);
    
  })
}
totalCommissionEarned(){
  this.service.getCandyPixelForms('p2p-exchange/get-total-commission-earned').subscribe(res=>{
this.totalCommissionCount = res['data']

  })
}
  navigateToPage(val) {
    this.router.navigate([val])
  }
  searchByDate(){
    var formData = {
      "fromDate" : this.dateForm.value.fromDate,
      "toDate" : this.dateForm.value.toDate
          }
    console.log("--------------_______--------" + this.dateForm.value.fromDate);
    
  }
  resetSearch(){}
  // profile() {
  //   this.router.navigate(['/my-profile'])
  // }
  // kyc() {
  //   this.router.navigate(['/kyc'])
  // }
  // security() {
  //   this.router.navigate(['/security'])
  // }
  // kyclist() {
  //   this.router.navigate(['/kyclist'])
  // }
  // referral() {
  //   this.router.navigate(['/referral'])
  // }
}
