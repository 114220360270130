<!DOCTYPE html>
  <body class="static-contant pt-0">
    <section>
      <div class="container1440">
        <div class="Aboutus00 mt-5">
            <h4>Terms & Conditions</h4>
              <div class="txt00">
                <p [innerHtml]="termsData">{{termsData}}</p>
              </div>
        </div>
     </div>
   </section>
  </body>
  <app-footer></app-footer>