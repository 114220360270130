<!DOCTYPE html>
    <section class="mainBody">
      <div class="container1440">
      <div class="log-in">
        <div class="bg-crypt">
          </div>
            <div class="crypto-log-login left">
              <form class="login-field-sec" [formGroup]="forgotForm">
                <p class="log" style="padding-bottom: 0px; padding: 70px 0px 10px 0px;">Forgot Password</p>
                <p class="passwordMsg">Enter the registered email address to reset the password. 
                </p>
               
                <div class="form-group inpt-frm" style="margin-top: 10px;">
                  <label for="exampleInputEmail1" class="add">Email Address :</label>
                  <input type="email" maxlength="256" formControlName="email" class="form-control email-pass" placeholder="Email address">
                  <span >
                    <small *ngIf="forgotForm.get('email').hasError('required') &&  forgotForm.get('email').dirty" class="danger"
                     padding>*Please enter registered email address.</small>
                     <small *ngIf="forgotForm.get('email').hasError('pattern') &&  forgotForm.get('email').dirty" class="danger"
                     padding>*Invalid email address.</small>
                 </span>
                </div>
               <button style="margin-top: 40px;
               margin-bottom: 35px;" type="submit" class="btn register-btn" [disabled]="forgotForm.invalid" (click)="forgotFunc()" data-toggle="modal"  >SUBMIT</button>
              </form>
            </div>
        </div>         
         </div>
         <div class="modal fade" id="puzzle" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content fit-pzz">
              <div class="modal-header puzzl">
                <img src="assets/images/crypto-8.png" alt="">
              </div>
              <div class="modal-body crypto-00">
                <img src="assets/images/crypto-9.png" alt="">
              </div>
              <div class="modal-footer puzzl">
                <img src="assets/images/crypto-11.png" alt="">
                <img src="assets/images/crypto-10.png" alt="">
              </div>
            </div>
          </div>
        </div>
    </section>
    <app-footer></app-footer>