import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { AppComponent } from 'src/app/app.component';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  notificationData: any = []
  foo

  constructor(private router: Router, public service: ServiceService, public appC: AppComponent) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.service.notification.subscribe((res) => {
      console.log(res)
      // this.getNotification()
    })
    this.readNotification()
  }

  //   let objToSend: NavigationExtras = {
  //     queryParams: {
  //     id: 1,
  //     productName: 'Netgear Cable Modem',
  //     productCode: 'CM700',
  //     description: 'Netgear Cable Modem compatible with all cables',
  //     prodRating: 4.9
  //     },
  //     skipLocationChange: false,
  //     fragment: 'top' 
  // };


  // this.router.navigate(['/productdetails'], { 
  // state: { productdetails: objToSend }
  // });
  // }

  navigateTo(order) {
    console.log("order", order)
    // let arr = order.message.split(' ')
    // console.log("arr",arr)
    // let tradeType = arr[0] == 'buy' ? 'sell' : 'buy';
    // console.log("tradeType",tradeType)
    // let my_data = `${order.fromUserId}-${order.tradeId}-${order.userName}-${tradeType}-${order.minutes}-${order.peerToPeerExchangeId}`
    // console.log("my_data",my_data)
    // this.appC.chatArr = []
    // this.router.navigate(['contactExchange', my_data])
    let arr = order.data
    console.log(arr)

    this.appC.chatArr = []

    // let navigationExtras: NavigationExtras = { state: { exchangeDetails: objToSend } };

    if ((order.message.includes('You have a Trade Request'))) {

      let exchangeDetails = arr
      let navigationExtras: NavigationExtras = { state: { exchangeDetails: exchangeDetails } };
      this.router.navigate(['contactExchange'], navigationExtras)

    }
  }

  // read notification make isSeen equal true
  readNotification() {
    this.service.getCandyPixelForms('notification/read-notification').subscribe((res) => {
      console.log(res)
      this.getNotification()
    })
  }

  // /get all notification data
  getNotification() {
    this.service.getCandyPixelForms('notification/get-notification-data').subscribe((res) => {
      console.log(res)
      if (res['status'] == 1618) {
        console.log(res['data'])
        this.service.notificationArr = res['data']
      }
    })
  }

}
