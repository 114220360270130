<div class="static-contant">
    <section>
        <div class="container1440">
            <div class="Aboutus00 mt-5">
                <!-- <div class="create-adv"> -->
                <div class="contactBying">
                    <!-- <h4 style="padding-bottom: 0px;">Contact #29763260 :</h4> -->
                    <h4 style="padding-bottom: 0px;">Contact #{{tradeId}} :</h4>
                    <!-- <span class="bitcoinBuy">Buying 0.01000162 bitcoins for 5,000.00 INR with</span> -->
                    <span class="bitcoinBuy">{{exchangeDetails?.orderType}} {{exchangeDetails?.noOfCoins}} bitcoins for
                        {{exchangeDetails?.price}} {{exchangeDetails?.fiatCoin}}</span>
                </div>
                <!-- <p class="adver">Buying from advertisement #283177 (National Bank Transfer) by Gonee it at the exchange
                    rate 499,919.01 INR/BTC</p> -->
                <p class="adver">{{exchangeDetails?.orderType}} from advertisement #{{peerToPeerExchangeId}} at the exchange rate
                    {{exchangeDetails?.price}} {{exchangeDetails?.fiatCoin}}/{{exchangeDetails?.cryptoCoin}}
                </p>

                <div class="statusTrade">
                    <span class="tradeStatus" style="color: #03CA9B;">Trade Status:</span>
                    <!-- <span class="wating">Waiting for buyer to confirm payment.</span> -->
                    <span *ngIf="!cancelPurchaseSectionStatus" class="wating">Waiting for buyer to confirm
                        payment.</span>
                    <span *ngIf="cancelPurchaseSectionStatus" class="wating">Cancelled by buyer</span>
                </div>
                <div class="wrapper center-block">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="tym-count">
                                        <p id="demo"></p>
                                    </div>
                                    <!-- <span class="tradeStatus" style="color: #03CA9B;">Send a message to Himanshu:</span> -->
                                    <span class="tradeStatus" style="color: #03CA9B;">Send a message to
                                        {{tradePartner}}:</span>

                                    <!-- <div class="typMess">
                                        <div class="statusTradeInputChat" style="width: 100%; max-width: 500px;">
                                            <input type="text" class="form-control chatInput" (keyup.enter)="sendChat()"
                                                placeholder="Enter your message here" id="usr" [(ngModel)]="obj.chat"
                                                autocomplete="off">
                                            <div class="chat-attachment-img-box" style="cursor: pointer;">
                                                <input type="file" class="statusTradeUploadChat"
                                                    (change)="handleFileInput1($event)">
                                                <img src="../../../assets/images/attached.png" alt=""
                                                    class="chat-attachment-img">
                                            </div>
                                        </div>
                                        <div class="sendMessage"
                                            style="opacity: 0.9;margin-left: -3px; cursor: pointer;"
                                            (click)="sendChat()">Send</div>
                                    </div> -->
                                    <!-- <div class="statusTrade" style="width: 100%; max-width: 500px;">
                                        <div>
                                            <div style="padding: 10px 12px 0 17px;">
                                                <div class="userDate">
                                                    <p style="color: #b4b7d9;">Avinish</p>
                                                    <p style="color: #b4b7d9;">02/23/2020, 00:00:18</p>
                                                </div>
                                                <p style="color: #b4b7d9;">How to pay advansh</p>
                                            </div>
                                        </div>
                                    </div> -->


                                    <div class="statusTrade" >
                                        <div class="chat-container" #scrollMe style="overflow-y: auto; height: 300px;">
                                            <div class="chat-box" *ngFor="let data of server.chatArr">
                                                <!-- sender -->
                                                <div class="main-right-chat" *ngIf="myId != data.toUserId" >
                                                    <div class="right-chat-box">
                                                        <div class="userDate">
                                                            <p class="user_name">{{profileData?.firstName}}</p>
                                                           
                                                        </div>
                                                        <p class="msg" *ngIf="data.messageFormat == 'TEXT' && data.message"
                                                            >{{data?.message}}</p>
                                                        <img class="img_def" target="_blank" [src]="data.message"
                                                            *ngIf="data.messageFormat == 'IMAGE'" alt="">
                                                    </div>
                                                    <span>{{currentDate | date}}</span>
                                                </div>
                                                <!-- receiver -->
                                                <div class="main-left-chat" *ngIf="myId == data.toUserId" >
                                                    <div >
                                                        <div class="left-chat-box">
                                                        <div class="userDate">
                                                            <p class="user_name">{{tradePartner}}</p>
                                                        </div>
                                                 
                                                        <p class="msg" *ngIf="data.messageFormat == 'TEXT' && data.message"
                                                            >{{data?.message}}</p>
                                                        <img class="img_def" *ngIf="data.messageFormat == 'IMAGE'"
                                                            target="_blank" [src]="data.message" alt="">

                                                    </div>
                                                    <span >{{data?.creationTime | date}}</span>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="typMess">
                                        <div class="statusTradeInputChat" >
                                            <input type="text" class="form-control chatInput" (keyup.enter)="sendChat()"
                                                placeholder="Enter your message here" id="usr" [(ngModel)]="obj.chat"
                                                autocomplete="off">
                                            <div class="chat-attachment-img-box" style="cursor: pointer;">
                                                <input type="file" class="statusTradeUploadChat"
                                                    (change)="handleFileInput1($event)">
                                                <img src="../../../assets/images/attached1.png" alt=""
                                                    class="chat-attachment-img">
                                            </div>
                                        </div>
                                        <div class="sendMessage "
                                            style="opacity: 0.9;margin-left: -3px; cursor: pointer;"
                                            (click)="sendChat()">Send</div>
                                    </div>




                                    <!-- feedback section -->
                                    <div *ngIf="!cancelPurchaseSectionStatus">
                                        <form [formGroup]="feedbackForm">
                                            <div>
                                                <span class="tradeStatus" style="color: #03CA9B;">Please leave a
                                                    feedback
                                                    for
                                                    {{tradePartner}}</span>
                                                <label class="containerRadio positive">Positive<p
                                                        style="color: #b4b7d9;">
                                                        Positive feedback will improve your partner rating</p>
                                                    <input type="radio" checked="checked" name="feedbackStatus"
                                                        value="POSITIVE" formControlName="feedbackStatus">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="containerRadio negative">Negative<p
                                                        style="color: #b4b7d9;">
                                                        Negative feedback will decrease your partner rating</p>
                                                    <input type="radio" checked="checked" name="feedbackStatus"
                                                        value="NEGATIVE" formControlName="feedbackStatus">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="containerRadio nutral">Neutral<p style="color: #b4b7d9;">
                                                        Neutral
                                                        feedback will not affect your partner rating</p>
                                                    <input type="radio" checked="checked" name="feedbackStatus"
                                                        value="NUTRAL" formControlName="feedbackStatus">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <!-- <div class="statusTrade" style="width: 100%; max-width: 500px;">
                                        <div>
                                            <div style="padding: 10px 12px 0 17px;">
                                                <div class="userDate">
                                                    <p style="color: #b4b7d9;">Enter your feedback here...
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                            <div class="statusTradeInput" style="width: 100%; max-width: 500px;">
                                                <input class="form-control" placeholder="Enter your feedback here..."
                                                    formControlName="feedbackMessage">
                                            </div>
                                            <div style="text-align: center; margin-bottom:15px;">
                                                <button class="btn save-btn " [disabled]="!feedbackForm.valid"
                                                    (click)="submitUserFeedback()">Send</button>
                                            </div>
                                        </form>
                                    </div>
                                    <!-- end feedback section -->
                                </div>
                                <div class="col-lg-1 col-md-12 col-sm-12"></div>
                                <div *ngIf="!cancelPurchaseSectionStatus" class="col-lg-5 col-md-12 col-sm-12">
                                    <div class="cover-rightmy">
                                        <div class="trade" >
                                            Payment Confirmation
                                        </div>
                                        <div>
                                            <p class="prieAmount" >Confirmation of payment. </p>
                                        </div>
                                        <!-- direct route -->
                                        <div *ngIf="exchangeDetails?.route == 'p2p-buy'" style="margin-bottom:15px;">
                                            <button *ngIf="tradeType == 'BUY' && !showDispute" class="btn save-btn mt-3"
                                                (click)="sendMessageOnPay()">I have paid</button>
                                            <button *ngIf="tradeType == 'SELL' && !showDispute" class="btn save-btn mt-3"
                                                (click)="releaseCoin()">Release Bitcoin</button>
                                            <button *ngIf="showDispute" class="btn save-btn mt-3"
                                                (click)="butnDisputeReason()"> Select Dispute Reason</button>
                                        </div>
                                        <!-- notification route -->
                                        <div *ngIf="exchangeDetails?.route != 'p2p-buy'" style="margin-bottom:15px;">
                                            <button *ngIf="tradeType == 'SELL' && !showDispute" class="btn save-btn mt-3"
                                                (click)="sendMessageOnPay()">I have paid</button>
                                            <button *ngIf="tradeType == 'BUY'" class="btn save-btn mt-3"
                                                (click)="releaseCoin()">Release Bitcoin</button>
                                            <button *ngIf="tradeType == 'SELL' && showDispute" class="btn save-btn mt-3"
                                                (click)="butnDisputeReason()">Select Dispute Reason</button>
                                        </div>
                                        <div *ngIf="showlist">
                                            <select class="search-select-box searcj"
                                                (change)="disputeReason=$event.target.value"
                                                [(ngModel)]="disputeReason">
                                                <option value="Raised">Raised</option>
                                                <option value="WIP">WIP</option>
                                                <option value="Pending_for_Release">Pending for Release</option>
                                            </select>
                                            <button class="btn save-btn mt-3" (click)="dispute()">Submit Dispute</button>
                                        </div>


                                    </div>
                                    <div class="cover-rightmy mt-3">
                                        <div class="trade" >
                                            Have trading issue ?
                                        </div>
                                        <div>
                                            <p class="prieAmount" >Have a problem or just need to ask us something?<br>
                                                Our Customer support team are available to help 24/7. </p>
                                        </div>
                                        <div style="margin-bottom:15px;">
                                            <button class="btn save-btn mt-3" (click)="cancelPurchase()">Cancel the
                                                purchase</button>
                                        </div>
                                    </div>

                                    <form [formGroup]="cancelPurchaseReasonForm">
                                        <div *ngIf="cancelReason" class="cover-rightmy">
                                            <div class="trade" >
                                                Please enter reason for cancellation
                                            </div>
                                            <input type="text" name="reason" class="form-control chatInput " value="reason1" formControlName="reason">
                                            <div style="margin-bottom:15px;">
                                                <button class="btn save-btn mt-3" (click)="cancelOrder()">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <!-- cancel purchase order section -->
                                <div *ngIf="cancelPurchaseSectionStatus" class="col-lg-5 col-md-12 col-sm-12">
                                    <form [formGroup]="updateFeedbackForm">
                                        <div>
                                            <span class="tradeStatus" style="color: #03CA9B;">
                                                Please leave a feedback
                                                for
                                            </span>
                                            <label class="containerRadio positive">Positive<p style="color: #b4b7d9;">
                                                    Positive feedback will improve your partner rating</p>
                                                <input type="radio" checked="checked" name="feedbackStatus"
                                                    value="POSITIVE" formControlName="feedbackStatus">
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="containerRadio negative">Negative<p style="color: #b4b7d9;">
                                                    Negative feedback will decrease your partner rating</p>
                                                <input type="radio" checked="checked" name="feedbackStatus"
                                                    value="NEGATIVE" formControlName="feedbackStatus">
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="containerRadio nutral">Neutral<p style="color: #b4b7d9;">
                                                    Neutral
                                                    feedback will not affect your partner rating</p>
                                                <input type="radio" checked="checked" name="feedbackStatus"
                                                    value="NUTRAL" formControlName="feedbackStatus">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <!-- <div class="statusTrade" style="width: 100%; max-width: 500px;">
                                        <div>
                                            <div style="padding: 10px 12px 0 17px;">
                                                <div class="userDate">
                                                    <p style="color: #b4b7d9;">Enter your feedback here...
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                        <div class="statusTradeInput" style="width: 100%; max-width: 500px;">
                                            <input class="form-control" placeholder="Enter your feedback here..."
                                                formControlName="feedbackMessage">
                                        </div>
                                        <div style="text-align: center; margin-bottom:15px;">
                                            <button class="btn save-btn" (click)="updateUserFeedback()">Update
                                                Feedback</button>
                                        </div>

                                    </form>
                                </div>
                                <!-- end cancel purchase order section  -->

                            </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>
