import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profileData: any;
  p2pUserId: number;
  // advertisement
  sellOrderArr: any = [];
  sellTotal: any
  buyOrderArr: any = [];
  buyTotal: any
  pageNumberSell: any = 1;
  pageNumberBuy: any = 1;
  pageSize: any = 5;

  // feedback
  feedbackData: any = []
  feedbackTotal: any;
  constructor(public activatedRoute: ActivatedRoute, private router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((res) => {
      if (res.id) {
        this.p2pUserId = res.id
      }
    })
    this.getTraderProfile();
  }

  // get profile of trader
  getTraderProfile() {
    this.service.showSpinner();
    this.service.getCandyPixelForms(`account/get-p2p-user-profile?p2pUserId=${this.p2pUserId}`).subscribe(res => {
      if (res['status'] == 200) {
        this.profileData = res['data'];
        this.getUserFeedback()
        this.buyOrderList()
        this.sellOrderList()
      }
      else {
        this.service.hideSpinner();
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      this.service.showErrorMessage(err['message']);
    })
  }

  /**
   * Advertisement of trader
   */

  buyOrderList() {
    this.sellOrderArr = []
    this.service.getCandyPixelForms(`p2p-exchange/search-and-filters-advertisement?orderType=BUY&page=${this.pageNumberBuy - 1}&pageSize=${this.pageSize}&userId2=${this.p2pUserId}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.sellOrderArr = res['data'].list;
        this.sellTotal = res['data'].totalCount
      }
    })
  }

  sellOrderList() {
    this.buyOrderArr = []
    this.service.getCandyPixelForms(`p2p-exchange/search-and-filters-advertisement?orderType=SELL&page=${this.pageNumberSell - 1}&pageSize=${this.pageSize}&userId2=${this.p2pUserId}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.buyOrderArr = res['data'].list
        this.buyTotal = res['data'].totalCount
      }
    })
  }

  // pagination
  buyPaginationEvent(page) {
    console.log(page)
    this.pageNumberSell = page;
    this.sellOrderList()
  }

  sellPaginationEvent(page) {
    console.log(page)
    this.pageNumberBuy = page;
    this.buyOrderList()
  }

  // to navigate
  navigateTo(path, data, type) {
    let val = data.userId + '-' + data.peerToPeerExchangeId + '-' + type
    this.router.navigate([path, val])
  }


  /**
   * Feedback of trader
   */
  // get user feedback
  getUserFeedback() {
    this.service.showSpinner()
    this.service.getCandyPixelForms(`account/get-user-feedback?&feedbackUserId=${this.profileData.userId}&page=0&pageSize=2`).subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.feedbackData = res['data'].list
        this.feedbackTotal = res['data'].size
      }
    })
  }

  seeFeedback() {
    this.router.navigate(['/feedback', this.profileData.userId, this.profileData.userName])
  }

  // block user
  blockUser() {
    this.service.postApi(`account/block-p2p-user?blockUserId=${this.p2pUserId}`, {}).subscribe((res: any) => {
      console.log(res)
      if (res['status'] == 200) {
        this.service.showSuccessMessage(res['message'])
        this.router.navigate(['/p2p-exchange'])
      }
    })
  }

}

