<!-- before login -->
<header *ngIf="!isLoggedIn">
    <nav class="navbar navbar-expand-lg navbar-light" aria-label="">
        <a class="navbar-brand" [routerLink]="['']">
            <!-- <img src="assets/images/logo.svg" alt="" > -->
            <img src="assets/images/logo1.svg" alt="">
        </a>

        <span class="oprn-togel" style="font-size:30px;cursor:pointer" onclick="openNav()">&#9776; </span>
        <div class="menu-top-1">
            <div class="sidenav" id="mySidenav">
                <div class="menu-top">
                    <ul class="navbar-nav mr-auto mt-2 mt-lg-0 ">
                        <li class="nav-item ">
                            <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                        </li>
                        <li class="nav-item active">
                            <a class="nav-link" [routerLink]="['/p2p-quick-buy-sell']" style="cursor: pointer">P2P
                                EXCHANGE</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/advanced-exchange']"
                                style="cursor: pointer">EXCHANGE</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/market-info']" style="cursor: pointer">MARKET INFO</a>
                        </li>
                    </ul>
                    <form class="form-inline my-2 my-lg-0 ryt-form">

                        <button style="border: 1px solid #01A781;" class="btn headerbtn my-2 my-sm-0" type="button"
                            [routerLink]="['/login']">Login</button>
                        <button style="border: 1px solid #01A781;" class="btn headerbtn my-2 my-sm-0" type="button"
                            [routerLink]="['/register']">Register</button>
                    </form>
                </div>
            </div>
        </div>
    </nav>
</header>

<!-- after login -->
<header *ngIf="isLoggedIn">
    <nav class="navbar navbar-expand-lg navbar-light" aria-label="" style="white-space: nowrap;">
        <a class="navbar-brand" [routerLink]="['']"><img src="assets/images/logo1.svg" alt="" ></a>

        <span class="oprn-togel" style="font-size:30px;cursor:pointer" onclick="openNav()">&#9776; </span>
        <div class="menu-top-1">
            <div class="sidenav" id="mySidenav">

                <div class="menu-top">
                    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li class="nav-item ">
                            <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                        </li>
                        <li class="nav-item active">
                            <a class="nav-link" [routerLink]="['/p2p-quick-buy-sell']" style="cursor: pointer">P2P
                                EXCHANGE</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/dashboard-trades']" style="cursor: pointer">P2P
                                DASHBOARD</a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/advanced-exchange']"
                                style="cursor: pointer">EXCHANGE</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/market-info']" style="cursor: pointer">MARKET INFO</a>
                        </li>
                       
                        <!-- <li class="nav-item">
                            <a class="nav-link" style="cursor: pointer" [routerLink]="['/transfer']">TRANSFER</a>
                        </li> -->
                    </ul>
                    <form class="form-inline my-2 my-lg-0 ryt-form">
                        <a class="nav-link" class="my-wallet-a" [routerLink]="['/my-wallet']" style="cursor: pointer">My
                            Wallet</a>
                        <span class="notification-box" style="cursor: pointer;">
                            <img src="assets/images/message.png" alt="" [routerLink]="['/admin-message-list']"
                                >
                            <span class="badge badge-light">{{totalMessage}}</span>
                        </span>
                        <span class="notification-box">
                            <img src="assets/images/Layer_20.png" class="notif-img" alt="" style="width: 20px;" (click)="notifications()">
                            <span class="badge badge-light">{{totalNotification}}</span>
                        </span>
                        <div class="dropdown">
                            <button class="btn  user-profile-btn profileButton" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img [src]="userImage || 'assets/images/user_un.png'" alt="">
                            </button>
                            <div class="dropdown-menu user-profile-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" value="profile" [routerLink]="['/my-profile']">My Profile</a>
                                <a class="dropdown-item" value="login" [routerLink]="['/login-history']">Login
                                    History</a>
                                <!-- <a class="dropdown-item" value="internal"
                                    [routerLink]="['/internal-transfer-history']">Internal Transfer History</a>
                                <a class="dropdown-item" value="esrow"
                                    [routerLink]="['/escrow-transfer-history']">Escrow Transfer History</a> -->
                                <a class="dropdown-item" value="logout" (click)="logout()">Logout</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </nav>
</header>


<!-- logout modal -->
<div class="modal fade" id="logout" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content after-login-modal">
            <div class="modal-header">
                <h5 class="modal-title">Logout</h5>
                <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body logout_text">
              Are you sure you want to Logout?
                <div class="btn-modal">
                    <button class="btn theam-btn mr-2" (click)="logoutFunc()">Yes</button>
                    <button class="btn theam-btn2 ml-2" data-dismiss="modal">No</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- logout modal end -->

<ngx-spinner size="small" color="#01a781" type="line-scale"></ngx-spinner>
<router-outlet></router-outlet>


