<!DOCTYPE html>
  <body class="static-contant">
    <section class="wraper">
      <div class="container1440">
      <div class=" pt-5">
        
          <ul>
            <li style="color: #a7a8b2;">Security > Account Activity</li>
            <li style="color: #de8011; margin-top: 28px;">Recent account activity record</li>
        
        </ul>
        <ul class="nav nav-pills tab-ul" id="pills-tab" role="tablist">
            <li class="nav-item tab-li">
              <a class="nav-link tab-a active" id="pills-buy-tab" data-toggle="pill" href="#pills-buy" role="tab" aria-controls="pills-buy" aria-selected="true">Login Activity</a>
            </li>
            <li class="nav-item tab-li">
              <a class="nav-link tab-a" id="pills-sell-tab" data-toggle="pill" href="#pills-sell" role="tab" aria-controls="pills-sell" aria-selected="false">Security Activity</a>
            </li>
          </ul>
        <div class="table-responsive mt-4 pb-5">
            <table class="table after-logintabel action" aria-describedby="">
                <thead>
                  <tr>
                    <th id="">Date</th>
                    <th id="">Source</th>
                    <th id="">Status</th>
                    <th id="">IP Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2019-11-08   12:34:35</td>
                    <td>Web</td>
                    <td>Completed</td>
                    <td>182.71.74.309</td>
                  </tr>
                  <tr>
                      <td>2019-11-08   12:34:35</td>
                    <td>Web</td>
                    <td>Completed</td>
                    <td>182.71.74.309</td>
                    </tr>
                    <tr>
                        <td>2019-11-08   12:34:35</td>
                    <td>Web</td>
                    <td>Completed</td>
                    <td>182.71.74.309</td>
                      </tr>
                      <tr>
                          <td>2019-11-08   12:34:35</td>
                    <td>Web</td>
                    <td>Completed</td>
                    <td>182.71.74.309</td>
                <tr>
                    <td>2019-11-08   12:34:35</td>
                    <td>Web</td>
                    <td>Completed</td>
                    <td>182.71.74.309</td>
                  </tr>
                  <tr>
                      <td>2019-11-08   12:34:35</td>
                    <td>Web</td>
                    <td>Completed</td>
                    <td>182.71.74.309</td>
                    </tr>
                    <tr>
                        <td>2019-11-08   12:34:35</td>
                        <td>Web</td>
                        <td>Completed</td>
                        <td>182.71.74.309</td>
                      <tr>
                          <td>2019-11-08   12:34:35</td>
                          <td>Web</td>
                          <td>Completed</td>
                          <td>182.71.74.309</td>
                        </tr>
                </tbody>
              </table>
        </div>
      </div>
      </div>


    </section>
    <app-footer></app-footer>
<!-- logout modal -->
<div class="modal fade" id="logout" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content after-login-modal">
        <div class="modal-header">
          <h5 class="modal-title" >Logout</h5>
          <button type="button" class="close " data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
         <p>Are you sure you want to Logout?</p>
         <div class="btn-modal">
            <button class="btn theam-btn mr-2">Yes</button>
            <button class="btn theam-btn2 ml-2">No</button>
         </div>
        </div>
      </div>
    </div>
</div>
<!-- logout modal end -->
<!-- deposit modal -->
<div class="modal fade" id="deposit" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content after-login-modal">
        <div class="modal-header">
          <h5 class="modal-title" >DEPOSIT</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label >Coin Amount</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control after-input" placeholder="Coin Amount" aria-label="Recipient's username" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">Max</span>
                    </div>
                  </div>
              </div>
              <div class="form-group">
                <label >BTC address you want to send.</label>
                <input type="email" class="form-control after-input" placeholder="BTC Address">
                
              </div>
              <label >Bit Trade Fee : <strong>$1.000</strong></label><br>
                <label >Final Amount: <strong> $250.00</strong></label>
         <div class="btn-modal">
            <button class="btn theam-btn mr-2">Submit</button>
         </div>
        </div>
      </div>
    </div>
</div>
<!-- deposit modal end -->
<!-- wallet-address modal -->
<div class="modal fade" id="wallet-address" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content after-login-modal">
        <div class="modal-header">
          <h5 class="modal-title" >WALLET ADDRESS</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
              <div class="form-group search-div mt-3">
                <input type="address" class="form-control after-input" placeholder="sjhlkskjosafskmcaf1254da1s52">
                <i class="fa fa-files-o copy" aria-hidden="true"></i>
                
              </div>
              <div class="qr-div mt-4 mb-3">
                  <img src="assets/images/qe-code.png" alt="">
              </div>
        </div>
      </div>
    </div>
</div>
<!-- wallet-address modal end -->

  </body>