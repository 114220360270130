<!DOCTYPE html>

<body class="static-contant">

  <section>
    <div class="container1440">
      <div class="security">
        <h6 class="google">Security > Google Authentication</h6>
        <div class="e-bled">
          <h1>Enabled Google Authenticator</h1>
        </div>
        <div class="download-app">
          <ul>
            <li>Download App
              <p class="active">1</p>
            </li>
            <li>
              Scan QR Code
              <p>2</p>
            </li>
            <li>
              Backup Key
              <p>3</p>
            </li>
            <li>
              Enabled Google Authenticator
              <p>4</p>
            </li>
          </ul>
        </div>

        <div class="step">
          <h3>Step 1</h3>
          <p>Download & install the Google Authenticator app</p>
        </div>
        <div class="app_store">
          <p><img src="assets/images/app-store.png" alt=""></p>
          <p><img src="assets/images/app-store2.jpg" alt=""></p>
        </div>
        <div class="send_button">
          <button (click)="next()">NEXT</button>
        </div>
      </div>
    </div>
  </section>
</body>