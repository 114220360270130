import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { AppComponent } from 'src/app/app.component';
declare var $: any;

@Component({
  selector: 'app-admin-chat',
  templateUrl: './admin-chat.component.html',
  styleUrls: ['./admin-chat.component.css']
})
export class AdminChatComponent implements OnInit {
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;
  showDispute: boolean = false;
  tradeId: any;
  toUserId: any;
  cancelReason: boolean = false;
  feedbackForm: FormGroup;
  myId: any;
  // userName: string;
  tradeType: any;
  minutes: number;
  peerToPeerExchangeId: any;

  obj: any = {};
  myImage: any;
  currentTime: any
  x: any;

  exchangeDetails: any
  tradePartner: any;
  profileData: any;

  cancelPurchaseReasonForm: FormGroup;
  cancelPurchaseSectionStatus: boolean = false;
  updateFeedbackForm: FormGroup;
  feedbackId: any

  currentDate: Date;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public server: ServiceService, public appC: AppComponent) {
    const token = localStorage.getItem('credential')
    if (token != null) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.tradeId = params.tradeId
        this.toUserId = params.toUserId
      });
    }
    else {
      this.router.navigate(['/login'])
    }
    //this.routes.navigateByUrl(path)
  }

  // this.server.chatArr = []
  // this.getPreviousChat()
  ngOnInit() {
    this.server.initSocketChat(); //chat
    window.scrollTo(0, 0)
    this.myId = localStorage.getItem('userId')
    console.log(this.myId)
    this.profileData = JSON.parse(localStorage.getItem('profiledata'))
    console.log(this.profileData)
    console.log(this.exchangeDetails.route)
    this.server.getMessage().subscribe((res) => {
      if (res.text == 'online' && localStorage.getItem('chat-type') == 'p-admin') {
        this.getPreviousChat()
      }
    })
    this.currentDate = new Date()
    // console.log(this.currentDate)
    // this.getPreviousChat()
  }


  getPreviousChat() {
    console.log("get previous chat call")
    this.server.getCandyPixelForms(`notification/get-chat-data-for-admin?tradeId=${this.tradeId}`).subscribe((res) => {
      console.log(res)
      if (res['status'] == 1620) {
        this.server.chatArr = res['data']
        this.scrollToBottom()
      }
    })
  }

  // to scroll down
  scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight
      } catch (err) { }
    }, 100);
  }

  // on destroy
  ngOnDestroy() {
    console.log("destroy call")
    clearInterval(this.x);
    localStorage.removeItem('exchangeDetails')
    this.server.chatArr = []
  }

  // send chat
  sendChat() {
    console.log("send chat clicked")
    if (this.obj.chat) {
      let data = {
        "userId": this.appC.profileData.userId,
        "toUserId": Number(this.toUserId),
        "messageFormat": "TEXT",
        "message": this.obj.chat,
        // "type": "TEXT"
        "tradeId": this.tradeId
      }
      console.log("send chat data->", data)
      this.server.wsChat.send(JSON.stringify(data))
      this.server.chatArr.push(data)
      this.obj.chat = ''
      this.scrollToBottom();
    }
  }


  // to upload image
  handleFileInput1(event) {
    this.server.showSuccessMessage('Please wait! Upload in progress...')
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg' || type === 'application/pdf') {
        let fileData = event.target.files[0];
        this.sendFormData1(fileData)
        var reader = new FileReader()
      } else {
        this.server.showErrorMessage("Select only jpg,jpeg and png file.");
      }
    }
  }

  /** to call form data */
  sendFormData1(fileData) {
    let formdata = new FormData()
    formdata.append('file', fileData);
    this.server.postApi('account/upload-file', formdata).subscribe((succ) => {
      if (succ.status == 200) {
        this.myImage = succ.data
        let data = {
          "userId": this.appC.profileData.userId,
          "toUserId": Number(this.toUserId),
          "messageFormat": "IMAGE",
          "message": this.myImage,
          "type": "IMAGE",
          "tradeId": this.tradeId
        }
        this.server.wsChat.send(JSON.stringify(data))
        this.server.chatArr.push(data)
        this.myImage = ''
      }
    })
  }
}