<div class="static-contant">
    <section class="wraper">
        <div class="container1440">
            <div class="row my-5">
                <div class="col-lg-2 mb-3">
                    <div>
                        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                            aria-orientation="vertical">
                            <a class="nav-link active profiletab" id="v-pills-home-tab" data-toggle="pill"
                                href="javascript:;" role="tab" aria-controls="v-pills-home"
                                aria-selected="true">Profile</a>
                            <a class="nav-link  profiletab" id="v-pills-profile-tab" data-toggle="pill"
                                href="javascript:;" role="tab" aria-controls="v-pills-profile" aria-selected="false"
                                (click)="navigateToPage('security')">Security</a>
                            <a class="nav-link profiletab" id="v-pills-messages-tab" data-toggle="pill"
                                href="javascript:;" role="tab" aria-controls="v-pills-messages" aria-selected="false"
                                (click)="navigateToPage('kyc')">KYC</a>
                            <a class="nav-link profiletab" id="v-pills-settings-tab" data-toggle="pill"
                                href="javascript:;" role="tab" aria-controls="v-pills-settings" aria-selected="false"
                                (click)="navigateToPage('kyclist')">KYC List</a>
                            <a class="nav-link profiletab" id="v-pills-settings-tab" data-toggle="pill"
                                href="javascript:;" role="tab" aria-controls="v-pills-settings" aria-selected="false"
                                (click)="navigateToPage('referral')">Referral</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-10">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-pro" role="tabpanel"
                            aria-labelledby="v-pills-home-tab">
                            <div class="cover-myprofile">

                                <div class="row">
                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="titleProfile">
                                            <h2>Profile</h2>
                                        </div>
                                        <div class="profile-pic">
                                            <img [src]="imageUrl" alt="">
                                            <i class="fa fa-camera customfa" aria-hidden="true">
                                                <input
                                                    style="position: absolute; position: absolute; top: 14px;opacity: -0.9;"
                                                    type="file" (change)="uploadImg($event)" />
                                            </i>
                                        </div>
                                        <div class="cover-leftmy">
                                            <div class="bank mt-3" (click)="addaccount()" style="cursor: pointer">Add
                                                Bank</div>
                                            <div class="bank mt-3" (click)="changepassword()" style="cursor: pointer">
                                                Change Password</div>
                                            <div class="bankVerify" (click)="verifyAccount()" style="cursor: pointer">
                                                Click to verify your account</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 profileForm">
                                        <div class="cover-rightmy">
                                            <form [formGroup]="profileForm">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">First Name</label>
                                                    <input type="text" formControlName="firstName" maxlength="50"
                                                        class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="First name">
                                                    <span class="danger">
                                                        <small
                                                            *ngIf="profileForm.get('firstName').hasError('pattern') &&  profileForm.get('firstName').dirty"
                                                            class="danger" padding>*Invalid first name, only alphabets
                                                            are allowed.</small>
                                                    </span>
                                                </div>

                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Last Name</label>
                                                    <input type="text" formControlName="lastName" maxlength="50"
                                                        class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Last name">
                                                    <span class="danger">
                                                        <p *ngIf="profileForm.get('lastName').hasError('pattern') &&  profileForm.get('lastName').dirty"
                                                            class="danger" padding>*Invalid last name, only alphabets
                                                            are allowed.</p>
                                                    </span>
                                                </div>

                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Email Address</label>
                                                    <input type="email" class="form-control" formControlName="email"
                                                        id="exampleInputEmail1" maxlength="256"
                                                        aria-describedby="emailHelp" placeholder="Tester@email.com"
                                                        readonly>
                                                    <span class="danger" maxlength="256">
                                                        <p *ngIf="profileForm.get('email').hasError('pattern') &&  profileForm.get('email').dirty"
                                                            class="danger" padding>*Invalid email.</p>
                                                    </span>
                                                </div>

                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Mobile Number</label>
                                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" formControlName="mobile"
                                                        maxlength="16" placeholder="+919650252525">
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Unique Id</label>
                                                    <input type="text" class="form-control wallrt-sec"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        formControlName="uniqueId" (click)="copy()">
                                                </div>

                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Address</label>
                                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" formControlName="address"
                                                        placeholder="Address">
                                                </div>

                                                <div class="last-save my-5">
                                                    <button class="btn save-btn" (click)="updateFunc()">Save</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>




<!-- logout modal -->
<div class="modal fade" id="logout" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content after-login-modal">
            <div class="modal-header">
                <h5 class="modal-title">Logout</h5>
                <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to Logout?</p>
                <div class="btn-modal">
                    <button class="btn theam-btn mr-2">Yes</button>
                    <button class="btn theam-btn2 ml-2">No</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- logout modal end -->