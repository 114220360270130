import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-fiat-wallet',
  templateUrl: './fiat-wallet.component.html',
  styleUrls: ['./fiat-wallet.component.css']
})
export class FiatWalletComponent implements OnInit {
  myFundingAccountBalance: boolean = true
  myFundingAccountBalancee: boolean = true;
  coinList: any = [];
  depositePageNumber: number = 1;
  selectedUserCoin: any = {};
  tags: any;
  account: any;
  sendForm: FormGroup;
  coinName: any;
  constructor(public router: Router, public service: ServiceService) { }

  ngOnInit(): void {
    this.getAllCoins()
    this.getFundingAccountBalance()
  }

  // formValidation Functionality
  formValidationFunctionality() {
    this.sendForm = new FormGroup({
      'coinAmount': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'address': new FormControl('', Validators.required)
    })
    // this.receiveForm = new FormGroup({
    //   'walletAdd': new FormControl('',),

    // })
  }

  getFundingAccountBalance() {
    var coinName = 'USDT'
    this.service.getCandyPixelForms('wallet/wallet/get-balance?coinName=' + coinName).subscribe(res => {
      if (res['status'] == 200) {
        this.myFundingAccountBalance = res['data']['walletBalance']
        this.myFundingAccountBalancee = false
      }

    })
  }

  // Get All The Coin Functionality
  getAllCoins() {
    let coinShortName = ["BTC", "ETH", "USDT", "QTE"]
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        var coinList = res['data'].coinList;
        // remove btc, eth, usdt and qte coin from coin list
        coinShortName.forEach(element => {
          let index = coinList.findIndex(x => x.coinShortName == element)
          if (index != 1) {
            coinList.splice(index, 1)
            this.coinList = coinList
          }
        })
        // end

        for (let x = 0; x < coinList.length; x++) {
          const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
          if (m != -1) {
            coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
            coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
            coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;
            this.depositCoin(coinList[x].coinShortName);

          }
        }
        this.coinList = coinList;

        this.selectedUserCoin = this.coinList[0]

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  getDepositePage(pageNumber) {
    this.depositePageNumber = pageNumber - 1;
    this.depositCoin(this.coinList);
  }


  depositCoin(coinShortName) {
    var url = ''
    this.tags = '';
    this.account = ''
    if (coinShortName == 'XRP' || coinShortName == 'XLM' || coinShortName == 'OMG' || coinShortName == 'USDT' || coinShortName == 'ETH') {
      url = 'wallet/wallet-type2/get-deposits?coinName=' + coinShortName + '&page=' + (this.depositePageNumber - 1) + '&pageSize=10';
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
          // this.service.showError('Something Went Wrong');
        }
      })
    }
    /*else if (coinShortName == 'USDT') {
      url = 'coin/get-usdt-network-balance';
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.usdtdata= res['data'].USDT_balance
          this.btcdata= res['data'].BTC_balance
          if(this.usdtdata>=1 && this.btcdata>=0.0001){
          this.getusdtdepositlist(coinShortName);
          }
          else{
            this.service.showError('You should have USDT more than 1 and BTC more than 0.001 to perform deposit')
          }
          // this.account = res['data']['eosAccountName']
          // this.walletAddress = res['data'].walletAddress;
          //$('#recieveModal').modal({ backdrop: 'static' })
        }
        else {
         // this.service.showError(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showError('Session Expired!');
        } else {
          //this.service.showError('Something Went Wrong');
        }
      })
    }*/
    else {
      url = 'wallet/wallet/get-deposits?coinName=' + coinShortName + '&page=' + (this.depositePageNumber - 1) + '&pageSize=10';
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          // this.getallcoins1();
          // this.account = res['data']['eosAccountName']
          // this.walletAddress = res['data'].walletAddress;
          //$('#recieveModal').modal({ backdrop: 'static' })
        }
        else {
          // this.service.showError(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
          //this.service.showError('Something Went Wrong');
        }
      })
    }
  }


  sendCoinFunc() {
    var apiReq = {};
    var base;
    if (this.coinName == 'OMG') {
      apiReq = {
        "amount": this.sendForm.value.coinAmount,
        "coinName": this.coinName,
        "isWithdraw": false,
        "isKycAccepted": true,
        "toAddress": this.sendForm.value.address,
        "url": this.service.webUrl + "/wallet-transaction-status"
      }
      base = 'wallet/wallet-type2/withdraw'
    }
    else if (this.coinName == 'USDT') {
      apiReq = {
        "amount": this.sendForm.value.coinAmount,
        "coinName": this.coinName,
        "isWithdraw": false,
        "isKycAccepted": true,
        "toAddress": this.sendForm.value.address,
        "url": this.service.webUrl + "/wallet-transaction-status"
      }
      base = 'wallet/wallet-type2/withdraw-type3'
    }
    else {
      apiReq = {
        "amount": this.sendForm.value.coinAmount,
        "coinName": this.coinName,
        "isWithdraw": false,
        "isKycAccepted": true,
        "tag": this.tags,
        "toAddress": this.sendForm.value.address,
        "url": this.service.webUrl + "/wallet-transaction-status"
      }
      base = 'wallet/wallet/withdraw'
    }

    this.service.showSpinner();
    this.service.postCandyPixelForms(base, apiReq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        // $('#sendModal').modal('hide');
        this.service.showSuccessMessage(res['message']);
        this.sendForm.reset();
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  // blocked coin address details
  walletAddress: any;
  userStatus: any;
  recieveCoin(coinShortName) {
    if (this.userStatus == 'BLOCK') {
      this.service.showErrorMessage('You have been blocked and suspended by admin, kindly contact our customer support')
      return
    }
    else {
      var url = ''
      this.tags = '';
      this.account = ''
      if (coinShortName == 'XRP' || coinShortName == 'XLM') {
        url = 'wallet/wallet-type2/get-address?coinName=' + coinShortName;
        this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            this.walletAddress = res['data'].walletAddress;
            this.tags = res['data'].tag;
            // $('#recieveModal').modal({ backdrop: 'static' })
          }
        }, err => {
          this.service.hideSpinner();
          if (err['status'] == '401') {
            localStorage.clear();
            this.router.navigate(['/login']);
            this.service.showErrorMessage('Session Expired!');
          } else {
            this.service.showErrorMessage('Kyc not done');
          }
        })
      }
      else if (coinShortName == 'OMG') {
        url = 'wallet/wallet/get-address-for-erc20?coinName=' + coinShortName;
        this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            this.walletAddress = res['data'];
            // $('#recieveModal').modal({ backdrop: 'static' })
          }
          else {
            this.service.showErrorMessage(res['message']);
          }
        }, err => {
          this.service.hideSpinner();
          if (err['status'] == '401') {
            localStorage.clear();
            this.router.navigate(['/login']);
            this.service.showErrorMessage('Session Expired!');
          } else {
            this.service.showErrorMessage('Kyc not done');
          }
        })
      } else {
        url = 'wallet/wallet/get-address?coinName=' + coinShortName;
        this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            this.account = res['data']['eosAccountName']
            this.walletAddress = res['data'].walletAddress;
            // $('#recieveModal').modal({ backdrop: 'static' })
          }
          else {
            this.service.showErrorMessage(res['message']);
          }
        }, err => {
          this.service.hideSpinner();
          if (err['status'] == '401') {
            localStorage.clear();
            this.router.navigate(['/login']);
            this.service.showErrorMessage('Session Expired!');
          } else {
            this.service.showErrorMessage('Kyc not done');
          }
        })
      }
    }
  }

  profileData
  getProfile() {
    if (localStorage.getItem("credential")) {
      this.service.showSpinner();
      this.service.getCandyPixelForms(`account/my-account`).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.profileData = res['data']['twoFaType'];
          this.userStatus = res['data']['userStatus']
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        // this.service.showError(err['message']);
      })
    }

  }


  googleAuth: any = '';
  smsAuth: any = '';
  coinamt: number;
  coinWithdrawAmount: any;
  openModal() {
    this.googleAuth = '';
    this.smsAuth = '';
    if (this.coinamt < this.coinWithdrawAmount) {
      this.service.showErrorMessage("Coin amount cannot be less than min. withdrawal amount")
    }
    else {
      if (this.profileData == 'GOOGLE') {
        // $('#googleAuth').modal({ backdrop: 'static' });
      } else if (this.profileData == 'SMS') {
        this.suggesstionFunc('sms')
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileData == 'NONE' || this.profileData == 'SKIP') {
        this.service.showErrorMessage('Please Enable Two FA First.')
      }
    }

  }

  secretKey: any = '';

   // 2FA Suggestion Modal Functionality
   suggesstionFunc(action) {
    this.googleAuth = '';
    if (action == 'Skip') {
      this.service.showSpinner();
      this.service.getCandyPixelForms('account/skip-twoFa').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.service.showSuccessMessage('Logged In');
          // $('#suggest').modal('hide');
          //this.service.changeLoginSub('login');
          let credential = localStorage.getItem('authToken')
          localStorage.removeItem('authToken')
          localStorage.setItem('credential', credential);
          this.router.navigate(['']);
        }
        else {
          this.service.showErrorMessage(res['message'])
        }
      }, err => {
        this.service.hideSpinner();
      })
    }
    else if (action == 'Google') {
      this.service.showSpinner();
      this.service.getCandyPixelForms('account/google-auth').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          // this.qrCode = res['data']['qrCode'];
          this.secretKey = res['data']['secretKey'];
          // $('#suggest').modal('hide');
          // $('#googleAuth').modal('show');
        }
      }, err => {
        this.service.hideSpinner();
      })
    }
    else if (action == 'sms') {
      this.service.showSpinner();
      this.service.getCandyPixelForms('account/send-sms-code').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          // $('#suggest').modal('hide');
          // $('#smsAuth').modal('show');

          this.service.showSuccessMessage(res['message']);
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '500') {
          this.service.showErrorMessage('Mobile Number Not Verified');
        }
        else {
          this.service.showErrorMessage(err['message']);
        }
      })
    }
  }


}
