import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-wallet-transaction-status',
  templateUrl: './wallet-transaction-status.component.html',
  styleUrls: ['./wallet-transaction-status.component.css']
})
export class WalletTransactionStatusComponent implements OnInit {
  action: any = [];
  responseTxt: any;
  responseCode: string;
  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    let arr = window.location.href.split('/');
    let lastContent = arr[arr.length - 1];
    if (lastContent.includes("#")) {

      this.rejectWithdraw(lastContent);
    } else {

      this.approveWithdraw(lastContent);
    }
  }
// Approve Withdraw 
approveWithdraw(url) {
  var apireq = {
    "isWithdraw": true,
    "withdrawToken": url.slice(5)
  }
  this.service.showSpinner();
  this.service.postCandyPixelForms('wallet/wallet/approve-withdraw', apireq).subscribe(res => {
    this.service.hideSpinner();
    this.responseTxt = res['message'];
    if (res['status'] == 200) {
      this.responseCode = '1'
      this.service.showSuccessMessage(res['message'])
    } else {
      this.service.showErrorMessage(res['message'])
    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      localStorage.clear();
      this.router.navigate(['/login']);
      this.service.showErrorMessage('Session Expired!');
    }
  })
}

// reject withdraw
rejectWithdraw(url) {
  let token = url.split("#")[1];
  var getUrl = 'wallet/wallet/cancel-withdraw?token=' + token.slice(5);
  this.service.showSpinner();
  this.service.getCandyPixelForms(getUrl).subscribe(res => {
    this.service.hideSpinner();
    this.responseTxt = res['message'];
    if (res['status'] == 200) {
      this.responseCode = '0'
      this.service.showSuccessMessage(res['message'])
    } else {
      this.service.showErrorMessage(res['message'])
    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      localStorage.clear();
      this.router.navigate(['/login']);
      this.service.showErrorMessage('Session Expired!');
    }
  })
}
}
