<!DOCTYPE html>
  <body class="static-contant pt-0">

     <section class="wraper">
      <div class="container1440">
          <div class="row pt-5">
              <div class="col-lg-3 col-md-12">
                  <div class="card-div">
                   
                      <div class="cad-box ">
                          <ul>
                              <li *ngFor="let data of coinList;"><figure><img class="coinLogo" [src]="data.coinImage" alt=""></figure>
                            <div class="card-text" (click)="selectcoin(data.coinShortName)" style="cursor: pointer;">
                        <h6>{{data?.coinFullName}} ({{data.coinShortName}})</h6>
                        <!-- <ul class="text-ul">
                            <li>Price</li>
                            <li>${{data?.price}}</li>
                            <li>Volume</li>
                            <li>${{data?.volume_24h}}</li>
                            <li>Change</li>
                            <li class="red-text">{{data?.marketPriceInUsd24HourBefore}}</li>
                        </ul> -->
                            </div>
                         </li>
                        
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="col-lg-9 col-md-12">
                <div class="wallet-div ">
                  
                    <ul><li>DEPOSIT HISTORY</li>
                
                  </ul>
                  <div class="table-responsive mt-4 pb-2">
                      <table class="table after-logintabel action" aria-describedby="">
                          <thead>
                            <tr>
                              <th id="">DATE</th>
                              <th id="">AMOUNT</th>
                              <th id="">ADDRESS  </th>
                              <th id="">TXN HASH</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let deposite of depositeList| paginate: { itemsPerPage: 10, currentPage: depositePageNumber, totalItems: totalDeposite , id:'deposite' } ; let i=index">
                              <td>{{deposite.txnTime | date : 'short'}}</td>
                              <td>{{deposite.units ? deposite?.units : '---'}}</td>
                              <td>{{deposite.address ? deposite?.address : '---'}}</td>
                              <td>{{deposite.txnHash ? deposite?.txnHash : '---'}}</td>
                            </tr> 
                            <tr *ngIf="depositeList == ''">
                              <td colspan="4"> No transaction found</td>
                          </tr>                          
                        </tbody>
                        </table>
                        <!-- <div class="custom-pagination mt20 text-right">
                          <pagination-controls id='deposite' (pageChange)="getDepositePage($event)">
                          </pagination-controls>
                      </div> -->
                  </div>
                </div>
            </div>
            
              </div>
             
      
      </div>


    </section>

  </body>
  <app-footer></app-footer>