import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";

declare var $: any;
declare const Datafeeds: any;
declare const TradingView: any;
declare const AmCharts: any;
declare var CanvasJS: any;

@Component({
  selector: 'app-advanced-exchange',
  templateUrl: './advanced-exchange.component.html',
  styleUrls: ['./advanced-exchange.component.css']
})
export class AdvancedExchangeComponent implements OnInit {
  coinList: any = [];
  regexForEightChar = (/^(\d+)?([*]?\d{0,8})?$/);
  drop: string = 'close';
  coinpair: any = [];
  tab: any = 'market';
  buyPercentage: number = 0;
  sellPercentage: number = 0;
  buyForm: FormGroup;
  sellForm: FormGroup;
  orderTab: string = 'open'
  coinDetail: any = {};
  openOrderList: any = [];
  openOrderPageNumber: number = 1;
  orderHistoryList: any = [];
  orderHistoryPageNumber: number = 1;
  orderId: any;
  baseCoinBalance: number;
  tradeHistoryList: any = [];
  execCoinBalance: any;
  stopLimit: any;
  buyOrderList: any = [];
  sellOrderList: any = [];
  private chart: AmChart;
  status: boolean;
  connection: any;
  sellQuantity: Number = 0;
  execCoin: any = 'BTC';
  baseCoin: any = 'USD';
  loginStatus: any = false;
  myInterval: any;
  renderer: any;
  subject: any;
  modeType: any;
  marketPrice: any = 0;
  marketalldata: any;
  mysktInterval: any
  arr: any[];
  selectedInterval: any = '1 day';
  showData: any = false;
  marketBuyForm: FormGroup;
  marketSellForm: FormGroup;
  limitSellForm: FormGroup;
  limitBuyForm: FormGroup;
  errMsg: string;

  activeButton: boolean = true
  clearField: { marketBuyClear: boolean, limitBuyClear: boolean, BuyClear: boolean, marketSellClear: boolean, limitSellClear: boolean, SellClear: boolean };
  fee: any = {
    TakerFee: 0,
    MakerFee: 0
  }

  orderVal: any = 'both';

  showGraph: any = 'Professional'
  myTradeHistoryList: any = []
  
  constructor(public service: ServiceService, public route: Router, private AmCharts: AmChartsService) {
    /** Listener for web socket connection */
    var self = this;
    service.connection.subscribe(connection => {
      this.connection = connection;
      if (this.connection == 'online') {
        if (this.coinDetail != {})
          this.manageSubscribeAndUnsubscribeFunc(1);
      }
    });
    this.service.wsExchange.addEventListener('message', function (event) {
      if (!event.data.includes('symbol not supported')) {
        let data = JSON.parse(event.data);
        switch (data.messageType) {
          case "TICKER_UPDATE":
            self.marketPrice = data.data.lastPrice ? data.data.lastPrice : 0;
            self.marketalldata = data.data
            if (self.tab == 'market') {
              self.buyForm.patchValue({
                'price': self.marketPrice
              });
              self.sellForm.patchValue({
                'price': self.marketPrice
              });
            }
            break;
          case "ORDER_BOOK_UPDATE":
            data.data.forEach(obj => {
              if (obj.side == "BUY") {
                var ind = self.buyOrderList.findIndex((x) => x.price == Number(obj.price).toFixed(8));
                if (ind != -1) {
                  self.buyOrderList[ind].amount = (Number(self.buyOrderList[ind].amount) + Number(obj.amount)).toFixed(8);
                  if (self.buyOrderList[ind].amount <= 0) {
                    self.buyOrderList.splice(ind, 1);
                    return;
                  } else {
                    self.buyOrderList[ind].total = (self.buyOrderList[ind].price * self.buyOrderList[ind].amount).toFixed(4);
                  }
                } else {
                  self.buyOrderList.push({
                    price: self.validatePointFunc(obj.price),
                    amount: self.validatePointFunc(obj.amount),
                    total: self.validatePointFunc(obj.price * obj.amount)
                  });
                  self.buyOrderList = self.sortDatabuy(self.buyOrderList);
                }
              }
              if (obj.side == "SELL") {
                var ind = self.sellOrderList.findIndex((x) => x.price == Number(obj.price).toFixed(8));
                if (ind != -1) {
                  self.sellOrderList[ind].amount = (Number(self.sellOrderList[ind].amount) + Number(obj.amount)).toFixed(8);
                  if (self.sellOrderList[ind].amount <= 0) {
                    self.sellOrderList.splice(ind, 1);
                    return;
                  } else {
                    self.sellOrderList[ind].total = (self.sellOrderList[ind].price * self.sellOrderList[ind].amount).toFixed(4);
                  }
                } else {
                  self.sellOrderList.push({
                    price: self.validatePointFunc(obj.price),
                    amount: self.validatePointFunc(obj.amount),
                    total: self.validatePointFunc(obj.price * obj.amount)
                  });
                  self.sellOrderList = self.sortDatasell(self.sellOrderList);
                }
              }
            });
            break;
          case "TRADE_HISTORY_UPDATE":
            data.data.forEach((obj) => {
              self.tradeHistoryList.push({
                price: self.validatePointFunc(obj.price),
                amount: self.validatePointFunc(obj.amount),
                total: self.validatePointFunc(obj.price * obj.amount),
                time: obj.time,
                side: obj.side
              });
            });
            self.tradeHistoryList = self.sortData1(self.tradeHistoryList);
            break;
        }
      }
    });
  }

  validatePointFunc(amt) {
    return Number(amt).toFixed(8);
  }

  ngOnInit() {
    if (localStorage.getItem('credential')) {
      this.loginStatus = true;
    }
    this.getAllCoinPairFunc();
    this.formValidationFunctionality();
    // this.modeChangeFunc('night')
  }

  /** Function to manage exponential data */
  manageExponential(num) {
    //if the number is in scientific notation remove it
    if (/\d+\.?\d*e[\+\-]*\d+/i.test(num)) {
      var zero = '0',
        parts = String(num).toLowerCase().split('e'), //split into coeff and exponent
        es = Number(parts.pop()), //store the exponential part
        l = Math.abs(es), //get the number of zeros
        sign = es / l,
        coeff_array = parts[0].split('.');
      if (sign === -1) {
        num = zero + '.' + new Array(l).join(zero) + coeff_array.join('');
      } else {
        var dec = coeff_array[1];
        if (dec) l = l - dec.length;
        num = coeff_array.join('') + new Array(l + 1).join(zero);
      }
      return num;
    } else {
      return num;
    }
  };

  modeChangeFunc(type) {
    this.modeType = type
    console.log("mode::", this.modeType)
    if (type == 'night') {
      $("body").attr("data-theme-version", "dark")
    } else {
      $("body").attr("data-theme-version", "light")
    }

  }

  // Form Validation Functionality
  formValidationFunctionality() {
    this.marketBuyForm = new FormGroup({
      'marketBuyPrice': new FormControl('', [Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'marketBuyquantity': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
    });
    this.marketSellForm = new FormGroup({
      'marketSellPrice': new FormControl('', [Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'marketSellquantity': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
    });
    this.limitBuyForm = new FormGroup({
      'limitBuyPrice': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'limitBuyQuantity': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'limitBuyTotal': new FormControl('', Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/)))
    });
    this.limitSellForm = new FormGroup({
      'limitSellPrice': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'limitSellQuantity': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'limitSellTotal': new FormControl('', Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/)))
    });
    this.buyForm = new FormGroup({
      'stopLimit': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'price': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'quantity': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'total': new FormControl('', Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/)))
    });
    this.sellForm = new FormGroup({
      'stopLimit': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'price': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'quantity': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
      'total': new FormControl('', Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/)))
    });
  }

  // Tab Navigation Functionality
  tabNav(val) {
    this.resetForm();
    this.tab = val;
    this.showData = false
    this.buyPercentage = 0;
    this.sellPercentage = 0;
    this.getCoinListFunc(this.coinDetail.baseCoin);
  }

  // Calculate Buy Amount Functioanlity
  selectBuyPerFunc(action, percent, type, orderType) {
    switch (orderType) {
      case 'MARKETBUY':
        if (action == 'percentage') {
          this.buyPercentage = Number(percent);
          if (type == 'buy') {
            this.marketBuyForm.patchValue({
              'marketBuyquantity': this.marketalldata.lastPrice ? ((Number(this.baseCoinBalance) * (Number(percent) / 100)) / this.marketalldata.lastPrice).toFixed(8) : 0,
            })
          }
        }
        break;
      case 'LIMITBUY':
        if (Number(this.limitBuyForm.value.limitBuyPrice) <= 0) {
          this.service.showErrorMessage('Please enter the valid price');
          return;
        }
        else {
          if (action == 'percentage') {
            this.buyPercentage = Number(percent)
            if (type == 'buy') {
              this.limitBuyForm.patchValue({
                'limitBuyQuantity': ((Number(this.baseCoinBalance) * (Number(percent) / 100)) / this.limitBuyForm.value.limitBuyPrice).toFixed(8),
              })
            }
            this.getPriceFunc(this.limitBuyForm.value.limitBuyPrice, orderType);  // Function call here so that total value updated as other values change
          }
        }
        break;
      case 'STOPLIMITBUY':
        if (Number(this.buyForm.value.price) <= 0) {
          this.service.showErrorMessage('Please enter the valid price');
          return;
        }
        else {
          if (action == 'percentage') {
            this.buyPercentage = Number(percent);
            if (type == 'buy') {
              this.buyForm.patchValue({
                'quantity': ((Number(this.baseCoinBalance) * (Number(percent) / 100)) / this.buyForm.value.price).toFixed(8),
              })
            }
            this.getPriceFunc(this.buyForm.value.price, orderType);  // Function call here so that total value updated as other values change
          }
        }
        break;
    }
  }

  //calculate sell amount functionality
  selectSellPerFunc(action, percent, type, orderType) {
    switch (orderType) {
      case 'MARKETSELL':
        if (action == 'percentage') {
          this.sellPercentage = Number(percent);
          if (type == 'sell') {
            this.marketSellForm.patchValue({
              'marketSellquantity': (Number(this.execCoinBalance) * (Number(percent) / 100)),
            })
          }
        }
        break;
      case 'LIMITSELL':
        if (action == 'percentage') {
          this.sellPercentage = Number(percent);
          if (type == 'sell') {
            this.limitSellForm.patchValue({
              'limitSellQuantity': (Number(this.execCoinBalance) * (Number(percent) / 100)),
            })

          }
          this.getPriceFunc(this.limitSellForm.value.limitSellPrice, orderType);  // Function call here so that total value updated as other values change
        }
        break;
      case 'STOPLIMITSELL':
        if (action == 'percentage') {
          this.sellPercentage = Number(percent);
          if (type == 'sell') {
            this.sellForm.patchValue({
              'quantity': (Number(this.execCoinBalance) * (Number(percent) / 100)),
            })
          }
          this.getPriceFunc(this.sellForm.value.price, orderType);  // Function call here so that total value updated as other values change         
        }
        break;
    }
  }

  // Order Status Functionality
  showOrderStatus(status) {
    this.orderTab = status;
    switch (status) {
      case 'open':
        this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
        this.activeButton = true
        break;
      case 'history':
        this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
        this.activeButton = false
        break;
      case 'trade':
        this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
        this.activeButton = false
        break;
      case 'fund':
        this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
        this.activeButton = false
        break;
    }
    console.log(this.activeButton);

  }

  // Get Coin List Functionality 
  getCoinListFunc(baseCoin) {
    var coinList = [];
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        coinList = res['data'].coinList;
        var coinDetail = coinList.filter(x => (x.coinShortName == baseCoin.toUpperCase()));
        this.getTakerMakerFee();
        if (this.tab == 'market') {
          this.buyForm.patchValue({
            'price': this.marketPrice
          })
          this.sellForm.patchValue({
            'price': this.marketPrice
          })
        }
      } else {
      }
    }, err => {
      this.service.hideSpinner();
    })
  }
  actualcoinpair: any
  // Get All Coin Pair Functionality
  getAllCoinPairFunc() {
    if (this.service.walletData.length > 0) {
      this.coinpair = this.service.walletData;
      var coinDetail = {
        'baseCoin': this.coinpair[0].baseCoin.toLowerCase(),
        'base': this.coinpair[0].baseCoin,
        'exec': this.coinpair[0].execCoin[0],
        'execCoin': this.coinpair[0].execCoin[0].toLowerCase(),
        'coinPair': this.coinpair[0].execCoin[0].toLowerCase() + '_' + this.coinpair[0].baseCoin.toLowerCase(),
        'CoinPair': this.coinpair[0].execCoin[0] + '/' + this.coinpair[0].baseCoin,
        'socketPair': this.coinpair[0].execCoin[0] + '_' + this.coinpair[0].baseCoin,
      };
      this.coinDetail = localStorage.getItem('coinDetail') ? JSON.parse(localStorage.getItem('coinDetail')) : coinDetail;
      this.baseCoin = this.coinDetail.base;
      this.execCoin = this.coinDetail.exec;

      setTimeout(() => {
        this.manageSubscribeAndUnsubscribeFunc(1);
      }, 1000);
      this.showallcoindata();
      this.drawTradingChart(this.coinDetail, 'D');
      this.drawDepthChart();
      if (localStorage.getItem('credential')) {
        this.getbaseCoinBalance(this.coinDetail.baseCoin, 'base');
        this.getbaseCoinBalance(this.coinDetail.execCoin, 'exec');
        this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
        this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
        this.getCoinListFunc(this.coinpair[0].baseCoin);
      }
    } else {
      this.service.showSpinner();
      this.service.getCandyPixelForms('wallet/coin/get-symbol-list').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.coinpair = res['data'];
          console.log("Actual coin pairs::", this.coinpair)
          var coinDetail = {
            'baseCoin': this.coinpair[0].baseCoin.toLowerCase(),
            'base': this.coinpair[0].baseCoin,
            'exec': this.coinpair[0].execCoin[0],
            'execCoin': this.coinpair[0].execCoin[0].toLowerCase(),
            'coinPair': this.coinpair[0].execCoin[0].toLowerCase() + '_' + this.coinpair[0].baseCoin.toLowerCase(),
            'CoinPair': this.coinpair[0].execCoin[0] + '/' + this.coinpair[0].baseCoin,
            'socketPair': this.coinpair[0].execCoin[0] + '_' + this.coinpair[0].baseCoin,
          };

          this.coinDetail = localStorage.getItem('coinDetail') ? JSON.parse(localStorage.getItem('coinDetail')) : coinDetail;
          this.baseCoin = this.coinDetail.base;
          this.execCoin = this.coinDetail.exec;
          console.log("base coin pairs::", this.baseCoin)
          console.log("ex coin pairs::", this.execCoin)
          //this.actualcoinpair = this.coinDetail.execCoin

          setTimeout(() => {
            this.manageSubscribeAndUnsubscribeFunc(1);
          }, 1000);
          this.showallcoindata();
          this.drawTradingChart(this.coinDetail, 'D');
          this.drawDepthChart();
          if (localStorage.getItem('credential')) {
            this.getbaseCoinBalance(this.coinDetail.baseCoin, 'base');
            this.getbaseCoinBalance(this.coinDetail.execCoin, 'exec');
            this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
            this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
            this.getCoinListFunc(this.coinpair[0].baseCoin);
          }
        }
      }, err => {
        this.service.hideSpinner();
      })
    }
  }

  // Get Selected Coin Pair Functionality
  getSelectedPair(baseCoin, execCoin) {
    this.tabNav('market');
    this.manageSubscribeAndUnsubscribeFunc(2);
    this.getTakerMakerFee();
    this.marketalldata = {};
    this.baseCoin = baseCoin;
    this.execCoin = execCoin;
    this.drop = 'hide';
    this.coinDetail = { 'baseCoin': baseCoin.toLowerCase(), 'base': baseCoin, 'execCoin': execCoin.toLowerCase(), 'exec': execCoin, 'coinPair': execCoin.toLowerCase() + '_' + baseCoin.toLowerCase(), 'CoinPair': execCoin + '/' + baseCoin, 'socketPair': execCoin + '_' + baseCoin }
    localStorage.setItem('coinDetail', JSON.stringify((this.coinDetail)))
    this.drawTradingChart(this.coinDetail, 'D');
    this.drawDepthChart();
    this.coinpair.map(e => {
      if (e.marketValue.length > 0) {
        e.marketValue = [];
      }
    })
    this.showallcoindata()
    this.buyOrderList = [];
    this.sellOrderList = [];
    this.tradeHistoryList = [];
    this.manageSubscribeAndUnsubscribeFunc(1);
    if (localStorage.getItem('credential')) {
      this.getbaseCoinBalance(this.coinDetail.baseCoin, 'base');
      this.getbaseCoinBalance(this.coinDetail.execCoin, 'exec');
      this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
      this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
      this.getCoinListFunc(baseCoin);
    }
  }

  // Get Price For Trade 
  getPriceFunc(price, type) {
    if (type == 'STOPLIMITBUY') {
      this.buyForm.patchValue({
        'total': (Number(this.buyForm.value.price) * Number(this.buyForm.value.quantity)).toFixed(8)
      })
    } else if (type == 'LIMITBUY') {
      this.limitBuyForm.patchValue({
        'limitBuyTotal': (Number(this.limitBuyForm.value.limitBuyPrice) * Number(this.limitBuyForm.value.limitBuyQuantity)).toFixed(8)
      })
    } else if (type == 'LIMITSELL') {
      this.limitSellForm.patchValue({
        'limitSellTotal': (Number(this.limitSellForm.value.limitSellPrice) * Number(this.limitSellForm.value.limitSellQuantity)).toFixed(8)
      })
    } else if (type == 'STOPLIMITSELL') {
      this.sellForm.patchValue({
        'total': (Number(this.sellForm.value.price) * Number(this.sellForm.value.quantity)).toFixed(8)
      })
    }
  }

  // Get Order History of Particular Coin Pair Functionality
  getOrderHistoryOfParticularCoinPair(coinPair) {
    this.orderHistoryList = [];
    this.service.showSpinner();
    this.service.getCandyPixelForms('order-service-' + coinPair + '/my-order-history?symbol=' + coinPair).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        res['data'].forEach(obj => {
          if (!(obj.orderType == "MARKET" && obj.orderStatus == "CREATED")) {
            this.orderHistoryList.push({
              orderTime: new Date(obj.creationTime).getTime(),
              pair: obj.instrument.replace("_", "/"),
              orderType: obj.orderType,
              orderSide: obj.orderSide,
              price: obj.avgExecutionPrice ? obj.avgExecutionPrice : obj.limitPrice,
              amount: obj.currentQuantity == 0 ? obj.quantity : obj.currentQuantity,
              orderStatus: obj.orderStatus,
              happening: obj.orderStatus == "CANCELLED" ? (obj.currentQuantity / obj.quantity) * 100 : ((obj.quantity - obj.currentQuantity) / obj.quantity) * 100,
              total: obj.avgExecutionPrice ? Number(obj.avgExecutionPrice) * (obj.currentQuantity == 0 ? Number(obj.quantity) : Number(obj.currentQuantity)) : (obj.currentQuantity == 0 ? Number(obj.quantity) : Number(obj.currentQuantity)) * Number(obj.limitPrice),
            });
            if (obj.stopPrice == "0") {
              obj.tri_cond = ">";
            } else if (obj.stopPrice == "1") {
              obj.tri_cond = "<";
            } else if (obj.stopPrice == "2") {
              obj.tri_cond = "----";
            } else {
              obj.tri_cond = "";
            }
          }
        });
        this.orderHistoryList = this.sortData(this.orderHistoryList);
        this.connection = 'online';
      } else {
        this.connection = 'offline';
      }
    }, err => {
      this.connection = 'offline';
      this.service.hideSpinner();
    })
  }

  /** Function for sort data */
  sortData(arr) {
    arr.sort((a, b) => b.orderTime - a.orderTime);
    return arr;
  }

  /** FUNCTION TO GET TAKER MAKER FEE */
  getTakerMakerFee() {
    this.service.getCandyPixelForms('wallet/get-taker-maker-fee?coinName=' + this.coinDetail.base).subscribe((response: any) => {
      if (response.status == 200) {
        this.fee = response.data;
        this.fee.TakerFee = response.data.TakerFee / 100;
        this.fee.MakerFee = response.data.MakerFee / 100;
      }
    })
  }

  // Get Open Order List of Particular Coin Pair Functionality
  getOpenOrderOfParticularCoinPair(coinPair) {
    this.openOrderList = [];
    this.service.showSpinner();
    this.service.getCandyPixelForms('order-service-' + coinPair + '/my-active-orders?symbol=' + coinPair).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        res['data'].forEach((obj) => {
          if (!(obj.orderType == "MARKET" && obj.orderStatus == "CREATED")) {
            this.openOrderList.push({
              orderId: obj.orderId,
              orderTime: new Date(obj.creationTime).getTime(),
              pair: obj.instrument.replace("_", "/"),
              orderType: obj.orderType,
              orderSide: obj.orderSide,
              orderStatus: obj.orderStatus,
              price: obj.avgExecutionPrice ? obj.avgExecutionPrice : obj.limitPrice,
              amount: obj.orderStatus == 'PARTIALLY_EXECUTED' ? obj.currentQuantity : obj.quantity,
              happening: obj.currentQuantity == obj.quantity ? '0' : (obj.currentQuantity / obj.quantity) * 100,
              total: obj.avgExecutionPrice ? obj.avgExecutionPrice * (obj.orderStatus == 'PARTIALLY_EXECUTED' ? obj.currentQuantity : obj.quantity) : (obj.orderStatus == 'PARTIALLY_EXECUTED' ? obj.currentQuantity : obj.quantity) * obj.limitPrice,
              click: false
            });
            if (obj.stopPrice == "0") {
              obj.tri_cond = ">";
            } else if (obj.stopPrice == "1") {
              obj.tri_cond = "<";
            } else if (obj.stopPrice == "2") {
              obj.tri_cond = "----";
            } else {
              obj.tri_cond = "";
            }
          }
        });
        this.openOrderList = this.sortData(this.openOrderList);
      }
    }, err => {
      this.service.hideSpinner();
    })
  }

  // Get Amount For Trade
  getAmountFunc(amt, type) {
    if (type == 'STOPLIMITBUY') {
      this.buyForm.patchValue({
        'total': (Number(this.buyForm.value.price) * Number(this.buyForm.value.quantity)).toFixed(8)
      })
    } else if (type == 'LIMITBUY') {
      this.limitBuyForm.patchValue({
        'limitBuyTotal': (Number(this.limitBuyForm.value.limitBuyPrice) * Number(this.limitBuyForm.value.limitBuyQuantity)).toFixed(8)
      })
    } else if (type == 'LIMITSELL') {
      this.limitSellForm.patchValue({
        'limitSellTotal': (Number(this.limitSellForm.value.limitSellPrice) * Number(this.limitSellForm.value.limitSellQuantity)).toFixed(8)
      })
    } else if (type == 'STOPLIMITSELL') {
      this.sellForm.patchValue({
        'total': (Number(this.sellForm.value.price) * Number(this.sellForm.value.quantity)).toFixed(8)
      })
    }
  }

  // Place Buy / Sell Order Functionality
  placeOrderFunc(action) {
    var apiReq;
    switch (action) {
      case 'MARKETBUY':
        if (this.marketBuyForm.invalid) {
          this.clearField.marketBuyClear = true;
          return;
        }
        if (this.marketPrice == 0) {
          this.service.showErrorMessage("OOPS! Market seems empty, order could not be placed");
          return;
        } else {
          apiReq = {
            "orderSide": "BUY",
            "orderType": "MARKET",
            "quantity": Number(this.marketBuyForm.value.marketBuyquantity),
            "symbol": this.coinDetail.coinPair
          }
        }
        break;
      case 'MARKETSELL':
        if (this.marketSellForm.invalid) {
          this.clearField.marketSellClear = true;
          return;
        }
        if (this.marketPrice == 0) {
          this.service.showErrorMessage("OOPS! Market seems empty, order could not be placed");
          return;
        } else {
          apiReq = {
            "orderSide": "SELL",
            "orderType": "MARKET",
            "quantity": Number(this.marketSellForm.value.marketSellquantity),
            "symbol": this.coinDetail.coinPair
          }
        }
        break;
      case 'STOPLIMITBUY':
        if (this.buyForm.invalid) {
          return;
        }
        apiReq = {
          "limitPrice": Number(this.buyForm.value.price),
          "orderSide": "BUY",
          "orderType": "STOP_LIMIT",
          "quantity": Number(this.buyForm.value.quantity),
          "stopPrice": Number(this.buyForm.value.stopLimit),
          "symbol": this.coinDetail.coinPair
        }
        break;
      case 'LIMITBUY':
        if (this.limitBuyForm.invalid) {
          return;
        }
        apiReq = {
          "limitPrice": Number(this.limitBuyForm.value.limitBuyPrice),
          "orderSide": "BUY",
          "orderType": "LIMIT",
          "quantity": Number(this.limitBuyForm.value.limitBuyQuantity),
          "symbol": this.coinDetail.coinPair
        }
        break;
      case 'LIMITSELL':
        if (this.limitSellForm.invalid) {
          return;
        }
        apiReq = {
          "limitPrice": Number(this.limitSellForm.value.limitSellPrice),
          "orderSide": "SELL",
          "orderType": "LIMIT",
          "quantity": Number(this.limitSellForm.value.limitSellQuantity),
          "symbol": this.coinDetail.coinPair
        }
        break;
      case 'STOPLIMITSELL':
        if (this.sellForm.invalid) {
          return;
        }
        apiReq = {
          "limitPrice": Number(this.sellForm.value.price),
          "orderSide": "SELL",
          "orderType": "STOP_LIMIT",
          "quantity": Number(this.sellForm.value.quantity),
          "stopPrice": Number(this.sellForm.value.stopLimit),
          "symbol": this.coinDetail.coinPair
        }
        break;
    }
    if (action == 'MARKETBUY') {
      if (apiReq.quantity > this.baseCoinBalance) {
        this.service.showErrorMessage("You don't have enough balance in your account");
        return;
      }
    } else if (action == 'MARKETSELL') {
      if (apiReq.quantity > this.execCoinBalance) {
        this.service.showErrorMessage("You don't have enough balance in your account");
        return;
      }
    } else {
      var fee: any = 0;
      if (apiReq.orderType == 'MARKET') {
        fee = this.fee.TakerFee * this.baseCoinBalance;
      } else {
        if (apiReq.orderSide == 'BUY') {
          fee = this.fee.MakerFee * this.baseCoinBalance;
          if (((apiReq.quantity * apiReq.limitPrice) + fee) > this.baseCoinBalance) {
            this.service.showErrorMessage("You don't have enough balance in your account");
            return;
          }
        } else {
          fee = this.fee.TakerFee * this.execCoinBalance;
          if (((apiReq.quantity) + fee) > this.execCoinBalance) {
            this.service.showErrorMessage("You don't have enough balance in your account");
            return;
          }
        }
      }
    }
    if (this.coinDetail.base == 'USDT') {
      if (action == 'MARKETBUY' || action == 'MARKETSELL') {
        if ((apiReq.quantity * this.marketalldata.lastPrice) < 10) {
          this.service.showErrorMessage("Minimum amount should be 10");
          return;
        }
      } else {
        if ((apiReq.quantity * apiReq.limitPrice) < 10) {
          this.service.showErrorMessage("Minimum amount should be 10");
          return;
        }
      }
    }
    if ((apiReq.orderType == 'STOP_LIMIT' || apiReq.orderType == 'LIMIT') && apiReq.limitPrice <= 0) {
      this.service.showErrorMessage('Please enter the valid amount');
      return;
    } else if (apiReq.quantity <= 0) {
      this.service.showErrorMessage('Please enter the valid quantity');
      return;
    }
    else {
      this.service.showSpinner();
      let data = {
        "messageType": "",
        "params": {
          "symbol": ""
        }
      }
      data.messageType = "UNSUBSCRIBE_ORDER_BOOK";
      data.params.symbol = this.coinDetail.socketPair;
      this.service.wsExchange.send(JSON.stringify(data));
      this.service.postCandyPixelForms('order-service-' + this.coinDetail.coinPair + '/place-order', apiReq).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.service.showSuccessMessage(res['message'])
          this.getbaseCoinBalance(this.coinDetail.baseCoin, 'base');
          this.getbaseCoinBalance(this.coinDetail.execCoin, 'exec');
          this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
          this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
          this.buyOrderList = [];
          this.sellOrderList = [];
          data.messageType = "SUBSCRIBE_ORDER_BOOK";
          data.params.symbol = this.coinDetail.socketPair;
          this.service.wsExchange.send(JSON.stringify(data));
          this.drawDepthChart();
        } else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.route.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
          this.service.showErrorMessage('Something Went Wrong');
        }
      })
    }
  }

  resetForm() {
    this.marketBuyForm.reset();
    this.marketSellForm.reset();
    this.limitBuyForm.reset();
    this.limitSellForm.reset();
    this.buyForm.reset();
    this.sellForm.reset();
  }

  // Manage Socket Subscribe And Unsubscribe Functionality 
  manageSubscribeAndUnsubscribeFunc(mode) {
    let data = {
      "messageType": "",
      "params": {
        "symbol": ""
      }
    }
    switch (mode) {
      case 1:
        /** Code for subscribe ticker data */
        if (this.service.wsExchange.readyState) {
          data.messageType = "SUBSCRIBE_TICKER";
          data.params.symbol = this.coinDetail.socketPair;
          this.service.wsExchange.send(JSON.stringify(data));
          /** Code for subscribe order book data */
          data.messageType = "SUBSCRIBE_ORDER_BOOK";
          data.params.symbol = this.coinDetail.socketPair;
          this.service.wsExchange.send(JSON.stringify(data));

          /** Code for subscribe trade history data */
          data.messageType = "SUBSCRIBE_TRADE_HISTORY";
          data.params.symbol = this.coinDetail.socketPair;
          this.service.wsExchange.send(JSON.stringify(data));
          return;
        } else {
          this.myInterval = setInterval(x => {
            this.manageSubscribeAndUnsubscribeFunc(1);
          }, 10000);
        }

        break;
      case 2:
        /** Code for unsubscribe ticker data */
        if (this.service.wsExchange.readyState) {
          data.messageType = "UNSUBSCRIBE_TICKER";
          data.params.symbol = this.coinDetail.socketPair;
          this.service.wsExchange.send(JSON.stringify(data));

          /** Code for unsubscribe order book data */
          data.messageType = "UNSUBSCRIBE_ORDER_BOOK";
          data.params.symbol = this.coinDetail.socketPair;
          this.service.wsExchange.send(JSON.stringify(data));

          /** Code for unsubscribe trade history data */
          data.messageType = "UNSUBSCRIBE_TRADE_HISTORY";
          data.params.symbol = this.coinDetail.socketPair;
          this.service.wsExchange.send(JSON.stringify(data));
          break;
        } else {
          this.myInterval = setInterval(x => {
            this.manageSubscribeAndUnsubscribeFunc(2);
          }, 10000);
        }
    }
  }


  // Delete Open Order Functionality
  openCancel(orderId) {
    this.orderId = orderId;
    $("#myModal").modal('show');
  }

  deleteOpenOrder() {
    var apiReq = {
      "orderId": this.orderId,
      "symbol": this.coinDetail.coinPair
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('order-service-' + this.coinDetail.coinPair + '/cancel-order', apiReq).subscribe(res => {
      $('#myModal').modal('hide');
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.service.showSuccessMessage(res['message']);
        this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);
        this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
        this.getbaseCoinBalance(this.coinDetail.baseCoin, 'base');
        this.getbaseCoinBalance(this.coinDetail.execCoin, 'exec');
      } else {
        this.service.showErrorMessage(res['message'])
      }
    }, err => {
      this.service.hideSpinner();
      $('#myModal').modal('hide');
    })
  }


  // GENERATING TRADING CHART FUNCTIONALITY
  drawTradingChart(coinDetail, resolution) {
    console.log("trading chart iff", this.modeType, coinDetail, resolution)
    // this.modeType = 'night'
    if (this.modeType == 'night') {
      new TradingView.widget({
        fullscreen: true,
        symbol: this.coinDetail.execCoin.toUpperCase() + '/' + coinDetail.baseCoin.toUpperCase(),
        interval: 'D',
        container_id: "tradingview_Ohl",
        datafeed: new Datafeeds.UDFCompatibleDatafeed(this.service.chartUrl, 10000),
        library_path: "assets/lib/charting_library_night/",
        locale: "en",
        drawings_access: { type: 'black', tools: [{ name: "Regression Trend" }] },
        disabled_features: ["use_localstorage_for_settings"],
      });
    }
    else {
      new TradingView.widget({
        fullscreen: true,
        symbol: this.coinDetail.execCoin.toUpperCase() + '/' + coinDetail.baseCoin.toUpperCase(),
        interval: resolution,
        timezone: 'Etc/UTC',
        container_id: "tradingview_Ohl",
        datafeed: new Datafeeds.UDFCompatibleDatafeed(this.service.chartUrl, 10000),
        library_path: "assets/lib/charting_library/",
        locale: "en",
        drawings_access: { type: 'black', tools: [{ name: "Regression Trend" }] },
        disabled_features: ["use_localstorage_for_settings"],
      });
    }
  }

  /** Function to draw depth chart */
  drawDepthChart() {
    let currVal = this.baseCoin;
    let exeVal = this.execCoin;
    AmCharts.makeChart("depth_chart", {
      "type": "serial",
      "theme": "light",
      "dataLoader": {
        "url": this.service.chartUrl + "/depth-chart?currency=" + currVal + "&exchangeCurrency=" + exeVal,
        "format": "json",
        "reload": 30,
        "postProcess": function (data) {
          var response = JSON.stringify(data);
          var parsedData = JSON.parse(response);
          var asks = parsedData.data.asks;
          var bids = parsedData.data.bids;
          // Function to process (sort and calculate cummulative volume)
          function processData(list, type, desc) {
            // Convert to data points
            for (var i = 0; i < list.length; i++) {
              list[i] = {
                value: Number(list[i][0]),
                volume: Number(list[i][1]),
              }
            }
            // Sort list just in case
            list.sort(function (a, b) {
              if (a.value > b.value) {
                return 1;
              } else if (a.value < b.value) {
                return -1;
              } else {
                return 0;
              }
            });
            // Calculate cummulative volume
            if (desc) {
              for (var i = list.length - 1; i >= 0; i--) {
                if (i < (list.length - 1)) {
                  list[i].totalvolume = list[i + 1].totalvolume + list[i].volume;
                } else {
                  list[i].totalvolume = list[i].volume;
                }
                var dp = {};
                dp["value"] = list[i].value;
                dp[type + "volume"] = list[i].volume;
                dp[type + "totalvolume"] = list[i].totalvolume;
                res.unshift(dp);
              }
            } else {
              for (var i = 0; i < list.length; i++) {
                if (i > 0) {
                  list[i].totalvolume = list[i - 1].totalvolume + list[i].volume;
                } else {
                  list[i].totalvolume = list[i].volume;
                }
                var dp = {};
                dp["value"] = list[i].value;
                dp[type + "volume"] = list[i].volume;
                dp[type + "totalvolume"] = list[i].totalvolume;
                res.push(dp);
              }
            }
          }
          var res = [];
          processData(bids, "bids", true);
          processData(asks, "asks", false);
          return res;
        }
      },
      "graphs": [{
        "id": "bids",
        "fillAlphas": 0.1,
        "lineAlpha": 1,
        "lineThickness": 2,
        "lineColor": "#0f0",
        "type": "step",
        "valueField": "bidstotalvolume",
        "balloonFunction": balloon
      }, {
        "id": "asks",
        "fillAlphas": 0.1,
        "lineAlpha": 1,
        "lineThickness": 2,
        "lineColor": "#f00",
        "type": "step",
        "valueField": "askstotalvolume",
        "balloonFunction": balloon
      }, {
        "lineAlpha": 0,
        "fillAlphas": 0.2,
        "lineColor": "#000",
        "type": "column",
        "clustered": false,
        "valueField": "bidsvolume",
        "showBalloon": false
      }, {
        "lineAlpha": 0,
        "fillAlphas": 0.2,
        "lineColor": "#000",
        "type": "column",
        "clustered": false,
        "valueField": "asksvolume",
        "showBalloon": false
      }],
      "categoryField": "value",
      "chartCursor": {},
      "balloon": {
        "textAlign": "left"
      },
      "categoryAxis": {
        "minHorizontalGap": 100,
        "startOnAxis": true,
        "showFirstLabel": false,
        "showLastLabel": false
      },
      "export": {
        "enabled": true
      }
    });

    function balloon(item, graph) {
      var txt;
      if (graph.id == "asks") {
        txt = "Ask: <strong>" + formatNumber(item.dataContext.value, graph.chart, 8) + "</strong><br />"
          + "Total volume: <strong>" + formatNumber(item.dataContext.askstotalvolume, graph.chart, 8) + "</strong><br />"
          + "Volume: <strong>" + formatNumber(item.dataContext.asksvolume, graph.chart, 8) + "</strong>";
      } else {
        txt = "Bid: <strong>" + formatNumber(item.dataContext.value, graph.chart, 8) + "</strong><br />"
          + "Total volume: <strong>" + formatNumber(item.dataContext.bidstotalvolume, graph.chart, 8) + "</strong><br />"
          + "Volume: <strong>" + formatNumber(item.dataContext.bidsvolume, graph.chart, 8) + "</strong>";
      }
      return txt;
    }
    function formatNumber(val, chart, precision) {
      return AmCharts.formatNumber(
        val, {
        precision: precision ? precision : chart.precision,
        decimalSeparator: chart.decimalSeparator,
        thousandsSeparator: chart.thousandsSeparator
      }
      );
    }
  }


  // Get Balance Of Base Coin Functionality
  getbaseCoinBalance(baseCoin, type) {
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-balance?coinName=' + baseCoin).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        if (type == 'base') {
          this.baseCoinBalance = res['data'].walletBalance;
        }
        else {
          this.execCoinBalance = res['data'].walletBalance;
        }
      } else {
      }
    }, err => {
      this.service.hideSpinner();
    })
  }

  ngOnDestroy() {
    $("body").removeClass("night-theme");
    if (this.chart) {
      AmCharts.destroyChart(this.chart);
    }
    clearInterval(this.myInterval);
    this.manageSubscribeAndUnsubscribeFunc(2);

    localStorage.removeItem('coinDetail');
    clearInterval(this.mysktInterval);
  }

  // Clear data From sellForm // suraj
  clearFilterOfSell() {
    this.sellPercentage = 0;
  }

  // Clear data From buyForm  // suraj
  clearFilterOfBuy() {
    this.buyPercentage = 0;
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }

  /** Function to restrict length after dot */
  restrictLength(type) {
    switch (type) {
      case 'bsl':
        if (this.buyForm.value.stopLimit.includes(".")) {
          if (!this.regexForEightChar.test(this.buyForm.value.stopLimit)) {
            let tempVal = this.buyForm.value.stopLimit.split('.');
            this.buyForm.patchValue({
              stopLimit: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;
      case 'bmp':
        if (this.marketBuyForm.value.marketBuyPrice.includes(".")) {
          if (!this.regexForEightChar.test(this.marketBuyForm.value.marketBuyPrice)) {
            let tempVal = this.marketBuyForm.value.marketBuyPrice.split('.');
            this.marketBuyForm.patchValue({
              marketBuyPrice: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;
      case 'smb':
        if (this.buyForm.value.price.includes(".")) {
          if (!this.regexForEightChar.test(this.buyForm.value.price)) {
            let tempVal = this.buyForm.value.price.split('.');
            this.buyForm.patchValue({
              price: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;
      case 'lmp':
        if (this.limitBuyForm.value.limitBuyPrice.includes(".")) {
          if (!this.regexForEightChar.test(this.limitBuyForm.value.limitBuyPrice)) {
            let tempVal = this.limitBuyForm.value.limitBuyPrice.split('.');
            this.limitBuyForm.patchValue({
              limitBuyPrice: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;
      case 'bsq':
        if (this.marketBuyForm.value.marketBuyquantity.includes(".")) {
          if (!this.regexForEightChar.test(this.marketBuyForm.value.marketBuyquantity)) {
            let tempVal = this.marketBuyForm.value.marketBuyquantity.split('.');
            this.marketBuyForm.patchValue({
              marketBuyquantity: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;
      case 'lsb':
        if (this.limitBuyForm.value.limitBuyQuantity.includes(".")) {
          if (!this.regexForEightChar.test(this.limitBuyForm.value.limitBuyQuantity)) {
            let tempVal = this.limitBuyForm.value.limitBuyQuantity.split('.');
            this.limitBuyForm.patchValue({
              limitBuyQuantity: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;
      case 'ssb':
        if (this.buyForm.value.quantity.includes(".")) {
          if (!this.regexForEightChar.test(this.buyForm.value.quantity)) {
            let tempVal = this.buyForm.value.quantity.split('.');
            this.buyForm.patchValue({
              quantity: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;
      case 'ssl':
        if (this.sellForm.value.stopLimit.includes(".")) {
          if (!this.regexForEightChar.test(this.sellForm.value.stopLimit)) {
            let tempVal = this.sellForm.value.stopLimit.split('.');
            this.sellForm.patchValue({
              stopLimit: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;
      case 'spm':
        if (this.sellForm.value.price.includes(".")) {
          if (!this.regexForEightChar.test(this.sellForm.value.price)) {
            let tempVal = this.sellForm.value.price.split('.');
            this.sellForm.patchValue({
              price: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;
      case 'ssq':
        if (this.sellForm.value.quantity.includes(".")) {
          if (!this.regexForEightChar.test(this.sellForm.value.quantity)) {
            let tempVal = this.sellForm.value.quantity.split('.');
            this.sellForm.patchValue({
              quantity: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;
      case 'lsq':
        if (this.limitSellForm.value.limitSellQuantity.includes(".")) {
          if (!this.regexForEightChar.test(this.limitSellForm.value.limitSellQuantity)) {
            let tempVal = this.limitSellForm.value.limitSellQuantity.split('.');
            this.limitSellForm.patchValue({
              limitSellQuantity: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;
      case 'msq':
        if (this.marketSellForm.value.marketSellquantity.includes(".")) {
          if (!this.regexForEightChar.test(this.marketSellForm.value.marketSellquantity)) {
            let tempVal = this.marketSellForm.value.marketSellquantity.split('.');
            this.marketSellForm.patchValue({
              marketSellquantity: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;
      case 'lpm':
        if (this.limitSellForm.value.limitSellPrice.includes(".")) {
          if (!this.regexForEightChar.test(this.limitSellForm.value.limitSellPrice)) {
            let tempVal = this.limitSellForm.value.limitSellPrice.split('.');
            this.limitSellForm.patchValue({
              limitSellPrice: tempVal[0] + '.' + tempVal[1].slice(0, 8)
            })
          }
        }
        break;

    }
  }

  callOHLCFunc() {
    this.chart = new CanvasJS.Chart("chartContainer", {
      animationEnabled: true,
      title: {
        text: "QTEXCHANGE"
      },
      axisX: {
        valueFormatString: "MMM"
      },
      axisY: {
        title: "",
        includeZero: false,
        prefix: "",
        lineThickness: 0
      },
      axisY2: {
        title: "Volume",
        labelFormatter: addSymbols
      },
      toolTip: {
        shared: true
      },
      legend: {
        cursor: "pointer",
        itemclick: toggleDataSeries
      },
      data: [{
        type: "ohlc",
        xValueFormatString: "MMMM 2020",
        name: "",
        showInLegend: true,
        yValueFormatString: "$###0.00",
        toolTipContent: "<b>{x}</b><br><span style=\"color:#4F81BC\">{name}</span>: <br>Open: {y[0]}<br>High: {y[1]}<br>Low: {y[2]}<br>Close: {y[3]}<br><b>Adj. Close</b>: {y[4]}",
        dataPoints: this.arr
      }]
    });

    this.chart.render();

    function addSymbols(e) {
      var suffixes = ["", "K", "M", "B"];
      var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

      if (order > suffixes.length - 1)
        order = suffixes.length - 1;

      var suffix = suffixes[order];
      return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
    }

    function toggleDataSeries(e) {
      if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      } else {
        e.dataSeries.visible = true;
      }
      e.chart.render();
    }
  }

  showallcoindata() {
    var item = []
    this.coinpair.map(e => e.marketValue = [])
    this.coinpair.forEach((ele, ind) => {
      if (ele.baseCoin == this.baseCoin && ind.execCoin == this.execCoin) {
        ele.execCoin.map(e => {
          return this.service.getCandyPixelForms('order-service-' + e.toLowerCase() + '_' + this.baseCoin.toLowerCase() + '/market-data?symbol=' + e + '_' + this.baseCoin).subscribe(res => {
            item.push(res['data'].lastPrice)

          })
        })
      }
    })
    this.coinpair.map(e => {
      if (e.baseCoin == this.baseCoin) {
        e.marketValue = item
      }
    })
  }

  sortData1(arr) {
    arr.sort((a, b) => b.time - a.time);
    return arr;
  }

  sortDatasell(arr) {
    arr.sort((a, b) => b.price - a.price);
    return arr;
  }

  sortDatabuy(arr) {
    arr.sort((a, b) => b.price - a.price);
    return arr;
  }

  orderNav(val) {
    this.orderVal = val
  }

  // switch graph professional or depth
  switchGraph(showGraph) {
    console.log(showGraph)

    this.showGraph = showGraph
    console.log(this.showGraph)
  }

  // order switch between sell, buy, both
  orderBookTabShow: any = 'Both'
  // Order Status Functionality
  orderBookTab(status) {

    console.log(status);
    switch (status) {
      case 'Both':
        this.orderBookTabShow = 'Both'
        // this.buy = false;
        // this.sell = true;
        // this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);

        break;
      case 'Buyers':
        this.orderBookTabShow = 'Buyers'
        // this.buy = false;
        // this.sell = true;
        // this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);

        break;
      case 'Sellers':
        this.orderBookTabShow = 'Sellers'
        // this.sell = false;
        // this.buy = true;
        // this.getOrderHistoryOfParticularCoinPair(this.coinDetail.coinPair);
        break;
    }

  }


  // buy sell trade
  buySellTradeTabShow: any = 'Buy'
  buySellTradeTab(status) {

    console.log(status);
    switch (status) {
      case 'Buy':
        this.buySellTradeTabShow = 'Buy'
        // this.buy = false;
        // this.sell = true;
        // this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);

        break;
      case 'Sell':
        this.buySellTradeTabShow = 'Sell'
        // this.buy = false;
        // this.sell = true;
        // this.getOpenOrderOfParticularCoinPair(this.coinDetail.coinPair);

        break;
    }

  }

}
