import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ngxCsv } from 'ngx-csv/ngx-csv';
@Component({
  selector: 'app-my-trading-report',
  templateUrl: './my-trading-report.component.html',
  styleUrls: ['./my-trading-report.component.css']
})
export class MyTradingReportComponent implements OnInit {
  minAge: Date;

  filterForm = new FormGroup({
    "coinPair": new FormControl(''),
    "fromDate": new FormControl(''),
    "toDate": new FormControl('')
  })
  data: any =[
    {
      "Pair": "QTE/USD",
       "Order Type":"Sell QTE",
        "Volume QTE": 100 ,
        "Commission %":0.01 ,
        "Reward":'0.01 QTE' ,
        "Fees" :0.001,
        "P/L":0.09
    },
    {
      "Pair": "QTE/USD",
       "Order Type":"Sell QTE",
        "Volume QTE": 100 ,
        "Commission %":0.01 ,
        "Reward":'0.01 QTE' ,
        "Fees" :0.001,
        "P/L":0.09
    }
  ]
  constructor() { }

  ngOnInit(): void {
    this.dateValidation()
  }
  dateValidation() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
  }
  searchByDate() {

  }
  resetSearch() {

  }
  filterOncoinPairChange() {

  }

  exportAsCSV(){

    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: false,
      // title: 'Your title',
      useBom: true,
      noDownload: false,
      headers: ["Pair", "Order Type", "Volume QTE" ,"Commission %" ,"Reward" ,"Fees" ,"P/L"]
    };
   
    new ngxCsv(this.data, 'My Report' ,options);
  }
}
