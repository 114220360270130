<header>
    <nav class="navbar navbar-expand-lg navbar-light">
        <!-- <a class="navbar-brand" href="index.html"><img src="assets/images/crypto-logo.png" alt=""></a> -->
        <a class="navbar-brand" href="index.html"><img src="assets/images/logo.svg" alt="img"></a>

        <span class="oprn-togel" style="font-size:30px;cursor:pointer" onclick="openNav()">&#9776; </span>
        <div class="sidenav" id="mySidenav">

            <div class="menu-top">
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li class="nav-item ">
                        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" (click)="p2pexchange()" style="cursor: pointer">P2P EXCHANGE</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="exchange()" style="cursor: pointer">EXCHANGE</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="marketInfo()" style="cursor: pointer">MARKET INFO</a>
                    </li>

                </ul>
                <form class="form-inline my-2 my-lg-0 ryt-form">
                    <div class="language-div">
                        <label>Language</label>
                        <img src="assets/images/crypto-1.png" alt="">
                        <img src="assets/images/drop.png" alt="">
                    </div>
                    <button style="border: 1px solid #01A781;" class="btn headerbtn my-2 my-sm-0" type="button"
                        (click)="login()">Login</button>
                    <button style="border: 1px solid #01A781;" class="btn headerbtn my-2 my-sm-0" type="button"
                        (click)="register()">Register</button>
                </form>
            </div>
        </div>
    </nav>
</header>

<header *ngIf="loginn">
    <nav class="navbar navbar-expand-lg navbar-light" aria-label="" style="white-space: nowrap;">

        <span class="oprn-togel" style="font-size:30px;cursor:pointer" onclick="openNav()">&#9776; </span>
        <div class="sidenav" id="mySidenav">
            <div class="menu-top">
                <!-- <a class="navbar-brand" href="index.html"><img src="assets/images/crypto-logo.png" alt=""></a> -->
                <a class="navbar-brand" href="index.html"><img src="assets/images/logo.svg" alt=""></a>
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li class="nav-item ">
                        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" (click)="p2pexchange()" style="cursor: pointer">P2P EXCHANGE</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="exchange()" style="cursor: pointer">EXCHANGE</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="marketInfo()" style="cursor: pointer">MARKET INFO</a>
                    </li>


                </ul>
                <form class="form-inline my-2 my-lg-0 ryt-form">

                    <div class="language-div">
                        <label>Language</label>
                        <img src="assets/images/crypto-1.png" alt="">
                        <img src="assets/images/drop.png" alt="">
                    </div>
                    <select class="btn headerbtn my-2 my-sm-0" style="width: 14%" name="cars" id="cars"
                        (change)="security($event.target.value)">
                        <option>Select</option>
                        <option value="wallet">My Wallet</option>
                        <option value="withdraw">Withdraw History</option>
                        <option value="deposit">Deposit History</option>
                    </select>&nbsp;&nbsp;
                    <img src="assets/images/Layer_20.png" alt="" style="    width: 6%;
                border-radius: 50%; background-color: white; cursor: pointer;" (click)="notifications()">
                    &nbsp;&nbsp;
                    <select style="width: 14%" name="cars" id="cars" (change)="new($event.target.value)">
                        <option>Select</option>
                        <option value="profile">My Profile</option>
                        <option value="login">Login History</option>
                        <option value="logout">Logout</option>
                    </select>
                </form>
            </div>
        </div>
    </nav>
</header>


<div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Logout</h4>
            </div>
            <div class="modal-body logout_text">
                Are you sure you want to logout?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Yes</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
            </div>
        </div>

    </div>
</div>