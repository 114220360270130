import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  signupForm: FormGroup;
  signupData: any = {};
  encryptedData: any = '';
  type_pass: any = 'password'
  type_pass2: any = 'password';
  recaptcha: any = "";
  webUrl: string;
  refferalCode:any;
  
  constructor(private activatedRoute: ActivatedRoute,public router: Router,
    public service: ServiceService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res)
      if (res.myReferalCode) {
        this.refferalCode = res.myReferalCode
      }
    })
    this.formValidation();
    window.scrollTo(0, 0);
    if (localStorage.getItem('signupData')) {
      this.signupData = this.service.decryptData(localStorage.getItem('signupData'));
      this.signupForm.patchValue({
        'email': this.signupData.email,
        'password': this.signupData.password,
        'confirmPassword': this.signupData.password
      })
    }

  }
  // Form Validation 
  formValidation() {
    this.signupForm = new FormGroup({
      'email': new FormControl(this.service.email, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'password': new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)]),
      'confirmPassword': new FormControl('', Validators.required),
      //'term': new FormControl('', [Validators.required]),
      //'agree': new FormControl('', [Validators.required]),
    })
  }
  
  // Signup Functionality
  signupFunc() {

    var formData = {
      'email': this.signupForm.value.email,
      'password': this.signupForm.value.password,
      'refferalCode': this.refferalCode
    }
   
    this.encryptedData = this.service.encryptData(formData);
    localStorage.setItem('signupData', this.encryptedData)
    this.router.navigate(['/register2']);
    this.signupForm.reset();
  }
  default() {
    this.webUrl = this.service.webUrl;
  }
  // to switch eye
  switchEye() {
    this.type_pass = 'text';
  }

  switchToText() {
    this.type_pass = 'password';
  }

  // to switch eye
  switchEye2() {
    console.log('dfdf')
    this.type_pass2 = 'text';
  }

  switchToText2() {
    this.type_pass2 = 'password';
  }

  login() {
    this.router.navigate(['/login'])
  }
 

}
