<h5 class="walletSubHeading">Wallet Trading limit</h5>
<div class=" dinAfterHeading">
    <div class="col-md-12 row childDivA1">
        <div class="col-md-4 text-center childDiv " >
            <h4>Fiat Deposit Limit</h4>
            <p class="amount">50k Daily</p>
        </div>
        <div class="col-md-4 text-center childDiv " >
            <h4>Crypto Withdraw  Limit</h4>
            <p class="amount">Unlimited</p>
        </div>
    </div>
    <div class="col-md-12 row childDivB1">
        <div class="col-md-4 text-center childDiv " >
            <h4>Crypto Deposit Limit</h4>
            <p class="amount">100BTC Daily</p>
        </div>
        <div class="col-md-4 text-center childDiv " >
            <h4>P2P transaction Limit</h4>
            <p class="amount">Unlimited</p>
        </div>
    </div>


    <div class="col-md-12 text-center kycButton" *ngIf="kycdata !=='ACCEPTED' ">
        <button class="enableButton text-center">Complete Your KYC</button>
    </div>
</div>