<body>
    <section>
        <div class="container1440">
            <div class="Aboutus00 confirmation_section mt-5">
                <img *ngIf="responseCode=='1'" src="assets/images/confirmation.png" alt="">
                <img *ngIf="responseCode=='0'" src="assets/images/2fa-image.png" alt="">
                <h3>{{responseTxt}}</h3>
            </div>
        </div>
    </section>
</body>