<div class="static-contant">
    <section>
        <div class="container1440">
            <div class="Aboutus00 mt-5">
                <div class="userProfile">
                  
                    <p style="color: #fff;font-size: 23px;margin-top: 10px;">{{profileData?.userName || '--'}}</p>
                </div>

                <div class="wrapper center-block">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="" style="padding: 0 17px;">
                            <div class="row" style="display: flex;justify-content: space-around; margin-top: 40px;">
                                <div class="col-lg-5 col-md-12 col-sm-12" style="border: 1px solid #4f5d78;">
                                    <div class="mainPrice">
                                        <div class="priceTag">
                                            <p class="prieList">Trade volume:</p>
                                          
                                            <p class="prieAmount">{{profileData?.tradeVolume || 0}} BTC</p>
                                        </div>
                                        <div class="priceTag">
                                            <p class="prieList">Number of confirmed trade:</p>
                                           
                                            <p class="prieAmount">{{profileData?.noOfConfirmTrades || 0}}</p>
                                        </div>
                                  
                                      
                                        <div class="priceTag">
                                            <p class="prieList">first purchase:</p>
                                      
                                            <p class="prieAmount">{{profileData?.firstPurchase || '--'}}</p>
                                        </div>
                                        <div class="priceTag">
                                            <p class="prieList">Account created:</p>
                                      
                                            <p class="prieAmount">
                                                {{(profileData?.accountCreated | date: 'MMM d, y, h:mm:ss a') || '--'}}
                                            </p>
                                        </div>
                                        <div class="priceTag">
                                            <p class="prieList">Last seen:</p>
                                         
                                            <p class="prieAmount">
                                                {{(profileData?.lastSeen | date: 'MMM d, y, h:mm:ss a') || '--'}} </p>
                                        </div>
                                 
                                        <div class="priceTag">
                                            <p class="prieList">Email:</p>
                                       
                                            <p class="prieAmount">
                                                {{(profileData?.emailVerificationTime | date: 'MMM d, y, h:mm:ss a') || '--'}}
                                            </p>
                                        </div>
                                   
                                        <div class="priceTag">
                                            <p class="prieList">ID, Passport or Driver,s license:</p>
                                      
                                            <p class="prieAmount">
                                                {{(profileData?.idPassportVerificationTime | date: 'MMM d, y, h:mm:ss a') || '--'}}
                                            </p>
                                        </div>
                                        <div class="priceTag">
                                            <p class="prieList">Block:</p>
                                       
                                            <p class="prieAmount">Blocked by {{profileData?.blocksCount || 0}} people
                                            </p>
                                        </div>
                                      
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-12 col-sm-12">
                                    <div class="cover-rightmy">
                                   
                                        <div class="statusTrade-profile" style="width: 100%; ">
                                   
                                            <div class=" text-center pt-2 pb-1  user-block-header tab00">
                                                <h5>Trust {{profileData?.userName}}</h5>
                                            </div>
                                            You do not currently trust {{profileData?.userName }}
                                            <span class="user-block" (click)="blockUser()"><i class="fa fa-times"
                                                    aria-hidden="true"></i> Block {{profileData?.userName }}</span>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>


                            <!-- other advertisement by trader -->
                            <div>
                                <h3 class="advertisement-header"><strong>Other advertisements by {{profileData?.userName}}</strong></h3>
                                <ul>
                                    <li style="color: #de8011; margin-top: 10px; font-size: 25px">Buy Bitcoin Online
                                    </li>
                                </ul>
                                <div class="table-responsive mt-4 pb-5">
                                    <table class="table after-logintabel action">
                                        <thead>
                                            <tr>
                                                <th scope="col">Seller</th>
                                                <th scope="col">Payment Method</th>
                                                <th scope="col">Price/BTC</th>
                                                <th scope="col">Limits</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let order of buyOrderArr | paginate : {itemsPerPage:5, currentPage: pageNumberSell, totalItems: buyTotal, id:'orderBuy'}; let i = index">
                                                <td>{{order?.userName || '--'}}</td>
                                                <td>{{order?.paymentType}}</td>
                                                <td>{{order?.price || '--'}}</td>
                                                <td>{{order?.minValue}} {{order?.maxValue}} {{order?.fiatCoin}}</td>
                                                <td>
                                                    <div class="buy-sellbtb">
                                                        <button class="btn btn-table" type="button"
                                                            (click)="navigateTo('p2p-buy', order, 'buy')">BUY</button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="buyOrderArr?.length == 0" class="date">
                                                <td colspan="5" class="space"> <b> No Record Found!</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngIf="buyTotal > 5" class="view">
                                        <p>
                                            <pagination-controls id="orderBuy"
                                                (pageChange)="buyPaginationEvent($event)"></pagination-controls>
                                        </p>
                                    </div>
                                </div>

                                <ul>
                                    <li style="color: #de8011; margin-top: 28px; font-size: 25px">Sell Bitcoin Online
                                    </li>
                                </ul>
                                <div class="table-responsive mt-4 pb-5">
                                    <table class="table after-logintabel action">
                                        <thead>
                                            <tr>
                                                <th scope="col">Seller</th>
                                                <th scope="col">Payment Method</th>
                                                <th scope="col">Price/BTC</th>
                                                <th scope="col">Limits</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let order of sellOrderArr | paginate: { itemsPerPage: 5, currentPage: pageNumberBuy, totalItems:sellTotal , id:'orderSell' } ; let i=index ">
                                                <td>{{order?.userName || '---'}}</td>
                                                <td>{{order?.paymentType}}</td>
                                                <td>{{order?.price || '--'}}</td>
                                                <td>{{order?.minValue}} {{order?.maxValue}} {{order?.fiatCoin}}</td>
                                                <td>
                                                    <div class="buy-sellbtb">
                                                        <button class="btn btn-table" type="button"
                                                            style="background: #e5565f; border-color: #e5565f"
                                                            (click)="navigateTo('p2p-buy',order,'sell')">SELL
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="data" *ngIf="sellOrderArr?.length == 0">
                                                <td colspan="5" class="space"><b>No Record Found!</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngIf="sellTotal > 5" class="view">
                                        <p>
                                            <pagination-controls id='orderSell'
                                                (pageChange)="sellPaginationEvent($event)">
                                            </pagination-controls>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- end other advertisement by trader -->


                            <!-- feedback section -->
                            <div *ngIf="feedbackTotal > 0" class="wrapper center-block" style="padding: 22px;">
                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                    <p class="prieList">Feedback left by user</p>
                                    <div *ngFor="let item of feedbackData" class="panel panel-default">
                                        <div role="tabpanel">
                                            <div class="panel-body00">
                                                <div class="feedLike">
                                                    <div>
                                                        <img *ngIf="item?.feedbackStatus == 'NUTRAL'"
                                                            src="assets/images/neutral_icon.png" alt="like image">
                                                        <img *ngIf="item?.feedbackStatus == 'POSITIVE'"
                                                            src="assets/images/like_icon.png" alt="like image">
                                                        <img *ngIf="item?.feedbackStatus == 'NEGATIVE'"
                                                            src="assets/images/dislike_icon.png" alt="like image">
                                                    </div>
                                                    <div class="tradeFeed">
                                                        <p class="niceTrade" style="margin: 0;font-size: 18px;">
                                                            {{item?.feedbackMessage || '---'}}</p>
                                                        <p class="tradeTime"
                                                            style="margin: 0;font-size: 12px;color: #aaaaaa;">
                                                            {{item?.createTime | date: 'MMM d, y, h:mm:ss a'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a class="see-feedback" (click)="seeFeedback()">See more feedback for
                                        {{profileData?.userName }}</a>
                                </div>
                            </div>
                            <!-- end feedback section -->
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>