import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/header/header.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { RegisterComponent } from './pages/register/register.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { SupportComponent } from './pages/support/support.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { FaqComponent } from './pages/faq/faq.component';
import { GoogleAuthenticationFirstPageComponent } from './pages/google-authentication-first-page/google-authentication-first-page.component';
import { GoogleAuthenticationSecondPageComponent } from './pages/google-authentication-second-page/google-authentication-second-page.component';
import { GoogleAuthenticationThirdPageComponent } from './pages/google-authentication-third-page/google-authentication-third-page.component';
import { GoogleAuthenticationFourthPageComponent } from './pages/google-authentication-fourth-page/google-authentication-fourth-page.component';
import { SmsAuthenticationPageComponent } from './pages/sms-authentication-page/sms-authentication-page.component';
import { MyWalletComponent } from './pages/my-wallet/my-wallet.component';
import { DepositHistoryComponent } from './pages/deposit-history/deposit-history.component';
import { EmailVerificationPageComponent } from './pages/email-verification-page/email-verification-page.component';
import { MarketInfoComponent } from './pages/market-info/market-info.component';
import { SecurityComponent } from './pages/security/security.component';
import { GoogleAuthComponent } from './pages/google-auth/google-auth.component';
import { AccountActivityComponent } from './pages/account-activity/account-activity.component';
import { HomeBeforeLoginComponent } from './pages/home-before-login/home-before-login.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { KycComponent } from './pages/kyc/kyc.component';
import { P2pExchangeComponent } from './pages/p2p-exchange/p2p-exchange.component';
import { QuickSellComponent } from './pages/quick-sell/quick-sell.component';
import { CreateAdvertisementComponent } from './pages/create-advertisement/create-advertisement.component';
import { WithdrawHistoryComponent } from './pages/withdraw-history/withdraw-history.component';
import { LoginHistoryComponent } from './pages/login-history/login-history.component';
import { DisableSmsComponent } from './pages/disable-sms/disable-sms.component';
import { LoginActivityComponent } from './pages/login-activity/login-activity.component';
import { SecurityActivityComponent } from './pages/security-activity/security-activity.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { Register2Component } from './pages/register2/register2.component';
import { AddAccountComponent } from './pages/add-account/add-account.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { SellBitcoinComponent } from './pages/sell-bitcoin/sell-bitcoin.component';
import { CloseTradeComponent } from './pages/close-trade/close-trade.component';
import { CancelTradeComponent } from './pages/cancel-trade/cancel-trade.component';
import { CancelContactExchangeComponent } from './pages/cancel-contact-exchange/cancel-contact-exchange.component';
import { ContactExchangeComponent } from './pages/contact-exchange/contact-exchange.component';
import { DashboardTradesComponent } from './pages/dashboard-trades/dashboard-trades.component';
import { P2pBuyCoinComponent } from './pages/p2p-buy-coin/p2p-buy-coin.component';
import { AdvancedExchangeComponent } from './pages/advanced-exchange/advanced-exchange.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { KyclistComponent } from './pages/kyclist/kyclist.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { BuySellComponent } from './pages/buy-sell/buy-sell.component';

import { WalletTransactionStatusComponent } from './pages/wallet-transaction-status/wallet-transaction-status.component';
import { InternalTransferHistoryComponent } from './pages/internal-transfer-history/internal-transfer-history.component';
import { EscrowTranferHistoryComponent } from './pages/escrow-tranfer-history/escrow-tranfer-history.component';
import { TransferComponent } from './pages/transfer/transfer.component';
import { AdminChatComponent } from './pages/admin-chat/admin-chat.component';
import { AdminMessageListComponent } from './pages/admin-message-list/admin-message-list.component';
import { FeesComponent } from './pages/fees/fees.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { ReferralComponent } from './pages/referral/referral.component';
import { P2pQuickBuySellComponent } from './pages/p2p-quick-buy-sell/p2p-quick-buy-sell.component';
import { AnnouncementListComponent } from './pages/announcement-list/announcement-list.component';
import { CancleOrderComponent } from './pages/cancle-order/cancle-order.component';



const routes: Routes = [
  // { path: '', redirectTo: '/home-before-login', pathMatch: 'full' },
  { path: '', component: HomeBeforeLoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'privacypolicy', component: PrivacypolicyComponent },
  { path: 'support', component: SupportComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'advanced-exchange', component: AdvancedExchangeComponent },
  { path: 'google-authentication-first-page', component: GoogleAuthenticationFirstPageComponent },
  { path: 'google-authentication-second-page', component: GoogleAuthenticationSecondPageComponent },
  { path: 'google-authentication-third-page', component: GoogleAuthenticationThirdPageComponent },
  { path: 'google-authentication-fourth-page', component: GoogleAuthenticationFourthPageComponent },
  { path: 'sms-authentication-page', component: SmsAuthenticationPageComponent },
  { path: 'my-wallet', component: MyWalletComponent },
  { path: 'deposit-history', component: DepositHistoryComponent },
  { path: 'email-verification-page', component: EmailVerificationPageComponent },
  { path: 'market-info', component: MarketInfoComponent },
  { path: 'security', component: SecurityComponent },
  { path: 'google-auth', component: GoogleAuthComponent },
  { path: 'account-activity', component: AccountActivityComponent },
  { path: 'my-profile', component: MyProfileComponent },
  { path: 'kyc', component: KycComponent },
  { path: 'kyclist', component: KyclistComponent },
  { path: 'p2p-exchange', component: P2pExchangeComponent },
  { path: 'quick-sell', component: QuickSellComponent },
  { path: 'create-advertisement', component: CreateAdvertisementComponent },
  { path: 'withdraw-history', component: WithdrawHistoryComponent },
  { path: 'login-history', component: LoginHistoryComponent },
  { path: 'disable-sms', component: DisableSmsComponent },
  { path: 'login-activity', component: LoginActivityComponent },
  { path: 'security-activity', component: SecurityActivityComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'register2', component: Register2Component },
  { path: 'add-account', component: AddAccountComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'sell-bitcoin', component: SellBitcoinComponent },
  { path: 'close-trade', component: CloseTradeComponent },
  { path: 'cancel-trade', component: CancelTradeComponent },
  { path: 'cancel-contact-exchange', component: CancelContactExchangeComponent },
  { path: 'contactExchange', component: ContactExchangeComponent },
  { path: 'dashboard-trades', component: DashboardTradesComponent },
  { path: 'p2p-buy/:id', component: P2pBuyCoinComponent },
  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: 'feedback/:id/:name', component: FeedbackComponent },
  { path: 'profile/:id', component: ProfileComponent },
  { path: 'buy-sell', component: BuySellComponent },
  { path: 'wallet-transaction-status/:action', component: WalletTransactionStatusComponent },
  { path: 'internal-transfer-history', component: InternalTransferHistoryComponent },
  { path: 'escrow-transfer-history', component: EscrowTranferHistoryComponent },
  { path: 'transfer', component: TransferComponent },
  { path: 'admin-chat', component: AdminChatComponent },
  { path: 'admin-message-list', component: AdminMessageListComponent },
  { path: 'fees', component: FeesComponent },
  { path: 'confirmation', component: ConfirmationComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'referral', component: ReferralComponent },
  { path: 'p2p-quick-buy-sell', component: P2pQuickBuySellComponent },
  { path: 'announcement-list', component: AnnouncementListComponent },
  {path:'cancel-order' , component:CancleOrderComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
