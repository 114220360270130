import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/header/header.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { RegisterComponent } from './pages/register/register.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { SupportComponent } from './pages/support/support.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { FaqComponent } from './pages/faq/faq.component';

import { GoogleAuthenticationFirstPageComponent } from './pages/google-authentication-first-page/google-authentication-first-page.component';
import { GoogleAuthenticationSecondPageComponent } from './pages/google-authentication-second-page/google-authentication-second-page.component';
import { GoogleAuthenticationThirdPageComponent } from './pages/google-authentication-third-page/google-authentication-third-page.component';
import { GoogleAuthenticationFourthPageComponent } from './pages/google-authentication-fourth-page/google-authentication-fourth-page.component';
import { SmsAuthenticationPageComponent } from './pages/sms-authentication-page/sms-authentication-page.component';
import { MyWalletComponent } from './pages/my-wallet/my-wallet.component';
import { DepositHistoryComponent } from './pages/deposit-history/deposit-history.component';
import { EmailVerificationPageComponent } from './pages/email-verification-page/email-verification-page.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MarketInfoComponent } from './pages/market-info/market-info.component';
import { SecurityComponent } from './pages/security/security.component';
import { GoogleAuthComponent } from './pages/google-auth/google-auth.component';
import { AccountActivityComponent } from './pages/account-activity/account-activity.component';
import { HomeBeforeLoginComponent } from './pages/home-before-login/home-before-login.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { KycComponent } from './pages/kyc/kyc.component';
import { P2pExchangeComponent } from './pages/p2p-exchange/p2p-exchange.component';
import { QuickSellComponent } from './pages/quick-sell/quick-sell.component';
import { CreateAdvertisementComponent } from './pages/create-advertisement/create-advertisement.component';
import { WithdrawHistoryComponent } from './pages/withdraw-history/withdraw-history.component';
import { LoginHistoryComponent } from './pages/login-history/login-history.component';
import { DisableSmsComponent } from './pages/disable-sms/disable-sms.component';
import { LoginActivityComponent } from './pages/login-activity/login-activity.component';
import { SecurityActivityComponent } from './pages/security-activity/security-activity.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { Register2Component } from './pages/register2/register2.component';
import { AddAccountComponent } from './pages/add-account/add-account.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { SellBitcoinComponent } from './pages/sell-bitcoin/sell-bitcoin.component';
import { CloseTradeComponent } from './pages/close-trade/close-trade.component';
import { CancelTradeComponent } from './pages/cancel-trade/cancel-trade.component';
import { CancelContactExchangeComponent } from './pages/cancel-contact-exchange/cancel-contact-exchange.component';
import { ContactExchangeComponent } from './pages/contact-exchange/contact-exchange.component';
import { DashboardTradesComponent } from './pages/dashboard-trades/dashboard-trades.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import {NgxPaginationModule} from 'ngx-pagination';
import { P2pBuyCoinComponent } from './pages/p2p-buy-coin/p2p-buy-coin.component';
import {FormsModule ,ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceService } from './service.service';
import { MyDatePickerModule } from 'mydatepicker';
import { AdvancedExchangeComponent } from './pages/advanced-exchange/advanced-exchange.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { KyclistComponent } from './pages/kyclist/kyclist.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DatePipe } from '@angular/common';
import { BuySellComponent } from './pages/buy-sell/buy-sell.component';
import { AmChartsModule } from "@amcharts/amcharts3-angular";
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ClipboardModule } from 'ngx-clipboard';

import { WalletTransactionStatusComponent } from './pages/wallet-transaction-status/wallet-transaction-status.component';
import { EscrowTranferHistoryComponent } from './pages/escrow-tranfer-history/escrow-tranfer-history.component';
import { InternalTransferHistoryComponent } from './pages/internal-transfer-history/internal-transfer-history.component';
import { TransferComponent } from './pages/transfer/transfer.component';
import { AdminChatComponent } from './pages/admin-chat/admin-chat.component';
import { AdminMessageListComponent } from './pages/admin-message-list/admin-message-list.component';
import { FeesComponent } from './pages/fees/fees.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { ReferralComponent } from './pages/referral/referral.component';
import { P2pQuickBuySellComponent } from './pages/p2p-quick-buy-sell/p2p-quick-buy-sell.component';
import { AnnouncementListComponent } from './pages/announcement-list/announcement-list.component';
import { CancleOrderComponent } from './pages/cancle-order/cancle-order.component';
import { FiatWalletComponent } from './pages/my-wallet/fiat-wallet/fiat-wallet.component';
import { WalletTradingLimitComponent } from './pages/my-wallet/wallet-trading-limit/wallet-trading-limit.component';
import { MyTradingReportComponent } from './pages/my-wallet/my-trading-report/my-trading-report.component';
import { TradingAccountComponent } from './pages/my-wallet/trading-account/trading-account.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    ForgotpasswordComponent,
    ResetpasswordComponent,
    RegisterComponent,
    AboutusComponent,
    PrivacypolicyComponent,
    SupportComponent,
    ContactusComponent,
    FaqComponent,
  
    GoogleAuthenticationFirstPageComponent,
    GoogleAuthenticationSecondPageComponent,
    GoogleAuthenticationThirdPageComponent,
    GoogleAuthenticationFourthPageComponent,
    SmsAuthenticationPageComponent,
    MyWalletComponent,
    DepositHistoryComponent,
    EmailVerificationPageComponent,
    MarketInfoComponent,
    SecurityComponent,
    GoogleAuthComponent,
    AccountActivityComponent,
    HomeBeforeLoginComponent,
    MyProfileComponent,
    KycComponent,
    P2pExchangeComponent,
    QuickSellComponent,
    CreateAdvertisementComponent,
    WithdrawHistoryComponent,
    LoginHistoryComponent,
    DisableSmsComponent,
    LoginActivityComponent,
    SecurityActivityComponent,
    NotificationsComponent,
    Register2Component,
    AddAccountComponent,
    ChangePasswordComponent,
    SellBitcoinComponent,
    CloseTradeComponent,
    CancelTradeComponent,
    CancelContactExchangeComponent,
    ContactExchangeComponent,
    DashboardTradesComponent,
    P2pBuyCoinComponent,
    AdvancedExchangeComponent,
    TermsConditionsComponent,
    KyclistComponent,
    FeedbackComponent,
    ProfileComponent,
    BuySellComponent,

    WalletTransactionStatusComponent,

    EscrowTranferHistoryComponent,

    InternalTransferHistoryComponent,

    TransferComponent,

    AdminChatComponent,

    AdminMessageListComponent,

    FeesComponent,

    FooterComponent,

    ConfirmationComponent,

    ReferralComponent,

    P2pQuickBuySellComponent,

    AnnouncementListComponent,
    CancleOrderComponent,
    FiatWalletComponent,
    WalletTradingLimitComponent,
    MyTradingReportComponent,
    TradingAccountComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxCaptchaModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      maxOpened :1,
      preventDuplicates: true,
      positionClass: 'toast-top-center'
    }), // ToastrModule added
    NgxSpinnerModule,
    HttpClientModule,
    MyDatePickerModule,
    NgxPaginationModule,
    RecaptchaModule,
    AmChartsModule,
    NgxQRCodeModule,
    ClipboardModule
  
  ],
  providers: [ { provide: HTTP_INTERCEPTORS, useClass: ServiceService, multi: true }, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
