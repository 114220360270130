import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  contactForm: FormGroup;
  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.formValidation()
  }
 // Form Validation 
 formValidation(){
  this.contactForm = new FormGroup({
    'name': new FormControl('',[Validators.required,Validators.pattern(/^[a-z A-Z]*$/i)]),
    'email': new FormControl('',[Validators.required,Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
    'message': new FormControl('',[Validators.required])
  })
}

// Contact Us Functionality
contactFunc(){
  let data={
    'name': this.contactForm.value.name,
    'email': this.contactForm.value.email,
    'message': this.contactForm.value.message,
  }
this.service.showSpinner();
this.service.postCandyPixelForms('static/submit-contact-us-request',data).subscribe(res=>{
  this.service.hideSpinner();
  if (res['status']==200){
    this.service.showSuccessMessage('Successfully submitted,we will get back to you shortly!')
    this.contactForm.reset();
  }
}, err=>{
  this.service.hideSpinner();
})
}
}
