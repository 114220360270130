<!DOCTYPE html>

<body class="static-contant">
    <section>
        <div class="container1440">
            <div class="security">
                <h6 class="google">Security > Google Authentication</h6>
                <div class="e-bled">
                    <h1>Enabled Google Authenticator</h1>
                </div>
                <div class="download-app">
                    <ul>
                        <li class="active">Download App
                            <p class="active">1</p>
                        </li>
                        <li class="active scan">
                            Scan QR Code
                            <p class="active">2</p>
                        </li>
                        <li>
                            Backup Key
                            <p class="active">3</p>
                        </li>
                        <li>
                            Enabled Google Authenticator
                            <p>4</p>
                        </li>
                    </ul>
                </div>

                <div class="step">
                    <h3>Step 3</h3>
                    <p>Please save this key on paper. This key will allow you to recover<br>
                        your Google Authenticatorin case of photo loss.</p>
                </div>
                <div class="scan_code">
                    <div class="scan"><img src="assets/images/step-3.png" alt=""></div>
                    <div class="are-you">
                        <p>Resetting your Google Authentication requires opening a support
                            ticket and takes atleast 7 Days to process.</p>
                        <button>AJBCZFG</button>
                    </div>
                </div>
                <div class="pre"><a (click)="back()">
                        << Prev Step</a> </div> <div class="send_button">
                            <button (click)="next()">NEXT</button>
                </div>
            </div>
        </div>
    </section>
</body>