<div class="static-contant">

   <section class="wraper">
      <!-- <div class="banner-section" style="background-image: url({{bannerData?.image}});"> -->
      <!-- <div class="container1440 bannerContent">
            <div class="banner-text">
               <h1>{{bannerData?.title}} </h1>
               <p> {{bannerData?.description}}
               </p>
               <a *ngIf=(!isLoggedIn) (click)="register()" class="theme-btn" style="cursor: pointer">Register Now</a>
            </div>
         </div> -->
      <!-- <div class="container1440"> -->

      <!-- banner slider section -->
      <div class="slick_slider">
         <div class="banner_slider slider">
            <div *ngFor="let data of bannerData; let i=index">
               <div class="banner-section" style="background-image: url({{data?.image}});">
                  <div class="container1440 bannerContent">
                     <div class="banner-text">
                        <h1 class="text-elipsis">{{data?.title}}</h1>
                        <p class="text-elipsis"> {{data?.description}}
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- end banner slider section -->

      <!-- </div> -->
      <!-- </div> -->


      <section>
         <!-- coin slider section -->
         <div class="blue-section1 p-3">
            <div class="container1440">
               <div *ngIf="coinList" class="slick_slider">
                  <div class="main_slider slider " style="max-height:13em">
                     <div class="custom-width custom-margin" *ngFor="let data of coinList; let i=index">
                        <div class="commonstyle bacground-img"
                           [ngClass]="(data.marketPriceInUsd24HourBefore > 0) ? 'bacground-img1' : 'bacground-img'">
                           <div class="card-img">
                              <img [src]="data.coinImage" alt="" class="coinImage">
                              <div class="uppertext">
                                 <p class="leftcoin">{{data?.coinFullName}}</p>
                                 <div>{{(data?.last_updated | date:'medium') || '--'}}</div>
                              </div>
                           </div>
                           <div class="text-cenetr price-div">
                              <p class="coinvalue red">{{data?.price}} USD</p>
                              <label class="rytvalue redvalue">
                                 <span
                                    [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green' : 'landing-red' "
                                    style=" display: contents">{{data?.marketPriceInUsd24HourBefore}} %
                                 </span>
                              </label>
                           </div>
                           <div class="uppertext text-cenetr">
                              <div class="text-center">24h Volume: ${{data.volume_24h}} </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <!-- end coin slider section -->

         <div class="col-lg-12 col-md-12 col-sm-12 investorIsnti">
            <div class="investor py-4">
               <!-- announcement section -->


               <!-- <div class="scrollText row">
                  <div class="col-md-1 col-sm-1">
                     <i class="fa fa-bullhorn " aria-hidden="true"></i>
                  </div>
                  <div class="col-md-8 col-sm-6 textScroll">
                     <marquee direction="left">
                        <span>
                           {{announcementlist}}
                        </span>
                     </marquee>
                  </div>
                  <div class="col-md-1 col-sm-1">
                     <span class="more cursor-pointer" (click)="navigateTo()">More ></span>
                  </div>
               </div> -->

               <div class="announcement-section scrollText">
                  <div class="mr-2">
                     <i class="fa fa-bullhorn " aria-hidden="true"></i>
                  </div>
                  <div class="textScroll">
                     <marquee direction="left">
                        <span (click)="navigateTo()">
                           {{announcementlist}}
                        </span>
                     </marquee>
                  </div>
                  <div class="ml-2">
                     <span class="more cursor-pointer" (click)="navigateTo()">More</span>
                  </div>
               </div>
               <!-- end announcement section -->



               <!-- <div class="investorIsnti">
                  <div class="investor">
                     <div class="col-sm-10 offset-sm-1">
                        <ul style="cursor: pointer;" class="upperPermotionslider">
                           <li><img src="assets/images/permotion6.png" alt="img" class="slider-img"></li>
                           <li><img src="assets/images/permotion5.jpeg" alt="img" class="slider-img"></li>
                           <li><img src="assets/images/permotion4.jpeg" alt="img" class="slider-img"></li>
                           <li><img src="assets/images/permotion3.png" alt="img" class="slider-img"></li>
                           <li><img src="assets/images/permotion2.jpeg" alt="img" class="slider-img"></li>
                           <li><img src="assets/images/permotion1.jpeg" alt="img" class="slider-img"></li>
                        </ul>
                     </div>
                  </div>
               </div> -->
               <div class="investorIsnti">
                  <div class="investor">
                     <div class="col-sm-10 offset-sm-1">
                        <div class="slick_slider">
                           <div class="promotional_slider slider">
                              <div *ngFor="let item of permotionList; let i=index">
                                 <img class="slider-img" [src]="item?.image" alt="">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>


            </div>
         </div>
      </section>
      <div class="exchenge-section">
         <div class="container1440">
            <div class="exchenge-heading">
               <!-- <h6>Exchange</h6> -->
               <!-- <p>Join the world's largest crypto exchange.</p> -->
               <div>
                  <div class="topnav">
                     <div class="search-container row">
                        <div class="col-md-12 exchenge-heading">
                           <p>Join an exchange engineered for a seamless trading experience</p>
                        </div>
                        <div class="col-md-4">
                           <form>
                              <button type="submit" class="searchButton"><i class="fa fa-search"
                                    aria-hidden="true"></i></button> <input class="searchInput" type="text"
                                 placeholder="Search by coin name" (keyup)="searchCoinList($event)">
                           </form>
                        </div>

                     </div>

                  </div>
               </div>
               <!-- <div class="" style="color: #fff;">
                  <div class="row headerContent  py-2">
                     <div class="col-6  col-md-2 pr-0">Market</div>
                     <div class="col-6  col-md-2 pr-0">Price</div>
                     <div class="col-6  col-md-2 pr-0">Volume</div>
                     <div class="col-6  col-md-2 pr-0">24H Change</div>
                     <div class="col-6  col-md-2 pr-0">Price trend (3D)</div>
                     <div class="col-6  col-md-2  action-tab">Action</div>

                  </div>

                  <div class="row py-lg-3 py-2 tableText" *ngFor="let data of coinList; let i=index"
                     (click)="buysell()">
                     <div class="col-6 col-md-2 ellysys"><span class="bitcointable"><img [src]="data.coinImage"
                              class="coinImage" alt=""></span><span class="bitcoin-btc">{{data.coinFullName}}</span>
                        <span class="market-usdt"> /USDT</span>
                     </div>
                     <div class="col-6  col-md-2 ">${{data?.price}}
                        <div
                           [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                           {{data?.marketPriceInUsd24HourBefore}} %</div>
                     </div>
                     <div class="col-6  col-md-2 ">${{data?.price}}
                        <div
                           [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                           {{data?.marketPriceInUsd24HourBefore}} %</div>
                     </div>
                     <div
                        [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green col-md-2 col-3 ' : 'landing-red col-md-2 col-3  ' ">
                        {{data?.marketPriceInUsd24HourBefore}} %</div>

                     <div class="col-12  col-md-2 d-flex  ">
                        <span style="     border: 1px solid white;">
                           <img style="height: 68px; width: 164px;"
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM6ZIxBUGf4b2i7PqRMdrySgYrFNroN8IxRw&usqp=CAU"
                              alt="">
                        </span>
                     </div>

                     <div class="col-12  col-md-2 d-flex  p-0">
                        <div class="actionButton"> <button>Trade</button></div>
                     </div>
                  </div>
               </div> -->

               <div class="table-responsive mt-4">
                  <table class="table after-logintabel action table-striped" aria-describedby="">
                     <thead>
                        <tr>
                           <th id="">Market</th>
                           <th id="">Price</th>
                           <th id="">Volume</th>
                           <th id="">24H Change</th>
                           <!-- <th id="">Price Trend (3D)</th> -->
                           <th id="">Action</th>
                           <!-- <th id=""><i class="fa fa-star-o"></i></th> -->
                        </tr>
                     </thead>
                     <tbody>
                        <!-- <tr *ngFor="let data of coinList; let i=index"> -->
                        <tr *ngFor="let data of coinListSearchData; let i=index">
                           <td>
                              <span class="bitcointable"><img [src]="data.coinImage" alt="img" class="coinImage">
                              </span><span class="bitcoin-btc mr-0">{{data.coinFullName}}</span>
                              <span class="market-usdt"> / USDT</span>
                           </td>
                           <td>
                              ${{data?.price}}
                              <div
                                 [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                                 {{data?.marketPriceInUsd24HourBefore}}%</div>
                           </td>
                           <td>
                              ${{data?.price}}<div
                                 [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green dataChanges deskhide' : 'landing-red dataChanges deskhide' ">
                                 {{data?.marketPriceInUsd24HourBefore }}%</div>
                           </td>

                           <td>
                              <div
                                 [ngClass]=" (data.marketPriceInUsd24HourBefore > 0) ? 'landing-green col-md-1 col-3 ' : 'landing-red col-md-1 col-3  ' ">
                                 {{data?.marketPriceInUsd24HourBefore | number : '1.6-6'}}%</div>
                           </td>
                           <!-- <td>
                                    <img style="height: 68px; width: 164px;"
                                       src="../../../assets/images/graph.png"
                                       alt="">
                             </td> -->
                           <td>
                              <div class="buy-sellbtb">
                                 <button class="btn btn-table" type="button"
                                    style="background: #ee374d; border-color: #ee374d;"
                                    (click)="navigateToTrade()">TRADE
                                 </button>
                              </div>
                           </td>

                           <!-- <td><div class="actionButton actionButtonStar"> <i class="fa fa-star-o star-clicked"></i></div></td> -->
                        </tr>
                        <!-- <tr class="data" *ngIf="sellOrderArr?.length == 0">
                              <td colspan="5" class="space"><strong>No record found</strong></td>
                          </tr> -->

                     </tbody>
                  </table>
                  <!-- <div *ngIf="sellTotal > 5" class="view">
                      <p>
                          <pagination-controls id='orderSell' (pageChange)="sellPaginationEvent($event)">
                          </pagination-controls>
                      </p>
                  </div> -->
               </div>
            </div>
         </div>
      </div>
      <div class="why-choosesection">
         <div class="container1440">
            <div class="why-chs">
               <h6>Why Choose Us?</h6>
               <p class="my-3">Buy, Sell & Trade Bitcoin, Ethereum, Ripple, Litecoin and more crypto currencies.<a [routerLink]="['advanced-exchange']"> Start trading now!</a>

               </p>
            </div>
            <div class="cover-rowsection">
               <div class="row mt-3">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                     <div class="main-coversecty">
                        <div class="cover-securty mb-3 col-md-12">
                           <div class="secuty-pic">
                              <img src="assets/images/security.png" alt="">
                           </div>
                           <div class="secuty-heading">
                              <h6>Security</h6>
                              <p>We use the latest technologies to keep your funds safe, and stay ahead of
                                 vulnerabilities and exploitation attempts.
                              </p>
                           </div>
                        </div>
                        <div class="cover-securty mb-3 col-md-12">
                           <div class="secuty-pic">
                              <img src="assets/images/liq.png" alt="">
                           </div>
                           <div class="secuty-heading">
                              <h6>Liquidity</h6>
                              <p>We offer you a deep pool of liquidity, thanks to our established relationships with
                                 top-tier banks and liquidity providers.

                              </p>
                           </div>
                        </div>
                        <div class="cover-securty mb-3 col-md-12">
                           <div class="secuty-pic">
                              <img src="assets/images/search.png" alt="">
                           </div>
                           <div class="secuty-heading">
                              <h6>Transparent Transaction</h6>
                              <p>Using multi-party computing we are able to offer fast round-the-clock withdrawals while
                                 maintaining our rigorous security standards.

                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12">
                     <div class="main-coversecty main-cover2">
                        <div class="cover-securty mb-3 col-md-12">
                           <div class="secuty-pic">
                              <img src="assets/images/Bitmap.png" alt="">
                           </div>
                           <div class="secuty-heading">
                              <h6>Reasonable Commissions</h6>
                              <p>Using multi-party computing we are able to offer fast round-the-clock withdrawals while
                                 maintaining our rigorous security standards with the less commission.

                              </p>
                           </div>
                        </div>
                        <div class="cover-securty mb-3 col-md-12">
                           <div class="secuty-pic">
                              <img src="assets/images/Bitmap2.png" alt="">
                           </div>
                           <div class="secuty-heading">
                              <h6>Easy to Access</h6>
                              <p>Trading on the platform is a super-fast experience you’ll fall in love with! We’ve
                                 built technology used by Millions, and that experience has helped us build this
                                 powerful exchange.

                              </p>
                           </div>
                        </div>
                        <div class="cover-securty mb-3 col-md-12">
                           <div class="secuty-pic">
                              <img src="assets/images/24.png" alt="">
                           </div>
                           <div class="secuty-heading">
                              <h6>24/7 Support</h6>
                              <p>Have a problem or just need to ask us something? Our Customer support team are
                                 available to help 24/7.
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="get-started">
         <div class="container1440">
            <div class="row mt-2">
               <div class="col-lg-5 col-md-12 col-sm-12">
                  <div class="laptop">
                     <img src="assets/images/macbook.png" alt="">
                  </div>
               </div>
               <div class="col-lg-7 col-md-12 col-sm-12">
                  <div class="satrt-section">
                     <h6>Get Started In Minutes</h6>
                     <p>Buy, Sell & Trade Bitcoin, Ethereum, Ripple, Litecoin and more crypto currencies in India. Start
                        trading now!
                     </p>
                  </div>
                  <div class="row">
                     <div class="col-lg-4 col-md-12 col-sm-12">
                        <div class="round-images">
                           <img src="assets/images/round1.png" alt="">
                           <h6>Registration</h6>
                           <p>All your data is encrypted and stored on secure cloud servers.</p>
                        </div>
                     </div>
                     <div class="col-lg-4 col-md-12 col-sm-12">
                        <div class="round-images">
                           <img src="assets/images/round2.png" alt="">
                           <h6>Finalize Account</h6>
                           <p>Submit your personal details. The more you add, the more you can buy.</p>
                        </div>
                     </div>
                     <div class="col-lg-4 col-md-12 col-sm-12">
                        <div class="round-images">
                           <img src="assets/images/round3.png" alt="">
                           <h6>Buy Cryptocurrency</h6>
                           <p>Choose the cryptocurrency you want to transact and the amount you want to transact.</p>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </div>
      <div *ngIf="institutionalList?.length" class="col-lg-12 col-md-12 col-sm-12 investorIsnti">
         <div class="investor py-4">
            <!-- <h6>Institutional Investors</h6> -->
            <div class="col-sm-10 offset-sm-1">
               <div class="slick_slider">
                  <div class="institutional_slider slider">
                     <div *ngFor="let item of institutionalList; let i=index">
                        <img class="slider-img" [src]="item?.image" alt="">
                     </div>
                  </div>
               </div>
               <!-- <ul style="cursor: pointer;" class="lowerPermotionSlider">
                  <li (click)="navigateToUrl('huobi')"><img src="assets/images/permotion6.png" alt="img"
                        class="slider-img">
                  </li>
                  <li (click)="navigateToUrl('okblockchain')"><img src="assets/images/permotion5.jpeg" alt="img"
                        class="slider-img"></li>
                  <li (click)="navigateToUrl('huobi')"><img src="assets/images/permotion4.jpeg" alt="img"
                        class="slider-img"></li>
                  <li (click)="navigateToUrl('nodecapital')"><img src="assets/images/permotion3.png" alt="img"
                        class="slider-img"></li>
                  <li (click)="navigateToUrl('genesis')"><img src="assets/images/permotion2.jpeg" alt="img"
                        class="slider-img"></li>
                  <li (click)="navigateToUrl('huobi')"><img src="assets/images/permotion2.jpeg" alt="img"
                        class="slider-img">
                  </li>
                  <li (click)="navigateToUrl('plumventures')"><img src="assets/images/permotion1.jpeg" alt="img"
                        class="slider-img"></li>
               </ul> -->
            </div>
         </div>
      </div>
   </section>

   <!-- <div class="cooki-div" *ngIf="showCookie">
      <i class="fa fa-times" aria-hidden="true" (click)="closeCookie()" style="cursor: pointer;"></i>
      <div class="container-fluid">
         <div class="row align-items-center">
            <div class="col-md-12 col-lg-7 col-xl-8">
               We use cookies to personalise content and ads, to provide social media features and to analyse our
               traffic. We also share information about your use of our site with our social media, advertising and
               analytics partners.
            </div>
            <div class="col-md-12 col-lg-5 col-xl-4">
               <button class="btn ml-3" (click)="closeCookie()">Disable All</button> <button class="btn ml-3"
                  (click)="closeCookie()">Accept All</button>
            </div>
         </div>
      </div>
   </div> -->
</div>
<app-footer></app-footer>