import { Component, OnInit } from '@angular/core';
import { repeat } from 'rxjs/operators';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms'
declare var $: any;
@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})
export class TransferComponent implements OnInit {
  constructor(private _service: ServiceService) { }
  coinList: any
  coinName: any
  coinNameEscrow: any
  inetrnalTranferForm: FormGroup;
  escrowForm: FormGroup
  lockList: any = []
  ngOnInit() {
    window.scrollTo(0, 0)
    this.getCoinTypeList()
    this.formValidation()
    this.formEscrow()
    this.lockList = [{ id: 1 }, { id: 3 }, { id: 7 }, { id: 15 }, { id: 30 }]
 
  }
  formValidation() {
    this.inetrnalTranferForm = new FormGroup({
      'toCoinAddress': new FormControl('', Validators.required),
      'amount': new FormControl('', Validators.required),
      'TagId': new FormControl(''),
    })
  }
  formEscrow() {
    this.escrowForm = new FormGroup({
      'toCoinAddress': new FormControl('', Validators.required),
      'amount': new FormControl('', Validators.required),
      'TagId': new FormControl(''),
    })
  }

  getCoinTypeList() {
    let url = 'wallet/coin/get-coin-list'
    this._service.getCandyPixelForms(url).subscribe(response => {
      if (response['status'] == 200) {
        this.coinList = response['data']
        this.coinName = this.coinList[0].coinShortName
        this.coinNameEscrow = this.coinList[0].coinShortName
        console.log(response)
      }
    }, err => {
      this._service.unauthorizedApi(err['status'])
    }
    )
  }

  transferAddress:any
  transferCoinBaln:any
  SubmitTransfer(){
    this.transferAddress = this.inetrnalTranferForm.value.toCoinAddress
    this.transferCoinBaln = this.inetrnalTranferForm.value.amount
    $("#internalTransferModal").modal('show')

  }
  submitConfirm() {
    let url = `wallet/wallet/transfer-internal-amount?amount=${this.inetrnalTranferForm.value.amount}&coinName=${this.coin}&randomId=${this.inetrnalTranferForm.value.toCoinAddress}`
    this._service.postApi(url, { userId: this._service.getUserId() }).subscribe(response => {
      if (response['status'] == 200) {
        this.inetrnalTranferForm.reset()
        this._service.showSuccessMessage(response['message'])
        $("#internalTransferModal").modal('hide')
      } else {
        this._service.showErrorMessage(response['message'])

      }
    }, err => {
      this._service.unauthorizedApi(err['status'])
    }
    )

  }



  lockDuraction: any = 1
  SubmitZEscrow() {
    let url = `wallet/wallet/user-to-admin-transfer`
    let input = {
      "amount": this.escrowForm.value.amount,
      "baseCoin": this.coin,
      //  "exeCoin": "string",
      //  "status": "string",
      // "TagId":this.escrowForm.value.TagId,
      "toRandomId": this.escrowForm.value.toCoinAddress,


    }
    this._service.postApi(url, input).subscribe(rsp => {
      if (rsp['status'] == 200) {
        this.escrowForm.reset()
        this._service.showSuccessMessage(rsp['message'])
      } else {
        this._service.showErrorMessage(rsp['message'])
      }
    },
      err => {
        this._service.unauthorizedApi(err['status'])
      })
  }

/** Function to restrict space */
restrictSpace(event) {
  var k = event.charCode;
  if (k === 32) return false;
}

/** Function to restrict character */
restrictChar(event) {
  var k = event.charCode;
  if (event.key === 'Backspace')
    k = 8;
  if (k >= 48 && k <= 57 || k == 8 || k == 46)
    return true;
  else
    return false;
}

totalBalance:any
coin:any=''
getCoinBalance(event) {
  this.coin = event.target.value
  let url = 'wallet/wallet/get-balance?coinName='+this.coin
  this._service.getCandyPixelForms(url).subscribe(response => {
    if (response['status'] == 200) {
       this.totalBalance = response['data']['walletBalance']
      // this.coinName = this.coinList[0].coinShortName
      // this.coinNameEscrow = this.coinList[0].coinShortName
      //console.log(response)
    }
  }, err => {
    this._service.unauthorizedApi(err['status'])
  }
  )
}

tabChange(){
  this.totalBalance=''
  this.coin=''
}

}
