import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { Router } from '@angular/router';
// declare var $: any;
// import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
// declare const AmCharts: any;
@Component({
  selector: 'app-market-info',
  templateUrl: './market-info.component.html',
  styleUrls: ['./market-info.component.css']
})
export class MarketInfoComponent implements OnInit {
  coinpair: any = [];
  coinDetail: any = {};
  execCoin: any = 'BTC';
  baseCoin: any = 'USD';
  marketdata: any;
  arr: any = [];
  basecoins = 'BTC';
  executables: any;
  tab = "BTC Market";
  baseCoinFun: string;
  baseCoinVal: string;
  myInterval: any;
  favourateCoinDiv: boolean = false;
  // slider
  coinList: any;
  currentTab: any;
  marketInfo: boolean = true;
  likeDislikeStar: any;
  dislike: boolean = false;
  like: boolean = true;
  favCoin: any;

  constructor(public service: ServiceService, public route: Router) { }

  ngOnInit() {
    this.favourite('dislike', '')
    window.scrollTo(0, 0)
    // this.getAllCoinPairFunc();
    // slider
    this.getCoinList();
    this.getPrice();
  }

  /**
   * Slider 
   */
  getPrice() {
    var getLiquiditydata = [];
    this.service.getLiquidity().subscribe((getLiquidity) => {
      console.log(getLiquidity)
      getLiquiditydata = getLiquidity['data']
      getLiquiditydata.forEach((element) => {
        var ind = this.coinList.findIndex((x) => x.coinShortName == element.symbol)
        if (ind != -1) {
          this.coinList[ind].marketPriceInUsd24HourBefore = element.quote.USD.percent_change_24h
          this.coinList[ind].marketPriceInUsdAWeekBefore = element.quote.USD.percent_change_7d
          this.coinList[ind].price = element.quote.USD.price.toFixed(4);
          this.coinList[ind].volume_24h = element.quote.USD.volume_24h
          this.coinList[ind].last_updated = element.last_updated
        }
      })
      console.log("get price from coin market cap=>", this.coinList)
    })  
  }

  // to get coin list
  getCoinList() {
    let coinShortName = ["USD","NAIRA", "ZAR" ]
    this.service.getCandyPixelForms('wallet/coin/get-coin-list').subscribe((getLiquidity) => {
      console.log("get coin pair", getLiquidity)
      if (getLiquidity['status'] == 200) {
        coinShortName.forEach(element=>{
          let index = getLiquidity['data'].findIndex(x => x.coinShortName == element)
          if (index != 1) {
            getLiquidity['data'].splice(index, 2)
            this.coinList = getLiquidity['data']
          }
        })
        console.log("get coin pair", this.coinList)
      }
    })
  }


  navigateTo() {
    this.route.navigate(['advanced-exchange']);
  }

  favourite(tab, coin) {
    console.log(tab + '-------------' + coin);
    // if(tab == 'dislike'){
    //   this.dislike = false
    //   this.like = true
    // }
    // else{
    //   this.dislike = true
    //   this.like = false
    // }
  }

  changeTab(tab) {
    this.currentTab = tab
    console.log(this.currentTab)
    // if( this.currentTab == 'marketInfo'){
    //   this.favourateCoinDiv = false
    //   this.marketInfo = true
    // }else{
    //   this.favourateCoinDiv = true
    //   this.marketInfo = false
    // }

  }

}