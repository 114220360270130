<div class="static-contant">
    <section class="wraper">
        <div class="container1440">
            <div class="pt-5 pb-5">
                <div class="row col-md-12 firstRow">
                    <div class="heading"><h2>Buy Order Closed</h2></div>
                    <div class="col-md-12 row orderClosedData">
                        <div class="ol-md-6">Completed <span class="data">0.00 USDT of 25.00 USDT</span> </div>
                        <div class="ol-md-6">Placed on <span >Sept 23 ,2021  , 10:45:50 AM</span></div>
                    </div>
                </div>
                <div class="col-md-12 firstRow">
                    <div class="col-md-12"><h4>Payment # 1 for 25.00 USDT</h4></div>
                    <div class="col-md-12"> Matched on Sept 23 ,2021 , 11:45:50 AM</div>
                </div>
                <div class="col-md-12 firstRow">
                    <div class="col-md-12"></div>
                    <div class="col-md-12"><h3 style="color:red">Cancelled</h3></div>
                    <div class="col-md-12">Seller has not recieved the payment.</div>
                </div>
                <div class="col-md-12 firstRow">
                    order Id : <span>42FsoVKllV8mHOAZNkrIAgfiq+zdcaUjXOQDKP0bOWs=</span>
                </div>
            </div>
        </div>
    </section>
</div>