<body class="static-contant pt-5">


  <section class="wraper">
    <div class="container1440">
      <div class="">

        <ul>
          <li  class="sameColor heading">Login History</li>
        </ul>
        <ul class="nav nav-pills tab-ul" id="pills-tab" role="tablist">


        </ul>
        <div class="table-responsive mt-4 pb-5">
          <table class="table after-logintabel action table-striped " aria-describedby="">
            <thead>
              <tr>
                <th id="">Date & Time</th>
                <th id="">Login From</th>
                <th id="">IP Address</th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let logindetails of loginHistoryList">
                <td>{{logindetails.createTime | date :'medium' }} </td>
                <td>{{logindetails.userAgent }}</td>

                <td>{{logindetails.ipAddress }}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </section>
</body>
<app-footer></app-footer>