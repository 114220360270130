<section class="static-contant ">
  


    <div class="newBox">
      <div class="container1440">

        <div class="market_info">
          <h3 class="usdMarket"><strong>Fee Schedule</strong></h3>
         <div class="table-responsive">
            <table class="table table-striped" aria-describedby="">
                <thead>
                  <tr>
                    <th scope="col">Coin</th>
                    <th scope="col">Trading Fee %</th>
                    <th scope="col">Taker Fee %</th>
                    <th scope="col">Maker Fee % </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of FeeArray">
                    <td>{{data.coinShortName}}</td>
                    <td>{{data.withdrawlFee}}</td>
                    <td>{{data.takerFee}}</td>
                    <td>{{data.makerFee}}</td>
                  </tr>
                 
                
                </tbody>
              </table>
         </div>
        </div>

         
      </div>
    </div>
  </section>

<app-footer></app-footer>
