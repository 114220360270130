<!DOCTYPE html>

<body class="static-contant py-0">

    <section class="ver-sec-eml pt-0">
        <div class="container1440">
         <div class="Aboutus00">
            <div class="support">
                <form [formGroup]="addAccountForm">
                <div class="form1_div pb-0">
                    <h2>Add Bank Account</h2>
                    <div class="spt_section">
                        <label>Account Number</label>
                        <input type="text" class="form-control sppt" formControlName="accountNumber" placeholder="Account Number" maxlength="20">
                        <span >
                            <small *ngIf="addAccountForm.get('accountNumber').hasError('required') &&  addAccountForm.get('accountNumber').dirty" class="danger"
                             >*Please enter Account number.</small>
                        </span>
                    </div>
                    <div class="spt_section">
                        <label>Contact Number</label>
                        <input type="text" class="form-control sppt" formControlName="contactNumber" placeholder="Contact Number">
                        <span>
                            <small
                                *ngIf="addAccountForm.get('contactNumber').hasError('required') &&  addAccountForm.get('contactNumber').dirty"
                                class="danger" padding>*Please enter contact number.</small>
                            <small
                                *ngIf="addAccountForm.get('contactNumber').hasError('pattern') &&  addAccountForm.get('contactNumber').dirty"
                                class="danger" padding>*Invalid contact number.</small>
                        </span>
                        
                    </div>
                    <div class="spt_section">
                        <label>Name :</label>
                        <input type="text" class="form-control sppt" placeholder="Name" formControlName="accountHolderName">
                        <span >
                            <small *ngIf="addAccountForm.get('accountHolderName').hasError('required') &&  addAccountForm.get('accountHolderNameme').dirty" class="danger"
                             >*Please enter account holder name.</small>
                             <small *ngIf="addAccountForm.get('accountHolderName').hasError('pattern') &&  addAccountForm.get('accountHolderName').dirty" class="danger"
                             >*Invalid name.</small>
                            
                         </span>
                    </div>

                    <div class="spt_section">
                        <label>Swift Number</label>
                        <input type="text" class="form-control sppt" placeholder="Swift Number" formControlName="swiftNumber">
                        <span >
                            <small *ngIf="addAccountForm.get('swiftNumber').hasError('required') &&  addAccountForm.get('swiftNumber').dirty" class="danger"
                             >*Please enter Swift number.</small>
                        </span>
                    </div>
                    <div class="spt_section">
                        <label>IBAN Number</label>
                        <input type="text" class="form-control sppt" placeholder="IBAN Number" formControlName="ibanNumber">
                        <span >
                            <small *ngIf="addAccountForm.get('ibanNumber').hasError('required') &&  addAccountForm.get('ibanNumber').dirty" class="danger"
                             >*Please enter IBAN number.</small>
                        </span>
                    </div>



                    <div class="send_button">
                        <button data-toggle="modal" (click)="submitFunc()">Save</button>
                    </div>
                </div>
            </form>
            </div>
         </div>
        </div>
    </section>
    <app-footer></app-footer>


    <!-- Modal -->
    <div class="modal fade" id="button_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body your_problem">
                    Your problem has been sent, one of our customer care executive will contact you soon!
                </div>
            </div>
        </div>
    </div>
</body>