<section class="mainBody">
  <div class="container1440">
    <div class="log-in">
      <div class="bg-crypt">
      </div>
      <div class="crypto-log-login left">
        <form class="login-field-sec" [formGroup]="signupForm">
          <p class="log">Register</p>
          <div class="form-group inpt-frm">
            <label for="exampleInputEmail1" class="add">Email Address :</label>
            <input type="email" class="form-control email-pass" placeholder="Enter registered email address"
              maxlength="256" formControlName="email">
            <span>
              <p *ngIf="signupForm.get('email').hasError('required') &&  signupForm.get('email').dirty" class="danger"
                padding>*Please enter registered email address.</p>
              <p *ngIf="signupForm.get('email').hasError('pattern') &&  signupForm.get('email').dirty" class="danger"
                padding>*Invalid email address.</p>
            </span>
          </div>

          <div class="form-group reg-sec">
            <label for="exampleInputPassword1" class="add">Password :</label>
            <input type="password" class="form-control email-pass" placeholder="Enter password" maxlength="16"
              type="password" formControlName="password" [type]="type_pass2">
            <span toggle="#password-field" class="fa fa-eye field-icon toggle-password pass-icon"
              *ngIf="type_pass2 == 'password'" (click)="switchEye2()"></span>
            <span toggle="#password-field" class="fa fa-eye-slash field-icon toggle-password pass-icon"
              *ngIf="type_pass2 == 'text'" (click)="switchToText2()"></span>
            <span>
              <p *ngIf="signupForm.get('password').hasError('required') &&  signupForm.get('password').dirty"
                class="danger" padding>*Please enter your password.</p>
            </span>
            <p *ngIf="signupForm.get('password').hasError('pattern')" class='danger' padding>
              *Password must be alphanumeric including special characters and atleast 8 characters.
            </p>
          </div>

          <div class="form-group reg-sec">
            <label for="exampleInputPassword1" class="add">Confirm Password :</label>
            <input type="password" class="form-control email-pass" placeholder="Retype your password" maxlength="16"
              [type]="type_pass" formControlName="confirmPassword">
            <span toggle="#password-field" class="fa fa-eye field-icon toggle-password pass-icon"
              *ngIf="type_pass == 'password'" (click)="switchEye()"></span>
            <span toggle="#password-field" class="fa fa-eye-slash field-icon toggle-password pass-icon"
              *ngIf="type_pass == 'text'" (click)="switchToText()"></span>
            <span>
              <p *ngIf="(signupForm.get('password').value != signupForm.get('confirmPassword').value) && signupForm.get('confirmPassword').dirty"
                class="danger" padding>*Confirm password does not match with password.</p>
            </span>
          </div>

          <button type="submit" class="btn register-btn"
            [disabled]="signupForm.invalid || signupForm.get('password').value != signupForm.get('confirmPassword').value"
            (click)="signupFunc()">REGISTER</button>
        </form>
        <p class="account" (click)="login()">Already have an account? <span class="accSign">Login</span></p>
      </div>
    </div>
  </div>
  <div class="modal fade" id="puzzle" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content fit-pzz">
        <div class="modal-header puzzl">
          <img src="assets/images/crypto-8.png" alt="">
        </div>
        <div class="modal-body crypto-00">
          <img src="assets/images/crypto-9.png" alt="">
        </div>
        <div class="modal-footer puzzl">
          <img src="assets/images/crypto-11.png" alt="">
          <img src="assets/images/crypto-10.png" alt="">
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>