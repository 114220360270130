<!DOCTYPE html>

<body class="static-contant">
    <section class="ver-sec-eml">
        <div class="container1440">
            <div class="security-sms">

                <div class="e-bled">
                    <h1>Disabled SMS Authenticator</h1>
                </div>

                <form class="login-sms-sec">
                    <div class="form-group">
                        <p style="color: #878691;">Login Password</p>
                        <input type="email" class="form-control email-pass-pro-sec00" placeholder="Enter password">
                    </div>
                    <p style="color: #878691;">Phone Number : +91 731****345</p>
                    <div class="sms-code">
                        <p>SMS Authenticatiaon Code</p>

                        <form>
                            <div class="form-group sms-authentication-1">
                                <input type="email" class="form-control">
                                <input type="email" class="form-control auth">
                                <input type="email" class="form-control">
                                <input type="email" class="form-control auth">
                                <input type="email" class="form-control auth1">
                                <input type="email" class="form-control">

                            </div>
                        </form>

                        <button type="submit" class="btn sms-btn">Send SMS</button>
                    </div>
                    <div class="ena-sms">
                        <button style="max-width: 350px;" type="submit" class="btn register-btn" (click)="back()">Disabled SMS
                            Authenticator</button>
                    </div>
                </form>

            </div>
        </div>
    </section>
</body>
<app-footer></app-footer>