<div class="static-contant">
    <section>
        <div class="container1440">
            <div class="Aboutus00 mt-5">
                <div class="wrapper center-block">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-sm-12 mb-3 ">
                                <h4>{{sale_type | titlecase}} Bitcoins Using {{userData?.paymentMode}}</h4>
                                <div class="p2p_buy">
                                    <div class="mainPrice">
                                        <div class="form-group row">
                                            <label class="col-5 col-sm-4 col-form-label">Price:</label>
                                            <div class="col-7 col-sm-8">
                                                <label
                                                    class=" col-form-label col-form-output">{{userData?.price | number: '.8-8'}}
                                                    {{userData?.valueInCoinType}}</label>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-5 col-sm-4 col-form-label">Payment Mode:</label>
                                            <div class="col-7 col-sm-8">
                                                <label
                                                    class=" col-form-label col-form-output">{{userData?.paymentMode || '---'}}</label>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-5 col-sm-4 col-form-label">User:</label>
                                            <div class="col-7 col-sm-8">

                                                <div *ngIf="!userLoginStatus" style="cursor: pointer; width: 60%;">
                                                    <label class=" col-form-label col-form-output"
                                                        (click)="message()">{{userData?.userName || '---'}}</label>
                                                    <p class="see-feedback" (click)="message()">See Feedback</p>
                                                </div>
                                                <div *ngIf="userLoginStatus" style="cursor: pointer;">
                                                    <label class=" col-form-label col-form-output"
                                                        (click)="seeProfile()">{{userData?.userName || '---'}}</label>
                                                    <p class="see-feedback" (click)="seeFeedback()">See Feedback</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-5 col-sm-4 col-form-label">Trade Limits:</label>
                                            <div class="col-7 col-sm-8">
                                                <label class=" col-form-label col-form-output">
                                                    {{userData?.minValue}} - {{userData?.maxValue}}
                                                    {{userData?.valueInCoinType}} <br>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <form [formGroup]="transactionForm" novalidate autocomplete="off">
                                                <p class="wish">How much you wish to {{sale_type}} ?</p>
                                                <div class="row">
                                                    <div class="col-md-6 mb-3">
                                                        <input type="number" class="form-control"
                                                            formControlName="usd_amount"
                                                            placeholder="0.00 {{userData.valueInCoinType}}" name="email"
                                                            (keyup)="calcCoinAmount($event.target.value)">
                                                    </div>
                                                    <div class="col-md-6 mb-3">
                                                        <input type="number" class="form-control"
                                                            formControlName="coin_amount"
                                                            placeholder="0.00 {{userData.coinType}}" name="email"
                                                            (keyup)="calcUsdAmount($event.target.value)">
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <textarea class="form-control" formControlName="traderMessage"
                                                            placeholder="Write your contact message and other informtion to the trader here...(Optional)"> </textarea>
                                                    </div>
                                                </div>
                                                <!-- user not logged in -->
                                                <p *ngIf="!userLoginStatus" class="regFur">Register to proceed further
                                                </p>
                                                <div *ngIf="!userLoginStatus">
                                                    <button class="btn save-btn" (click)="register()"
                                                        [disabled]="!transactionForm.valid || disableButton">Register</button>
                                                </div>
                                                <!-- user logged in -->
                                                <p></p>
                                                <div *ngIf="userLoginStatus " class="text-center">
                                                    <button class="btn save-btn" (click)="sendTradeReq()"
                                                        [disabled]="!transactionForm.valid || disableButton">Send Trade
                                                        Request</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 mb-3 ">
                                <h4>Terms of trade with {{userData.userName || '---'}}</h4>
                                <div class="p2p_buy tred_discription">
                                    <div class="mainPrice mainPrice-2">
                                        <div>
                                            <p class="prieAmount" style="word-break: break-all;">
                                                {{userData.termsOfTrade || '---'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>