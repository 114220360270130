import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  supportForm: any;
  fileData: any;
  imageUrl: any;

  constructor(public router: Router,public service: ServiceService) { 

  }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.formValidation()
  }

  formValidation(){
    this.supportForm = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'name': new FormControl('',[Validators.required,Validators.pattern(/^[a-z A-Z]*$/i)]),
      'subject': new FormControl('',[Validators.required]),
      'description': new FormControl('',[Validators.required])
    })
  }
  // Image Functionality Start Here
uploadImg(event){
  var self = this;
      if(event.target.files && event.target.files[0]){
          var type = event.target.files[0].type;
          if(type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
              this.fileData = event.target.files[0];
              this.uploadImageFunc()
              var reader = new FileReader()
              reader.onload = (e)=> {
              }
          } else {
              this.service.showErrorMessage("Select only jpg,jpeg and png file.");
              self.fileData = "";
          }
      }
}

uploadImageFunc() {
  let formdata = new FormData()
  formdata.append('file',this.fileData)
  this.service.showSpinner();
  this.service.postCandyPixelForms('account/upload-file', formdata).subscribe(res => {
    this.service.hideSpinner();
    if (res['status'] == 200) {
      this.imageUrl = res['data'];
    }
  }, err => {
    this.service.hideSpinner();
  })
}


  // Contact Us Functionality
supportFunc(){
  let data={
    'name': this.supportForm.value.name,
    'email': this.supportForm.value.email,
    'subject': this.supportForm.value.subject,
    'description': this.supportForm.value.description,
    'imageUrl': this.imageUrl
  }
this.service.showSpinner();
this.service.postCandyPixelForms('static/submit-support-ticket',data).subscribe(res=>{
  this.service.hideSpinner();
  if (res['status']==200){
    this.service.showSuccessMessage('Successfully submitted,we will get back to you shortly!')
    this.supportForm.reset();
  }
}, err=>{
  this.service.hideSpinner();
})
}
}
