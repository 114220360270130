import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-internal-transfer-history',
  templateUrl: './internal-transfer-history.component.html',
  styleUrls: ['./internal-transfer-history.component.css']
})
export class InternalTransferHistoryComponent implements OnInit {
  profiledata:any
  historyList:any
  constructor(private router:Router,private _service : ServiceService) {

   }
  ngOnInit() {
    window.scrollTo(0,0)
    this.getTransferInternalData()
  }

  getTransferInternalData (){
    this._service.showSpinner();
    let url='wallet/wallet/get-transfer-internal-for-user?userId='+ this._service.getUserId();
      this._service.getCandyPixelForms(url).subscribe(response=>{
        if(response['status']==200){
            this.historyList =  response['data']
        }
      }
      , err => {
        this._service.unauthorizedApi(err['status'])
      })
  }

}
