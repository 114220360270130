

    <!-- <section class="mainBody" style="background-image: url(assets/images/supportBack.png) "> -->
      <section class="mainBody" >

      <div class="container1440">
      <div class="log-in">       
        <div class="bg-crypt">
        
          </div>

            <div class="crypto-log-login left" >
              <form class="login-field-sec" [formGroup]="contactForm">
                <p class="log">Contact Us</p>
                <div class="form-group inpt-frm">
                  <label for="exampleInputEmail1" class="add">Name</label>
                  <input type="email" class="form-control email-pass" formControlName="name" placeholder="Enter full name">
                  <span >
                    <small *ngIf="contactForm.get('name').hasError('required') &&  contactForm.get('name').dirty" class="danger"
                     >*Please enter your full name.</small>
                     <small *ngIf="contactForm.get('name').hasError('pattern') &&  contactForm.get('name').dirty" class="danger"
                     >*Invalid name, only alphabets are acceptable.</small>
                    
                 </span>

                </div>

                <div class="form-group reg-sec">
                  <label for="exampleInputPassword1" class="add">Email</label>
                  <input type="text" class="form-control email-pass" formControlName="email" placeholder="Enter your email address">
                  <span >
                    <small *ngIf="contactForm.get('email').hasError('required') &&  contactForm.get('email').dirty" class="danger"
                     padding>*Please enter your email address.</small>
                     <small *ngIf="contactForm.get('email').hasError('pattern') &&  contactForm.get('email').dirty" class="danger"
                     padding>*Invalid email address.</small>
                 </span>
                </div>

                <div class="form-group reg-sec">
                  <label for="exampleInputPassword1" class="add">Message</label>
                  <textarea type="text" class="form-control email-pass" placeholder="Message"  formControlName="message" maxlength="512"></textarea>
                  <span >
                    <small *ngIf="contactForm.get('message').hasError('required') &&  contactForm.get('message').dirty" class="danger"
                    padding>*Please enter your message.</small>
                    </span>
                </div>
              
                
                <button type="submit" class="btn register-btn" data-toggle="modal" [disabled]="contactForm.invalid" (click)="contactFunc()" >SEND</button>
              </form>
               
             

            </div>
        </div>         
         </div> 
        
    </section>
    <app-footer></app-footer>